import { useEffect, useState } from 'react'

import { GlobeIcon } from '../icon'
import { ILanguage, IWindow } from './index'
import { LanguageCodeText, LanguageContainer } from './language-pickers.styles'

const InlineLanguagePicker = () => {
  const transifex = ((window as unknown) as IWindow)?.Transifex
  const [currentLanguage, setCurrentLanguage] = useState<ILanguage['code'] | undefined>(undefined)

  const changeLanguage = (langCode: string) => {
    if (transifex?.live) {
      transifex.live.translateTo(langCode)
      setCurrentLanguage(langCode)
    }
  }

  useEffect(() => {
    transifex?.live?.onFetchLanguages(() => {
      setCurrentLanguage(transifex.live?.detectLanguage())
    })
  }, [transifex?.live])

  if (!currentLanguage) return <></>
  return (
    <LanguageContainer>
      <GlobeIcon />
      <LanguageCodeText selected={currentLanguage === 'en'} onClick={() => changeLanguage('en')}>
        en
      </LanguageCodeText>
      <LanguageCodeText selected={currentLanguage === 'de'} onClick={() => changeLanguage('de')}>
        de
      </LanguageCodeText>
    </LanguageContainer>
  )
}

export default InlineLanguagePicker
