import styled from 'styled-components/macro'

import { ChevronIcon } from '../icon'

interface IListProps {
  title: string
  showRevealArrow?: boolean
  onClick?: (event: React.MouseEvent) => void
  color?: string
}

const StyledList = styled.ul`
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
`

const StyledListItem = styled.li`
  margin-bottom: 1.6rem;
`

const InnerListItem = styled.button`
  display: flex;
  justify-content: space-between;
  background: none;
  border: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7;
  letter-spacing: 0.75px;
`

export const ListItem = ({ title, showRevealArrow, onClick, color }: IListProps) => {
  return (
    <StyledListItem>
      <InnerListItem onClick={onClick} style={{ color }}>
        <div>{title}</div>
        {showRevealArrow && (
          <div>
            <ChevronIcon />
          </div>
        )}
      </InnerListItem>
    </StyledListItem>
  )
}

export const ListView = ({ children }: { children: React.ReactElement[] | React.ReactNode }) => {
  return <StyledList>{children}</StyledList>
}
