import Step1 from '../../../../assets/instructions-gateway-step1.png'
import Step2 from '../../../../assets/instructions-gateway-step2.png'
import Step3 from '../../../../assets/instructions-gateway-step3.png'
import Carousel from '../../../../components/carousel'
import InstructionCard from '../../../../components/instruction-card'
import { InstructionCardGrid, MobileCarousel } from './instruction-cards.styles'

const Cards = [
  (index: number) => {
    return (
      <InstructionCard
        key={`card-${index}`}
        imageSrc={Step1}
        stepText="Step 1"
        headerText="Attach Antenna"
        instructionText="Attach antenna."
      />
    )
  },
  (index: number) => {
    return (
      <InstructionCard
        key={`card-${index}`}
        imageSrc={Step2}
        stepText="Step 2"
        headerText="Power Device"
        instructionText="Use the power cord provided to plug the gateway into an outlet that is not controlled by a light switch."
      />
    )
  },
  (index: number) => {
    return (
      <InstructionCard
        key={`card-${index}`}
        imageSrc={Step3}
        imageStyle={{ marginTop: '5.5rem' }} // Fix alignment of gateway step 3 graphic
        stepText="Step 3"
        headerText="Wait for Solid Green"
        instructionText="A green light, followed by a red light with flash as the gateway attempts to connect. The light will turn solid green once connected, which may take up to 5 minutes."
      />
    )
  },
]

export const InstructionCards = () => {
  return (
    <>
      <MobileCarousel>
        <Carousel numberOfSlides={3} autoplay={false}>
          {Cards.map((card, index) => {
            return card(index)
          })}
        </Carousel>
      </MobileCarousel>
      <InstructionCardGrid>
        {Cards.map((card, index) => {
          return card(index)
        })}
      </InstructionCardGrid>
    </>
  )
}

export default InstructionCards
