import { createContext } from 'react'

const ModalContext = createContext<{
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  modalIndex: string | undefined
  setModalIndex: (index: string) => void
}>({
  showModal: false,
  setShowModal: () => {},
  modalIndex: undefined,
  setModalIndex: () => {},
})

export default ModalContext
