import theme from '../../theme'
import { INewPasswordReq, INewPasswordReqItem } from '../../utils/validators'
import { CheckIconNoCircle, CheckIconOutline, CircleIcon } from './../icon/index'
import {
  PasswordMeetsReqs,
  PasswordMustText,
  PasswordReqResult,
  PasswordReqResultIcon,
  PasswordReqResults,
} from './display-password-validation-result.styles'

const DisplayPasswordReqValidationResult = ({
  newPasswordValidationResult,
  newPasswordLength,
}: {
  newPasswordValidationResult: INewPasswordReq
  newPasswordLength: number
}) => {
  return (
    <>
      {!newPasswordValidationResult.valid ? <PasswordMustText>Password must:</PasswordMustText> : null}

      {newPasswordValidationResult.valid ? (
        <PasswordMeetsReqs>
          <CheckIconOutline fill={theme.colors.support_success} width={13} height={13} /> Password meets requirements
        </PasswordMeetsReqs>
      ) : (
        <PasswordReqResults>
          {displayPasswordReqItem(
            newPasswordValidationResult.passwordLength,
            newPasswordValidationResult.validationInitialized,
            true
          )}
          {displayPasswordReqItem(
            newPasswordValidationResult.aleastOneNumber,
            newPasswordValidationResult.validationInitialized,
            newPasswordLength >= 2
          )}
          {displayPasswordReqItem(
            newPasswordValidationResult.noIdenticalCharacters,
            newPasswordValidationResult.validationInitialized,
            newPasswordLength >= 2
          )}
        </PasswordReqResults>
      )}
    </>
  )
}

const displayPasswordReqItem = (
  newPasswordReqItem: INewPasswordReqItem,
  validationInitialized: boolean,
  showStatus: boolean
) => {
  const reqMet = showStatus && validationInitialized && newPasswordReqItem.valid
  return (
    <PasswordReqResult
      aria-label={
        reqMet ? `Requirement met: ${newPasswordReqItem.title}` : `Requirement failed: ${newPasswordReqItem.title}`
      }
    >
      <PasswordReqResultIcon>
        {reqMet ? (
          <CheckIconNoCircle fill={theme.colors.support_success} width={12} height={9} aria-label="Requirement met" />
        ) : (
          <CircleIcon fill={theme.colors.ui05} width={4} height={4} aria-label="Requirement failed" />
        )}
      </PasswordReqResultIcon>
      <div>{newPasswordReqItem.title}</div>
    </PasswordReqResult>
  )
}

export default DisplayPasswordReqValidationResult
