import axios, { CancelTokenSource } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import { CTASection } from '.'
import { Accordion, AccordionItem } from '../../components/accordion'
import Alert from '../../components/alert'
import { AddContactIcon, ServiceDeskIcon } from '../../components/icon'
import NotificationContactForm from '../../components/notification-contact-form'
import Sidepanel from '../../components/sidepanel'
import SkeletonLine from '../../components/skeleton-line'
import { AppContext } from '../../contexts/app-context'
import { MobileOnly } from '../../shared-styles/responsive.styles'
import API from '../../utils/api'
import { hasMultiFolderAccess, parseNotificationPreferences, SD_ROLE } from '../../utils/helpers'
import NotificationContactsList from './../../components/notification-contacts-list/index'
import {
  AddContactButton,
  AlertContainer,
  Instructions,
  NotificationContainer,
  NotificationHeader,
  NotificationHeaderButtonContainer,
  NotificationHeaderButtonText,
  NotificationHeaderContainer,
  NotificationMobileButtonContainer,
  StepHeader,
  StepSubheader,
} from './onboarding.styles'

const OnboardingContactList = ({
  parentLoading = false,
  folder,
  onBack,
  onNext,
}: {
  parentLoading?: boolean
  folder: IFolderType
  onBack: () => void
  onNext: () => void
}) => {
  const handleError = useErrorHandler()

  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const { folders, user } = useContext(AppContext)
  const [data, setData] = useState<INotificationContactsType>()
  const { users } = data || {}
  const [localLoading, setLocalLoading] = useState<boolean>(false)
  const [editUserInfo, setEditUserInfo] = useState<INotificationContactType>()
  const [showModal, setShowModal] = useState(false)
  const [selectedServiceDesk, setSelectedServiceDesk] = useState<boolean>(false)
  const [buttonWaiting, setButtonWaiting] = useState<boolean>(false)
  const [successAlerts, setSuccessAlerts] = useState<string[]>([])
  const [errorAlerts, setErrorAlerts] = useState<string[]>([])

  const receiveAlertsCount = useMemo(
    () => data?.users.filter(c => c.user.preferences.notification?.length > 0).length || 0,
    [data?.users]
  )

  const loading = parentLoading || localLoading
  const hasServiceDesk = !!users?.find(contact => contact.user.role.name === SD_ROLE)

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      // clean up axios calls when component unmounts
      source.cancel('cancelled')
    }
  }, [source])

  const getFolderContacts = useCallback(async () => {
    try {
      setLocalLoading(true)
      const apiResponse = await API.get(`/api/v2/protect/folders/${folder.id}/notification-contacts`, {
        cancelToken: source.token,
      })
      const apiData = apiResponse.data as INotificationContactsAPIType
      // Add a doNotContact property to each contact depending on notification preferences and existence of a primary phone number
      const contacts: INotificationContactType[] = apiData.users.map(c => {
        return {
          ...c,
          doNotContact: !!(parseNotificationPreferences(c.user.preferences.notification).length === 0 && c.user.phone),
        }
      })
      // Batch the contacts into those with notifications turned on or those with no phone (invited users) and those with notifications turned off with phone set up (do not contact), then sort by `notificationPriority`
      const notificationContacts = contacts
        .filter(c => !c.doNotContact)
        .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      const noNotificationContacts = contacts
        .filter(c => c.doNotContact)
        .sort((a, b) =>
          `${a.user.information.first} ${a.user.information.last}`.localeCompare(
            `${b.user.information.first} ${b.user.information.last}`
          )
        )
      setData({ ...apiData, users: notificationContacts.concat(noNotificationContacts) })
      setLocalLoading(false)
    } catch (e) {
      if (e.message !== 'cancelled') handleError((e as unknown) as Error)
    }
  }, [folder.id, handleError, source])

  useEffect(() => {
    getFolderContacts()
  }, [getFolderContacts])

  const onContinue = async () => {
    if (!user) return

    if (receiveAlertsCount < 1) {
      setErrorAlerts([
        'Please select at least one notification preference for yourself or create an additional contact with active notification preferences',
      ])
      return
    }

    try {
      setButtonWaiting(true)
      await API.patch(`/api/v2/folders/${folder.id}/attributes`, {
        stepContactInformationUser: user.id,
      })
      if (data) {
        // Save notifyPriority
        await API.post(`/api/v2/protect/folders/${folder.id}/notification-priority`, {
          userIds: data.users.map(c => c.user.id),
        })
      }
      setButtonWaiting(false)
      onNext()
    } catch (e) {
      setErrorAlerts(['There was a problem setting up your account.'])
      setButtonWaiting(false)
    }
  }

  const onModalBack = (message: string) => {
    setShowModal(false)
    if (message) {
      setSuccessAlerts([message])
      getFolderContacts()
    }
  }

  const startEditing = (type: 'service-desk' | 'contact') => {
    setSelectedServiceDesk(type === 'service-desk')
    setShowModal(true)
  }

  const startEditingServiceDesk = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    startEditing('service-desk')
  }

  const startEditingContact = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    startEditing('contact')
  }

  return (
    <>
      <StepHeader>Step 3 of 3</StepHeader>
      <StepSubheader>Add Notification Contacts</StepSubheader>
      <Instructions>
        Notification contacts will receive text or email alerts regarding conditions detected by a sensor at your
        facility.
      </Instructions>
      <Accordion collapsible={true} defaultIndex={0} variant="information">
        <AccordionItem headerText="Roles and Permissions">
          <p>
            <strong>Location Manager</strong> is the admin for the account; they can add, remove users and update
            location level settings. They can also opt in and out of receiving alerts.
          </p>
        </AccordionItem>
      </Accordion>
      <NotificationContainer>
        <NotificationHeaderContainer>
          <NotificationHeader>Notification Contacts</NotificationHeader>
          <NotificationHeaderButtonContainer>
            {!loading ? (
              <>
                {!hasServiceDesk && (
                  <AddContactButton onClick={startEditingServiceDesk}>
                    <ServiceDeskIcon />
                    <NotificationHeaderButtonText>Add Service Desk</NotificationHeaderButtonText>
                  </AddContactButton>
                )}
                <AddContactButton onClick={startEditingContact} data-testid="addContactBtnDesktop">
                  <AddContactIcon />
                  <NotificationHeaderButtonText>Add Person</NotificationHeaderButtonText>
                </AddContactButton>
              </>
            ) : (
              <SkeletonLine height="20px" width="140px" />
            )}
          </NotificationHeaderButtonContainer>
        </NotificationHeaderContainer>
        <NotificationContactsList
          contacts={users ?? []}
          onSelectContact={contact => {
            setEditUserInfo(contact)
            startEditing('contact')
          }}
          loading={loading}
        />
        <MobileOnly>
          <NotificationMobileButtonContainer>
            {!loading ? (
              <>
                {!hasServiceDesk && (
                  <AddContactButton onClick={startEditingServiceDesk}>
                    <AddContactIcon />
                    <div>Add Service Desk</div>
                  </AddContactButton>
                )}
                <AddContactButton onClick={startEditingContact} data-testid="addContactBtnMobile">
                  <AddContactIcon />
                  <div>Add Person</div>
                </AddContactButton>
              </>
            ) : (
              <SkeletonLine height="20px" width="140px" />
            )}
          </NotificationMobileButtonContainer>
        </MobileOnly>
      </NotificationContainer>

      {successAlerts.length > 0 || errorAlerts.length > 0 ? (
        <AlertContainer>
          {successAlerts.length > 0 &&
            successAlerts.map((successAlert, i) => (
              <Alert key={i} type="info_success" icon="check_nocircle" message={successAlert} iconAlignment="top" />
            ))}
          {errorAlerts.length > 0 &&
            errorAlerts.map((errorAlert, i) => (
              <Alert key={i} type="info_error" icon="solid_info" message={errorAlert} iconAlignment="top" />
            ))}
        </AlertContainer>
      ) : null}

      <CTASection
        folder={folder}
        hasMultiFolderAccess={hasMultiFolderAccess(folders)}
        step={3}
        goBack={onBack}
        onPrimaryClick={onContinue}
        primaryButtonText="Complete"
        buttonWaiting={buttonWaiting}
        disabled={loading}
      />

      <Sidepanel
        title="Add Contact"
        showTitle={false}
        openModal={showModal}
        onDismiss={() => {
          setShowModal(false)
          setEditUserInfo(undefined)
        }}
        showBackButton={false}
      >
        {folder && user && (
          <NotificationContactForm
            onBack={onModalBack}
            user={user}
            folder={folder}
            editUserInfo={editUserInfo}
            canAddUsers={data?.canAddUsers ?? false}
            serviceDeskContact={selectedServiceDesk}
            receiveAlertsCount={receiveAlertsCount}
            userCount={data?.users.length || 0}
          />
        )}
      </Sidepanel>
    </>
  )
}

export default OnboardingContactList
