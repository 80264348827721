import TroubleshootingPanel from './troubleshooting-panel'
import { Hr, TroubleshootingContent } from './troubleshooting-panels.styles'

export const TroubleshootingPanelConnectionErrorContent = () => {
  return (
    <TroubleshootingContent>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Force a connection by turning sensor over (label facing down), waiting 10 seconds and turning face up.</li>
        <li>You may repeat this process three times.</li>
        <li>Check the sensor readings or the data tab to confirm connection status.</li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor SUpport at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelConnectionError = () => {
  return (
    <TroubleshootingPanel title="Connection Error" modalIndexValue="connection_error">
      <TroubleshootingPanelConnectionErrorContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowBatteryContent = ({ type }: { type: LoRaNodeType }) => {
  return (
    <TroubleshootingContent>
      <p>You can continue activation, but replace battery as soon as possible.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      {type === 'leak' || type === 'leak2' ? (
        <ul>
          <li>Contact Sensor Support to receive a replacement sensor.</li>
        </ul>
      ) : (
        <ul>
          <li>
            Open the battery compartment on the back of sensor by sliding it down. Place 2 AA batteries, and slide the
            cover back on.
          </li>
        </ul>
      )}
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowBattery = ({ type }: { type: LoRaNodeType }) => {
  return (
    <TroubleshootingPanel title="Low Battery" modalIndexValue="low_battery">
      <TroubleshootingPanelLowBatteryContent type={type} />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowSignalContent = () => {
  return (
    <TroubleshootingContent>
      <p>You can continue activation, but poor signal may impact battery life or sensor connectivity.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Check the placement area for signal interference (walls/metal, etc).</li>
        <li>
          Try moving the sensor to a new location and force connection by turning sensor over (label facing down),
          waiting 10 seconds and turning face up. You may repeat this process three times.
        </li>
        <li>Gateway may be too far; you may need to move gateway closer.</li>
        <li>If you experience poor signal with other sensors, you may need to add another gateway.</li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowSignal = () => {
  return (
    <TroubleshootingPanel title="Poor Signal" modalIndexValue="low_signal">
      <TroubleshootingPanelLowSignalContent />
    </TroubleshootingPanel>
  )
}
