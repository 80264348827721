import styled from 'styled-components/macro'

export const Panel = styled.div`
  background: ${({ theme }: ThemeProps) => theme.colors.ui01};
  ${({ theme }: ThemeProps) => theme.elevation.elevation03}
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  margin-bottom: 2.4rem;
`

export const StepHeader = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }: ThemeProps) => theme.colors.ui01};
  padding: 1.1rem 1.6rem;
  margin: 0;
  cursor: pointer;
  /** Reset default button styles */
  background: transparent;
  border: 0;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  width: 100%;
`

export const StepHeaderLeft = styled.div`
  display: flex;
`

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }: ThemeProps) => theme.colors.ui05};
  border-radius: 10rem;
  width: 1.6rem;
  height: 1.6rem;
  color: ${({ theme }: ThemeProps) => theme.colors.ui05};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.75px;
`

export const StepHeaderText = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin-left: 0.6rem;
`

export const StepHeaderSubText = styled.span`
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  letter-spacing: 0;
  text-transform: none;
`

interface StepIconContainerProps {
  toggled?: boolean
}
export const StepIconContainer = styled.div<StepIconContainerProps>`
  svg {
    transform: ${({ toggled }: StepIconContainerProps) => (toggled ? `rotate(-90deg)` : `rotate(90deg)`)};
    transition: all 0.5s ease;
  }
`

interface StepPanelContentProps {
  toggled?: boolean
  theme: IThemeProps
}
export const StepPanelContent = styled.div<StepPanelContentProps>`
  padding: 1.6rem;
  border-top: 0.5px solid ${({ theme }: ThemeProps) => theme.colors.ui04};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    padding: 2.4rem 3.2rem;
  }
`
