import { Dialog } from '@reach/dialog'
import styled from 'styled-components/macro'

import { GhostButton } from '../../shared-styles/button.styles'

interface ModalContainerProps {
  theme: IThemeProps
  width: string
  maxwidth: string
  minheight: string
  height: string
  padding: string
  mobilepadding: string
  background: string
  mobilebackground: string
  margin: string
  roundedcorners: string
  roundedcornersmobile: string
  displayshadows: string
}

export const ModalContainer = styled(Dialog)`
  font-family: ${({ theme }: ModalContainerProps) => theme.fontFamily};
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0;
  background-color: ${({ mobilebackground }: ModalContainerProps) => mobilebackground};
  padding: ${({ mobilepadding }: ModalContainerProps) => mobilepadding};
  position: relative;
  border-radius: ${({ roundedcornersmobile }: ModalContainerProps) => (roundedcornersmobile === 'true' ? '1rem' : 0)};
  box-shadow: ${({ displayshadows }: ModalContainerProps) =>
    displayshadows === 'true' ? '0px 20px 40px rgba(0, 0, 0, 0.15)' : 'none'};

  @media (min-width: 992px) {
    width: ${({ width }: ModalContainerProps) => width};
    max-width: ${({ maxwidth }: ModalContainerProps) => maxwidth};
    height: ${({ height }: ModalContainerProps) => height};
    background-color: ${({ background }: ModalContainerProps) => background};
    padding: ${({ padding }: ModalContainerProps) => padding};
    margin: ${({ margin }: ModalContainerProps) => margin};
    min-height: ${({ minheight }: ModalContainerProps) => minheight};
    border-radius: ${({ roundedcorners }: ModalContainerProps) => (roundedcorners === 'true' ? '1rem' : 0)};
    overflow-x: hidden;
  }
`

export const ModalTitle = styled.h2`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.s};
  margin: 0;
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
  text-align: center;

  @media (min-width: 992px) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xxl};
    line-height: ${({ theme }: ThemeProps) => theme.lineHeights.default};
    margin-top: 3.3rem;
  }
`

interface ModalContentProps {
  theme: IThemeProps
  textalign: 'left' | 'right' | 'center'
  showClose: boolean
  showCloseMobile: boolean
}
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  text-align: ${({ textalign }: ModalContentProps) => textalign};

  @media (min-width: 992px) {
    margin-top: ${({ showClose }: ModalContentProps) => (showClose ? '3.3rem' : '0')};
  }

  p,
  ul {
    width: 100%;
  }
  ul {
    box-sizing: border-box;
  }
`

interface ModalCloseButtonProps {
  showClose: boolean
  showCloseMobile: boolean
}

export const ModalCloseButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 15px;
  margin: -20px -15px 0 0;

  @media (max-width: 991px) {
    display: ${({ showCloseMobile }: ModalCloseButtonProps) => (showCloseMobile ? 'block' : 'none')};
  }
  @media (min-width: 992px) {
    margin-top: -15px;
    display: ${({ showClose }: ModalCloseButtonProps) => (showClose ? 'block' : 'none')};
  }

  &:hover {
    cursor: pointer;
  }
`

interface ModalCloseButtonContainerProps {
  theme: IThemeProps
  showDivider?: boolean
  showDividerMobile?: boolean
  showBackButton?: boolean
  showCloseMobile?: boolean
}

export const ModalCloseButtonContainer = styled.div<ModalCloseButtonContainerProps>`
  display: flex;
  justify-content: ${({ showBackButton }) => (showBackButton ? 'space-between' : 'flex-end')};
  ${({ theme, showDividerMobile }: ModalCloseButtonContainerProps) => {
    if (showDividerMobile) {
      return `
          border-bottom: 1px solid ${theme.colors.ui04};
          margin: 0 -1.6rem;
          padding: 0 1.6rem;
        `
    }
  }};

  @media (max-width: 992px) {
    justify-content: ${({ showCloseMobile }) => (showCloseMobile ? 'flex-end' : 'space-between')};
    margin: ${({ showCloseMobile, showDividerMobile }) =>
      showDividerMobile ? '0 -1.6rem' : showCloseMobile && !showDividerMobile ? '0' : '1.2rem 0'};
  }
  @media (min-width: 992px) {
    position: absolute;
    width: 100%;
    right: 0;
    padding: 0 1.875rem;
    box-sizing: border-box;
    ${({ theme, showDivider }: ModalCloseButtonContainerProps) => {
      if (showDivider) {
        return `
        border-bottom: 1px solid ${theme.colors.ui04};
        margin: 0;
      `
      } else return `border-bottom: 0;`
    }};
  }
`

interface ModalBackButtonProps {
  theme: IThemeProps
  showBackButton?: boolean
  backButtonUppercase?: boolean
}

export const ModalBackButton = styled<any>(GhostButton)`
  color: ${({ theme }: ThemeProps) => theme.colors.ui07};
  display: flex;
  align-items: center;
  margin: -15px -8px 0 0;
  text-align: left;
  overflow: hidden;
  width: 100%;
  text-transform: ${({ backButtonUppercase }: ModalBackButtonProps) => (backButtonUppercase ? 'uppercase' : 'none')};

  svg {
    transform: rotate(180deg);
    margin-right: 0.9rem;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg,
  span {
    display: block;
  }

  @media (min-width: 992px) {
    ${({ showBackButton }: ModalBackButtonProps) => {
      if (!showBackButton) {
        return `display: none;`
      }
    }};
  }
`

export const ModalTitleAboveDivider = styled.h2`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  display: flex;
  align-items: center;
  margin: -15px -8px 0 0;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  @media (min-width: 992px) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  }
`
