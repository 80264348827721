import { FieldError } from './../../shared-styles/alert.styles'
import { FormField, Input, Label } from './../../shared-styles/form.styles'

interface IProps {
  id: string
  label: string | React.ReactElement
  placeholder?: string
  type?: 'email' | undefined
  /** Formik values, touched, and errors values for the form */
  formikData: {
    values: any
    touched: {
      [x: string]: boolean | undefined
    }
    errors: {
      [x: string]: string | undefined
    }
    handleChange: (e: any) => void
    handleBlur: (e: any) => void
  }
  errorState?: boolean
  errorDataCy?: string
}

/**
 * The FormFieldInput component is used in forms
 */
export const FormFieldInput = ({
  id,
  type,
  label,
  placeholder,
  formikData,
  errorState,
  errorDataCy,
  ...rest
}: IProps) => {
  return (
    <FormField>
      <Label htmlFor={id}>{label}</Label>
      <Input
        {...rest}
        id={id}
        type={type}
        placeholder={placeholder}
        value={formikData.values[id]}
        onChange={formikData.handleChange}
        onBlur={formikData.handleBlur}
        errorState={
          typeof errorState !== 'undefined' ? errorState : !!(formikData.touched[id] && formikData.errors[id])
        }
      />
      {formikData.touched[id] && formikData.errors[id] && (
        <FieldError data-cy={errorDataCy}>{formikData.errors[id]}</FieldError>
      )}
    </FormField>
  )
}

export default FormFieldInput
