import styled from 'styled-components/macro'

export const Error = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.support_error};
  border-radius: 0.4rem;
  box-shadow: ${({ theme }: ThemeProps) => theme.elevation.elevation03};
  box-sizing: border-box;
  margin-top: ${({ theme }: ThemeProps) => theme.space.l};
  padding: 1.2rem 1.6rem;
  width: 100%;

  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.default};
`

export const FieldError = styled.div`
  margin-top: ${({ theme }: ThemeProps) => theme.space.s};
  color: ${({ theme }: ThemeProps) => theme.colors.support_error};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
`
