export interface INewPasswordReqItem {
  title: string
  valid: boolean
}
export interface INewPasswordReq {
  valid: boolean
  validationInitialized: boolean
  passwordLength: INewPasswordReqItem
  aleastOneNumber: INewPasswordReqItem
  noIdenticalCharacters: INewPasswordReqItem
}

export const newPasswordReqValidation = (password: string): INewPasswordReq => {
  let valid: boolean = true

  const newPasswordReq: INewPasswordReq = {
    valid: false,
    validationInitialized: false,
    passwordLength: { title: 'Be a minimum of 8 characters', valid: false },
    aleastOneNumber: { title: 'Include at least one number', valid: false },
    noIdenticalCharacters: { title: 'Not contain more than two consecutive identical characters', valid: false },
  }

  if (password.length <= 0) {
    return newPasswordReq
  }

  newPasswordReq.validationInitialized = true

  // Must be at min characters long
  if (password.length >= 8) {
    newPasswordReq.passwordLength.valid = true
  } else {
    valid = false
  }

  // Must must have 1 number
  if (password.match(/\d+/g) !== null) {
    newPasswordReq.aleastOneNumber.valid = true
  } else {
    valid = false
  }

  // Should not contain more than two consecutive identical characters
  newPasswordReq.noIdenticalCharacters.valid = true
  const someText = password.split('')
  const strLen = password.length - 1
  someText.forEach((v, i) => {
    if (i <= strLen - 2) {
      if (
        v.toLowerCase() === password.charAt(i + 1).toLowerCase() &&
        v.toLowerCase() === password.charAt(i + 2).toLowerCase()
      ) {
        newPasswordReq.noIdenticalCharacters.valid = false
        return true
      }
    }
  })

  if (!newPasswordReq.noIdenticalCharacters.valid) {
    valid = false
  }

  newPasswordReq.valid = valid

  return newPasswordReq
}
