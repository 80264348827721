import Level from '../../contexts/level-context'

interface IProps {
  children?: any
}

const Section = ({ children }: IProps) => {
  return <Level.Consumer>{level => <Level.Provider value={level + 1}>{children}</Level.Provider>}</Level.Consumer>
}

export default Section
