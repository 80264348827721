import {
  Accordion as ReachAccordion,
  AccordionButton as ReachAccordionButton,
  AccordionItem as ReachAccordionItem,
  AccordionPanel as ReachAccordionPanel,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import styled from 'styled-components/macro'

import { ChevronIcon } from '../icon'

interface TabProps {
  theme: IThemeProps
  variant: 'default' | 'information'
  showDividers: boolean
}

const StyledAccordion = styled(ReachAccordion)`
  border-bottom: 1px solid
    ${({ theme, variant, showDividers }: TabProps) =>
      variant === 'information' || !showDividers ? 'transparent' : theme.colors.ui04};
  color: ${({ theme }: TabProps) => theme.colors.text02};
  font-family: ${({ theme }: TabProps) => theme.fontFamily};
  font-size: ${({ theme }: TabProps) => theme.fontSizes.s};
  width: 100%;
  ${({ theme, variant }: TabProps) => {
    if (variant === 'information') {
      return `background-color: ${theme.colors.support_info}20;`
    }
  }}

  [data-reach-accordion-item] {
    border-top: 1px solid ${({ theme }: TabProps) => theme.colors.ui04};
    ${({ variant, showDividers }: TabProps) => {
      if (variant === 'information' || !showDividers) {
        return `border: 0;`
      }
    }}
  }
  [data-reach-accordion-button] {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    width: 100%;
    color: ${({ theme }: TabProps) => theme.colors.text01};
    font-family: ${({ theme }: TabProps) => theme.fontFamily};
    font-weight: ${({ theme }: TabProps) => theme.fontWeights.semibold};
    line-height: ${({ theme }: TabProps) => theme.lineHeights.l};
    padding: 0;
    padding-top: ${({ theme }: ThemeProps) => theme.space.s};
    padding-right: ${({ theme }: ThemeProps) => theme.space.s};
    padding-bottom: ${({ theme }: ThemeProps) => theme.space.s};
    text-align: left;

    &:hover {
      cursor: pointer;
    }
  }
  [data-reach-accordion-item][data-state='open'] {
    svg {
      transform: rotate(90deg);
    }
  }
  [data-reach-accordion-panel] {
    margin-left: ${({ theme }: ThemeProps) => theme.space.xl};
    margin-right: ${({ theme }: ThemeProps) => theme.space.xxs};
    line-height: ${({ theme }: TabProps) => theme.lineHeights.l};
    text-align: left;

    > p:first-child {
      margin-top: 0;
    }
  }
`

const IconContainer = styled.div`
  margin-left: ${({ theme }: ThemeProps) => theme.space.m};
  margin-right: ${({ theme }: ThemeProps) => theme.space.s};
`

interface AccordionItemProps {
  children: React.ReactNode
  headerText: string
}

export const AccordionItem = ({ children, headerText }: AccordionItemProps) => {
  return (
    <ReachAccordionItem>
      <ReachAccordionButton>
        <IconContainer>
          <ChevronIcon />
        </IconContainer>
        {headerText}
      </ReachAccordionButton>
      <ReachAccordionPanel>{children}</ReachAccordionPanel>
    </ReachAccordionItem>
  )
}

interface AccordionProps {
  children: React.ReactNode
  /**
   * A default value for the open panel's index or indices when it is initially rendered.
   *
   * If an accordion has no defaultIndex, the initially rendered open panel depends on the collapsible prop.
   *
   * - If `collapsible` is set to `true`, without a `defaultIndex` no panels will initially be open. Otherwise, the first panel at index `0` will initially be open.
   *
   * You can only pass an array of indices to `defaultIndex` if you also set `multiple` to `true`.
   */
  defaultIndex?: number | number[]
  /**
   * Used to dictate that an accordion should allow all panels to be collapsed simultaneously. By default, one panel must be in an open state at all times.
   */
  collapsible?: boolean
  /**
   * Used to dictate that any number of panels may be open at the same time. By default, when a user opens a new accordion item, the previously open item will be set to collapsed.
   */
  multiple?: boolean
  /** Used to change how the accordion should be styled */
  variant?: 'default' | 'information'
  /** Used to hide dividers on the Accordion */
  showDividers?: boolean
}

/**
 * Import both Accordion and AccordionItem from the Accordion component file:
 *
 * `import { Accordion, AccordionItem } from "{src_directory}/components/accordion"`
 *
 * The `<Accordion>` component is the parent component and `<AccordionItem>` is the child component that displays the header and content of each accordion item. The `AccordionItem` takes a `headerText` prop as a string, and children elements for the content.
 */
export const Accordion = ({
  children,
  defaultIndex,
  collapsible = false,
  multiple = false,
  variant = 'default',
  showDividers = true,
}: AccordionProps) => {
  return (
    <StyledAccordion
      defaultIndex={defaultIndex}
      collapsible={collapsible}
      multiple={multiple}
      variant={variant}
      $showDividers={showDividers}
    >
      {children}
    </StyledAccordion>
  )
}

export default Accordion
