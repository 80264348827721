import TroubleshootingPanel from './troubleshooting-panel'
import { Hr, TroubleshootingContent } from './troubleshooting-panels.styles'

export const TroubleshootingPanelConnectionErrorContent = () => {
  return (
    <TroubleshootingContent>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Check that the cables are properly connected.</li>
        <li>Ensure the outlet has electricity and is not controlled by a light switch.</li>
        <li>Check the placement area for signal interference (walls/metal, etc).</li>
        <li>Try moving the gateway to a new location with better cellular reception.</li>
        <li>
          If you have no cell signal in your building, Sensor Support can assist you with the Ethernet installation
          method.
        </li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelConnectionError = () => {
  return (
    <TroubleshootingPanel title="Connection Error" modalIndexValue="connection_error">
      <TroubleshootingPanelConnectionErrorContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelOnBatteryErrorContent = () => {
  return (
    <TroubleshootingContent>
      <p>Due to a power loss, the gateway is operating on the built-in backup battery.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Please check that the cables are properly connected.</li>
        <li>Ensure the outlet has electricity and is not controlled by a light switch.</li>
        <li>
          Built-in backup battery is intended for power outages and has a life span of approximately 3 hours. If the
          power is not restored within this time, the device may disconnect.
        </li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelOnBatteryError = () => {
  return (
    <TroubleshootingPanel title="Backup Battery" modalIndexValue="on_batt">
      <TroubleshootingPanelOnBatteryErrorContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowSignalErrorContent = () => {
  return (
    <TroubleshootingContent>
      <p>You can continue activation, but poor signal may impact sensor data transmission.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Bring gateway outside or to an area with good cell signal (gateway can be on battery).</li>
        <li>
          Bring the gateway back to the desired install location and finish the Protect app installation sequence.
        </li>
        <li>
          If signal is still inadequate, change the install location. Move the gateway to a new install area with better
          signal.
        </li>
        <li>
          Alternative option: Connect via Ethernet using the included cable. Contact Sensor Support for more
          information.
        </li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowSignalError = () => {
  return (
    <TroubleshootingPanel title="Poor Signal" modalIndexValue="low_signal">
      <TroubleshootingPanelLowSignalErrorContent />
    </TroubleshootingPanel>
  )
}
