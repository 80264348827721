import { Alert as ReachAlert } from '@reach/alert'
import styled from 'styled-components/macro'

import { AlertTypes } from './'

interface StyledAlertProps {
  alerttype: AlertTypes
  theme: IThemeProps
  backgroundcolor?: string
}

export const StyledAlert = styled(ReachAlert)`
  background-color: ${(props: StyledAlertProps) => {
    switch (props.alerttype) {
      case 'offline':
        return 'rgba(161, 164, 177, 0.2)'
      case 'success':
        return 'rgba(71, 178, 130, 0.2)'
      case 'warning':
        return 'rgba(255, 255, 255, 1)'
      case 'error':
        return 'rgba(201, 36, 52, 0.2)'
      case 'info_success':
        return props.theme.colors.support_success
      case 'info_support':
        return props.theme.colors.support_info
      case 'info_warning':
        return 'rgba(255, 204, 105, 0.2)'
      case 'info_error':
        return props.theme.colors.support_error
      case 'info_permission_denied':
        return 'rgba(161, 164, 177, 0.2)'
      case 'custom':
        return props.backgroundcolor || 'rgba(161, 164, 177, 0.2)'
      default:
        return 'rgba(161, 164, 177, 0.2)'
    }
  }};
  border-radius: 4px;
  box-shadow: ${(props: StyledAlertProps) =>
    props.alerttype === 'info_success' ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : ''};
  margin-bottom: ${({ theme }: StyledAlertProps) => theme.space.s};
  display: grid;
  grid-template-columns: 42px 1fr;
  color: ${(props: StyledAlertProps) => {
    switch (props.alerttype) {
      case 'info_success':
        return props.theme.colors.text_inverse
      case 'info_support':
        return props.theme.colors.text_inverse
      case 'info_error':
        return props.theme.colors.text_inverse
      default:
        return props.theme.colors.text02
    }
  }};
  font-family: ${({ theme }: StyledAlertProps) => theme.fontFamily};
  font-size: ${({ theme, alerttype }: StyledAlertProps) =>
    alerttype === 'info_error' || alerttype === 'info_success' ? theme.fontSizes.xs : theme.fontSizes.s};
  font-weight: ${(props: StyledAlertProps) => {
    switch (props.alerttype) {
      case 'info_success':
        return props.theme.fontWeights.semibold
      case 'info_support':
        return props.theme.fontWeights.semibold
      default:
        return props.theme.fontWeights.regular
    }
  }};
  line-height: ${({ theme }: StyledAlertProps) => theme.lineHeights.l};

  svg {
    ${({ alerttype }: StyledAlertProps) => {
      if (alerttype === 'info_error' || alerttype === 'info_success') {
        return `width: 1.3rem;`
      }
    }};
  }

  @media (min-width: ${({ theme }: StyledAlertProps) => theme.breakpoint.m}) {
    font-size: ${({ theme }: StyledAlertProps) => theme.fontSizes.s};

    svg {
      ${({ alerttype }: StyledAlertProps) => {
        if (alerttype === 'info_error') {
          return `width: 2rem;`
        }
        if (alerttype === 'info_success') {
          return `width: 1.8rem;`
        }
      }};
    }
  }
`

interface StyledIconProps {
  iconAlignment: 'top' | 'center'
  alerttype: AlertTypes
}
export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props: StyledIconProps) => {
    if (
      (props.iconAlignment === 'top' && props.alerttype === 'info_success') ||
      (props.iconAlignment === 'top' && props.alerttype === 'info_error')
    )
      return `
      align-items: flex-start;
      padding-top: .9rem;
    `
    if (props.iconAlignment === 'top')
      return `
      align-items: flex-start;
      padding-top: 1.2rem;
    `
  }};
`

export const StyledMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0.8rem 0;
`

export const StyledContent = styled.p`
  margin: 0;
  text-align: left;
`

export const StyledAction = styled.button`
  background-color: transparent;
  border: 0;
  display: inline;
  color: ${(props: StyledAlertProps) => {
    switch (props.alerttype) {
      case 'info_success':
        return props.theme.colors?.text_inverse
      case 'info_error':
        return props.theme.colors?.text_inverse
      default:
        return props.theme.colors?.support_info
    }
  }};
  font-weight: ${({ theme }: StyledAlertProps) => theme.fontWeights?.semibold};
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`

export const StyledActionSeparator = styled.span`
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
`
