import styled from 'styled-components/macro'

import ArrowDownImg from '../assets/arrow-down-small-gray.png'

interface InputProps {
  errorState?: boolean
  theme: IThemeProps
}

export const CenteredButtonWrapper = styled.div`
  text-align: center;
`

export const StyledForm = styled.form`
  width: 100%;
`

export const ModalForm = styled.form`
  width: 100%;
  text-align: left;

  @media (min-width: 992px) {
    max-width: 340px;
  }
`

export const FormField = styled.div`
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
  width: 100%;
`

export const Label = styled.label`
  display: block;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xs};
`

// Used for (required) and (optional) text in labels
export const LabelNote = styled.span`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  line-height: 1.2;
`

export const Input = styled.input<InputProps>`
  border: 1px solid
    ${(props: InputProps) => (props.errorState ? props.theme.colors.support_error : props.theme.colors.ui04)};
  border-radius: 0.4rem;
  box-sizing: border-box;
  height: 4rem;
  padding: 1.2rem 1.6rem;
  margin-top: ${(props: InputProps) => props.theme.space.xs};
  width: 100%;

  color: ${(props: InputProps) => props.theme.colors.text02};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${(props: InputProps) => props.theme.fontSizes.default};
  line-height: ${(props: InputProps) => props.theme.lineHeights.l};

  &:focus {
    border: 1px solid ${(props: InputProps) => props.theme.colors.brand01};
  }
  &:disabled {
    color: ${(props: InputProps) => props.theme.colors.text03};
  }

  @media (min-width: ${(props: InputProps) => props.theme.breakpoint.l}) {
    font-size: ${(props: InputProps) => props.theme.fontSizes.s};
  }
`

export const Textarea = styled.textarea`
  border: 1px solid
    ${(props: InputProps) => (props.errorState ? props.theme.colors.support_error : props.theme.colors.ui04)};
  border-radius: 0.4rem;
  box-sizing: border-box;
  height: 10rem;
  padding: 1.2rem 1.6rem;
  margin-top: ${(props: InputProps) => props.theme.space.xs};
  width: 100%;

  color: ${(props: InputProps) => props.theme.colors.text02};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${(props: InputProps) => props.theme.fontSizes.default};
  line-height: ${(props: InputProps) => props.theme.lineHeights.l};

  &:focus {
    border: 1px solid ${(props: InputProps) => props.theme.colors.brand01};
  }

  @media (min-width: ${(props: InputProps) => props.theme.breakpoint.l}) {
    font-size: ${(props: InputProps) => props.theme.fontSizes.s};
  }
`

export const SelectContainer = styled.div`
  position: relative;

  &:after {
    content: '';
    background: url(${ArrowDownImg}) no-repeat;
    display: block;
    position: absolute;
    top: 18px;
    right: 23px;
    width: 10px;
    height: 5px;
    pointer-events: none;
  }
`
export const Select = styled.select`
  border: 1px solid
    ${(props: InputProps) => (props.errorState ? props.theme.colors.support_error : props.theme.colors.ui04)};
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  height: 40px;
  padding: 10px 53px 10px 16px;
  margin-top: 7px;
  width: 100%;
  appearance: none;
  word-break: normal;

  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xs};

  &:focus {
    border: 1px solid ${({ theme }: ThemeProps) => theme.colors.brand01};
  }

  @media (min-width: ${(props: InputProps) => props.theme.breakpoint.l}) {
    font-size: ${(props: InputProps) => props.theme.fontSizes.s};
  }
`

/**
 * Example usage:
 * <Radio id="radio" type="radio" />
 * <Label htmlFor="radio">Radio label</Label>
 */
export const Radio = styled.input`
  position: absolute;
  left: -99999px;

  & + label {
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    margin-right: 70px;
    color: ${({ theme }: ThemeProps) => theme.colors.text02};
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
  }
  & + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 11px;
    height: 11px;
    border: 1px solid ${({ theme }: ThemeProps) => theme.colors.text02};
    border-radius: 100%;
    background: ${({ theme }: ThemeProps) => theme.colors.ui01};
  }
  & + label:after {
    content: '';
    width: 7px;
    height: 7px;
    background: ${({ theme }: ThemeProps) => theme.colors.brand01};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:before {
    border: 1px solid ${({ theme }: ThemeProps) => theme.colors.brand01};
  }
  &:not(:checked):focus + label:before {
    border: 2px solid ${({ theme }: ThemeProps) => theme.colors.brand01};
  }
  &:checked:focus + label:before {
    border: 2px solid ${({ theme }: ThemeProps) => theme.colors.text02};
  }
  &:checked:focus + label:after {
    top: 4px;
    left: 4px;
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`

/**
 * Example usage:
 * <Checkbox>
 *   <input type="checkbox" />
 *   <span>Checkbox label</span>
 * </Checkbox>
 */
export const Checkbox = styled.label`
  z-index: 0;
  position: relative;
  display: inline-block;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: inherit;
  line-height: 1.6;

  > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;

    & + span::before {
      border-color: ${({ theme }: ThemeProps) => theme.colors.ui05};
      flex-shrink: 0;
    }
    &:checked {
      background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};

      & + span::before {
        border-color: ${({ theme }: ThemeProps) => theme.colors.brand01};
        background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
      }
      & + span::after {
        border-color: ${({ theme }: ThemeProps) => theme.colors.brand01};
      }
      &:disabled {
        & + span::before {
          border-color: transparent;
          background-color: currentColor;
        }
      }
    }
    &:focus {
      & + span::before {
        border-color: ${({ theme }: ThemeProps) => theme.colors.brand01};
      }
    }
    &:disabled {
      opacity: 0;
      & + span {
        color: rgba(0, 0, 0, 0.38);
        cursor: initial;

        &::before {
          border-color: currentColor;
        }
      }
    }
  }
  > span {
    display: flex;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 11px 3px 1px;
      border: solid 2px; /* Safari */
      border-color: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      width: 18px;
      height: 18px;
      vertical-align: top;
      transition: border-color 0.2s, background-color 0.2s;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 1px;
      width: 10px;
      height: 5px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(3px, 4px) rotate(-45deg);
    }
  }
  &:hover {
    > input {
      opacity: 0.04;

      &:focus {
        opacity: 0.16;
      }
    }
  }
`
