import styled from 'styled-components/macro'

export const DashBoardDeviceSearchWrapper = styled.div`
  & > div {
    & > input {
      width: 30rem;
      color: ${({ theme }: ThemeProps) => theme.colors.text02};
    }
  }
`

export const SearchResultPane = styled.div`
  ${({ theme }: ThemeProps) => theme.elevation.elevation03}
  border-radius: 5px;
  max-height: 350px;
  width: 29.9rem;
  margin-top: 0.4rem;
  overflow-y: auto;
  position: absolute;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  z-index: 1;
`

export const SearchResultNoDateMessageLine = styled.div`
  margin-top: ${({ theme }: ThemeProps) => theme.space.xxs};
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${(props: ThemeProps) => props.theme.fontSizes.xs};
  font-weight: ${(props: ThemeProps) => props.theme.fontWeights.regular};
  letter-spacing: 0;
  line-height: 1.2;
`

export const SearchResultNoDataPane = styled<any>(SearchResultPane)`
  & ${SearchResultNoDateMessageLine} {
    p {
      color: ${({ theme }: ThemeProps) => theme.colors.text02};
      font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
      line-height: 1.7;
      padding: 1.6rem;
      padding-top: 0;
      padding-right: 1.2rem;
      margin: 0;
    }
  }
`

export const ResultHeader = styled.div`
  box-sizing: border-box;
  padding: 1.2rem 1.6rem;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: 1.1;
`

export const DeviceListItem = styled.li`
  padding: 0 1.6rem 2.4rem 1.6rem;

  &:first-child {
    margin-top: 1.2rem;
  }
`

export const DeviceButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  width: 100%;
  display: flex;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  text-align: left;

  &:hover {
    cursor: pointer;
  }
`

export const DeviceIcon = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui03};
  border-radius: 10rem;
  width: 5.3rem;
  height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;

  > svg {
    position: relative;
    bottom: 0;
    right: 0;
    width: 3.2rem;
    height: 3.2rem;
  }
`

export const DeviceText = styled.div`
  flex: 1;
`

export const DeviceHeader = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: 1.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`

export const DeviceSubheader = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  line-height: 1.2;
  margin-top: 0.4rem;
`
