import TroubleshootingPanel from './troubleshooting-panel'
import { Hr, TroubleshootingContent } from './troubleshooting-panels.styles'

export const TroubleshootingPanelConnectionErrorContent = () => {
  return (
    <TroubleshootingContent>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Check the placement area for signal interference (walls/metal, etc).</li>
        <li>
          Try relocating the gateway closer to the shut-off device. Make sure gateway is connected to network. Then,
          power cycle the shut-off device to force device to reacquire connection to the gateway.
        </li>
        <li>
          If gateway cannot be relocated closer to the shut-off device due to dependencies on other sensors, an
          additional gateway may be needed.
        </li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelConnectionError = () => {
  return (
    <TroubleshootingPanel title="Connection Error" modalIndexValue="connection_error">
      <TroubleshootingPanelConnectionErrorContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowSignalContent = () => {
  return (
    <TroubleshootingContent>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Check the placement area for signal interference (walls/metal, etc).</li>
        <li>
          Try relocating the gateway closer to the shut-off device. Make sure gateway is connected to network. Then,
          power cycle the shut-off device to force device to reacquire connection to the gateway.
        </li>
        <li>
          If gateway cannot be relocated closer to the shut-off device due to dependencies on other sensors, an
          additional gateway may be needed.
        </li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowSignal = () => {
  return (
    <TroubleshootingPanel title="Poor Signal" modalIndexValue="low_signal">
      <TroubleshootingPanelLowSignalContent />
    </TroubleshootingPanel>
  )
}
