import styled from 'styled-components/macro'

import { ButtonA } from '../../../../shared-styles/link.styles'

export const AddressParagraph = styled.p`
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xl};
  margin: 0;
`

export const AddressName = styled.span`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  letter-spacing: 0.75px;
  text-transform: uppercase;
`

export const BuyDevicesButton = styled(ButtonA)`
  margin-top: 0;
`

export const OrderSku = styled.span`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
`
