import { PanelBulletList, PanelSubHeader } from './placement-guides.styles'

const NbiotPlacementGuide = () => {
  return (
    <>
      <PanelSubHeader>Leak & Freeze</PanelSubHeader>
      <p>
        Your device can be placed near appliances or plumbing to monitor presence of water, in a heated room to monitor
        the temperature changes, or basement near pipes to detect temperature drops which can lead to burst pipes.
      </p>
      <p>Examples:</p>
      <PanelBulletList>
        <li>Under your sink</li>
        <li>At the low point in your basement or utility room floor</li>
        <li>Next to a boiler or near your water heater, sump or other pump</li>
        <li>A heated room/space where freezing conditions would be a concern</li>
      </PanelBulletList>
      <p>Be sure to place your sensor in an area away from foot traffic where it cannot be disturbed.</p>
    </>
  )
}

export default NbiotPlacementGuide
