import styled from 'styled-components'

import theme from '../../theme'

export const SupportSubTitle = styled.p`
  color: ${theme.colors.text01};
  font-size: ${theme.fontSizes.l};
  font-weight: 600;
  text-align: center;
  margin-top: 5rem;
`

export const SupportIntro = styled.p``

export const SupportContent = styled.div`
  width: 100%;
`
