import { useContext } from 'react'

import Btn from '../../../../components/button'
import { SolidInfoIcon } from '../../../../components/icon'
import { AppContext } from '../../../../contexts/app-context'
import { DesktopOnly, MobileOnly } from '../../../../shared-styles/responsive.styles'
import { NoticeContainer, NoticeText } from './locations-setup-notice.styles'

const LocationsSetupNotice = ({ onClick }: { onClick: (value: React.SetStateAction<boolean>) => void }) => {
  const { folders, foldersLoading } = useContext(AppContext)
  const foldersToOnboard = folders.filter(f => (!f.isActivated || !f.isOnboarded) && f.folderType === 'Participant')

  return (
    <>
      {!foldersLoading && foldersToOnboard.length > 0 ? (
        <>
          <DesktopOnly>
            <NoticeContainer>
              <NoticeText>
                <SolidInfoIcon />
                <div>You have new locations that need to be setup.</div>
              </NoticeText>
              <Btn onClick={onClick} style={{ marginTop: 0, maxWidth: '20rem' }}>
                Setup Now
              </Btn>
            </NoticeContainer>
          </DesktopOnly>
          <MobileOnly>
            <NoticeContainer>
              <Btn
                buttonType="ghost"
                onClick={onClick}
                style={{ marginTop: 0, width: '100%' }}
                uppercaseStyling={false}
              >
                See new locations to setup
              </Btn>
            </NoticeContainer>
          </MobileOnly>
        </>
      ) : null}
    </>
  )
}

export default LocationsSetupNotice
