import styled from 'styled-components/macro'

export const PasswordInputContainer = styled.div`
  position: relative;
`

export const PasswordInputIcon = styled.svg`
  fill: none;
`

export const PasswordLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PasswordLabelButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }: ThemeProps) => theme.colors.support_info};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};

  svg {
    margin-right: 7px;
  }
`
