import { VisuallyHidden } from '@reach/visually-hidden'
import { useContext, useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import logo from '../../assets/meshify-logo.png'
import AuthContext from '../../contexts/auth-context'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import API from '../../utils/api'
import { DropdownButton, DropdownMenu, DropdownMenuItem, DropdownMenuList } from '../dropdown/index'
import { ChevronIcon, MiniHamburgerIcon } from '../icon'
import { SelectLanguagePicker } from '../language-pickers/index'
import Sidepanel from '../sidepanel'
import SkeletonLine from '../skeleton-line'
import ActivationHelp from './activation-help'
import {
  HeaderButton,
  HeaderLocation,
  HeaderLogo,
  HeaderRightButtonsDesktop,
  HeaderRightButtonsMobile,
  HeaderStyled,
} from './header.styles'
import NativeAppBanner, { NativeAppButton } from './native-app-banner'
import OnboardingHelp from './onboarding-help'

const Header = ({
  type,
  locationName,
  handleBack,
}: {
  type: 'activating' | 'onboarding'
  locationName?: string
  handleBack?: () => void
}) => {
  const handleError = useErrorHandler()
  const { setIsAuth } = useContext(AuthContext)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  // When the help sidepanel is open, add the `modalIsOpen` class to the <body> element, so the map z-index will change to 0 and the map will not show on top of the sidepanel
  // When the help sidepanel is closed, remove this class from the <body> element
  useEffect(() => {
    if (showDialog) document?.querySelector('body')?.classList.add('modalIsOpen')
    else document?.querySelector('body')?.classList.remove('modalIsOpen')
  }, [showDialog])

  const doLogout = async () => {
    try {
      const response = await API.get('/api/logout', {})
      if (response.status === 200) {
        localStorage.removeItem('protectToken')
        setIsAuth(false)
      }
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <>
      <MobileOnly>
        <HeaderStyled>
          {handleBack && (
            <HeaderButton onClick={handleBack} aria-label="Go back" style={{ transform: 'rotate(180deg)' }}>
              <ChevronIcon />
            </HeaderButton>
          )}
          {locationName ? (
            <HeaderLocation>{locationName}</HeaderLocation>
          ) : (
            <SkeletonLine rounded={true} height="1.4rem" />
          )}

          <HeaderRightButtonsMobile>
            <NativeAppButton />
            <DropdownMenu align="right">
              <DropdownButton highlightExpanded={true}>
                <MiniHamburgerIcon />
                <VisuallyHidden>Menu</VisuallyHidden>
              </DropdownButton>
              <DropdownMenuList minwidth="120px" variation="secondary">
                <DropdownMenuItem onSelect={() => setShowDialog(true)} value="Help">
                  Help
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={doLogout} value="Log out">
                  Log out
                </DropdownMenuItem>
              </DropdownMenuList>
            </DropdownMenu>
          </HeaderRightButtonsMobile>
        </HeaderStyled>
      </MobileOnly>
      <DesktopOnly>
        <HeaderStyled>
          <HeaderLogo>
            <img src={logo} alt="Meshify Logo" />
          </HeaderLogo>
          <HeaderRightButtonsDesktop>
            <NativeAppButton />
            <SelectLanguagePicker />
            <HeaderButton onClick={() => setShowDialog(true)}>Help</HeaderButton>
            <HeaderButton onClick={doLogout}>Log out</HeaderButton>
          </HeaderRightButtonsDesktop>
        </HeaderStyled>
      </DesktopOnly>
      <NativeAppBanner />
      <Sidepanel
        openModal={showDialog}
        onDismiss={() => setShowDialog(false)}
        title={
          type === 'onboarding' ? 'Account Setup Support' : type === 'activating' ? 'Device Activation Support' : 'Help'
        }
        titleSize="large"
        showMobileTitleAboveDivider={true}
        variation="support"
      >
        {type === 'activating' && <ActivationHelp />}
        {type === 'onboarding' && <OnboardingHelp />}
      </Sidepanel>
    </>
  )
}

export default Header
