const WaterIcon = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.61 11.2599L12.37 2.19993C12.3301 2.138 12.2754 2.08707 12.2107 2.0518C12.1461 2.01653 12.0736 1.99805 12 1.99805C11.9263 1.99805 11.8539 2.01653 11.7892 2.0518C11.7246 2.08707 11.6698 2.138 11.63 2.19993L6.38998 11.2699C5.57756 12.6426 5.24747 14.2476 5.45223 15.8294C5.65699 17.4113 6.38483 18.8793 7.51998 19.9999C8.69346 21.228 10.3022 21.9462 12 21.9999C13.5654 21.9608 15.0612 21.3447 16.2 20.2699C17.4439 19.165 18.2648 17.6618 18.522 16.018C18.7793 14.3742 18.4569 12.6921 17.61 11.2599ZM15.27 18.9999C14.3882 19.8459 13.2216 20.3311 12 20.3599C10.6774 20.3254 9.42242 19.768 8.50998 18.8099C7.62201 17.9372 7.05264 16.7918 6.89309 15.557C6.73353 14.3223 6.99302 13.0697 7.62998 11.9999L11.72 4.93993C11.7509 4.89247 11.7931 4.85347 11.8429 4.82647C11.8926 4.79947 11.9484 4.78533 12.005 4.78533C12.0616 4.78533 12.1173 4.79947 12.1671 4.82647C12.2169 4.85347 12.2591 4.89247 12.29 4.93993L16.37 11.9999C17.0237 13.1141 17.2713 14.4203 17.0708 15.6965C16.8702 16.9726 16.2339 18.1399 15.27 18.9999Z"
        fill={color}
      />
      <path
        d="M11.26 17.9401C10.6758 17.6104 10.1855 17.137 9.83552 16.5647C9.48553 15.9925 9.28743 15.3403 9.25999 14.6701C9.228 14.0204 9.35141 13.3725 9.61999 12.7801C9.65248 12.7082 9.66996 12.6304 9.67134 12.5515C9.67272 12.4726 9.65798 12.3942 9.62803 12.3212C9.59807 12.2482 9.55353 12.182 9.49712 12.1268C9.44072 12.0716 9.37364 12.0285 9.29999 12.0001C9.22919 11.9666 9.15242 11.9475 9.07417 11.944C8.99591 11.9404 8.91773 11.9525 8.8442 11.9795C8.77066 12.0065 8.70323 12.0479 8.64585 12.1012C8.58847 12.1545 8.54229 12.2187 8.50999 12.2901C8.16707 13.056 8.00948 13.8919 8.04999 14.7301C8.08832 15.6094 8.35221 16.464 8.81631 17.2118C9.28041 17.9596 9.92909 18.5755 10.7 19.0001C10.7954 19.0494 10.903 19.0703 11.01 19.0601C11.1117 19.056 11.2105 19.0251 11.2965 18.9707C11.3825 18.9163 11.4527 18.8402 11.5 18.7501C11.5749 18.6107 11.5917 18.4474 11.5467 18.2957C11.5018 18.144 11.3987 18.0162 11.26 17.9401Z"
        fill={color}
      />
    </svg>
  )
}

export default WaterIcon
