import { FullStory } from '@fullstory/browser'
import { Redirect, Router } from '@reach/router'
import queryString from 'query-string'
import { useContext, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import SnackbarProvider from 'react-simple-snackbar'
import { ThemeProvider } from 'styled-components'

import ErrorBoundaryFallback from './components/error-screen/error-boundary'
import ImpersonateBanner from './components/impersonate-banner'
import AppProvider, { AppContext } from './contexts/app-context'
import AuthContext from './contexts/auth-context'
import GlobalStyle from './GlobalStyle'
import Activating from './routes/activating'
import Dashboard from './routes/dashboard'
import Login from './routes/login'
import LoginRedirect from './routes/login-redirect'
import Onboarding from './routes/onboarding'
import ResetPassword from './routes/reset-password'
import SetPassword from './routes/set-password'
import Support from './routes/support'
import theme from './theme'
import API from './utils/api'
import { errorHandler } from './utils/helpers'

interface CustomRouteProps {
  component: any
  path: string
}

interface ThemeProviderProps {
  children: React.ReactNode
}

const Theme = ({ children }: ThemeProviderProps) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export const PrivateRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  const { user } = useContext(AppContext)

  if (user)
    FullStory('setIdentity', {
      uid: String(user.id),
      properties: {
        userId: user.id,
      },
    })

  return (
    <AuthContext.Consumer>
      {({ isAuth }) =>
        isAuth ? (
          <div id="privateRouteWrapper">
            {user?.impersonatedBy && user?.impersonatedBy?.id !== user?.id && <ImpersonateBanner />}
            <Component {...rest} />
          </div>
        ) : (
          <Redirect from="" to="/login?message=log-out-successful" noThrow={true} />
        )
      }
    </AuthContext.Consumer>
  )
}

const PublicRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  const { isAuth } = useContext(AuthContext)
  const isAuthOnLanding = useRef(isAuth) // Store this value in useRef so it doesn't update when login updates isAuth; we only want to redirect if the user is logged in when they visit the screen, not if they become logged in while on the screen
  return !isAuthOnLanding.current ? <Component {...rest} /> : <Redirect from="" to="/login-redirect" noThrow={true} />
}

const App = () => {
  const [userToImpersonate, setUserToImpersonate] = useState<string | undefined>(
    queryString.parse(window.location.search).userToImpersonate as string
  )

  // Token from localStorage is intended only to persist login state on domain close and reopen. Not stateful like isAuth boolean.
  const [isAuth, setAuth] = useState<boolean>(!!localStorage.getItem('protectToken'))
  const setIsAuth = (bool: boolean) => {
    if (bool) {
      localStorage.setItem('protectToken', 'meshify-authenticated')
    } else {
      localStorage.removeItem('protectToken')
    }
    setAuth(bool)
  }

  useEffect(() => {
    async function checkServerLogin() {
      if (isAuth) {
        try {
          await API.get('/api/users/me')
        } catch (e) {
          // Log the user out if there is an API error
          localStorage.removeItem('protectToken')
          setIsAuth(false)
        }
      }
    }
    checkServerLogin()
  }, [isAuth])

  return (
    <Theme>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorHandler}>
        <AuthContext.Provider value={{ setIsAuth, isAuth, setUserToImpersonate, userToImpersonate }}>
          <SnackbarProvider>
            <Router>
              <PublicRoute component={Login} path="/" />
              <PublicRoute component={Login} path="login" />
              <PublicRoute component={ResetPassword} path="reset-password" />
              <PublicRoute component={SetPassword} path="set-password" />
            </Router>
            <AppProvider>
              <Router>
                <PrivateRoute component={Activating} path="activating" />
                <PrivateRoute component={Activating} path="activating/:folderId" />
                <PrivateRoute component={Activating} path="activating/:folderId/device/:deviceId" />
                <PrivateRoute component={Dashboard} path="dashboard" />
                <PrivateRoute component={Dashboard} path="dashboard/:folderId" />
                <PrivateRoute component={Dashboard} path="dashboard/:folderId/:deviceId" />
                <PrivateRoute component={Support} path="support" />
                <PrivateRoute component={Onboarding} path="onboarding" />
                <PrivateRoute component={Onboarding} path="onboarding/:currentStep" />
                <PrivateRoute component={Onboarding} path="onboarding/:currentStep/location/:folderId" />
                <PrivateRoute component={LoginRedirect} path="login-redirect" />
              </Router>
            </AppProvider>
          </SnackbarProvider>
        </AuthContext.Provider>
      </ErrorBoundary>
    </Theme>
  )
}

export default App
