import styled from 'styled-components/macro'

export const Table = styled.table`
  ${(props: ThemeProps) => {
    return props.theme.elevation.elevation03
  }};
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
`

export const Th = styled.th`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  border: 0;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: ${({ theme }: ThemeProps) => `${theme.space.xs} ${theme.space.s}`};
  margin: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  line-height: 1.2;
  text-align: left;
  text-transform: uppercase;
`

export const Td = styled.td`
  border: 0;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: ${({ theme }: ThemeProps) => theme.space.s};
  margin: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
  text-align: left;
`

export const StyledTable = styled(Table)`
  margin-bottom: 2.4rem;

  &:first-child {
    @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
      margin-top: 2.4rem;
    }
  }
`

export const StyledTbody = styled.tbody`
  display: block;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
`

export const StyledTh = styled(Th)`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  letter-spacing: 0.075rem;
  padding: 1.6rem;
`

export const StyledTd = styled(Td)`
  border: 0;
  padding: 0.8rem 1.6rem;
  vertical-align: top;
`

export const TdBold = styled(Td)`
  font-weight: 600;
`

export const TdNoPadding = styled(StyledTd)`
  padding: 0;
`

export const TdAlignRight = styled(StyledTd)`
  text-align: right;
  width: 10rem;
  vertical-align: top;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    width: 17rem;
  }
`

export const TableHeaderButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin: 0;
    line-height: 1;
  }
`
