import styled from 'styled-components/macro'

import SearchDeviceGraphic from '../../assets/search-device.svg'
import { ContentSection, InstructionalImageContainer, InstructionalText } from './activating.styles'

const DesktopContentSection = styled(ContentSection)`
  display: none;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: block;
    background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  }
`

const Welcome = () => (
  <DesktopContentSection id="mainContent">
    <InstructionalImageContainer>
      <img src={SearchDeviceGraphic} alt="" />
    </InstructionalImageContainer>
    <InstructionalText data-cy="gettingStarted">Select a device to get started</InstructionalText>
  </DesktopContentSection>
)

export default Welcome
