import { VisuallyHidden } from '@reach/visually-hidden'

import theme from '../../theme'
import { getUseCaseIcon } from '../../utils/helpers'
import { CircleIcon } from '../icon'
import SkeletonLine from '../skeleton-line'
import {
  AlertCount,
  DeviceImageContainer,
  IconContainer,
  ImageCell,
  NameCell,
  SkeletonButton,
  StatusCell,
  StyledButton,
  StyledButtonContainer,
  SubText,
} from './sensor-select.styles'

interface IProps {
  id: number
  /** Determines which icon is displayed */
  useCase: DeviceUseCaseType | ''
  name: string
  /** Optional text that appears below the name */
  subText?: string
  /** Use this when using a component for the activation flow. Should not be used with `statusText`. */
  status?: 'inactive' | 'active' | 'incomplete'
  /** Use this if passing status text in a component for the dashboard. Should not be used with `status`. */
  statusText?: string
  /** Does not display if empty or 0, displays a blank orange circle if 1, and displays an orange circle with the alert count if greater than 1. */
  alertCount?: number
  disabled?: boolean
  selected?: boolean
  onSelect: (id: number) => void
}

/**
 * The SensorSelect component is used in the activation flow and in the dashboard.
 */
export const SensorSelect = ({
  id,
  useCase,
  name,
  subText,
  status,
  statusText,
  alertCount = 0,
  disabled = false,
  selected = false,
  onSelect,
}: IProps) => {
  let fill: string | undefined
  let stroke: string | undefined
  let displayStatus: string | undefined

  switch (status) {
    case 'active':
      fill = theme.colors.support_success
      stroke = 'none'
      displayStatus = 'Activated'
      break
    case 'inactive':
      fill = theme.colors.ui04
      stroke = 'none'
      displayStatus = 'Inactive'
      break
    case 'incomplete':
      fill = theme.colors.support_warning
      stroke = 'none'
      displayStatus = 'Missing Location'
      break
    default:
      break
  }
  return (
    <StyledButton disabled={disabled} selected={selected} onClick={() => onSelect(id)}>
      <StyledButtonContainer status={status} statusText={statusText}>
        <ImageCell>
          <DeviceImageContainer data-testid={`device-icon-${useCase}`} className="fs-exclude">
            {getUseCaseIcon(useCase)}
          </DeviceImageContainer>
          {alertCount > 0 && (
            <AlertCount type={useCase} className="fs-exclude">
              {alertCount > 1 && <span aria-hidden="true">{alertCount}</span>}
              <VisuallyHidden>{`${alertCount} alert${alertCount > 1 ? 's' : ''}`}</VisuallyHidden>
            </AlertCount>
          )}
        </ImageCell>
        <NameCell data-testid={`sensor-select-${useCase}`}>
          <div className="fs-exclude">
            {name}
            {subText && <SubText>{subText}</SubText>}
          </div>
        </NameCell>
        {status && (
          <StatusCell status={status} className="fs-exclude">
            <IconContainer>
              <CircleIcon fill={fill} stroke={stroke} />
            </IconContainer>
            {displayStatus}
          </StatusCell>
        )}
        {statusText && <StatusCell className="fs-exclude">{statusText}</StatusCell>}
      </StyledButtonContainer>
    </StyledButton>
  )
}

interface SkeletonProps {
  name?: boolean
  subText?: boolean
  image?: boolean
  status?: boolean
}

export const SensorSelectSkeleton = ({
  name = false,
  subText = false,
  image = false,
  status = false,
}: SkeletonProps) => {
  return (
    <SkeletonButton disabled={false} selected={false}>
      <StyledButtonContainer status={status}>
        {image && (
          <ImageCell>
            <SkeletonLine />
          </ImageCell>
        )}
        {(name || subText) && (
          <NameCell>
            <div style={{ width: '100%' }}>
              {name && <SkeletonLine />}
              {subText && (
                <SubText>
                  <SkeletonLine />
                </SubText>
              )}
            </div>
          </NameCell>
        )}
        {status && (
          <StatusCell>
            <div style={{ width: '95px' }}>
              <SkeletonLine />
            </div>
          </StatusCell>
        )}
      </StyledButtonContainer>
    </SkeletonButton>
  )
}

export default SensorSelect
