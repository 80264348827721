import Step1 from '../../../../assets/instructions-water-step1.png'
import Step2 from '../../../../assets/instructions-water-step2.png'
import Step3 from '../../../../assets/instructions-water-step3.png'
import Carousel from '../../../../components/carousel'
import InstructionCard from '../../../../components/instruction-card'
import { InstructionCardGrid, MobileCarousel } from './instruction-cards.styles'

const Cards = [
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step1}
      stepText="Step 1"
      headerText="Tap Firmly"
      instructionText="Wake sensor by firmly tapping with your finger."
    />
  ),
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step2}
      stepText="Step 2"
      headerText="Turn Over"
      instructionText="Turn sensor over (label facing down), wait 10 seconds and turn face up."
    />
  ),
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step3}
      stepText="Step 3"
      headerText="Wait for solid green"
      instructionText="A green light will flash as sensor connects and turns solid green once connected. The light will turn off to save battery life."
    />
  ),
]

export const InstructionCards = () => {
  return (
    <>
      <MobileCarousel>
        <Carousel numberOfSlides={3} autoplay={false}>
          {Cards.map((card, index) => {
            return card(index)
          })}
        </Carousel>
      </MobileCarousel>
      <InstructionCardGrid>
        {Cards.map((card, index) => {
          return card(index)
        })}
      </InstructionCardGrid>
    </>
  )
}

export default InstructionCards
