import { VisuallyHidden } from '@reach/visually-hidden'
import { AxiosError } from 'axios'
import { Formik, FormikProps } from 'formik'
import libphonenumber from 'google-libphonenumber'
import { useContext, useMemo, useRef, useState } from 'react'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import { useSnackbar } from 'react-simple-snackbar'
import 'react-toggle/style.css'
import * as Yup from 'yup'

import { AppContext } from '../../contexts/app-context'
import { EmptyFillerSpace } from '../../routes/activating/activating.styles'
import { FieldError } from '../../shared-styles/alert.styles'
import { GhostButton, NotificationPreferencesButton } from '../../shared-styles/button.styles'
import { Checkbox, FormField, Input, Label, Select, SelectContainer } from '../../shared-styles/form.styles'
import { A, AgreementLink } from '../../shared-styles/typography.styles'
import theme from '../../theme'
import API from '../../utils/api'
import { PRIVACY_POLICY, SIDEPANEL_ERROR_OPTS, TERMS_AND_CONDITIONS } from '../../utils/constants'
import { formatIntlPhoneNumber, prettifyPhoneNumber, SD_ROLE } from '../../utils/helpers'
import parsePhoneNumber from '../../utils/parse-phone-number'
import { Alert } from '../alert'
import Btn from '../button'
import { CheckIconOutline, ErrorIconOutline } from '../icon'
import '../phone-input/phoneInput.css'
import '../toggle-input/toggle.css'
import { PasswordField } from './../password-field'
import { getPayload } from './helpers'
import {
  CTAButtonContainer,
  DisabledEmailAlert,
  ModalForm,
  ModalHeader,
  NotificationPreferencesContainer,
  NotificationPreferencesText,
  PhoneExtField,
  PhoneExtTypeContainer,
  PhoneFieldContainer,
  PhoneTypeField,
  TextAgreement,
} from './notification-contact-form.styles'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export interface FormValues {
  firstName: string
  lastName: string
  email: string
  emailChanged: boolean
  currPassword: string
  mobileNumberCountry: string
  mobileNumberCountryCode: string
  mobileNumberNoCountry: string
  phone_type: 'Landline' | 'Mobile Phone'
  phone_ext: string
  backupNumberCountry: string
  backupNumberCountryCode: string
  backupNumberNoCountry: string
  backup_phone_type: 'Landline' | 'Mobile Phone'
  backup_phone_ext: string
  textAgreement: boolean
  role: string
  receiveAlerts: boolean
  notificationPreferences: NotificationPreference[]
}

let alreadyValidatedMobileNumber: string
let currentlyValidatingMobileNumber: string | undefined

const NotificationPreferenceButton = ({
  active,
  label,
  onClick,
  disabled = false,
}: {
  active: boolean
  label: string
  onClick: () => void
  disabled?: boolean
}) => {
  return (
    <NotificationPreferencesButton
      onClick={onClick}
      active={active}
      disabled={disabled}
      type="button"
      data-testid={`${label}-${active}`}
      className="fs-exclude"
    >
      {active ? <CheckIconOutline fill={theme.colors.brand01} /> : <ErrorIconOutline fill={theme.colors.text01} />}
      <NotificationPreferencesText>{label}</NotificationPreferencesText>
    </NotificationPreferencesButton>
  )
}

const NotificationContactForm = ({
  onBack,
  user,
  folder,
  editUserInfo,
  canAddUsers,
  serviceDeskContact,
  receiveAlertsCount,
  userCount,
  screen,
}: {
  onBack: (msg: string) => void
  user: IUserType
  folder: IFolderType
  editUserInfo: INotificationContactType | undefined
  canAddUsers: boolean
  serviceDeskContact: boolean
  receiveAlertsCount: number
  userCount: number
  screen?: string
}) => {
  const { setUser } = useContext(AppContext)

  const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)
  const [deleteConfirmationScreenVisible, setDeleteConfirmationScreenVisible] = useState<boolean>(false)
  const [openErrorSnackbar] = useSnackbar(SIDEPANEL_ERROR_OPTS)

  const formikRef = useRef<FormikProps<FormValues>>(null)

  const { user: editUser } = editUserInfo || {}
  const mobileNumber = parsePhoneNumber(editUser?.phone, user, folder)
  const backupNumber = parsePhoneNumber(editUser?.metadata?.backup_phone, user, folder)

  const isEditable = editUserInfo ? editUserInfo.isEditable : canAddUsers
  const folderId = folder.id
  const handlingServiceDesk = editUser?.role?.name === SD_ROLE || serviceDeskContact

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('First Name is required.'),
        lastName: Yup.string().test('requiredLastName', 'Last Name is required.', function () {
          return this.parent.role === SD_ROLE || !!this.parent.lastName?.trim()
        }),
        email: Yup.string().trim().required('Email is required.').email('Please enter correct email format.'),
        currPassword: Yup.string().test(
          'requiredIfChangingEmail',
          "Your current password is required if changing your account's email address.",
          function () {
            return (
              !this.parent.emailChanged || (this.parent.currPassword && this.parent.email && this.parent.emailChanged)
            )
          }
        ),
        mobileNumberNoCountry: Yup.string()
          .test(
            'validMobileNumber',
            handlingServiceDesk ? 'Please enter a valid phone number.' : 'Please enter a valid mobile number.',
            function () {
              if (!this.parent.mobileNumberNoCountry) return true

              let isValid = true
              const phoneNumber = `+${this.parent.mobileNumberCountryCode} ${this.parent.mobileNumberNoCountry}`
              try {
                const parsedPhoneNumber = phoneUtil.parse(phoneNumber)
                isValid = phoneUtil.isValidNumber(parsedPhoneNumber)
              } catch {
                isValid = false // If the number can't be parsed by phoneUtil
              }

              // To limit the number of API calls, we only check that this is a valid mobile number via the API if it is in the right phone number format and if it hasn't already been checked
              if (
                isValid &&
                alreadyValidatedMobileNumber !== formatIntlPhoneNumber(phoneNumber) &&
                currentlyValidatingMobileNumber !== formatIntlPhoneNumber(phoneNumber)
              ) {
                currentlyValidatingMobileNumber = formatIntlPhoneNumber(phoneNumber)
                return new Promise(resolve => {
                  API.get(`/api/users/phoneinfo?number=${formatIntlPhoneNumber(phoneNumber)}`)
                    .then(res => {
                      if (
                        (!res.data.carrier.error_code &&
                          res.data.carrier.type === 'mobile' &&
                          this.parent.phone_type === 'Mobile Phone') ||
                        (!res.data.carrier.error_code && this.parent.phone_type === 'Landline')
                      ) {
                        alreadyValidatedMobileNumber = formatIntlPhoneNumber(phoneNumber)
                        resolve(true)
                      } else resolve(false)
                    })
                    .catch(() => {
                      resolve(false)
                    })
                    .finally(() => {
                      currentlyValidatingMobileNumber = undefined
                    })
                })
              } else return isValid
            }
          )
          .test('requiredIfCallCenter', 'A valid phone number is required for Service Desks.', function () {
            return (this.parent.role === SD_ROLE && this.parent.mobileNumberNoCountry) || this.parent.role !== SD_ROLE
          })
          .test('requiredIfSelf', 'Please enter a valid mobile number.', function () {
            return (
              (this.parent.mobileNumberNoCountry && editUser?.id === user?.id) ||
              editUser?.id !== user?.id ||
              this.parent.role === SD_ROLE
            )
          }),
        textAgreement: Yup.boolean().test(
          'requiredIfMobileNumber',
          'You must agree to receive text alerts if you enter your mobile number.',
          function () {
            return (
              !this.parent.notificationPreferences.includes('sms') ||
              (this.parent.notificationPreferences.includes('sms') && this.parent.textAgreement)
            )
          }
        ),
        backupNumberNoCountry: Yup.string().test(
          'validBackupNumber',
          'Please enter a valid phone number.',
          function () {
            if (!this.parent.backupNumberNoCountry) return true

            let isValid = true
            try {
              const phoneNumber = phoneUtil.parse(
                `+${this.parent.backupNumberCountryCode} ${this.parent.backupNumberNoCountry}`
              )
              isValid = phoneUtil.isValidNumber(phoneNumber)
            } catch {
              isValid = false // If the number can't be parsed by phoneUtil
            }
            return isValid
          }
        ),
        backup_phone_type: Yup.string().test('requiredBackupPhoneType', 'Backup number type is required.', function () {
          return (
            !this.parent.backupNumberNoCountry || (this.parent.backupNumberNoCountry && this.parent.backup_phone_type)
          )
        }),
        backup_phone_ext: Yup.string().test(
          'requiredBackupPhoneForExtension',
          'Backup number is required if entering an extension.',
          function () {
            return !this.parent.backup_phone_ext || (this.parent.backup_phone_ext && this.parent.backupNumberNoCountry)
          }
        ),
      }),
    [editUser?.id, user?.id, handlingServiceDesk]
  )

  const initialValues: FormValues = {
    firstName: editUser?.information?.first || '',
    lastName: editUser?.information?.last || '',
    email: editUser?.email || '',
    emailChanged: false,
    currPassword: '',
    mobileNumberCountry: mobileNumber.countryAbbr,
    mobileNumberCountryCode: mobileNumber.countryCode,
    mobileNumberNoCountry: mobileNumber.number,
    phone_type: editUser?.metadata?.phone_type || (handlingServiceDesk ? 'Landline' : 'Mobile Phone'),
    phone_ext: editUser?.metadata?.phone_ext || '',
    backupNumberCountry: backupNumber.countryAbbr,
    backupNumberCountryCode: backupNumber.countryCode,
    backupNumberNoCountry: backupNumber.number,
    backup_phone_type: editUser?.metadata?.backup_phone_type || (handlingServiceDesk ? 'Landline' : ''),
    backup_phone_ext: editUser?.metadata?.backup_phone_ext || '',
    textAgreement: !!(editUser && editUser?.phone) || false,
    role: handlingServiceDesk ? SD_ROLE : editUser?.role?.name ?? 'Notification Contact',
    receiveAlerts: JSON.parse(JSON.stringify(editUser?.preferences?.notification || [])).length > 0,
    notificationPreferences: JSON.parse(JSON.stringify(editUser?.preferences?.notification || [])),
  }

  const isDeleteButtonDisabled = () =>
    userCount < 2 ||
    ((editUser?.preferences.notification?.length || 0) > 0 && receiveAlertsCount < 2) ||
    editUser?.id === user?.id ||
    editUser?.isRoot ||
    !isEditable

  const onSubmit = async (formValues: FormValues) => {
    if (editUser) {
      await editContact(formValues)
    } else {
      await addContact(formValues)
    }
  }

  const deleteContact = async () => {
    setSubmitWaiting(true)

    if (folderId && editUser?.id) {
      try {
        await API.doDelete(`/api/v2/protect/folders/${folderId}/notification-contacts/${editUser.id}`)
        setDeleteConfirmationScreenVisible(false)
        onBack('Contact removed.') // Pass success messages to previous screen (contacts list)
      } catch {
        setDeleteConfirmationScreenVisible(false)
        openErrorSnackbar('Unable to remove contact.')
      } finally {
        setSubmitWaiting(false)
      }
    }
  }

  const editContact = async (values: FormValues) => {
    if (!editUser) return
    try {
      const updatedUserResponse = await API.put(
        `/api/v2/protect/folders/${folderId}/notification-contacts`,
        getPayload({ values, contact: editUser })
      )
      const updatedUser = updatedUserResponse.data.user

      if (updatedUser.id === user.id) {
        // Refresh global user state if editing self
        setUser(prev => prev && { ...prev, ...updatedUser })
      }
      onBack('Contact updated.')
    } catch {
      openErrorSnackbar('Unable to save changes.')
    }
  }

  const addContact = async (values: FormValues) => {
    try {
      await API.post(
        `/api/v2/protect/folders/${folderId}/notification-contacts`,
        getPayload({ values, folderId, role: values.role })
      )
      onBack('Contact added.')
    } catch (e) {
      if (
        (e as AxiosError).response?.status === 403 &&
        ((e as AxiosError).response?.data?.message === 'folders access is not allowed' ||
          ((e as AxiosError).response?.data as ApiV2Error)?.details?.[0].value.signature ===
            'protect_permission_denied_folders_not_allowed')
      ) {
        openErrorSnackbar(
          'The user you are attempting to add to this location currently exists. Please contact Sensor Support at (844) 468-1866',
          60000
        )
      } else {
        openErrorSnackbar('Unable to add contact.')
      }
    }
  }

  const toggleNotificationPreferences = async (type: NotificationPreference | NotificationPreference[]) => {
    if (!formikRef.current) return

    const { values, setFieldValue } = formikRef.current
    const preferences = [...values.notificationPreferences]

    const updatePreferences = (notifType: NotificationPreference) => {
      // Remove the preference
      if (preferences.includes(notifType)) {
        const i = preferences.indexOf(notifType)
        preferences.splice(i, 1)
      }
      // Add the preference
      else {
        preferences.push(notifType)
      }
    }

    if (Array.isArray(type)) {
      type.forEach(notifType => {
        updatePreferences(notifType)
      })
    } else {
      updatePreferences(type)
    }

    // If Call Center, make sure Phone is always toggled
    if (handlingServiceDesk && !preferences.includes('phone')) preferences.push('phone')

    const receiveAlerts = preferences.length > 0
    if (receiveAlertsCount < 2 && !receiveAlerts) {
      openErrorSnackbar('There must be at least one contact receiving notifications on the account.', 10000)
      return
    }
    setFieldValue('receiveAlerts', receiveAlerts)
    setFieldValue('notificationPreferences', preferences)
  }

  const hasMobileNumber = (values: FormValues) => {
    return (
      (values.phone_type === 'Mobile Phone' && values.mobileNumberNoCountry) ||
      (values.backup_phone_type === 'Mobile Phone' && values.backupNumberNoCountry)
    )
  }

  const autoTogglePhoneNotifications = (values: FormValues, newValue: string, prevValue: string) => {
    const types: NotificationPreference[] = []
    // If we're adding a new phone number, toggle the phone notification preference on
    if (!prevValue && newValue && !values.notificationPreferences.includes('phone')) types.push('phone')

    // If we're removing a phone number, toggle the phone notification preference off if there are no other numbers added
    if (
      !newValue &&
      values.notificationPreferences.includes('phone') &&
      !values.backupNumberNoCountry &&
      !values.mobileNumberNoCountry
    )
      types.push('phone')

    // If we're adding a new phone number and it is selected as a Mobile Number, toggle the sms notification preference on
    if (!prevValue && newValue && !values.notificationPreferences.includes('sms') && hasMobileNumber(values))
      types.push('sms')

    // If we're removing a phone number, toggle the sms notification preference off if there are no other Mobile Numbers added
    if (!newValue && values.notificationPreferences.includes('sms') && !hasMobileNumber(values)) types.push('sms')

    // If we're changing from Landline to Mobile, toggle SMS on
    if (newValue === 'Mobile Phone' && !values.notificationPreferences.includes('sms') && hasMobileNumber(values))
      types.push('sms')
    // If we're changing from Mobile to Landline, toggle SMS off
    if (newValue === 'Landline' && values.notificationPreferences.includes('sms') && !hasMobileNumber(values))
      types.push('sms')

    if (types.length > 0) toggleNotificationPreferences(types)
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
      validateOnMount={!!editUser}
      initialTouched={
        !!editUser
          ? {
              firstName: true,
              lastName: true,
              email: true,
              mobileNumberNoCountry: true,
              phone_type: true,
              phone_ext: true,
              backupNumberNoCountry: true,
              backup_phone_type: true,
              backup_phone_ext: true,
              textAgreement: true,
            }
          : undefined
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        dirty,
        isSubmitting,
        submitCount,
        isValid,
      }) => (
        <ModalForm data-testid="notification-form" onSubmit={handleSubmit}>
          {!deleteConfirmationScreenVisible && (
            <>
              <ModalHeader>
                {editUser ? 'Edit Contact' : serviceDeskContact ? 'Add Service Desk' : 'Add Contact'}
              </ModalHeader>
              {!isEditable && (
                <Alert
                  type="info_permission_denied"
                  icon="solid_info"
                  message={
                    folder.folderType !== 'Participant' && screen === 'profile'
                      ? '(View only) Please select a participant folder to edit your details.'
                      : '(View only) Please ask your account manager for edit access.'
                  }
                  iconAlignment="top"
                />
              )}
              <NotificationPreferencesContainer>
                <NotificationPreferenceButton
                  active={values.notificationPreferences.includes('sms')}
                  label="SMS"
                  onClick={() => toggleNotificationPreferences('sms')}
                  disabled={!isEditable}
                />
                <NotificationPreferenceButton
                  active={values.notificationPreferences.includes('email')}
                  label="EMAIL"
                  onClick={() => toggleNotificationPreferences('email')}
                  disabled={!isEditable}
                />
                <NotificationPreferenceButton
                  active={values.notificationPreferences.includes('phone')}
                  label="PHONE"
                  onClick={() => toggleNotificationPreferences('phone')}
                  disabled={!isEditable || (handlingServiceDesk && values.notificationPreferences.includes('phone'))}
                />
              </NotificationPreferencesContainer>
              <FormField>
                <Label htmlFor="firstName">{handlingServiceDesk ? 'Name' : 'First Name'}</Label>
                <Input
                  disabled={!isEditable}
                  id="firstName"
                  placeholder={handlingServiceDesk ? 'Name' : 'First Name'}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorState={!!(touched.firstName && errors.firstName)}
                />
                {touched.firstName && errors.firstName && <FieldError>{errors.firstName}</FieldError>}
              </FormField>
              {!handlingServiceDesk && (
                <FormField>
                  <Label htmlFor="lastName">Last Name</Label>
                  <Input
                    disabled={!isEditable}
                    id="lastName"
                    placeholder="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorState={!!(touched.lastName && errors.lastName)}
                  />
                  {touched.lastName && errors.lastName && <FieldError>{errors.lastName}</FieldError>}
                </FormField>
              )}
              <FormField>
                <Label htmlFor="email">Email</Label>
                <Input
                  disabled={!isEditable || !!editUser}
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={e => {
                    const value = e.target.value
                    if (
                      (!values.email && value && !values.notificationPreferences.includes('email')) ||
                      (!value && values.notificationPreferences.includes('email'))
                    )
                      toggleNotificationPreferences('email')
                    handleChange(e)
                    if (value !== initialValues.email && editUser?.id === user.id) setFieldValue('emailChanged', true)
                    if (value === initialValues.email) setFieldValue('emailChanged', false)
                  }}
                  onBlur={handleBlur}
                  errorState={!!(touched.email && errors.email)}
                />
                {touched.email && errors.email && <FieldError>{errors.email}</FieldError>}
              </FormField>
              {isEditable && !!editUser ? (
                <DisabledEmailAlert>
                  <Alert
                    type="info_permission_denied"
                    icon="solid_info"
                    message="To change email address, please contact Sensor Support at (844) 468-1866."
                    iconAlignment="top"
                  />
                </DisabledEmailAlert>
              ) : null}
              {values.emailChanged && (
                <PasswordField
                  name="currPassword"
                  label="Current Password"
                  onChange={value => setFieldValue('currPassword', value)}
                  onBlur={handleBlur}
                  errorState={!!(touched.currPassword && errors.currPassword)}
                  formikData={{ touched, errors }}
                />
              )}
              <PhoneFieldContainer>
                <FormField>
                  <Label htmlFor="mobileNumberNoCountry">{handlingServiceDesk ? 'Main Number' : 'Mobile Number'}</Label>
                  <IntlTelInput
                    disabled={!isEditable}
                    fieldId="mobileNumberNoCountry"
                    value={values.mobileNumberNoCountry}
                    placeholder=""
                    inputClassName={touched.mobileNumberNoCountry && errors.mobileNumberNoCountry ? 'errorState' : ''}
                    preferredCountries={['us', 'ca', 'de']}
                    separateDialCode={true}
                    defaultCountry={values.mobileNumberCountry}
                    format={true}
                    onPhoneNumberChange={(phoneIsValid: boolean, value: string) => {
                      const formattedValue = phoneIsValid
                        ? prettifyPhoneNumber(`+${values.mobileNumberCountryCode} ${value}`)
                        : value

                      autoTogglePhoneNotifications(
                        { ...values, mobileNumberNoCountry: formattedValue },
                        formattedValue,
                        values.mobileNumberNoCountry
                      )

                      setFieldValue('mobileNumberNoCountry', formattedValue)
                    }}
                    onPhoneNumberBlur={() => setFieldTouched('mobileNumberNoCountry', true)}
                    onSelectFlag={(
                      _currentNumber: string,
                      selectedCountryData: {
                        dialCode: string
                      }
                    ) => {
                      setFieldValue('mobileNumberCountryCode', selectedCountryData.dialCode)
                    }}
                  />
                </FormField>
                <PhoneExtTypeContainer>
                  {values.phone_type === 'Landline' || handlingServiceDesk ? (
                    <PhoneExtField>
                      <Label htmlFor="phone_ext">Extension</Label>
                      <Input
                        disabled={!isEditable}
                        id="phone_ext"
                        value={values.phone_ext}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </PhoneExtField>
                  ) : null}
                  {handlingServiceDesk ? (
                    <PhoneTypeField>
                      <Label htmlFor="phone_type">Type</Label>
                      <SelectContainer>
                        <Select
                          id="phone_type"
                          disabled={!isEditable}
                          value={values.phone_type}
                          onChange={e => {
                            const value = e.target.value as 'Landline' | 'Mobile Phone'
                            autoTogglePhoneNotifications({ ...values, phone_type: value }, value, values.phone_type)
                            setFieldValue('phone_type', value)
                            setFieldTouched('phone_type', true)
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="Landline">Landline</option>
                          <option value="Mobile Phone">Mobile Phone</option>
                        </Select>
                      </SelectContainer>
                    </PhoneTypeField>
                  ) : null}
                </PhoneExtTypeContainer>
              </PhoneFieldContainer>
              {touched.mobileNumberNoCountry && errors.mobileNumberNoCountry && (
                <FieldError>{errors.mobileNumberNoCountry}</FieldError>
              )}
              {values.notificationPreferences.includes('sms') ? (
                <FormField>
                  <Checkbox>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setFieldValue('textAgreement', !values.textAgreement)
                      }}
                      checked={values.textAgreement}
                      disabled={!isEditable}
                    />
                    <span>
                      <TextAgreement>
                        By checking this box, you agree to receive text alerts on your mobile phone when a condition is
                        detected. Message and data rates may apply. You can opt out by replying STOP. For more
                        information, please see our{' '}
                        <AgreementLink href={PRIVACY_POLICY} target="_blank" rel="noopener">
                          Privacy Policy<VisuallyHidden>(PDF)</VisuallyHidden>
                        </AgreementLink>{' '}
                        or{' '}
                        <AgreementLink href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener">
                          Terms of Use<VisuallyHidden>(PDF)</VisuallyHidden>
                        </AgreementLink>
                        . For help, please contact us at <A href="mailto:support@meshify.com">support@meshify.com</A>
                      </TextAgreement>
                    </span>
                  </Checkbox>
                  {touched.mobileNumberNoCountry && errors.textAgreement && submitCount > 0 && (
                    <FieldError>{errors.textAgreement}</FieldError>
                  )}
                </FormField>
              ) : null}
              <PhoneFieldContainer>
                <FormField>
                  <Label htmlFor="backupNumberNoCountry">Backup Number</Label>
                  <IntlTelInput
                    disabled={!isEditable}
                    fieldId="backupNumberNoCountry"
                    value={values.backupNumberNoCountry}
                    placeholder=""
                    inputClassName={touched.backupNumberNoCountry && errors.backupNumberNoCountry ? 'errorState' : ''}
                    preferredCountries={['us', 'ca', 'de']}
                    separateDialCode={true}
                    defaultCountry={values.backupNumberCountry}
                    format={true}
                    onPhoneNumberChange={(phoneIsValid: boolean, value: string) => {
                      const formattedValue = phoneIsValid
                        ? prettifyPhoneNumber(`+${values.backupNumberCountryCode} ${value}`)
                        : value

                      autoTogglePhoneNotifications(
                        { ...values, backupNumberNoCountry: formattedValue },
                        formattedValue,
                        values.backupNumberNoCountry
                      )

                      setFieldValue('backupNumberNoCountry', formattedValue)
                    }}
                    onPhoneNumberBlur={() => setFieldTouched('backupNumberNoCountry', true)}
                    onSelectFlag={(
                      _currentNumber: string,
                      selectedCountryData: {
                        dialCode: string
                      }
                    ) => {
                      setFieldValue('backupNumberCountryCode', selectedCountryData.dialCode)
                    }}
                  />
                </FormField>
                <PhoneExtTypeContainer>
                  <PhoneExtField>
                    <Label htmlFor="backup_phone_ext">Extension</Label>
                    <Input
                      disabled={!isEditable}
                      id="backup_phone_ext"
                      value={values.backup_phone_ext}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorState={!!(touched.backup_phone_ext && errors.backup_phone_ext)}
                    />
                  </PhoneExtField>
                  <PhoneTypeField>
                    <Label htmlFor="backup_phone_type">Type</Label>
                    <SelectContainer>
                      <Select
                        id="backup_phone_type"
                        disabled={!isEditable}
                        value={values.backup_phone_type}
                        onChange={e => {
                          const value = e.target.value as 'Landline' | 'Mobile Phone'
                          autoTogglePhoneNotifications(
                            { ...values, backup_phone_type: value },
                            value,
                            values.backup_phone_type
                          )
                          setFieldValue('backup_phone_type', value)
                          setFieldTouched('backup_phone_type', true)
                        }}
                        onBlur={handleBlur}
                      >
                        <option />
                        <option value="Landline">Landline</option>
                        <option value="Mobile Phone">Mobile Phone</option>
                      </Select>
                    </SelectContainer>
                  </PhoneTypeField>
                </PhoneExtTypeContainer>
              </PhoneFieldContainer>
              {((touched.backupNumberNoCountry && errors.backupNumberNoCountry) ||
                (touched.backup_phone_ext && errors.backup_phone_ext) ||
                (touched.backup_phone_type && errors.backup_phone_type)) && (
                <FieldError>
                  {errors.backupNumberNoCountry ?? errors.backup_phone_ext ?? errors.backup_phone_type}
                </FieldError>
              )}
              <CTAButtonContainer center={!(screen === 'profile' || !isDeleteButtonDisabled())}>
                {!isDeleteButtonDisabled() && (
                  <GhostButton
                    onClick={e => {
                      e.preventDefault()
                      setDeleteConfirmationScreenVisible(true)
                    }}
                    dangerStyling={true}
                  >
                    Delete Contact
                  </GhostButton>
                )}
                {screen === 'profile' && (
                  <GhostButton
                    onClick={e => {
                      e.preventDefault()
                      onBack('dismiss')
                    }}
                  >
                    Cancel
                  </GhostButton>
                )}
                <Btn
                  buttonType="secondary"
                  type="submit"
                  disabled={!isValid || !(dirty && isEditable)}
                  waiting={isSubmitting}
                  data-testid="contactSubmitBtn"
                >
                  {editUser ? 'Save Changes' : 'Add Contact'}
                </Btn>
              </CTAButtonContainer>
              {screen !== 'profile' && <EmptyFillerSpace />}
            </>
          )}
          {editUser && deleteConfirmationScreenVisible && (
            <>
              <ModalHeader className="fs-exclude">
                Are you sure you want to delete {editUser.information?.first} {editUser.information?.last}?
              </ModalHeader>
              <p>
                If you delete this contact, they will no longer receive alerts or have access to monitor this location.
                You cannot undo this action.
              </p>
              <CTAButtonContainer center={false}>
                <GhostButton
                  onClick={e => {
                    e.preventDefault()
                    setDeleteConfirmationScreenVisible(false)
                  }}
                >
                  Cancel
                </GhostButton>

                <Btn
                  onClick={e => {
                    e.preventDefault()
                    deleteContact()
                  }}
                  dangerStyling={true}
                  waiting={submitWaiting}
                >
                  Delete
                </Btn>
              </CTAButtonContainer>
            </>
          )}
        </ModalForm>
      )}
    </Formik>
  )
}

export default NotificationContactForm
