const ServerRoomMonitoringIcon = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 2H4.5C4.36739 2 4.24021 2.05268 4.14645 2.14645C4.05268 2.24021 4 2.36739 4 2.5V7.25C4 7.38261 4.05268 7.50979 4.14645 7.60355C4.24021 7.69732 4.36739 7.75 4.5 7.75H19.5C19.6326 7.75 19.7598 7.69732 19.8536 7.60355C19.9473 7.50979 20 7.38261 20 7.25V2.5C20 2.36739 19.9473 2.24021 19.8536 2.14645C19.7598 2.05268 19.6326 2 19.5 2ZM18.5 6.07C18.5 6.1363 18.4737 6.19989 18.4268 6.24678C18.3799 6.29366 18.3163 6.32 18.25 6.32H5.77C5.7037 6.32 5.64011 6.29366 5.59322 6.24678C5.54634 6.19989 5.52 6.1363 5.52 6.07V3.69C5.52 3.6237 5.54634 3.56011 5.59322 3.51322C5.64011 3.46634 5.7037 3.44 5.77 3.44H18.23C18.2963 3.44 18.3599 3.46634 18.4068 3.51322C18.4537 3.56011 18.48 3.6237 18.48 3.69L18.5 6.07Z"
        fill={color}
      />
      <path
        d="M7.77002 5.62988C8.18423 5.62988 8.52002 5.2941 8.52002 4.87988C8.52002 4.46567 8.18423 4.12988 7.77002 4.12988C7.35581 4.12988 7.02002 4.46567 7.02002 4.87988C7.02002 5.2941 7.35581 5.62988 7.77002 5.62988Z"
        fill={color}
      />
      <path d="M16.98 4.37988H10.02V5.37988H16.98V4.37988Z" fill={color} />
      <path
        d="M19.5 16.25H4.5C4.36739 16.25 4.24021 16.3027 4.14645 16.3964C4.05268 16.4902 4 16.6174 4 16.75V21.5C4 21.6326 4.05268 21.7598 4.14645 21.8536C4.24021 21.9473 4.36739 22 4.5 22H19.5C19.6326 22 19.7598 21.9473 19.8536 21.8536C19.9473 21.7598 20 21.6326 20 21.5V16.75C20 16.6174 19.9473 16.4902 19.8536 16.3964C19.7598 16.3027 19.6326 16.25 19.5 16.25ZM18.5 20.31C18.5 20.3763 18.4737 20.4399 18.4268 20.4868C18.3799 20.5337 18.3163 20.56 18.25 20.56H5.77C5.7037 20.56 5.64011 20.5337 5.59322 20.4868C5.54634 20.4399 5.52 20.3763 5.52 20.31V17.93C5.52 17.8637 5.54634 17.8001 5.59322 17.7532C5.64011 17.7063 5.7037 17.68 5.77 17.68H18.23C18.2963 17.68 18.3599 17.7063 18.4068 17.7532C18.4537 17.8001 18.48 17.8637 18.48 17.93L18.5 20.31Z"
        fill={color}
      />
      <path
        d="M7.77002 19.8701C8.18423 19.8701 8.52002 19.5343 8.52002 19.1201C8.52002 18.7059 8.18423 18.3701 7.77002 18.3701C7.35581 18.3701 7.02002 18.7059 7.02002 19.1201C7.02002 19.5343 7.35581 19.8701 7.77002 19.8701Z"
        fill={color}
      />
      <path d="M16.98 18.6201H10.02V19.6201H16.98V18.6201Z" fill={color} />
      <path
        d="M19.5 9.12012H4.5C4.36739 9.12012 4.24021 9.1728 4.14645 9.26656C4.05268 9.36033 4 9.48751 4 9.62012V14.3801C4 14.5127 4.05268 14.6399 4.14645 14.7337C4.24021 14.8274 4.36739 14.8801 4.5 14.8801H19.5C19.6326 14.8801 19.7598 14.8274 19.8536 14.7337C19.9473 14.6399 20 14.5127 20 14.3801V9.62012C20 9.48751 19.9473 9.36033 19.8536 9.26656C19.7598 9.1728 19.6326 9.12012 19.5 9.12012ZM18.5 13.1901C18.5 13.2564 18.4737 13.32 18.4268 13.3669C18.3799 13.4138 18.3163 13.4401 18.25 13.4401H5.77C5.7037 13.4401 5.64011 13.4138 5.59322 13.3669C5.54634 13.32 5.52 13.2564 5.52 13.1901V10.8101C5.52 10.7438 5.54634 10.6802 5.59322 10.6333C5.64011 10.5865 5.7037 10.5601 5.77 10.5601H18.23C18.2963 10.5601 18.3599 10.5865 18.4068 10.6333C18.4537 10.6802 18.48 10.7438 18.48 10.8101L18.5 13.1901Z"
        fill={color}
      />
      <path
        d="M7.77002 12.75C8.18423 12.75 8.52002 12.4142 8.52002 12C8.52002 11.5858 8.18423 11.25 7.77002 11.25C7.35581 11.25 7.02002 11.5858 7.02002 12C7.02002 12.4142 7.35581 12.75 7.77002 12.75Z"
        fill={color}
      />
      <path d="M16.98 11.5H10.02V12.5H16.98V11.5Z" fill={color} />
    </svg>
  )
}

export default ServerRoomMonitoringIcon
