import theme from '../theme'

export const TERMS_AND_CONDITIONS = 'https://hsbprod.carbon.meshify.com/api/files/public/legal/termsofuse.pdf'
export const PRIVACY_POLICY = 'https://www.munichre.com/hsb/en/general/legal/privacy-statement.html'
export const DEFAULT_DEVICE_LOCATIONS = [
  'Bathroom',
  'Kitchen',
  'Basement',
  'Closet',
  'Utility Room',
  'Laundry Room',
  'Office',
  'Stairwell',
  'Other',
]
export const ONBOARDING_STEP: { [x: string]: OnboardingSteps } = {
  NOT_STARTED: 0,
  PARTICIPATION_AGREEMENT: 1,
  CONFIRM_ADDRESS: 2,
  COMPLETE: 3,
}
export const ERROR_MESSAGE = {
  folders_forbidden_update_folder:
    'Error 403: Folder access is not allowed. This is usually caused by the user not having access to the parent folder or not having Write Folders permissions.',
  users_forbidden_update_user:
    "Error 403: Can't update user. This is usually caused by the current user not having access to all the folders that the user being updated has access to.",
}

/** The temp_reporting_level channel for nbiot_leak (Leak & Freeze) devices is considered 'In Range' if it has a value of 2. Less than 2 is considered low, more than two is considered high. */
export const TEMP_LEVEL_IN_RANGE = 2

/** The default date used by the API for a timestamp property when no date has been set. This is essentially a `null` or `undefined` date. */
export const API_DEFAULT_DATE = '1970-01-01T00:00:00Z'

/** Snackbar options for styling and positioning snackbars when there is a sidepanel */
export const SIDEPANEL_SUCCESS_OPTS = {
  position: 'bottom-right',
  style: { backgroundColor: theme.colors.support_success, width: '43vw', maxWidth: '60rem' },
}
export const SIDEPANEL_ERROR_OPTS = {
  position: 'bottom-right',
  style: { backgroundColor: theme.colors.support_error, width: '43vw', maxWidth: '60rem' },
}
