import { useEffect, useState } from 'react'

import theme from '../../theme'
import { CheckIconOutline, ChevronIcon } from '../icon'
import { Btn } from './../button/index'
import {
  Panel,
  StepHeader,
  StepHeaderLeft,
  StepHeaderSubText,
  StepHeaderText,
  StepIconContainer,
  StepNumber,
  StepPanelContent,
} from './step-panel.styles'

interface IProps {
  children: React.ReactChildren | React.ReactNode | string
  step: number
  stepHeader: string
  secondaryStepHeader?: string
  stepComplete?: boolean
  initialToggle?: boolean
  toggleStep?: boolean
  continueIsDisabled: boolean
  onSelectContinue: () => void
}

/**
 * The StepPanel component is used in activation for the placement steps.
 */
export const StepPanel = ({
  children,
  step,
  stepHeader,
  secondaryStepHeader,
  stepComplete = false,
  initialToggle = false,
  toggleStep,
  continueIsDisabled,
  onSelectContinue,
}: IProps) => {
  const [toggled, setToggled] = useState<boolean>(initialToggle)

  useEffect(() => {
    if (toggleStep) setToggled(true)
  }, [toggleStep])

  return (
    <Panel>
      <StepHeader
        onClick={() => {
          setToggled(!toggled)
        }}
        aria-label={
          secondaryStepHeader
            ? `${stepComplete ? 'Step Complete. ' : ''}Toggle Step ${step}, ${stepHeader}: ${secondaryStepHeader}`
            : `${stepComplete ? 'Step Complete. ' : ''}Toggle Step ${step}, ${stepHeader}`
        }
      >
        <StepHeaderLeft>
          {stepComplete ? (
            <div data-testid="check-mark">
              <CheckIconOutline />
            </div>
          ) : (
            <StepNumber>{step}</StepNumber>
          )}
          {secondaryStepHeader ? (
            <StepHeaderText>
              {stepHeader}: <StepHeaderSubText>{secondaryStepHeader}</StepHeaderSubText>
            </StepHeaderText>
          ) : (
            <StepHeaderText>{stepHeader}</StepHeaderText>
          )}
        </StepHeaderLeft>
        <StepIconContainer toggled={toggled}>
          <ChevronIcon fill={theme.colors.ui05} />
        </StepIconContainer>
      </StepHeader>
      {toggled && (
        <StepPanelContent>
          <div>
            <div>{children}</div>
            <Btn
              buttonType="ghost"
              onClick={() => {
                onSelectContinue()
                setToggled(false)
              }}
              disabled={continueIsDisabled}
            >
              Continue
            </Btn>
          </div>
        </StepPanelContent>
      )}
    </Panel>
  )
}

export default StepPanel
