import { Alert as ReachAlert } from '@reach/alert'
import styled from 'styled-components/macro'

import Btn from '../../components/button'
import { GhostButton, SecondaryLinkButton } from '../../shared-styles/button.styles'
import { FormField } from '../../shared-styles/form.styles'

export const MobileAgreement = styled.div`
  [data-reach-accordion] {
    background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
    margin-top: ${({ theme }: ThemeProps) => theme.space.l};
  }
  [data-reach-accordion-button] {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  }
  [data-reach-accordion-panel] {
    margin-right: ${({ theme }: ThemeProps) => theme.space.s};
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: none;
  }
`

export const DesktopAgreement = styled.div`
  display: none;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    box-sizing: border-box;
    display: block;
    background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
    ${({ theme }: ThemeProps) => theme.elevation.elevation02};
    padding: ${({ theme }: ThemeProps) => `${theme.space.xs} ${theme.space.m}`};
    height: 45rem;
    overflow-y: auto;
    color: ${({ theme }: ThemeProps) => theme.colors.text02};
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    height: 28rem;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.xl}) {
    height: 35rem;
  }
`

export const DesktopAgreementSkeleton = styled.div`
  display: none;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    box-sizing: border-box;
    display: block;
    background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
    ${({ theme }: ThemeProps) => theme.elevation.elevation02};
    height: 45rem;
    overflow-y: auto;
    color: ${({ theme }: ThemeProps) => theme.colors.text02};
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    height: 28rem;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.xl}) {
    height: 35rem;
  }
`

export const PageLayout = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  padding: ${({ theme }: ThemeProps) => theme.space.m};
  padding-bottom: 7.5rem;
  min-height: calc(100vh - 6.2rem);

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    padding: ${({ theme }: ThemeProps) => theme.space.m} 0;
    padding-bottom: 11rem;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    padding: ${({ theme }: ThemeProps) => theme.space.m} 0;
    padding-bottom: 11rem;
  }
`

export const PageContent = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    max-width: 58.6rem;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    max-width: 93rem;
  }
`

export const LoadingContainer = styled.div`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StepHeader = styled.h2`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.075rem;
  text-transform: uppercase;
`

export const StepSubheader = styled.h2`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.s};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xl};
    line-height: ${({ theme }: ThemeProps) => theme.lineHeights.s};
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xxl};
    line-height: ${({ theme }: ThemeProps) => theme.lineHeights.default};
  }
`

export const Instructions = styled.p`
  margin-top: ${({ theme }: ThemeProps) => theme.space.s};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
  text-indent: 0;
`
export const InstructionsSkeleton = styled.div`
  margin-top: ${({ theme }: ThemeProps) => theme.space.s};
  line-height: 1.2;
`

interface CTAPanelProps {
  step?: number
  theme: IThemeProps
}
export const CTAPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }: CTAPanelProps) => theme.colors.ui01};
  width: 100vw;
  padding: 0.7rem 2.4rem;
  position: fixed;
  bottom: 0;
  left: 0;

  button {
    display: block;
    margin-top: 0;
  }

  @media (min-width: ${({ theme }: CTAPanelProps) => theme.breakpoint.m}) {
    padding: 2.2rem;
    text-align: right;

    button {
      display: inline-block;
    }
  }
`

export const CTAPanelLeft = styled.div``

export const CTAPanelRight = styled.div<CTAPanelProps>``

export const PrimaryActionButton = styled(Btn)`
  width: 14rem;

  @media (min-width: ${({ theme }: CTAPanelProps) => theme.breakpoint.m}) {
    width: 22rem;
    margin-left: ${({ theme }: CTAPanelProps) => theme.space.xl};
  }
`

export const ExitButton = styled(SecondaryLinkButton)`
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  min-width: 10rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    margin-left: 2rem;
  }
`

export const IAcceptText = styled.span`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
`

export const AddressContainer = styled.section<{ loading: boolean }>`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  margin-top: ${({ theme }: ThemeProps) => theme.space.l};
  width: 100%;
  ${({ theme }: ThemeProps) => theme.elevation.elevation02};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: ${props => (props.loading ? 'block' : 'grid')};
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

export const AddressSection = styled.div`
  padding: ${({ theme }: ThemeProps) => `${theme.space.s} ${theme.space.l}`};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    padding: ${({ theme }: ThemeProps) => `${theme.space.xl} ${theme.space.xxl}`};
    grid-row: 2 / 3;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    padding: ${({ theme }: ThemeProps) => theme.space.xl};
    grid-row: 1 / 2;
  }
`

export const AddressHeader = styled.h4`
  margin-top: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.075rem;
  text-transform: uppercase;
`

export const AddressSubheader = styled.h5`
  margin-bottom: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
`

export const AddressContent = styled.address`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-style: normal;
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xl};
`

export const MapSection = styled.div`
  display: none;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: block;
  }
`

export const TwoColumnFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
`

export const NotificationContainer = styled.section`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  border-radius: 0.4rem 0.4rem 0 0.4rem;
  margin-top: ${({ theme }: ThemeProps) => theme.space.l};
  width: 100%;
  ${({ theme }: ThemeProps) => theme.elevation.elevation02};
`

export const NotificationHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: 1.2rem 1.8rem;
`

export const NotificationHeader = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.075rem;
  text-transform: uppercase;
`

export const NotificationHeaderButtonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.8rem;
`

export const NotificationHeaderButtonText = styled.div`
  display: none;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.s}) {
    display: block;
  }
`

export const NotificationMobileButtonContainer = styled.div`
  padding: 1.2rem 1.8rem;
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  display: flex;
  gap: 1.8rem;
  justify-content: center;
  text-align: center;

  button {
    margin-right: 0;
  }
`

export const AlertContainer = styled.div`
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
`

export const AddContactButton = styled(GhostButton)`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  svg:first-child {
    display: block;
    margin-right: 1rem;
  }
`

export const ModalForm = styled.form`
  box-sizing: border-box;
  padding: ${({ theme }: ThemeProps) => theme.space.s};
  width: 100%;
  text-align: left;
`

export const ModalHeader = styled.h3`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.s};
`

export const ToggleFormField = styled(FormField)`
  display: flex;
  align-items: center;
`

export const ToggleContainer = styled.label`
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-left: ${({ theme }: ThemeProps) => theme.space.xs};
    margin-right: ${({ theme }: ThemeProps) => theme.space.s};
  }
`

export const CTAButtonContainer = styled.div`
  display: flex;
  justify-content: ${({ center }: { center: boolean }) => (center ? 'center' : 'space-between')};
`

export const WarningIconContainer = styled.div`
  width: 6.5rem;
  text-align: center;
`

export const PrimaryContactAlert = styled(ReachAlert)`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
`

export const DeleteAlert = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 20px;

  svg {
    margin-top: 0.8rem;
  }
`

export const ContentHeader = styled.h1`
  width: 100%;
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
`

export const ContentSubheader = styled.h2`
  width: 100%;
  color: #000000;
  font-size: 20px;
  font-weight: 499;
  line-height: 40px;
  text-align: center;
`

export const ContentSectionMinimizedWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9rem;
  margin-top: -2.1rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.xl}) {
    grid-template-columns: 22rem 1fr 22rem;
  }

  a {
    min-width: 22rem;
  }
  button {
    position: unset;
  }
`

export const InstructionalText = styled.p`
  margin: 0 auto;
  margin-bottom: 27px;
  width: 100%;
  max-width: 630px;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding-right: 8px;
  padding-left: 8px;
`

export const PrimaryContactWarning = styled.div`
  display: flex;
  text-align: left;
  margin: 2.4rem 0;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};

  svg {
    margin-right: 0.8rem;
    vertical-align: middle;
  }
`
