import moment from 'moment'
import { useState } from 'react'
import { ThemeProvider } from 'styled-components/macro'

import { CheckIconOutline, ChevronIcon } from '../../components/icon'
import { CenteredButtonWrapper } from '../../shared-styles/form.styles'
import { GhostButtonLink } from '../../shared-styles/link.styles'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import {
  ButtonContainer,
  CompletionPanelButtonA,
  CompletionPanelButtonLink,
  ContentIconContainer,
  SuccessModal,
  SuccessModalContent,
  SuccessModalMinimized,
  ToggleButton,
  ToggleButtonWrapper,
  TwoButtonContainer,
} from '../../shared-styles/success-modals.styles'
import theme from '../../theme'
import { ContentHeader, ContentSectionMinimizedWrapper, ContentSubheader, InstructionalText } from './onboarding.styles'

const MinimizedModalButtons = ({ folder }: { folder: IParticipantFolderType }) => {
  return (
    <>
      {folder.isActivated ? (
        <CenteredButtonWrapper>
          <CompletionPanelButtonLink to={`/dashboard/${folder.folder.id}`}>
            Exit and View Dashboard
          </CompletionPanelButtonLink>
        </CenteredButtonWrapper>
      ) : folder.orderStatus !== 'shipped' ? (
        <CenteredButtonWrapper>
          <CompletionPanelButtonLink to={`/activating/${folder.folder.id}`}>Activate Devices</CompletionPanelButtonLink>
        </CenteredButtonWrapper>
      ) : (
        <CenteredButtonWrapper>
          <CompletionPanelButtonA href={folder.trackingUrl}>View Tracking Info</CompletionPanelButtonA>
        </CenteredButtonWrapper>
      )}
    </>
  )
}

const OnboardingSuccessModal = ({
  folder,
  attributes,
}: {
  folder: IParticipantFolderType
  attributes: AttributesDataType
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalMinimized, setModalMinimized] = useState<boolean>(false)

  const maximizeModal = () => {
    setModalOpen(!modalOpen)
    setModalMinimized(!modalMinimized)
  }

  return (
    <>
      {!modalMinimized ? (
        <SuccessModal allowPinchZoom={true} aria-label="Confirmation Panel">
          <ThemeProvider theme={theme}>
            <SuccessModalContent>
              <ToggleButtonWrapper>
                <ToggleButton
                  minimized={!modalOpen}
                  onClick={() => {
                    setModalOpen(!modalOpen)
                    setModalMinimized(!modalMinimized)
                    setTimeout(() => document.getElementById('maximizeBtn')?.focus(), 0)
                  }}
                  aria-label="Minimize Pane"
                >
                  <ChevronIcon />
                </ToggleButton>
              </ToggleButtonWrapper>
              <ContentIconContainer>
                <CheckIconOutline height={63} width={63} />
              </ContentIconContainer>
              <ContentHeader>Success!</ContentHeader>
              {folder.isActivated ? (
                <ContentSubheader>You completed your account setup.</ContentSubheader>
              ) : (
                <ContentSubheader>You completed the first part of your account setup.</ContentSubheader>
              )}

              {folder.isActivated ? (
                <CenteredButtonWrapper>
                  <CompletionPanelButtonLink to={`/dashboard/${folder.folder.id}`}>
                    Exit and View Dashboard
                  </CompletionPanelButtonLink>
                </CenteredButtonWrapper>
              ) : folder.orderStatus === 'delivered' && moment(attributes.deliveredDate).valueOf() > 0 ? (
                <>
                  <InstructionalText>
                    We detect that your sensors have arrived. You can continue to activate your devices.
                  </InstructionalText>
                  <TwoButtonContainer>
                    <ButtonContainer>
                      <GhostButtonLink to="/dashboard" uppercasestyling="true">
                        Exit and View Dashboard
                      </GhostButtonLink>
                    </ButtonContainer>
                    <ButtonContainer>
                      <CompletionPanelButtonLink to={`/activating/${folder.folder.id}`}>
                        Activate Devices
                      </CompletionPanelButtonLink>
                    </ButtonContainer>
                  </TwoButtonContainer>
                </>
              ) : folder.orderStatus !== 'shipped' && moment(attributes.deliveredDate).valueOf() < 1 ? (
                <>
                  <InstructionalText>Your sensors are on their way.</InstructionalText>
                  <CenteredButtonWrapper>
                    <CompletionPanelButtonLink to="/dashboard">Exit and View Dashboard</CompletionPanelButtonLink>
                  </CenteredButtonWrapper>
                </>
              ) : (
                <>
                  <InstructionalText>
                    Your sensors are on their way. Track your shipment to see the delivery status.
                  </InstructionalText>
                  <TwoButtonContainer>
                    <ButtonContainer>
                      <GhostButtonLink to="/dashboard" uppercasestyling="true">
                        Exit and View Dashboard
                      </GhostButtonLink>
                    </ButtonContainer>
                    <ButtonContainer>
                      <CompletionPanelButtonA href={folder.trackingUrl}>View Tracking Info</CompletionPanelButtonA>
                    </ButtonContainer>
                  </TwoButtonContainer>
                </>
              )}
            </SuccessModalContent>
          </ThemeProvider>
        </SuccessModal>
      ) : (
        <SuccessModalMinimized>
          <ThemeProvider theme={theme}>
            <DesktopOnly>
              <SuccessModalContent>
                <ContentSectionMinimizedWrapper>
                  <ToggleButtonWrapper>
                    <ToggleButton
                      minimized={!modalOpen}
                      onClick={maximizeModal}
                      aria-label="Maximize Pane"
                      id="maximizeBtn"
                    >
                      <ChevronIcon />
                    </ToggleButton>
                  </ToggleButtonWrapper>
                  <MinimizedModalButtons folder={folder} />
                </ContentSectionMinimizedWrapper>
              </SuccessModalContent>
            </DesktopOnly>
            <MobileOnly>
              <SuccessModalContent modalMinimized={modalMinimized}>
                <ToggleButtonWrapper>
                  <ToggleButton minimized={!modalOpen} onClick={maximizeModal} aria-label="Maximize Pane">
                    <ChevronIcon />
                  </ToggleButton>
                </ToggleButtonWrapper>
                <MinimizedModalButtons folder={folder} />
              </SuccessModalContent>
            </MobileOnly>
          </ThemeProvider>
        </SuccessModalMinimized>
      )}
    </>
  )
}

export default OnboardingSuccessModal
