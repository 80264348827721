import { VisuallyHidden } from '@reach/visually-hidden'

import { StyledSkeletonLine } from './skeleton-line.styles'

/** Used to display a skeleton loading screen. Multiple skeleton lines can be used in a screen to display different portions of loading content, usually text. */

export const SkeletonLine = ({
  rounded,
  maxWidth,
  height,
  width,
  light = false,
  alt = 'Loading...',
}: {
  rounded?: boolean
  maxWidth?: string
  height?: string
  width?: string
  /** Display a lighter version of the skeleton line */
  light?: boolean
  /** Used for accessibility to screen readers */
  alt?: string
}) => (
  <StyledSkeletonLine rounded={rounded} maxWidth={maxWidth} height={height} width={width} light={light}>
    <VisuallyHidden>{alt}</VisuallyHidden>
  </StyledSkeletonLine>
)

export default SkeletonLine
