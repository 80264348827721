import { Alert as ReachAlert } from '@reach/alert'
import { Dialog } from '@reach/dialog'
import styled, { keyframes } from 'styled-components/macro'

import { ButtonA, ButtonLink } from './link.styles'

const SlideIn = keyframes`
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
`

export const SuccessModal = styled(Dialog)`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${({ theme }: ThemeProps) => theme.colors.ui02};
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  animation: ${SlideIn} 1s ease-in-out;
  margin-bottom: 0;
`

interface ToggleButtonProps {
  minimized: boolean
}
export const ToggleButton = styled.button<any>`
  position: absolute;
  left: 16px;
  top: ${(props: ToggleButtonProps) => (props.minimized ? '20px' : '24px')};
  background: 0;
  border: 0;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    left: 24px;
    top: 32px;
  }

  svg {
    width: 12px;
    transform: ${(props: ToggleButtonProps) => (props.minimized ? 'rotate(90deg)' : 'rotate(270deg)')};
  }

  &:hover {
    cursor: pointer;
  }
`

export const SuccessModalMinimized = styled(ReachAlert)`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }: ThemeProps) => theme.colors.ui02};
  border-top: 0.5px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: 10px 16px;
  z-index: 201;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    height: 81px;
    padding: 16px;
  }
`

interface SuccessModalContentProps {
  modalMinimized?: boolean
}
export const SuccessModalContent = styled.div<SuccessModalContentProps>`
  margin: 0 auto;
  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    ${({ modalMinimized }) => {
      if (modalMinimized)
        return `display: flex;
           justify-content: flex-end;`
    }}
    padding-right: 8px;
  }
`

export const ToggleButtonWrapper = styled.div`
  width: 30px;
`

export const CompletionPanelButtonLink = styled(ButtonLink)`
  margin-top: 0;
`

export const CompletionPanelButtonA = styled(ButtonA)`
  margin-top: 0;
`

export const ContentIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-left: 0;
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    a,
    button {
      margin: 0;
    }
  }
`

export const TwoButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 65px;
    margin: -8px auto 8px auto;
    max-width: 550px;
  }
`
