import { useEffect, useRef, useState } from 'react'

import { getUseCaseIcon, truncateText } from '../../utils/helpers'
import { getUseCase } from '../../utils/usecase-matrix'
import { ListView } from '../list-view'
import SearchBar from '../search-bar'
import {
  DashBoardDeviceSearchWrapper,
  DeviceButton,
  DeviceHeader,
  DeviceIcon,
  DeviceListItem,
  DeviceSubheader,
  DeviceText,
  ResultHeader,
  SearchResultNoDataPane,
  SearchResultNoDateMessageLine,
  SearchResultPane,
} from './search-input.styles'

interface DeviceListType {
  value: number
  label: string
  nodeType: DeviceNodeType
  useCase: DeviceUseCaseType | ''
  vanity: string
  uniqueId: string
  location: string
}

export const SearchInput = ({
  devices,
  setSelectedDeviceId,
  placeholderText,
}: {
  devices: IDeviceType[] | undefined
  setSelectedDeviceId: (deviceId: number | undefined) => void
  placeholderText?: string
}) => {
  const [searchQueryText, setSearchQueryText] = useState<string>('')
  const [deviceList, setDeviceList] = useState<DeviceListType[]>([])
  const [searchResult, setSearchResult] = useState<DeviceListType[]>([])
  const [noResultsFound, setNoResultsFound] = useState<boolean>(false)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(true)

  const wrapperRef = useRef(null)

  useEffect(() => {
    const _searchOptions = devices?.map((item: IDeviceType) => {
      return {
        value: item.id,
        label: getUseCase(item.deviceUseCase).vanity,
        nodeType: item.nodeTypeName as DeviceNodeType,
        useCase: item.deviceUseCase,
        vanity: item.vanity,
        uniqueId: item.uniqueId,
        location: truncateText(
          `${item.area || ''} ${item.area && item.deviceLocationNotes ? ' · ' : ''} ${item.deviceLocationNotes || ''}`,
          55
        ),
      }
    })

    if (_searchOptions) setDeviceList(_searchOptions)
  }, [devices])

  useEffect(() => {
    if (!!searchQueryText && searchQueryText.length > 0) {
      const _res = deviceList?.filter(
        (item: DeviceListType) =>
          item.label.toLowerCase().includes(searchQueryText.toLowerCase()) ||
          item.location.toLowerCase().includes(searchQueryText.toLowerCase()) ||
          item.nodeType.toLowerCase().includes(searchQueryText.toLowerCase()) ||
          item.vanity.toLowerCase().includes(searchQueryText.toLowerCase()) ||
          item.uniqueId.toLowerCase().includes(searchQueryText.toLowerCase())
      )

      setSearchResult(_res)
      if (Array.isArray(_res) && _res.length <= 0) {
        setNoResultsFound(true)
      }
    } else {
      setSearchResult([])
    }
  }, [searchQueryText, deviceList])

  /**
   * Detect click outside the current element:
   */
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * close results pane if clicked outside of search element
       */
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownOpen(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  return (
    <DashBoardDeviceSearchWrapper onClick={() => setDropdownOpen(true)}>
      <SearchBar
        placeHolderText={placeholderText || 'Search by device or location'}
        setSearchText={setSearchQueryText}
        searchText={searchQueryText}
      />
      {dropdownOpen && searchQueryText && !!searchResult && searchResult.length > 0 && (
        <SearchResultPane ref={wrapperRef}>
          <ResultHeader>{`Search Results (${searchResult.length})`}</ResultHeader>
          <ListView>
            {searchResult.map((item: DeviceListType, index: number) => {
              return (
                <DeviceListItem key={index}>
                  <DeviceButton
                    onClick={() => {
                      setSelectedDeviceId(item.value)
                    }}
                  >
                    <DeviceIcon>{getUseCaseIcon(item.useCase)}</DeviceIcon>
                    <DeviceText>
                      <DeviceHeader>{item.label}</DeviceHeader>
                      <DeviceSubheader>{item.location}</DeviceSubheader>
                    </DeviceText>
                  </DeviceButton>
                </DeviceListItem>
              )
            })}
          </ListView>
        </SearchResultPane>
      )}

      {dropdownOpen && searchQueryText && searchResult.length === 0 && noResultsFound && (
        <SearchResultNoDataPane ref={wrapperRef}>
          <SearchResultNoDateMessageLine>
            <ResultHeader>Search Results (0)</ResultHeader>
            <p>We could not find any results matching "{searchQueryText}". Please try again.</p>
          </SearchResultNoDateMessageLine>
        </SearchResultNoDataPane>
      )}
    </DashBoardDeviceSearchWrapper>
  )
}

export default SearchInput
