import styled from 'styled-components/macro'

export const PasswordMeetsReqs = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }: ThemeProps) => theme.colors.support_success};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  margin-top: 0.8rem;
  width: 100%;

  svg {
    margin-right: 0.5rem;
  }
`

export const PasswordReqResults = styled.ul`
  margin: 0;
  padding: 0;
`

export const PasswordReqResult = styled.li`
  display: flex;
  align-items: start;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 2;
  width: 100%;
`

export const PasswordReqResultIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
  position: relative;

  svg {
    position: absolute;
    top: 1rem;
  }
`

export const PasswordMustText = styled.p`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  margin-bottom: 1rem;
  line-height: 1.2;
  text-align: left;
  width: 100%;
`
