import axios, { CancelTokenSource } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import { CTASection } from '.'
import { Accordion, AccordionItem } from '../../components/accordion'
import SkeletonLine from '../../components/skeleton-line'
import { AppContext } from '../../contexts/app-context'
import { FieldError } from '../../shared-styles/alert.styles'
import { Checkbox, FormField, Input, Label } from '../../shared-styles/form.styles'
import API from '../../utils/api'
import { hasMultiFolderAccess } from '../../utils/helpers'
import AgreementText from './agreement-text'
import {
  DesktopAgreement,
  IAcceptText,
  Instructions,
  MobileAgreement,
  StepHeader,
  StepSubheader,
} from './onboarding.styles'

const ParticipationAgreement = ({
  parentLoading = false,
  folder,
  onBack,
  onNext,
}: {
  parentLoading?: boolean
  folder: IFolderType
  onBack: () => void
  onNext: () => void
}) => {
  const handleError = useErrorHandler()

  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const { folders } = useContext(AppContext)
  const [localLoading, setLocalLoading] = useState<boolean>(true)
  const [agreementText, setAgreementText] = useState<string>('')
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false)
  const [acceptanceError, setAcceptanceError] = useState<boolean>(false)
  const [signatureError, setSignatureError] = useState<boolean>(false)
  const [buttonWaiting, setButtonWaiting] = useState<boolean>(false)
  const [signature, setSignature] = useState<string>('')

  const loading = parentLoading || localLoading

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      // clean up axios calls when component unmounts
      source.cancel('cancelled')
    }
  }, [source])

  const fetchAgreementData = useCallback(async () => {
    setLocalLoading(true)

    try {
      const response = await API.get(`/api/v2/protect/participation-agreement/${folder.id}`, {
        cancelToken: source.token,
      })
      setAgreementText(response.data.agreementText)
      setSignature(response.data.signedBy)
      setLocalLoading(false)
    } catch (e) {
      if (e.message !== 'cancelled') {
        handleError(e as unknown as Error)
        setLocalLoading(false)
      }
    }
  }, [folder.id, handleError, source])

  useEffect(() => {
    fetchAgreementData()
  }, [fetchAgreementData])

  const onAcceptChange = () => {
    setAgreementAccepted(old => !old)
    setAcceptanceError(false)
  }

  const onContinue = async () => {
    if (!agreementAccepted) {
      setAcceptanceError(true)
    }
    if (!signature) {
      setSignatureError(true)
    }
    if (!agreementAccepted || !signature) {
      return
    }

    try {
      setButtonWaiting(true)
      await API.post(`/api/v2/protect/participation-agreement/${folder.id}`, { signedBy: signature })
      setButtonWaiting(false)
      onNext()
    } catch (e) {
      handleError(e as unknown as Error)
      setButtonWaiting(false)
    }
  }

  return (
    <>
      <StepHeader data-cy="secondStep">Step 2 of 3</StepHeader>
      <StepSubheader data-cy="agreementTitle">Sign Participation Agreement</StepSubheader>
      <MobileAgreement className="fs-exclude">
        {!loading ? (
          <Accordion collapsible={true}>
            <AccordionItem headerText="Participation Agreement">
              <AgreementText agreementContent={agreementText} />
            </AccordionItem>
          </Accordion>
        ) : (
          <SkeletonLine height="75px" />
        )}
      </MobileAgreement>
      <DesktopAgreement className="fs-exclude">
        {!loading ? <AgreementText agreementContent={agreementText} /> : <SkeletonLine height="100%" />}
      </DesktopAgreement>
      <Instructions>
        By entering your name below and clicking “I Accept”, you agree you are signing this Participation Agreement
        electronically. You also agree your Electronic Signature is the legal equivalent of your manual signature on
        this Agreement.
      </Instructions>
      <FormField>
        <Checkbox>
          <input type="checkbox" checked={agreementAccepted} onChange={onAcceptChange} data-cy="termsCheckBox" />
          <IAcceptText>I Accept</IAcceptText>
        </Checkbox>
        {acceptanceError && <FieldError data-cy="acceptanceError">You must accept to continue.</FieldError>}
      </FormField>
      <FormField>
        {!loading ? (
          <>
            <Label htmlFor="signature">Signature</Label>
            <Input
              id="signature"
              type="text"
              placeholder="Please enter full name"
              defaultValue={signature}
              onChange={e => {
                setSignature(e.target.value)
                setSignatureError(false)
              }}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  e.preventDefault()
                  onNext()
                }
              }}
              errorState={signatureError}
              data-cy="signatureInput"
            />
            {signatureError && <FieldError data-cy="signatureError">This field is required.</FieldError>}
          </>
        ) : (
          <SkeletonLine height="65px" />
        )}
      </FormField>

      <CTASection
        folder={folder}
        hasMultiFolderAccess={hasMultiFolderAccess(folders)}
        step={2}
        goBack={onBack}
        onPrimaryClick={onContinue}
        showBackButton={true}
        disabledStyling={!agreementAccepted || !signature || loading}
        buttonWaiting={buttonWaiting}
      />
    </>
  )
}

export default ParticipationAgreement
