import { useState } from 'react'

import SensorSelect from '../../../../components/sensor-select'
import { getUseCase } from '../../../../utils/usecase-matrix'
import { getAlarmCount } from '../../methods/genericMethods'
import ModalMultipleDevices from '../../modals/devices/multiple-devices'

interface GroupedDeviceProps {
  device: IDeviceType
  devices: IDeviceType[]
  groupType: 'type' | 'location'
  index: number
  setShowModal: (arg: boolean) => void
  setSelectedCardKey: (arg: number | undefined) => void
  setDevice: (arg: IDeviceType | undefined) => void
}

/** Display a card for a group of devices and a modal listing the devices in the group */
const GroupedDevice = ({
  device,
  devices,
  groupType,
  index,
  setShowModal,
  setSelectedCardKey,
  setDevice,
}: GroupedDeviceProps) => {
  const [showMultipleModal, setMultipleShowModal] = useState<boolean>(false)
  const [multipleSelectedCardKey, setMultipleSelectedCardKey] = useState<number | undefined>()

  const alarmCount = getAlarmCount(devices)
  const allLocations = new Set(devices.map(d => d.area))
  // @ts-ignore
  const groupedLocations = allLocations.size > 1 ? 'Multiple Locations' : [...allLocations][0]

  const getName = () => {
    if (groupType === 'type') {
      return `${
        device.deviceUseCase === 'gateway' ? 'Gateway' : `${getUseCase(device.deviceUseCase).vanity} Sensor`
      }s (${devices.length})`
    } else {
      if (device.area) {
        return `${device.area} (${devices.length})`
      } else {
        return `Undefined Location (${devices.length})`
      }
    }
  }
  const getTitle = () => {
    if (groupType === 'type') {
      return `${device.deviceUseCase === 'gateway' ? 'Gateway' : `${getUseCase(device.deviceUseCase).vanity} Sensor`}s`
    } else {
      if (device.area) {
        return `${device.area}`
      } else {
        return `Undefined Location`
      }
    }
  }
  const getGroupDeviceType = () => {
    // if all the devices are of the same nodeType, then use the nodeTypeVanityPretty of the first device
    // if they are not, return "Multiple device types"
    if (devices.find(thisDevice => thisDevice.nodeTypeName !== device.nodeTypeName)) {
      return 'Multiple device types'
    } else return getUseCase(device.deviceUseCase).vanity
  }

  const viewSingleDevice = (deviceToView: IDeviceType) => {
    setDevice(deviceToView)
    setShowModal(true)
    setMultipleShowModal(false)
    setSelectedCardKey(deviceToView.id)
    setMultipleSelectedCardKey(undefined)
  }

  return (
    <>
      <SensorSelect
        id={device.id}
        useCase={device.deviceUseCase}
        name={getName()}
        subText={groupType === 'type' ? groupedLocations : getGroupDeviceType()}
        onSelect={() => {
          if (devices.length > 1) {
            setMultipleShowModal(true)
            setMultipleSelectedCardKey(index)
          } else {
            viewSingleDevice(device)
          }
        }}
        alertCount={alarmCount}
      />
      {showMultipleModal && multipleSelectedCardKey === index && (
        <ModalMultipleDevices
          devices={devices}
          groupType={groupType}
          title={getTitle()}
          showModal={showMultipleModal && multipleSelectedCardKey === index}
          closeModal={() => {
            setMultipleShowModal(false)
            setMultipleSelectedCardKey(undefined)
            setShowModal(false)
            setSelectedCardKey(undefined)
            setDevice(undefined)
          }}
          onViewSingleDevice={viewSingleDevice}
        />
      )}
    </>
  )
}

export default GroupedDevice
