import { VisuallyHidden } from '@reach/visually-hidden'
import { useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { SmallChevronIcon } from '../icon'
import {
  CarouselGetStartedButton,
  CarouselGetStartedButtonContainer,
  CarouselNextButton,
  CarouselNextSkipButtonContainer,
  CarouselSkipButton,
  NextArrowContainer,
  PrevArrowContainer,
  SliderContainer,
} from './carousel-tutorial.styles'

const PrevArrow = ({
  slides,
  onClick = () => {},
  currentSlide = 0,
}: {
  slides: number
  /** The following are passed in by the slick package */
  onClick?: () => void
  currentSlide?: number
}) => {
  return (
    <PrevArrowContainer onClick={onClick} slides={slides} isDisabled={currentSlide === 0}>
      <SmallChevronIcon />
      {currentSlide > 0 && <VisuallyHidden>Previous Slide</VisuallyHidden>}
    </PrevArrowContainer>
  )
}
const NextArrow = ({
  slides,
  onClick = () => {},
  currentSlide = 0,
}: {
  slides: number
  /** The following are passed in by the slick package */
  onClick?: () => void
  currentSlide?: number
}) => {
  return (
    <NextArrowContainer onClick={onClick} slides={slides} isDisabled={currentSlide === slides - 1}>
      <SmallChevronIcon />
      {currentSlide < slides - 1 && <VisuallyHidden>Next Slide</VisuallyHidden>}
    </NextArrowContainer>
  )
}

interface CarouselProps {
  children: React.ReactNode
  /** The number of slides this carousel should display. Should match the number of elements passed in as children. */
  numberOfSlides: number
  autoplay?: boolean
  autoplaySpeed?: number
  speed?: number
  onClose?: () => void
}

export const CarouselTutorial = ({
  children,
  autoplay = true,
  numberOfSlides,
  autoplaySpeed = 6000,
  speed = 600,
  onClose = () => {},
}: CarouselProps) => {
  const sliderRef = useRef<Slider | null>(null)
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const settings = {
    accessibility: true,
    autoplay,
    autoplaySpeed,
    dots: true,
    arrows: true,
    infinite: false,
    pauseOnFocus: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    speed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_: number, next: number) => setCurrentSlide(next),
    prevArrow: <PrevArrow slides={numberOfSlides} />,
    nextArrow: <NextArrow slides={numberOfSlides} />,
  }

  return (
    <SliderContainer>
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
      {currentSlide < numberOfSlides - 1 && (
        <CarouselNextSkipButtonContainer>
          <CarouselSkipButton buttonType="secondary-link" onClick={onClose}>
            Skip
          </CarouselSkipButton>
          <CarouselNextButton buttonType="ghost" onClick={() => sliderRef.current?.slickNext()}>
            Next
          </CarouselNextButton>
        </CarouselNextSkipButtonContainer>
      )}
      {currentSlide === numberOfSlides - 1 && (
        <CarouselGetStartedButtonContainer>
          <CarouselGetStartedButton onClick={onClose}>Get Started</CarouselGetStartedButton>
        </CarouselGetStartedButtonContainer>
      )}
    </SliderContainer>
  )
}

export default CarouselTutorial
