import { navigate, RouteComponentProps } from '@reach/router'
import { useContext, useEffect } from 'react'
import styled from 'styled-components/macro'

import LoadingScreen from '../../components/loading-screen'
import { AppContext } from '../../contexts/app-context'
import { PageLayout } from '../onboarding/onboarding.styles'

const LoadingPageLayout = styled(PageLayout)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

const LoginRedirect = (_: RouteComponentProps) => {
  const { folders, foldersLoading } = useContext(AppContext)

  useEffect(() => {
    navigate(`/dashboard`)
  }, [folders, foldersLoading])

  return (
    <LoadingPageLayout>
      <LoadingScreen />
    </LoadingPageLayout>
  )
}

export default LoginRedirect
