import styled from 'styled-components/macro'

interface StyledButtonProps {
  disabled: boolean
  selected: boolean
  theme: IThemeProps
}

interface StyledButtonContainerProps {
  status?: StatusTypes | boolean
  statusText?: string
  theme: IThemeProps
}

interface StatusCellProps {
  status?: StatusTypes
  theme: IThemeProps
}

interface AlertCountProps {
  type: string
  theme: IThemeProps
}

export const StyledButton = styled.button`
  box-sizing: border-box;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  border: ${(props: StyledButtonProps) => (props.selected ? `1px solid ${props.theme.colors.brand01}` : '0')};
  border-radius: 4px;
  ${(props: StyledButtonProps) => {
    return props.theme.elevation.elevation02
  }};
  margin-bottom: 15px;
  min-height: 87px;
  opacity: ${(props: StyledButtonProps) => (props.disabled ? '0.7' : '1')};
  width: 100%;
  padding: 10px;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
  text-align: left;

  @media (min-width: ${(props: StyledButtonProps) => props.theme.breakpoint.l}) {
    ${(props: StyledButtonProps) => {
      if (props.disabled) return props.theme.elevation.elevation02
      if (props.selected) return props.theme.elevation.elevation05
      else return props.theme.elevation.elevation03
    }};
  }
  @media (min-width: ${(props: StyledButtonProps) => props.theme.breakpoint.xl}) {
    padding: 16px;
  }

  &:hover {
    @media (min-width: ${(props: StyledButtonProps) => props.theme.breakpoint.m}) {
      cursor: ${(props: StyledButtonProps) => (props.disabled ? 'inherit' : 'pointer')};
      background-color: ${(props: StyledButtonProps) => props.theme.colors.ui01};
      ${(props: StyledButtonProps) =>
        props.disabled ? props.theme.elevation.elevation02 : props.theme.elevation.elevation05};
    }
  }
  &:focus {
    @media (min-width: ${(props: StyledButtonProps) => props.theme.breakpoint.m}) {
      border: 1px solid ${({ theme }: StyledButtonProps) => theme.colors.brand01};
      ${(props: StyledButtonProps) =>
        props.disabled ? props.theme.elevation.elevation02 : props.theme.elevation.elevation03};
      outline: 0;
    }
  }
`

export const SkeletonButton = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }: StyledButtonProps) => theme.colors.ui01};
  border: ${(props: StyledButtonProps) => (props.selected ? `1px solid ${props.theme.colors.brand01}` : '0')};
  border-radius: 4px;
  ${(props: StyledButtonProps) => {
    return props.theme.elevation.elevation02
  }};
  margin-bottom: 15px;
  min-height: 87px;
  width: 100%;
  padding: 10px;
`

export const StyledButtonContainer = styled.div<StyledButtonContainerProps>`
  display: grid;
  grid-template-columns: 30px 1fr ${(props: StyledButtonContainerProps) =>
      props.status || props.statusText ? '135px' : ''};
  grid-gap: 10px;

  img {
    width: 30px;
  }

  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoint.xl}) {
    grid-template-columns: 50px 1fr ${(props: StyledButtonContainerProps) =>
        props.status || props.statusText ? '135px' : ''};
    grid-gap: 16px;

    img {
      width: auto;
      height: 49px;

      &.device-icon-bvs {
        width: 36px;
        height: auto;
      }
    }
  }
`

export const ImageCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const NameCell = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  letter-spacing: 1px;

  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoint.l}) {
    font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
    text-transform: uppercase;
  }
`

export const SubText = styled.span`
  display: block;
  margin-top: ${({ theme }: ThemeProps) => theme.space.xxs};
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${(props: ThemeProps) => props.theme.fontSizes.xs};
  font-weight: ${(props: ThemeProps) => props.theme.fontWeights.regular};
  letter-spacing: 0;
  line-height: 1.2;
  text-transform: none;
`

export const StatusCell = styled.div<StatusCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};

  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoint.m}) {
    text-align: left;
  }
`

export const IconContainer = styled.span`
  padding-right: 6px;
  @media (min-width: ${(props: ThemeProps) => props.theme.breakpoint.xl}) {
    padding-right: 10px;
  }
`

export const DeviceImageContainer = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui03};
  border-radius: 10rem;
  width: 5.3rem;
  height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    position: relative;
    bottom: 0;
    right: 0;
    width: 3.2rem;
    height: 3.2rem;
  }
`

export const AlertCount = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: ${(props: AlertCountProps) =>
    props.type === 'multiple_probe' ||
    props.type === 'multiple_motion' ||
    props.type === 'probe' ||
    props.type === 'motion'
      ? '-0.2rem'
      : '-0.9rem'};
  bottom: ${(props: AlertCountProps) =>
    props.type === 'multiple_probe' ||
    props.type === 'multiple_motion' ||
    props.type === 'probe' ||
    props.type === 'motion'
      ? '-0.1rem'
      : '-0.5rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: ThemeProps) => props.theme.colors.brand01};
  border: 0.1rem solid ${(props: ThemeProps) => props.theme.colors.ui01};
  border-radius: 10rem;
  color: ${(props: ThemeProps) => props.theme.colors.text_inverse};
  font-size: ${(props: ThemeProps) => props.theme.fontSizes.xs};
  font-weight: ${(props: ThemeProps) => props.theme.fontWeights.semibold};
  min-height: 1.9rem;
  min-width: 1.9rem;
`
