import { createGlobalStyle } from 'styled-components'

import theme from './theme'

const GlobalStyle = createGlobalStyle`
  :root {
    /** CSS custom properties for CSS files that cannot access theme */
    --fontFamily: ${theme.fontFamily};
    --brand01: ${theme.colors.brand01};
    --brand02: ${theme.colors.brand02};
    --brand03: ${theme.colors.brand03};
    --text01: ${theme.colors.text01};
    --text02: ${theme.colors.text02};
    --text03: ${theme.colors.text03};
    --text_inverse: ${theme.colors.text_inverse};
    --ui01: ${theme.colors.ui01};
    --ui02: ${theme.colors.ui02};
    --ui03: ${theme.colors.ui03};
    --ui04: ${theme.colors.ui04};
    --ui05: ${theme.colors.ui05};
    --ui06: ${theme.colors.ui06};
    --ui07: ${theme.colors.ui07};
    --hover_primary: ${theme.colors.hover_primary};
    --hover_row: ${theme.colors.hover_row};
    --support_info: ${theme.colors.support_info};
    --support_success: ${theme.colors.support_success};
    --support_error: ${theme.colors.support_error};
    --support_warning: ${theme.colors.support_warning};
    --accent_blue: ${theme.colors.accent_blue};
    --accent_green: ${theme.colors.accent_green};
    --accent_orange: ${theme.colors.accent_orange};
    --accent_gray: ${theme.colors.accent_gray};
  }

  html {
    font-size: 62.5%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
    color: ${theme.colors.text01};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.default};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.xl};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

/* Make modals win the z-index war with the mobile nav bar 😬 */
[data-reach-dialog-overlay] {
  z-index: 101;
}

/* Fixes issue with y-axis labels being cut off in certain cases */
.apexcharts-yaxis {
  transform: translate(28px, 0);
}
.apexcharts-inner {
  transform: translate(58px, 30px);
}

[class*="Snackbar_snackbar-wrapper"] {
  z-index: 9999 !important;
}
`

export default GlobalStyle
