import styled from 'styled-components/macro'

import Btn from '../button'

export const SliderContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 5rem;
  position: relative;
  width: 100%;

  .slick-dots {
    pointer-events: none;

    li {
      width: 10px;
      height: 10px;
      margin: 0 2px;
    }
    button {
      pointer-events: all;
      width: 10px;
      height: 10px;
      padding: 0;
    }
  }
  .slick-dots .slick-active button:before {
    color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  }
  .slick-dots button:before {
    color: ${({ theme }: ThemeProps) => theme.colors.ui04};
    opacity: 1;
    font-size: 8px;
    line-height: 8px;
    width: 10px;
    height: 10px;
  }

  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    .slick-dots {
      position: fixed;
      bottom: 1.8rem;
      width: calc(100% - 3.6rem);
      z-index: 1;
    }
    .slick-slider > button {
      display: none;
    }
  }
`

export const CarouselNextSkipButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 8rem 0 -5rem 0;

  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    margin: 0;
    justify-content: space-between;
    position: fixed;
    bottom: 1.3rem;
    width: calc(100% - 2.4rem);
  }
`
export const CarouselGetStartedButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 7rem 0 -5rem 0;

  button {
    margin: 0;
  }

  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    margin: 1rem 0 0 0;
    justify-content: center;
  }
`
export const CarouselSkipButton = styled(Btn)`
  font-weight: normal;
`
export const CarouselNextButton = styled(Btn)`
  margin: 0 0 0 5.4rem;

  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    margin: 0;
  }
`

interface ArrowButtonProps {
  slides: number
  isDisabled: boolean
  theme: IThemeProps
}
export const PrevArrowContainer = styled.button<ArrowButtonProps>`
  background: transparent;
  border: 0;
  position: absolute;
  bottom: -34px;
  left: calc(50% - (${({ slides }) => slides} * 14px) - 12px);
  padding: 10px;
  cursor: ${({ isDisabled }) => (isDisabled ? null : 'pointer')};

  svg {
    transform: rotate(180deg);

    path {
      fill: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.ui04 : null)};
    }
  }
`
export const NextArrowContainer = styled.button<ArrowButtonProps>`
  background: transparent;
  border: 0;
  position: absolute;
  bottom: -34px;
  right: calc(50% - (${({ slides }) => slides} * 14px) - 12px);
  padding: 10px;
  cursor: ${({ isDisabled }) => (isDisabled ? null : 'pointer')};

  svg path {
    fill: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.ui04 : null)};
  }
`
export const CarouselGetStartedButton = styled(Btn)`
  max-width: 20rem;
`
