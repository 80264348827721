import { useContext } from 'react'

import UserContext from '../../../contexts/user-context'
import { getGatewayStatus, parseAlarm } from './genericMethods'

interface StatusArrayType {
  value: string | number | undefined
}

export const useStatusText = (device: IDeviceType) => {
  const { folderAlarms: alarms } = useContext(UserContext)

  /** Pushes a status (object with channel and value keys) to provided statuses array */
  const pushStatus = (statusesArray: StatusArrayType[], value: string) => {
    statusesArray.push({
      value,
    })
  }

  let status: string = ''
  // Gateways
  if (device.nodeTypeName === 'gateway') {
    return getGatewayStatus(device, alarms)
  }
  // Sensors
  else {
    // Offline sensors
    if (device.isOffline) return 'Offline'
    // Immediately return the status if the device is offline, otherwise we can continue to adding statuses based on channel values and alarms

    // Online sensors
    const statuses: StatusArrayType[] = []
    // Check for alarms
    if (alarms) {
      alarms.map((alarm: IAlertHistory) => {
        // Parse the alarm name to get the display text for the alarm (e.g., "Level 4 - Leak Detected - nbiot_leak" becomes "Leak Detected")
        const parsedAlarm = parseAlarm(alarm)
        // const parsedAlarm = alarm.name
        if (parsedAlarm) {
          if (alarm.nodeId === device.id && alarm.status === 'active') {
            // Handle temp and leak channel for all devices
            if (alarm.channel === 'temp' || alarm.channel === 'probe' || alarm.channel === 'leak') {
              // Add a parsed temp alarm to status
              pushStatus(statuses, parsedAlarm)
            }
          }
        }
        return alarm
      })
    }

    // Add low battery/low signal statuses
    if (device.hasLowBattery) pushStatus(statuses, 'Low Battery')
    if (device.hasLowSignal) pushStatus(statuses, 'Poor Signal')

    if (statuses.length === 0) return 'Online'

    // Join all the statuses with | separator
    const statusValues: any[] = []
    statuses.map(s => {
      if (s.value) statusValues.push(s.value)
      return s
    })
    status = statusValues.join(' | ')
  }
  return status
}
