import { navigate } from '@reach/router'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { omit } from 'lodash'
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import api from '../utils/api'
import { getFolders } from '../utils/helpers'
import AuthenticationContext from './auth-context'

export const AppContext = createContext<{
  foldersLoading: boolean
  folders: IGenericFolderType[]
  setFolders: Dispatch<SetStateAction<IGenericFolderType[]>>
  roles: IRoleType[]
  permissions: IPermissionType[]
  userIsLocationManager: boolean
  user: IUserType | undefined
  setUser: Dispatch<SetStateAction<IUserType | undefined>>
  minimizeNativeBanner: boolean
  setMinimizeNativeBanner: Dispatch<SetStateAction<boolean>>
}>({
  foldersLoading: true,
  folders: [] as IGenericFolderType[],
  setFolders: () => {},
  roles: [] as IRoleType[],
  permissions: [] as IPermissionType[],
  userIsLocationManager: false,
  user: undefined,
  setUser: () => {},
  minimizeNativeBanner: false,
  setMinimizeNativeBanner: () => {},
})

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAuth: contextIsAuth, userToImpersonate: contextUserToImpersonate } = useContext(AuthenticationContext)
  const _isMounted = useRef(true)
  const handleError = useErrorHandler()

  const [folders, setFolders] = useState<IGenericFolderType[]>([])
  const [roles, setRoles] = useState<IRoleType[]>([])
  const [permissions, setPermissions] = useState<IPermissionType[]>([])
  const [userIsLocationManager, setUserIsLocationManager] = useState<boolean>(false)
  const [user, setUser] = useState<IUserType | undefined>()
  const [foldersLoading, setFoldersLoading] = useState<boolean>(true)
  const [minimizeNativeBanner, setMinimizeNativeBanner] = useState<boolean>(false)
  // This allows us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())

  const throwError = useCallback(
    (e: AxiosError | Error) => {
      if ((e as AxiosError).response?.status === 401) {
        // User is not logged in on the server, log them out of Protect
        localStorage.removeItem('protectToken')
        navigate('/')
      } else {
        if (_isMounted.current) handleError(e)
      }
    },
    [handleError]
  )

  useEffect(() => {
    const updateMinimizeBanner = localStorage.getItem('minimizeNativeBanner')
    if (updateMinimizeBanner === 'true') setMinimizeNativeBanner(true)
    else setMinimizeNativeBanner(false)
  }, [])

  useEffect(() => {
    const asyncLoadUser = async () => {
      try {
        const apiUser = await api.get('/api/users/me')
        // Current user is not being impersonated, but should be
        if (
          contextUserToImpersonate &&
          apiUser.data.id !== Number(contextUserToImpersonate) &&
          !apiUser.data.impersonatedBy
        ) {
          const impersonateUser = await api.get(`/api/users/${contextUserToImpersonate}/impersonate`)
          setUser(impersonateUser.data)
        }
        // We have a user want to impersonate, but we are currently impersonating
        // First, unimpersonate, and then if the user we want to impersonate is not self, then impersonate
        else if (
          contextUserToImpersonate &&
          apiUser.data.id !== Number(contextUserToImpersonate) &&
          apiUser.data.impersonatedBy
        ) {
          const originalUser = await api.get('/api/users/unimpersonate')
          // We are not trying to impersonate the original user, so go ahead and impersonate
          if (originalUser.data.id !== Number(contextUserToImpersonate)) {
            const impersonateUser = await api.get(`/api/users/${contextUserToImpersonate}/impersonate`)
            setUser(impersonateUser.data)
          }
          // We are trying to impersonate the original user, this is a no-no, just set the current user and don't impersonate
          else setUser(originalUser.data)
        }
        // No impersonation happening here
        else {
          setUser(apiUser.data)
        }
      } catch (e) {
        if (_isMounted.current) {
          if ((e as AxiosError).response?.status === 403) {
            throwError(
              new Error(
                'You do not have the appropriate permission to impersonate this user. Refresh to continue as yourself.'
              )
            ) // Custom error message to display to the user on the generic error screen
          } else throwError(e as AxiosError | Error)
        }
      }
    }

    if (contextIsAuth) asyncLoadUser()
  }, [contextIsAuth, contextUserToImpersonate, throwError])

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      // clean up axios calls when component unmounts
      source.cancel('cancelled')
      _isMounted.current = false // used to handle memory leaks when performing a state changing when the component has already unmounted
    }
  }, [source])

  const resetData = () => {
    setFolders([])
    setRoles([])
    setPermissions([])
    setFoldersLoading(true)
  }

  // Reset data when user logs out
  useEffect(() => {
    if (!contextIsAuth) {
      resetData()
      setUser(undefined)
    }
  }, [contextIsAuth])

  // Get all the data we need - nodeTypes, nodes, rules, alarms, and folders
  useEffect(() => {
    const fetchFolders = async () => {
      if (user) {
        resetData()
        const allowedFolders: number[] = user.folders

        // Get the data for nodeTypes, nodes, rules, and the user (for temperature units) in parallel, then go on to get the alarms and folders
        Promise.all([
          api.get('/api/roles', {
            cancelToken: source.token,
          }),
          api.get('/api/permissions', {
            cancelToken: source.token,
          }),
        ])
          .then(async responses => {
            responses.forEach(res => {
              if (res.status === 200) {
                if (res.config.url === '/api/roles') {
                  setRoles(res.data)
                }
                if (res.config.url === '/api/permissions') {
                  setPermissions(res.data)
                }
              }
            })

            if (allowedFolders.length > 0) {
              try {
                const foldersRes = await getFolders(source)
                setFolders([
                  ...foldersRes.companies,
                  ...foldersRes.programs,
                  ...foldersRes.participants.map(f => ({ ...omit(f, 'folder'), ...f.folder })), // Flatten the participant folders
                ])
                setUserIsLocationManager(foldersRes.isLocationManager)
                setFoldersLoading(false)
              } catch (e) {
                throwError(e as AxiosError | Error)
              }
            } else {
              setFolders([])
              setFoldersLoading(false)
            }
          })
          .catch(e => {
            if (e.message !== 'cancelled') throwError(e)
          })
      }
    }
    if (contextIsAuth && user) fetchFolders()
  }, [contextIsAuth, user, throwError, source])

  return (
    <AppContext.Provider
      value={{
        foldersLoading,
        folders,
        setFolders,
        roles,
        permissions,
        userIsLocationManager,
        user,
        setUser,
        minimizeNativeBanner,
        setMinimizeNativeBanner,
      }}
    >
      <>{children}</>
    </AppContext.Provider>
  )
}
export default AppProvider
