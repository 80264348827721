import { ErrorMessage, Formik } from 'formik'
import { Dispatch, SetStateAction, useContext, useMemo } from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import UserContext from '../../contexts/user-context'
import { GhostButton } from '../../shared-styles/button.styles'
import { FormField, Input, Label, Select, SelectContainer, Textarea } from '../../shared-styles/form.styles'
import API from '../../utils/api'
import { SIDEPANEL_ERROR_OPTS } from '../../utils/constants'
import Btn from '../button'
import { CTAButtonContainer } from '../notification-contact-form/notification-contact-form.styles'

interface FormValues {
  area: string
  building: string
  floor: string
  deviceLocationNotes: string
}

interface FormFieldsProps {
  theme: IThemeProps
  twoColumns?: boolean
}

const FormFields = styled.div<FormFieldsProps>`
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    display: grid;
    grid-template-columns: 202px 202px 1fr;
    grid-column-gap: 44px;

    ${({ twoColumns }: FormFieldsProps) => {
      if (twoColumns) {
        return `
        grid-template-columns: 1fr 1fr;
      `
      }
    }}
  }
`

const SelectFormField = styled.div<FormFieldsProps>`
  grid-column: 1 / 3;
`

const StyledErrorMessage = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.support_error};
`

interface IProps {
  device: IDeviceType
  deviceLocations: string[] | undefined
  setDevice: Dispatch<SetStateAction<IDeviceType>>
  onBack: () => void
}

export const DeviceLocationForm = ({ device, deviceLocations, setDevice, onBack }: IProps) => {
  const { setDevices } = useContext(UserContext)
  const [openErrorSnackbar] = useSnackbar(SIDEPANEL_ERROR_OPTS)

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        area: Yup.string().trim().required('Required'),
        building: Yup.string(),
        floor: Yup.string().max(4, 'Floor must be four or fewer characters'),
        deviceLocationNotes: Yup.string().trim().required('Required'),
      }),
    []
  )

  const onSave = async (values: FormValues) => {
    try {
      await API.patch(`/api/v2/protect/devices/${device.id}`, values)
      setDevices(
        prev =>
          prev &&
          prev.map(d => {
            if (d.id === device.id) {
              return { ...d, ...values }
            } else return d
          })
      )
      setDevice(prev => ({ ...prev, ...values }))
      onBack()
    } catch (e: unknown) {
      openErrorSnackbar('Unable to save. Please try again.')
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          area: device.area,
          building: device.building,
          floor: device.floor,
          deviceLocationNotes: device.deviceLocationNotes,
        }}
        enableReinitialize={true}
        onSubmit={onSave}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, dirty }) => (
          <>
            <div>
              <FormFields twoColumns={true}>
                <SelectFormField>
                  <FormField>
                    <Label htmlFor="sensor_location">Sensor Location</Label>
                    <SelectContainer>
                      <Select
                        id="sensor_location"
                        name="area"
                        // Check if the pre-populated location value is included in the list of available device locations
                        // If it's not, return an empty string
                        defaultValue={deviceLocations?.find(l => l === values.area) ? values.area : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {!values.area && (
                          <option value="" disabled={true}>
                            Select a location
                          </option>
                        )}
                        {deviceLocations &&
                          deviceLocations.sort().map((deviceLocation, index) => (
                            <option key={index} value={deviceLocation}>
                              {deviceLocation}
                            </option>
                          ))}
                      </Select>
                    </SelectContainer>
                    <StyledErrorMessage>
                      <ErrorMessage name="area" />
                    </StyledErrorMessage>
                  </FormField>
                </SelectFormField>

                <FormField>
                  <Label htmlFor="building">Building (optional)</Label>
                  <Input
                    id="building"
                    name="building"
                    defaultValue={values.building}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <StyledErrorMessage>
                    <ErrorMessage name="building" />
                  </StyledErrorMessage>
                </FormField>
                <FormField>
                  <Label htmlFor="floor">Floor (optional)</Label>
                  <Input
                    id="floor"
                    name="floor"
                    defaultValue={values.floor}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <StyledErrorMessage>
                    <ErrorMessage name="floor" />
                  </StyledErrorMessage>
                </FormField>

                <SelectFormField>
                  <FormField>
                    <Label htmlFor="additional_notes">Additional Notes</Label>
                    <Textarea
                      id="additional_notes"
                      placeholder="Ex. behind fridge"
                      name="deviceLocationNotes"
                      defaultValue={values.deviceLocationNotes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <StyledErrorMessage>
                      <ErrorMessage name="deviceLocationNotes" />
                    </StyledErrorMessage>
                  </FormField>
                </SelectFormField>
              </FormFields>
            </div>
            <CTAButtonContainer center={false}>
              <GhostButton onClick={onBack}>Cancel</GhostButton>
              <Btn
                buttonType="secondary"
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting || !dirty}
                waiting={isSubmitting}
              >
                Save Changes
              </Btn>
            </CTAButtonContainer>
          </>
        )}
      </Formik>
    </>
  )
}

export default DeviceLocationForm
