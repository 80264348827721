import styled from 'styled-components/macro'

interface StyledSkeletonPulseProps {
  maxWidth?: string
  height?: string
  width?: string
  light?: boolean
}

export const StyledSkeletonPulse = styled.div`
  display: inline-block;
  height: ${(props: StyledSkeletonPulseProps) => (props.height ? props.height : '100%')};
  width: ${(props: StyledSkeletonPulseProps) => (props.width ? props.width : '100%')};
  max-width: ${(props: StyledSkeletonPulseProps) => (props.maxWidth ? props.maxWidth : '100%')};
  background: ${(props: StyledSkeletonPulseProps) =>
    props.light
      ? 'linear-gradient(-90deg, #ebebeb 0%, #f8f8f8 50%, #ebebeb 100%)'
      : 'linear-gradient(-90deg, #d3d4d8 0%, #f8f8f8 50%, #d3d4d8 100%)'};
  background-size: 400% 400%;
  animation: skeletonpulse 1.2s ease-in-out infinite;
  @keyframes skeletonpulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

interface StyledSkeletonLineProps {
  rounded?: boolean
  maxWidth?: string
}
export const StyledSkeletonLine = styled(StyledSkeletonPulse)`
  border-radius: ${(props: StyledSkeletonLineProps) => (props.rounded ? '20px' : '0')};

  &::before {
    content: '\\00a0';
  }
`
