import { GhostButtonA, GhostButtonLink } from '../../shared-styles/link.styles'
import { MapPinIcon } from '../icon'
import { Card, CardBody, CardButtonContainer, CardHeading, CardMeta, ChipStatus } from './location-card.styles'

interface IProps {
  name: string
  status: 'Not Complete' | 'In Progress' | 'In Transit'
  bodyText: string
  buttonText: string
  buttonLink: string
  openInNewTab?: boolean
}

/**
 * The LocationCard component is used for users with multiple locations to activate.
 */
export const LocationCard = ({ name, status, bodyText, buttonText, buttonLink, openInNewTab = false }: IProps) => {
  return (
    <Card data-cy="locationCard" className="fs-exclude">
      <CardMeta>
        <div>
          <MapPinIcon />
        </div>
        <div>
          <ChipStatus status={status}>{status}</ChipStatus>
        </div>
      </CardMeta>
      <CardHeading>{name}</CardHeading>
      <CardBody>{bodyText}</CardBody>
      <CardButtonContainer>
        {openInNewTab ? (
          <GhostButtonA href={buttonLink} target="_blank" rel="noopener noreferrer" uppercasestyling="true">
            {buttonText}
          </GhostButtonA>
        ) : (
          <GhostButtonLink to={buttonLink} uppercasestyling="true">
            {buttonText}
          </GhostButtonLink>
        )}
      </CardButtonContainer>
    </Card>
  )
}

export default LocationCard
