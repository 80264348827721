import styled from 'styled-components'

interface IProps {
  percentComplete?: number
}

const BarOuter = styled.div`
  height: 10px;
  width: 100%;
  background-color: #ebebeb;
`

const BarInner = styled.div`
  background-color: #3487b2;
  height: 100%;
  width: ${(props: IProps) => (props.percentComplete ? props.percentComplete : 0)}%;
`

/** This component is used in the header for onboarding and activation to display progress */

export const ProgressBar = ({ percentComplete }: IProps) => {
  return (
    <BarOuter>
      <BarInner percentComplete={percentComplete} aria-label={`Progress: ${Math.round(percentComplete || 0)}%`} />
    </BarOuter>
  )
}

export default ProgressBar
