import { useContext, useState } from 'react'

import { BriefcaseIcon, ContactsIcon, SearchIcon } from '../../../../components/icon'
import SearchInput from '../../../../components/search-input'
import { SkeletonLine } from '../../../../components/skeleton-line'
import { AppContext } from '../../../../contexts/app-context'
import UserContext from '../../../../contexts/user-context'
import { GhostButton } from '../../../../shared-styles/button.styles'
import { ButtonLink, SecondaryButtonLink } from '../../../../shared-styles/link.styles'
import { DesktopOnly, MobileOnly } from '../../../../shared-styles/responsive.styles'
import { useTemperatures } from '../../../../utils/preference-hooks'
import {
  LocationActionButton,
  LocationActionButtonLabel,
  LocationActionIconContainer,
  LocationActionLeftButtons,
  LocationActionRow,
  LocationDetails,
  LocationDetailsAddress,
  LocationDetailsHeaderRow,
  LocationDetailsName,
  LocationMobileButtonRow,
  MobileSearchContainer,
} from '../../dashboard.styles'
import AccountModal from '../../modals/account'
import ContactsModal from '../../modals/contacts'
import HeaderContext from './context'
import NotificationsDropdown from './notifications-dropdown'

const LocationDetailsHeader = ({
  selectedLocation,
  parentFolder,
  devices,
  setSelectedDeviceId,
}: {
  selectedLocation: IFolderType | undefined
  parentFolder: IFolderType | undefined
  devices: IDeviceType[] | undefined
  setSelectedDeviceId: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
  const { foldersLoading } = useContext(AppContext)
  const { welcomeData } = useContext(UserContext)
  const { formatTemperature, tempUnits } = useTemperatures()

  const [showAccountModal, setShowAccountModal] = useState<boolean>(false)
  const [showBackButton, setShowBackButton] = useState<boolean>(false)
  const [showContactsModal, setShowContactsModal] = useState<boolean>(false)
  const { weather } = welcomeData || {}
  const [openMobileSearch, setOpenMobileSearch] = useState<boolean>(false)
  const folderId = selectedLocation?.id
  const notActivatedDevices = devices ? devices.filter(d => !d.isActivated) : []
  const extraDevices = devices ? devices.filter(d => d.isExtra) : []
  const activateDevicesText = `Activate (${notActivatedDevices.length}) ${
    extraDevices.length > 0 && extraDevices.length === notActivatedDevices.length ? 'Extra' : 'New'
  } ${notActivatedDevices.length === 1 ? 'Device' : 'Devices'}`
  const loading = foldersLoading || (selectedLocation?.folderType === 'Participant' && !welcomeData)

  const manageContacts = () => {
    setShowAccountModal(false)
    setShowContactsModal(true)
  }

  const isUS = (country: string) => {
    if (country === 'United States' || country === 'US' || country === 'USA') return true
    return false
  }

  return (
    <HeaderContext.Provider
      value={{
        showAccountModal,
        showContactsModal,
        showBackButton,
        setShowAccountModal,
        setShowContactsModal,
        setShowBackButton,
      }}
    >
      <LocationDetails>
        {loading ? (
          <>
            <LocationDetailsHeaderRow>
              <LocationDetailsName style={{ width: '100%' }}>
                <SkeletonLine rounded={true} maxWidth="306px" height="20px" alt="Loading selected location" />
              </LocationDetailsName>
            </LocationDetailsHeaderRow>
            <LocationDetailsAddress>
              <SkeletonLine rounded={true} maxWidth="306px" height="15px" light={true} />
            </LocationDetailsAddress>
          </>
        ) : (
          selectedLocation && (
            <>
              <LocationDetailsHeaderRow>
                <LocationDetailsName className="fs-exclude">{selectedLocation.name}</LocationDetailsName>
                {selectedLocation.folderType === 'Participant' && devices && notActivatedDevices.length > 0 && (
                  <DesktopOnly>
                    <SecondaryButtonLink to={`/activating/${folderId}`} className="fs-exclude">
                      {activateDevicesText}
                    </SecondaryButtonLink>
                  </DesktopOnly>
                )}
              </LocationDetailsHeaderRow>
              <LocationDetailsAddress className="fs-exclude">
                {weather && (
                  <>
                    {formatTemperature(weather.temperature)}
                    {tempUnits}
                    {selectedLocation.information.address.locality && <> in </>}
                  </>
                )}
                {selectedLocation.information.address.locality && selectedLocation.information.address.region ? (
                  <>
                    {selectedLocation.information.address.locality}
                    {selectedLocation.information.address.region && (
                      <>, {selectedLocation.information.address.region}</>
                    )}
                    {selectedLocation.information.address.country &&
                      !isUS(selectedLocation.information.address.country) && (
                        <>, {selectedLocation.information.address.country}</>
                      )}
                  </>
                ) : (
                  <>
                    {parentFolder && parentFolder.information.address && (
                      <>
                        {weather && parentFolder.information.address.locality && <> in </>}
                        {parentFolder.information.address.locality}
                        {parentFolder.information.address.region && <>, {parentFolder.information.address.region}</>}
                        {parentFolder.information.address.country &&
                          !isUS(parentFolder.information.address.country) && (
                            <>, {parentFolder.information.address.country}</>
                          )}
                      </>
                    )}
                  </>
                )}
              </LocationDetailsAddress>
              {selectedLocation.folderType === 'Participant' &&
                devices &&
                devices.filter(d => !d.isActivated)?.length > 0 && (
                  <LocationMobileButtonRow>
                    <ButtonLink to={`/activating/${folderId}`} className="fs-exclude">
                      {activateDevicesText}
                    </ButtonLink>
                  </LocationMobileButtonRow>
                )}
              <LocationActionRow>
                <LocationActionLeftButtons>
                  {selectedLocation.folderType === 'Participant' && (
                    <>
                      <LocationActionButton
                        aria-labelledby="accountButtonLabel"
                        onClick={() => setShowAccountModal(true)}
                      >
                        <BriefcaseIcon />
                        <LocationActionButtonLabel id="accountButtonLabel">Account</LocationActionButtonLabel>
                      </LocationActionButton>
                      <LocationActionButton
                        aria-labelledby="contactsButtonLabel"
                        onClick={() => setShowContactsModal(true)}
                      >
                        <LocationActionIconContainer>
                          <ContactsIcon />
                        </LocationActionIconContainer>
                        <LocationActionButtonLabel id="contactsButtonLabel">Contacts</LocationActionButtonLabel>
                      </LocationActionButton>
                    </>
                  )}
                </LocationActionLeftButtons>

                <LocationActionLeftButtons>
                  <NotificationsDropdown />
                  {devices && devices.length > 0 && (
                    <MobileOnly>
                      <LocationActionButton
                        aria-labelledby="searchButtonLabel"
                        onClick={() => {
                          setOpenMobileSearch(!openMobileSearch)
                        }}
                        style={{ borderRight: '0' }}
                      >
                        <SearchIcon />
                        <LocationActionButtonLabel id="searchButtonLabel">Search</LocationActionButtonLabel>
                      </LocationActionButton>
                    </MobileOnly>
                  )}
                </LocationActionLeftButtons>
              </LocationActionRow>
            </>
          )
        )}
      </LocationDetails>
      {selectedLocation && openMobileSearch && (
        <MobileOnly>
          <MobileSearchContainer>
            <SearchInput devices={devices} setSelectedDeviceId={setSelectedDeviceId} placeholderText="Search" />
            <GhostButton onClick={() => setOpenMobileSearch(false)}>Cancel</GhostButton>
          </MobileSearchContainer>
        </MobileOnly>
      )}
      {selectedLocation && showAccountModal && (
        <AccountModal selectedLocation={selectedLocation} manageContacts={manageContacts} />
      )}
      {selectedLocation && showContactsModal && <ContactsModal selectedLocation={selectedLocation} />}
    </HeaderContext.Provider>
  )
}

export default LocationDetailsHeader
