import { createContext } from 'react'

const authToken = localStorage.getItem('protectToken')

const AuthContext = createContext<{
  setIsAuth: (bool: boolean) => void
  isAuth: boolean
  setUserToImpersonate: (str: string | undefined) => void
  userToImpersonate: string | undefined
}>({
  isAuth: !!authToken,
  setIsAuth: () => null,
  setUserToImpersonate: () => null,
  userToImpersonate: undefined,
})

export default AuthContext
