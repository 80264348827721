import { GhostButton } from '../../shared-styles/button.styles'
import { ContentSection, ErrorReasonText, InstructionalImageContainer, InstructionalText } from './error-screen.styles'

const showErrorStrings = [
  'You do not have the appropriate folder access to impersonate this user. Refresh to continue as yourself.',
  'As a non-root user, you cannot impersonate a root user. Refresh to continue as yourself.',
]

export const ErrorBoundaryFallback = ({ error }: { error: Error }) => {
  const refreshPage = () => {
    window.location.reload()
  }
  const origin = window.location.origin
  const showError =
    origin.includes('dev.meshifyprotect.com') ||
    origin.includes('staging.meshifyprotect.com') ||
    origin.includes('localhost') ||
    showErrorStrings.includes(error.message)

  return (
    <ContentSection verticallyCenter={true} height="100%">
      <InstructionalImageContainer>
        <svg width="100" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M97.918.418H2.082C.934.418 0 1.348 0 2.5v75a2.085 2.085 0 002.082 2.082h95.836A2.084 2.084 0 00100 77.5v-75A2.085 2.085 0 0097.918.418zm-2.086 4.164V21.25H4.168V4.582h91.664zM4.168 75.418v-50h91.664v50H4.168zm6.25-62.5c0-1.152.93-2.086 2.082-2.086h8.332a2.085 2.085 0 110 4.168H12.5a2.081 2.081 0 01-2.082-2.082zm16.668 0h-.004c0-1.152.934-2.086 2.086-2.086H37.5c1.152 0 2.082.934 2.082 2.086 0 1.149-.93 2.082-2.082 2.082h-8.332a2.085 2.085 0 01-2.086-2.082h.004zm16.668 0h-.004c0-1.152.934-2.086 2.082-2.086h8.336a2.085 2.085 0 010 4.168h-8.336a2.084 2.084 0 01-2.082-2.082h.004zM37.063 47.73l-1.645-1.668-1.644 1.668h-.004c-.39.395-.922.613-1.477.613-.558 0-1.09-.218-1.48-.613a2.087 2.087 0 010-2.96l1.668-1.645-1.668-1.645a2.091 2.091 0 112.957-2.96l1.644 1.667 1.645-1.668h.004a2.091 2.091 0 112.957 2.961l-1.668 1.645 1.668 1.644a2.087 2.087 0 010 2.961c-.391.395-.922.613-1.477.613-.559 0-1.09-.218-1.48-.613zm32.125-2.957v-.004a2.087 2.087 0 010 2.961c-.39.395-.922.614-1.48.614a2.074 2.074 0 01-1.477-.614l-1.645-1.668-1.644 1.668h-.004c-.39.395-.922.614-1.48.614a2.074 2.074 0 01-1.477-.614 2.087 2.087 0 010-2.96l1.668-1.645-1.668-1.645a2.091 2.091 0 112.957-2.96l1.644 1.667 1.645-1.668h.004a2.091 2.091 0 112.957 2.961l-1.669 1.645 1.669 1.648zm-4.77 18.875v-.004A2.087 2.087 0 0162.5 65a2.312 2.312 0 01-.73-.125A33.461 33.461 0 0050 62.918c-3.996-.051-7.97.61-11.73 1.957a2.09 2.09 0 11-1.457-3.918A38.481 38.481 0 0150 58.75a38.49 38.49 0 0113.188 2.207c.52.191.941.586 1.172 1.09.234.504.254 1.078.058 1.597v.004z"
            fill="#D3D4D8"
          />
        </svg>
      </InstructionalImageContainer>
      <InstructionalText>
        Having trouble loading this screen. Please try again.
        <br />
        <GhostButton onClick={refreshPage}>Refresh Page</GhostButton>
      </InstructionalText>
      {showError && <ErrorReasonText>{error.message}</ErrorReasonText>}
    </ContentSection>
  )
}

export default ErrorBoundaryFallback
