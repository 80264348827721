import { FormValues } from '.'
import { formatIntlPhoneNumber } from '../../utils/helpers'

interface IBaseProps {
  values: FormValues
  contact?: INotificationContactUserType
  folderId?: number
  role?: string
}
type IProps = RequireOnlyOne<IBaseProps, 'contact' | 'folderId'>

export const getPayload = ({ values, contact, folderId, role = 'Notification Contact' }: IProps) => {
  const mobileNumber = `+${values.mobileNumberCountryCode} ${values.mobileNumberNoCountry}`
  const backupNumber = `+${values.backupNumberCountryCode} ${values.backupNumberNoCountry}`
  const formattedMobileNumber = mobileNumber.length > 3 ? formatIntlPhoneNumber(mobileNumber) : ''
  const formattedBackupNumber = backupNumber.length > 3 ? formatIntlPhoneNumber(backupNumber) : ''

  const payload = {
    user: {
      ...(contact && { id: contact.id }),
      ...(values.currPassword && { currPassword: values.currPassword }),
      information: {
        ...contact?.information,
        first: values.firstName,
        last: values.lastName,
      },
      phone: formattedMobileNumber,
      email: values.email,
      metadata: {
        ...contact?.metadata,
        backup_phone: formattedBackupNumber,
        backup_phone_type: values.backup_phone_type,
        backup_phone_ext: values.backup_phone_ext,
        phone_type: values.phone_type,
        phone_ext: values.phone_ext,
        receiveAlerts: values.receiveAlerts,
      },
      preferences: {
        ...contact?.preferences,
        notification: values.notificationPreferences,
      },
    },
    ...(folderId && { folderId }),
    ...(!contact && { role }),
  }
  return payload
}
