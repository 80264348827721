const RefrigeratorIcon = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 2.73C19 2.53639 18.9231 2.35071 18.7862 2.21381C18.6493 2.07691 18.4636 2 18.27 2H5.75C5.55189 2.00259 5.36263 2.08244 5.22253 2.22253C5.08244 2.36263 5.00259 2.55189 5 2.75V21.25C5.00259 21.4481 5.08244 21.6374 5.22253 21.7775C5.36263 21.9176 5.55189 21.9974 5.75 22H18.25C18.4489 22 18.6397 21.921 18.7803 21.7803C18.921 21.6397 19 21.4489 19 21.25V2.73ZM17.5 19.78C17.4974 19.9781 17.4176 20.1674 17.2775 20.3075C17.1374 20.4476 16.9481 20.5274 16.75 20.53H7.25C7.05189 20.5274 6.86263 20.4476 6.72253 20.3075C6.58244 20.1674 6.50259 19.9781 6.5 19.78V11.28C6.5 11.0811 6.57902 10.8903 6.71967 10.7497C6.86032 10.609 7.05109 10.53 7.25 10.53H16.75C16.9489 10.53 17.1397 10.609 17.2803 10.7497C17.421 10.8903 17.5 11.0811 17.5 11.28V19.78ZM17.5 8.71C17.5 8.90984 17.4213 9.10165 17.2809 9.2439C17.1406 9.38614 16.9498 9.46737 16.75 9.47H7.25C7.05017 9.46737 6.85942 9.38614 6.71905 9.2439C6.57868 9.10165 6.49998 8.90984 6.5 8.71V4.22C6.5 4.02109 6.57902 3.83032 6.71967 3.68967C6.86032 3.54902 7.05109 3.47 7.25 3.47H16.75C16.9489 3.47 17.1397 3.54902 17.2803 3.68967C17.421 3.83032 17.5 4.02109 17.5 4.22V8.71Z"
        fill={color}
      />
      <path
        d="M8.48002 4.69043H8.44002C8.06999 4.69043 7.77002 4.9904 7.77002 5.36043V7.66043C7.77002 8.03046 8.06999 8.33043 8.44002 8.33043H8.48002C8.85005 8.33043 9.15002 8.03046 9.15002 7.66043V5.36043C9.15002 4.9904 8.85005 4.69043 8.48002 4.69043Z"
        fill={color}
      />
      <path
        d="M8.48002 12.0195H8.44002C8.06999 12.0195 7.77002 12.3195 7.77002 12.6895V16.1595C7.77002 16.5296 8.06999 16.8295 8.44002 16.8295H8.48002C8.85005 16.8295 9.15002 16.5296 9.15002 16.1595V12.6895C9.15002 12.3195 8.85005 12.0195 8.48002 12.0195Z"
        fill={color}
      />
    </svg>
  )
}

export default RefrigeratorIcon
