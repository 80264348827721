import { escapeRegExp } from 'lodash'

import { StyledHighlightedTitle, StyledNoWrapDiv } from './styled'
import Tooltip from './tooltip'

interface IProps {
  name: string
  searchText: string
  width: number
}

export const HighlightMatch = (highlightProps: IProps) => {
  const { name: folderName, searchText: textToHighlight, width } = highlightProps
  const searchResultIdx = folderName.toLowerCase().search(escapeRegExp(textToHighlight).toLowerCase())
  const first = folderName.slice(0, searchResultIdx)
  const middle = folderName.slice(searchResultIdx, searchResultIdx + textToHighlight.length)
  const last = folderName.slice(searchResultIdx + textToHighlight.length)

  if (searchResultIdx === -1) {
    return <>{folderName}</>
  }
  return (
    <Tooltip label={folderName} ariaLabel={`Name for currently hovered`}>
      <StyledNoWrapDiv>
        <StyledHighlightedTitle width={width}>
          {first}
          <b>{middle}</b>
          {last}
        </StyledHighlightedTitle>
      </StyledNoWrapDiv>
    </Tooltip>
  )
}
