import { Accordion, AccordionItem } from '../../components/accordion'
import { SupportContent, SupportIntro, SupportSubTitle } from './support.styles'

const Support = () => {
  return (
    <>
      <SupportIntro>
        If you have any questions regarding your account, please call <strong>Sensor Support </strong>
        at (844) 468-1866 | Monday – Friday, 8 a.m. – 8 p.m. Eastern Time.
      </SupportIntro>
      <SupportContent>
        <SupportSubTitle>Frequently Asked Questions</SupportSubTitle>
        <Accordion collapsible={true}>
          <AccordionItem headerText="What happens if an alert is triggered at my location?">
            <p>
              If your location triggers an urgent sensor alert, a text and/or email notification is sent to your
              designated alert contacts. If no one acknowledges the alert and the condition persists, the first contact
              on your account will receive an automated or live support call from the Sensor Support Team. You will not
              receive phone calls about less urgent alerts, for example if your device’s batteries run out.
            </p>
          </AccordionItem>
          <AccordionItem headerText="What should I do if I receive an alert?">
            <p>
              After being notified about an urgent alert, review the alert information and respond with your next course
              of action. Typically, your response will either be Acknowledge (I am taking action), Ignore (I am not
              taking action due to a known issue or test), or Decline (I cannot take action at this time). In addition,
              take appropriate action to remedy the situation and avoid a loss due to property or equipment damage.
            </p>
          </AccordionItem>
          <AccordionItem headerText="Do I have to actively monitor my sensor readings?">
            <p>
              In the event of a potential issue, alerts will be sent to you, based on your notification preferences.
              Monitoring your sensor readings on a regular basis is optional.
            </p>
          </AccordionItem>
          <AccordionItem headerText="Can I check the output readings measured by my sensors?">
            <p>
              Yes, you can! You can view your data readings with the Protect monitoring dashboard. View more data
              readings associated with your devices in the Protect mobile app. Each sensor offers information about
              device details, including graphs.
            </p>
          </AccordionItem>
          <AccordionItem headerText="What if the power goes out at my location?">
            <p>
              Because your devices do not use Wi-Fi, they do not require power from your location. They will continue to
              operate for an extended period of time while the power is out.
            </p>
          </AccordionItem>
          <AccordionItem headerText="How will I know if the sensor batteries are low?">
            <p>
              Protect tracks battery status and will indicate when sensor batteries are low. You may also receive a text
              and/or email indicating that a sensor requires battery replacement.
            </p>
          </AccordionItem>
          <AccordionItem headerText="How do I update my contact information?">
            <p>
              Contacts can be updated in-app by going to the location section and selecting the contacts tab. It is very
              important that contact information is up-to-date, so notification contacts can easily be reached when
              issues are detected.
            </p>
          </AccordionItem>
          <AccordionItem headerText="What if I need more help or support?">
            <p>
              For additional support, please contact the Sensor Support Team at sensorsupport@hsb.com or (844) 468-1866
              | Monday - Friday 8am - 8pm Eastern Time
            </p>
          </AccordionItem>
        </Accordion>
      </SupportContent>
    </>
  )
}

export default Support
