import styled, { CSSObject } from 'styled-components/macro'

const InstructionContainer = styled.div`
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  width: 100%;
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    width: 275px;
  }
`

const InstructionText = styled.p`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
  margin: 13px 5px;
`

const Card = styled.div`
  border: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui06};
  border-top: 0;
  height: 185px;
  margin: 0 5px;
`

const CardHeader = styled.h3`
  display: flex;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui06};
  margin: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xs};
  letter-spacing: 1px;
  text-transform: uppercase;
`

const CardStep = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  height: 24px;
  margin-left: -1px;
  padding: 0 ${({ theme }: ThemeProps) => theme.space.xxs};
  white-space: nowrap;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -6px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 7px solid ${({ theme }: ThemeProps) => theme.colors.brand01};
  }
`

const CardHeaderText = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }: ThemeProps) => theme.space.xxs};
  padding-left: 0;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 24px);

  img {
    height: 197px;
  }
`

interface CommonProps {
  stepText: string
  headerText: string
  instructionText: string
  /** Max width of the card, it is full width of the container by default */
  maxWidth?: string
}

interface WithImageSrc extends CommonProps {
  /** Path to image source
   *
   * Either 'imageSrc' or 'imageComponent' must be provided
   */
  imageSrc: string
  imageComponent?: never
  imageStyle?: CSSObject
}

interface WithImageComponent extends CommonProps {
  imageSrc?: never
  /** React Component (such as imported SVG) that should render in the image area
   *
   * Either 'imageComponent' or 'imageSrc' must be provided
   */
  imageComponent: React.ReactNode
  imageStyle?: CSSObject
}

type IProps = WithImageSrc | WithImageComponent

export const InstructionCard = ({
  stepText,
  headerText,
  imageSrc,
  imageComponent,
  instructionText,
  maxWidth = 'none',
  imageStyle,
}: IProps) => {
  return (
    <InstructionContainer style={{ maxWidth }}>
      <Card>
        <CardHeader>
          <CardStep>{stepText}</CardStep>
          <CardHeaderText>{headerText}</CardHeaderText>
        </CardHeader>

        <CardImageContainer>
          <div>
            {imageSrc && <img src={imageSrc} alt="" style={{ ...imageStyle }} />}
            {imageComponent && imageComponent}
          </div>
        </CardImageContainer>
      </Card>
      <InstructionText>{instructionText}</InstructionText>
    </InstructionContainer>
  )
}

export default InstructionCard
