import styled from 'styled-components/macro'

export const Card = styled.div`
  box-sizing: border-box;
  ${({ theme }: ThemeProps) => theme.elevation.elevation03};
  border-radius: 0.4rem;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  padding: ${({ theme }: ThemeProps) => theme.space.m};
  margin-bottom: ${({ theme }: ThemeProps) => theme.space.m};
  overflow: hidden;
  width: 100%;
`

export const CardMeta = styled.div`
  display: flex;
  justify-content: space-between;
`

interface ChipStatusProps {
  theme: IThemeProps
  status: 'Not Complete' | 'In Progress' | 'In Transit'
}

export const ChipStatus = styled.div`
  background-color: ${({ theme, status }: ChipStatusProps) =>
    status === 'Not Complete'
      ? theme.colors.ui05
      : status === 'In Transit'
      ? theme.colors.accent_orange
      : theme.colors.support_info};
  border-radius: 1.6rem;
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.xs}`};
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
`

export const CardHeading = styled.h3`
  margin-bottom: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: 1.7;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CardBody = styled.p`
  margin-top: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: 1.7;
`

export const CardButtonContainer = styled.div`
  text-align: center;
  button {
    margin: 0;
  }
`
