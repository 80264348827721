import styled from 'styled-components/macro'

import Btn from '../../../components/button'

interface FormFieldsProps {
  theme: IThemeProps
  twoColumns?: boolean
}

export const FormFields = styled.div<FormFieldsProps>`
  width: 100%;
  max-width: 42.3rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    ${({ twoColumns }: FormFieldsProps) => {
      if (twoColumns) {
        return `
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1.2rem;
      `
      }
    }}
  }
`

export const NoColumnsFormField = styled.div<FormFieldsProps>`
  grid-column: 1 / 3;
`

export const MoveDeviceContainer = styled.div`
  margin-top: 3rem;
`

export const MoveFolderButton = styled(Btn)`
  span {
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 1.1rem;
  }
`

export const ScrollContainer = styled.tbody`
  max-height: calc(100vh - 325px);
  overflow-y: auto;
  display: block;
  width: 100%;

  tr,
  td {
    box-sizing: border-box;
    display: block;
    width: 100%;
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    max-height: calc(100vh - 365px);
  }
`

export const CurrentFolderText = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
`

interface TableButtonProps {
  theme: IThemeProps
  selected?: boolean
  notCurrent?: boolean
}
export const TableButton = styled.button<TableButtonProps>`
  background-color: ${({ theme, selected }: TableButtonProps) =>
    selected ? theme.colors.hover_row : theme.colors.ui01};
  border: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme, selected }: TableButtonProps) =>
    selected ? theme.fontWeights.semibold : theme.fontWeights.regular};
  margin: -1.6rem;
  padding: 1.6rem;
  width: calc(100% + 3.2rem);
  text-align: left;
  display: ${({ notCurrent }: TableButtonProps) => (notCurrent ? 'flex' : 'block')};
  justify-content: ${({ notCurrent }: TableButtonProps) => (notCurrent ? 'space-between' : null)};

  &:hover,
  &:focus {
    background-color: ${({ theme }: ThemeProps) => theme.colors.hover_row};
    cursor: pointer;
  }
`

export const CurrentText = styled.span`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
`

export const SaveButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const AlertContainer = styled.div`
  margin-top: 0.8rem;
`
