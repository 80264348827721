import { Dispatch, SetStateAction, useRef } from 'react'

import CloseButton from '../../assets/close-btn.svg'
import SearchIcon from '../../assets/search.svg'
import { Search, SearchWrapper, StyledClearButton, StyledMagnifying } from './search-bar.styled'

interface SearchProps {
  placeHolderText?: string | undefined
  setSearchText: Dispatch<SetStateAction<string>>
  searchText: string
  disabled?: boolean
}

export const SearchBar = (props: SearchProps) => {
  const { setSearchText, searchText, disabled } = props
  const input = useRef<HTMLInputElement>(null)
  return (
    <SearchWrapper>
      <StyledMagnifying src={SearchIcon} alt="Search" />

      <Search
        type="text"
        placeholder={props.placeHolderText || 'Search'}
        ref={input}
        value={searchText}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setSearchText(evt.target.value)}
        onBlur={(evt: React.ChangeEvent<HTMLInputElement>) => setSearchText(evt.target.value)}
        data-testid="search-field"
        disabled={props.hasOwnProperty('disabled') ? disabled : false}
      />

      {!disabled && searchText && (
        <StyledClearButton
          onClick={() => {
            setSearchText('')
            if (input.current) {
              input.current.focus()
            }
          }}
        >
          <img src={CloseButton} alt="Clear Search" />
        </StyledClearButton>
      )}
    </SearchWrapper>
  )
}

export default SearchBar
