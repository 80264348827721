import { LoadingIcon } from '../../components/icon'
import { ContentSection, InstructionalImageContainer, InstructionalTextLoading } from './activating.styles'

const Loading = () => (
  <ContentSection verticallyCenter={true}>
    <InstructionalImageContainer>
      <LoadingIcon />
    </InstructionalImageContainer>
    <InstructionalTextLoading>Just a few seconds while we get your information.</InstructionalTextLoading>
  </ContentSection>
)

export default Loading
