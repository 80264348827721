/** Sort an array of Folders by name in reverse */
export const sortFolderByName = (array: IGenericFolderType[]) => {
  const sortedArray = array.sort((a: IGenericFolderType, b: IGenericFolderType) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0
  })
  return sortedArray
}

export const generateAnnotatedFolderMap = (
  folderList: IGenericFolderType[],
  searchText: string
): [Map<string, AnnotatedFolder>, IGenericFolderType[], boolean] => {
  const nameMatchesSearch = (name: string, text: string) =>
    Boolean(name?.toLowerCase().includes(text.toLowerCase().trim()))

  // Create a map of ids from a list
  const folderMap = new Map<string, IGenericFolderType>(folderList.map(folder => [folder.id.toString(), folder]))

  const rootFolders = folderList.filter(folder => {
    const parent = folderMap.get(folder.parentId.toString())
    return parent == null || parent.id === folder.id
  })

  // calculate all the children folders for faster lookup
  const folderChildrenMap = new Map<string, string[]>()
  folderList.forEach(folder => {
    const folderId = folder.id.toString()
    const parentFolderId = folder.parentId.toString()
    const childrenList = folderChildrenMap.get(parentFolderId)
    if (parentFolderId !== folderId) {
      if (childrenList) {
        childrenList.push(folderId)
      } else {
        folderChildrenMap.set(parentFolderId, [folderId])
      }
    }
    if (!folderChildrenMap.get(folderId)) {
      // if the current folder doesn't have any children yet - lets initialize the child list
      // because if it's a leaf - it won't ever get one.
      folderChildrenMap.set(folderId, [])
    }
  })

  const folderTree = new Map<string, AnnotatedFolder>()
  let showTree = false

  // Assign children based on parentFolderIds, initialize map with all default values
  const assignChildren = (rootIds: string[]) => {
    rootIds.forEach(currentRootId => {
      const currentRoot = folderMap.get(currentRootId)
      const rootsChildren = folderChildrenMap.get(currentRootId)
      if (rootsChildren && currentRoot) {
        const folderNameMatchesSearch = searchText !== '' && nameMatchesSearch(currentRoot.name, searchText)
        // we want to show the tree if:
        //  - there are any roots (within this loop)
        //  - the search text is empty (which should display all user folders)
        //  - or at least one folder name matches the search
        if (searchText === '' || folderNameMatchesSearch) {
          showTree = true
        }

        folderTree.set(currentRootId, {
          id: currentRootId,
          folder: currentRoot,
          name: currentRoot.name,
          children: rootsChildren,
          hasAlerts: currentRoot.hasAlerts ?? false,
          childHasAlerts: false,

          childrenContainMatch: false,
          nameMatchesSearch: folderNameMatchesSearch,
        })

        // assign alerts to the parents as well
        let parent = folderTree.get(currentRoot.parentId.toString())
        while (parent) {
          if (currentRoot.hasAlerts) {
            parent.childHasAlerts = true
          }
          if (folderNameMatchesSearch) {
            parent.childrenContainMatch = true
          }
          if (parent.id === parent.folder.parentId.toString()) {
            break
          }
          parent = folderTree.get(parent.folder.parentId.toString())
        }

        if (rootsChildren?.length > 0) {
          return assignChildren(rootsChildren)
        }
      }
    })
  }
  assignChildren(rootFolders.map(folder => folder.id.toString()))

  return [folderTree, sortFolderByName(rootFolders), showTree]
}
