import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import styled from 'styled-components/macro'

import themeObject from '../../theme'

interface ChildrenProps {
  children: React.ReactNode
  buttonstyle?: 'default' | 'ghostbutton'
  minwidth?: string
  align?: 'right' | 'left'
  variation?: 'default' | 'secondary'
  highlightExpanded?: boolean
}

const DropdownMenuContainer = styled.div`
  position: relative;

  [data-reach-menu-list],
  [data-reach-menu-items] {
    border: 0;
  }
  [data-reach-menu-popover] {
    text-align: left;
    z-index: 20;

    ${({ align }: ChildrenProps) => {
      if (align === 'right') {
        return `right: 0; width: 100%;`
      }
    }};
  }
`

export const DropdownMenu = ({ align = 'left', children }: ChildrenProps) => {
  return (
    <Menu>
      <DropdownMenuContainer align={align}>{children}</DropdownMenuContainer>
    </Menu>
  )
}

interface StyledDropdownButtonProps {
  theme: IThemeProps
  buttonstyle: 'default' | 'ghostbutton'
  highlightexpanded: 'true' | 'false'
}
const StyledDropdownButton = styled(MenuButton)<StyledDropdownButtonProps>`
  background: none;
  border: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  display: flex;
  justify-content: center;

  ${({ theme, buttonstyle }: StyledDropdownButtonProps) => {
    if (buttonstyle === 'ghostbutton') {
      return `
      color: ${theme.colors.brand01};
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizes.s};
      font-weight: ${theme.fontWeights.semibold};
      letter-spacing: 1px;
      line-height: ${theme.lineHeights.l};
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      `
    }
  }}

  &[aria-expanded] svg, &[aria-expanded] path {
    ${({ highlightexpanded }: StyledDropdownButtonProps) => {
      if (highlightexpanded === 'true') {
        return `fill: ${themeObject.colors.support_info};`
      }
    }};
  }
`

export const DropdownButton = ({ children, buttonstyle = 'default', highlightExpanded = false }: ChildrenProps) => {
  return (
    <StyledDropdownButton buttonstyle={buttonstyle} highlightexpanded={highlightExpanded ? 'true' : 'false'}>
      {children}
    </StyledDropdownButton>
  )
}

const StyledDropdownMenuList = styled(MenuList)<ChildrenProps>`
  border: 0;
  border-radius: 0.4rem;
  ${({ theme }: ThemeProps) => theme.elevation.elevation03};
  min-width: ${({ minwidth }: ChildrenProps) => minwidth};
  ${({ variation }: ChildrenProps) => {
    if (variation === 'secondary') {
      return `color: ${themeObject.colors.support_info};`
    } else {
      return `color: ${themeObject.colors.text02};`
    }
  }};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  text-transform: none;
  ${({ variation }: ChildrenProps) => {
    if (variation === 'secondary') {
      return `padding: 0;`
    }
  }};

  [data-reach-menu-item] {
    padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.s}`};
    ${({ variation }: ChildrenProps) => {
      if (variation === 'secondary') {
        return `border-bottom: 1px solid ${themeObject.colors.ui04};`
      }
    }};

    &:last-child {
      border: 0;
    }
  }
  [data-reach-menu-item][data-selected] {
    background-color: ${({ theme }: ThemeProps) => theme.colors.hover_row};
    ${({ variation }: ChildrenProps) => {
      if (variation === 'secondary') {
        return `color: ${themeObject.colors.support_info};`
      } else {
        return `color: ${themeObject.colors.text02};`
      }
    }};
  }
  .selected[data-reach-menu-item] {
    font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  }
`

export const DropdownMenuList = ({ children, minwidth = '300px', variation = 'default' }: ChildrenProps) => {
  return (
    <StyledDropdownMenuList portal={false} minwidth={minwidth} variation={variation}>
      {children}
    </StyledDropdownMenuList>
  )
}

interface DropdownMenuItemProps {
  children: React.ReactNode
  value: string | number
  onSelect: (selectedValue: string | number) => void
  isSelected?: boolean
}

export const DropdownMenuItem = ({ children, value, onSelect, isSelected = false }: DropdownMenuItemProps) => {
  return (
    <MenuItem
      onSelect={() => onSelect(value)}
      className={isSelected ? 'selected' : ''}
      data-testid={`menuitem-` + value}
    >
      {children}
    </MenuItem>
  )
}
