import { Alert as ReachAlert } from '@reach/alert'
import styled from 'styled-components/macro'

import { FormField } from '../../shared-styles/form.styles'

export const ToggleContainer = styled.label`
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-left: ${({ theme }: ThemeProps) => theme.space.xs};
    margin-right: ${({ theme }: ThemeProps) => theme.space.s};
  }
`

export const ModalForm = styled.form`
  box-sizing: border-box;
  width: 100%;
  text-align: left;
`

export const ModalHeader = styled.h3`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.s};
  margin-top: 0;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  }
`

export const ToggleFormField = styled(FormField)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    justify-content: flex-start;
  }
`

interface ArrowToggleContainerProps {
  toggle: boolean
}
export const ArrowToggleContainer = styled.button`
  padding: 1.5rem 1.3rem;
  margin-right: -1.1rem;
  line-height: 0;
  background: transparent;
  border: 0;

  svg {
    transform: ${({ toggle }: ArrowToggleContainerProps) => (toggle ? `rotate(0deg)` : `rotate(180deg)`)};
  }
`

export const PrimaryContactAlert = styled(ReachAlert)`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
`

export const WarningIconContainer = styled.div`
  width: 6.5rem;
  text-align: center;
  margin-right: 1rem;
`

export const CTAButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ center }: { center: boolean }) => (center ? '1fr' : '1fr 1fr')};
  justify-content: ${({ center }: { center: boolean }) => (center ? 'center' : 'space-between')};
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: flex;
  }

  button:nth-child(1) {
    grid-row: 2;
    margin-top: ${({ center }: { center: boolean }) => (center ? '3.2rem' : '1.6rem')};

    @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
      margin-top: 3.2rem;
    }
  }
`

export const DeleteAlert = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 20px;

  svg {
    margin-top: 0.8rem;
  }
`

export const DeleteAlertInnerText = styled.span`
  margin-top: 0.8rem;
`

export const TextAgreement = styled.span`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;

  a {
    color: ${({ theme }: ThemeProps) => theme.colors.support_info};
    font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  }
`

export const NotificationPreferencesContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const NotificationPreferencesText = styled.div`
  padding-top: 8px;
`

export const PhoneFieldContainer = styled.div`
  @media (min-width: 667px) and (max-width: 991px) {
    display: flex;
  }
  @media (min-width: 1200px) {
    display: flex;
  }
`

export const PhoneExtTypeContainer = styled.div`
  display: flex;
`

export const PhoneExtField = styled(FormField)`
  flex: 1;

  @media (min-width: 667px) and (max-width: 991px) {
    width: 8rem;
    margin-left: 0.8rem;
  }
  @media (min-width: 1200px) {
    width: 8rem;
    margin-left: 0.8rem;
  }
`

export const PhoneTypeField = styled(FormField)`
  width: 18rem;
  margin-left: 0.8rem;

  @media (min-width: 1200px) {
    width: 16.5rem;
  }
`

export const DisabledEmailAlert = styled.div`
  margin-top: 0.8rem;
  margin-bottom: -1.6rem;
`
