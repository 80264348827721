import TroubleshootingPanel from './troubleshooting-panel'
import { Hr, TroubleshootingContent } from './troubleshooting-panels.styles'

export const TroubleshootingPanelConnectionErrorContent = () => {
  return (
    <TroubleshootingContent>
      <p>
        Sensor will automatically try to reconnect after a few minutes, and retry at 15-20 minute intervals until
        connection is made.
      </p>
      <p>If device is unable to establish connection, it will enter power saving mode.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Force connection by removing the battery, waiting 10 seconds, and replacing the battery.</li>
        <li>Check the sensor readings or the data tab to confirm connection status.</li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelConnectionError = () => {
  return (
    <TroubleshootingPanel title="Connection Error" modalIndexValue="connection_error">
      <TroubleshootingPanelConnectionErrorContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowBatteryContent = () => {
  return (
    <TroubleshootingContent>
      <p>You can continue activation, but replace battery as soon as possible.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Purchase a CR123a 3 Volt Battery.</li>
        <li>Remove battery cover from sensor, insert new battery, replace battery cover and press to close.</li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowBattery = () => {
  return (
    <TroubleshootingPanel title="Low Battery" modalIndexValue="low_battery">
      <TroubleshootingPanelLowBatteryContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowSignalContent = () => {
  return (
    <TroubleshootingContent>
      <p>You can continue activation, but poor signal may impact battery life or sensor connectivity.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>
          Try moving the sensor to a new location away from walls and other electronics to check for a better signal.
        </li>
        <li>
          After moving the sensor, force connection by removing the battery, waiting 10 seconds, and replacing the
          battery.
        </li>
        <li>Check the readings or the data tab to confirm signal status.</li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowSignal = () => {
  return (
    <TroubleshootingPanel title="Poor Signal" modalIndexValue="low_signal">
      <TroubleshootingPanelLowSignalContent />
    </TroubleshootingPanel>
  )
}
