import styled from 'styled-components/macro'

export const LocationCardsContainer = styled.div`
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.4rem;
  }
`
