import Modal from '../modal'

interface SuppoortModalProps {
  openModal: boolean
  onDismiss: () => void
}

const SupportModal = (props: SuppoortModalProps) => {
  return (
    <Modal
      openModal={props.openModal}
      onDismiss={props.onDismiss}
      title="Monitoring Support"
      maxWidth="72.2rem"
      padding="1.875rem 6.4rem 5.4rem 6.4rem"
    >
      <p>
        If you have any questions regarding your account, please call <strong>Sensor Support</strong> at (844) 468-1866
        | Monday – Friday, 8 a.m. – 8 p.m. Eastern Time.
      </p>
    </Modal>
  )
}

export default SupportModal
