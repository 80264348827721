import { Menu } from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import { VisuallyHidden } from '@reach/visually-hidden'
import moment from 'moment'
import React, { useContext, useState } from 'react'

import {
  AlertConnectionErrorIcon,
  AlertSevereIcon,
  AlertWarningIcon,
  NoDeviceAlertsIcon,
  NotificationsIcon,
} from '../../../../components/icon'
import { DesktopOnly, MobileOnly } from '../../../../shared-styles/responsive.styles'
import { ModalHeader } from '../../../../shared-styles/typography.styles'
import theme from '../../../../theme'
import { API_DEFAULT_DATE } from '../../../../utils/constants'
import { getUseCaseIcon } from '../../../../utils/helpers'
import { getUseCase } from '../../../../utils/usecase-matrix'
import { AlertCount, LocationActionButton, LocationActionButtonLabel } from '../../dashboard.styles'
import { Modal } from './../../../../components/modal/index'
import UserContext from './../../../../contexts/user-context'
import {
  AlarmCard,
  AlarmCardHeader,
  AlarmCardTime,
  ButtonRow,
  CategoryHeading,
  CategoryHeadingWithBorder,
  DeviceImageContainer,
  DropdownMenuContainer,
  ImageCell,
  ImageContainer,
  InfoCell,
  MobileAlarmCard,
  NoAlertsContainer,
  NotificationsListContainer,
  StyledDropdownButton,
  StyledDropdownMenuList,
} from './notifications-dropdown.styles'

interface ChildrenProps {
  children: React.ReactNode
}

const DropdownMenu = ({ children }: ChildrenProps) => {
  return (
    <Menu>
      <DropdownMenuContainer>{children}</DropdownMenuContainer>
    </Menu>
  )
}

const DropdownButton = ({ children }: ChildrenProps) => {
  return <StyledDropdownButton>{children}</StyledDropdownButton>
}

const DropdownMenuList = ({ children }: ChildrenProps) => {
  return <StyledDropdownMenuList portal={false}>{children}</StyledDropdownMenuList>
}

const AlarmImage = ({
  alarm,
  isMobile,
  isResolved,
}: {
  alarm: IAlertHistory
  isMobile: boolean | undefined
  isResolved: boolean
}) => {
  return (
    <ImageCell>
      <ImageContainer>
        <DeviceImageContainer>{getUseCaseIcon(alarm.usecase)}</DeviceImageContainer>
        {alarm.name.toLowerCase().includes('offline') || alarm.name.toLowerCase().includes('inactivity') ? (
          <AlertConnectionErrorIcon
            width={isMobile ? 16 : 24}
            height={isMobile ? 16 : 24}
            fill={isResolved ? theme.colors.ui04 : undefined}
          />
        ) : (
          <>
            {alarm.tags.includes('warning') && (
              <AlertWarningIcon
                width={isMobile ? 16 : 24}
                height={isMobile ? 16 : 24}
                fill={isResolved ? theme.colors.ui04 : undefined}
              />
            )}
            {alarm.tags.includes('urgent') && (
              <AlertSevereIcon
                width={isMobile ? 16 : 24}
                height={isMobile ? 16 : 24}
                fill={isResolved ? theme.colors.ui04 : undefined}
              />
            )}
          </>
        )}
      </ImageContainer>
    </ImageCell>
  )
}

const AlarmInfo = ({ alarm }: { alarm: IAlertHistory }) => {
  return (
    <InfoCell>
      <div>
        <AlarmCardHeader data-testid={`alarm-card-header-${alarm.usecase}`}>
          {getUseCase(alarm.usecase).vanity}
        </AlarmCardHeader>
        {alarm.date !== API_DEFAULT_DATE ? (
          <AlarmCardTime>
            {' · '}
            {moment(alarm.date).fromNow()}
          </AlarmCardTime>
        ) : null}
      </div>
      <div>
        {alarm.name}
        {alarm.deviceLocation && <> · {alarm.deviceLocation}</>}
      </div>
    </InfoCell>
  )
}

const Alarm = ({
  alarm,
  isMobile,
  isResolved,
}: {
  alarm: IAlertHistory
  isMobile: boolean | undefined
  isResolved: boolean
}) => {
  return (
    <>
      <AlarmImage alarm={alarm} isMobile={isMobile} isResolved={isResolved} />
      <AlarmInfo alarm={alarm} />
    </>
  )
}

const NotificationsList = ({
  alarmsInFolder,
  resolvedAlarmsInFolder,
  isMobile,
}: {
  alarmsInFolder: IAlertHistory[]
  resolvedAlarmsInFolder: IAlertHistory[]
  isMobile?: boolean
}) => {
  const { setSelectedDeviceId } = useContext(UserContext)
  const [showResolved, setShowResolved] = useState<boolean>(false)

  return (
    <NotificationsListContainer className="fs-exclude">
      <CategoryHeading>Active</CategoryHeading>
      {alarmsInFolder.map((alarm, i) => {
        if (isMobile)
          return (
            <MobileAlarmCard key={`active-alarm-${i}`} onClick={() => setSelectedDeviceId(alarm.nodeId)}>
              <Alarm alarm={alarm} isMobile={isMobile} isResolved={false} />
            </MobileAlarmCard>
          )
        else
          return (
            <AlarmCard key={`active-alarm-${i}`} onSelect={() => setSelectedDeviceId(alarm.nodeId)}>
              <Alarm alarm={alarm} isMobile={isMobile} isResolved={false} />
            </AlarmCard>
          )
      })}
      {alarmsInFolder.length === 0 && (
        <NoAlertsContainer>
          <NoDeviceAlertsIcon />
          <div>No active alerts</div>
        </NoAlertsContainer>
      )}
      {resolvedAlarmsInFolder.length > 0 && <CategoryHeadingWithBorder>Resolved</CategoryHeadingWithBorder>}
      {showResolved &&
        resolvedAlarmsInFolder.map((alarm, i) => {
          if (isMobile)
            return (
              <MobileAlarmCard key={`resolved-alarm-${i}`} onClick={() => setSelectedDeviceId(alarm.nodeId)}>
                <Alarm alarm={alarm} isMobile={isMobile} isResolved={true} />
              </MobileAlarmCard>
            )
          else
            return (
              <AlarmCard key={`resolved-alarm-${i}`} onSelect={() => setSelectedDeviceId(alarm.nodeId)}>
                <Alarm alarm={alarm} isMobile={isMobile} isResolved={true} />
              </AlarmCard>
            )
        })}

      {!showResolved && resolvedAlarmsInFolder.length > 0 && (
        <ButtonRow onClick={() => setShowResolved(true)}>View Resolved</ButtonRow>
      )}
    </NotificationsListContainer>
  )
}

const NotificationsDropdown = () => {
  const { folderAlarms: alarmsInFolder } = useContext(UserContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const activeAlarms = alarmsInFolder.filter(alarm => alarm.status === 'active')
  const resolvedAlarmsInFolder = alarmsInFolder.filter(alarm => alarm.status === 'resolved')

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <MobileOnly>
        <LocationActionButton
          aria-labelledby="notificationsButtonLabel"
          onClick={() => {
            setShowModal(true)
          }}
        >
          <div style={{ position: 'relative' }}>
            <NotificationsIcon />
            {activeAlarms.length > 0 && (
              <AlertCount
                style={{
                  left: activeAlarms.length > 9 ? '0.2rem' : '0.7rem',
                }}
              >
                <span aria-hidden="true">{activeAlarms.length}</span>
                <VisuallyHidden>{`${activeAlarms.length} notification${
                  activeAlarms.length > 1 ? 's' : ''
                }`}</VisuallyHidden>
              </AlertCount>
            )}
          </div>
          <LocationActionButtonLabel id="notificationsButtonLabel">Notifications</LocationActionButtonLabel>
        </LocationActionButton>
      </MobileOnly>
      <DesktopOnly>
        <DropdownMenu>
          <DropdownButton>
            <NotificationsIcon /> <span>Notifications</span>
            {activeAlarms.length > 0 && (
              <AlertCount
                style={{
                  left: activeAlarms.length > 9 ? '0.2rem' : '0.7rem',
                }}
              >
                <span aria-hidden="true">{activeAlarms.length}</span>
                <VisuallyHidden>{`${activeAlarms.length} notification${
                  activeAlarms.length > 1 ? 's' : ''
                }`}</VisuallyHidden>
              </AlertCount>
            )}
          </DropdownButton>

          <DropdownMenuList>
            <NotificationsList alarmsInFolder={activeAlarms} resolvedAlarmsInFolder={resolvedAlarmsInFolder} />
          </DropdownMenuList>
        </DropdownMenu>
      </DesktopOnly>

      <Modal
        title="Notifications"
        showTitle={false}
        openModal={showModal}
        onDismiss={closeModal}
        onBack={closeModal}
        showBackButton={true}
        showDivider={true}
        showClose={true}
        height="100vh"
        mobilePadding="1.6rem 1.6rem 4.8rem 1.6rem"
      >
        <ModalHeader aria-hidden="true">Notifications</ModalHeader>
        <NotificationsList
          alarmsInFolder={activeAlarms}
          resolvedAlarmsInFolder={resolvedAlarmsInFolder}
          isMobile={true}
        />
      </Modal>
    </>
  )
}

export default NotificationsDropdown
