import { init as initFullStory } from '@fullstory/browser'
import '@reach/tooltip/styles.css'
import * as Sentry from '@sentry/browser'
import { createRoot } from 'react-dom/client'
import WebFont from 'webfontloader'

import App from './App'
import * as serviceWorker from './serviceWorker'

initFullStory({ orgId: 'o-1SRWH4-na1' })

WebFont.load({
  google: {
    families: ['Work Sans:400,600'],
  },
})

// Api key is public
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://a1956b4184844e50ae2af411f063ab43@o114884.ingest.sentry.io/5681235',
    environment: process.env.REACT_APP_ENV,
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
