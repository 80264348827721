import { useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components/macro'

import { SmallChevronIcon } from '../icon'

const SliderContainer = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;

  .slick-dots {
    pointer-events: none;

    li {
      width: 10px;
      height: 10px;
      margin: 0 2px;
    }
    button {
      pointer-events: all;
      width: 10px;
      height: 10px;
      padding: 0;
    }
  }
  .slick-dots .slick-active button:before {
    color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  }
  .slick-dots button:before {
    color: ${({ theme }: ThemeProps) => theme.colors.ui06};
    opacity: 1;
    font-size: 8px;
    line-height: 8px;
    width: 10px;
    height: 10px;
  }
`

const PrevArrowContainer = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  bottom: -31px;
  left: calc(50% - (${({ slides }: { slides: number }) => slides} * 14px) - 12px);
  padding: 10px;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }
`
const NextArrowContainer = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  bottom: -31px;
  right: calc(50% - (${({ slides }: { slides: number }) => slides} * 14px) - 12px);
  padding: 10px;
  cursor: pointer;
`

const PrevArrow = ({ onClick, slides }: { onClick?: () => void; slides: number }) => {
  return (
    <PrevArrowContainer onClick={onClick} slides={slides}>
      <SmallChevronIcon />
    </PrevArrowContainer>
  )
}
const NextArrow = ({ onClick, slides }: { onClick?: () => void; slides: number }) => {
  return (
    <NextArrowContainer onClick={onClick} slides={slides}>
      <SmallChevronIcon />
    </NextArrowContainer>
  )
}

interface CarouselProps {
  children: React.ReactNode
  /** The number of slides this carousel should display. Should match the number of elements passed in as children. */
  numberOfSlides: number
  autoplay?: boolean
  autoplaySpeed?: number
  speed?: number
}

export const Carousel = ({
  children,
  autoplay = true,
  numberOfSlides,
  autoplaySpeed = 6000,
  speed = 600,
}: CarouselProps) => {
  const [iteration, setIteration] = useState(0)

  const slider = useRef<Slider>()

  const settings = {
    accessibility: true,
    // Stop the animation after 5 iterations (arbitrary, need clarification on requirements)
    afterChange: (index: number) => {
      if (index === 5) setIteration(iteration + 1)
      if (iteration === 5) slider?.current?.slickPause()
    },
    autoplay,
    autoplaySpeed,
    dots: true,
    arrows: true,
    infinite: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    speed,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnDotsHover: true,
    prevArrow: <PrevArrow slides={numberOfSlides} />,
    nextArrow: <NextArrow slides={numberOfSlides} />,
  }

  return (
    <SliderContainer>
      <Slider
        {...settings}
        ref={thisSlider => {
          if (thisSlider) {
            slider.current = thisSlider
          }
        }}
      >
        {children}
      </Slider>
    </SliderContainer>
  )
}

export default Carousel
