import { VisuallyHidden } from '@reach/visually-hidden'
import React, { useContext, useState } from 'react'

import GetAppQR from '../../assets/get-native-app-qr.svg'
import { AppContext } from '../../contexts/app-context'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import theme from '../../theme'
import { CloseIcon, SolidInfoIcon } from '../icon'
import Modal from '../modal'
import {
  NativeAppButtonDesktop,
  NativeAppButtonMobile,
  NativeAppButtonStyled,
  NativeBanner,
  NativeBannerButton,
  NativeBannerCloseButton,
  NativeBannerLeft,
  NativeBannerModalContent,
  NativeBannerModalContentButton,
  NativeBannerRight,
  NativeBannerText,
} from './header.styles'

export const NativeAppButton = () => {
  const { minimizeNativeBanner } = useContext(AppContext)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const showButton = minimizeNativeBanner

  return (
    <>
      {showButton ? (
        <NativeAppButtonDesktop>
          <NativeAppButtonStyled onClick={() => setOpenModal(true)}>Download App</NativeAppButtonStyled>
        </NativeAppButtonDesktop>
      ) : null}
      <NativeAppButtonMobile>
        <NativeAppButtonStyled onClick={() => setOpenModal(true)}>Use App</NativeAppButtonStyled>
      </NativeAppButtonMobile>
      <NativeAppModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  )
}

const NativeAppModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Modal
      title="The New Meshify Protect App"
      openModal={openModal}
      onDismiss={() => setOpenModal(false)}
      roundedCorners={true}
      shadows={true}
      showTitleAboveDivider={true}
      showDivider={true}
      showDividerMobile={true}
      maxWidth="46.7rem"
      margin="30vh auto"
    >
      <MobileOnly>
        <NativeBannerModalContent>Our new mobile app is out now.</NativeBannerModalContent>
        <NativeBannerModalContent>
          Get the app to see all of the great features we have included so far.
        </NativeBannerModalContent>
        <p>
          <NativeBannerModalContentButton href="https://meshifyprotect.app.link/app">
            Download App
          </NativeBannerModalContentButton>
        </p>
      </MobileOnly>
      <DesktopOnly>
        <p>Our new mobile app is out now. Download it to see all of the great features we have included so far.</p>
        <p>
          <img src={GetAppQR} alt="QR Code" width="128" height="128" />
        </p>
        <p>Scan QR with your mobile device to download the app.</p>
      </DesktopOnly>
    </Modal>
  )
}

const NativeAppBanner = () => {
  const { minimizeNativeBanner, setMinimizeNativeBanner } = useContext(AppContext)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const showBanner = !minimizeNativeBanner

  const minimizeBanner = () => {
    localStorage.setItem('minimizeNativeBanner', 'true')
    setMinimizeNativeBanner(true)
  }

  return (
    <>
      {showBanner ? (
        <>
          <NativeBanner>
            <NativeBannerLeft>
              <SolidInfoIcon fill={theme.colors.text_inverse} />
              <NativeBannerText>The New Meshify Protect App is out now.</NativeBannerText>
            </NativeBannerLeft>
            <NativeBannerRight>
              <NativeBannerButton onClick={() => setOpenModal(true)}>Download App</NativeBannerButton>
              <NativeBannerCloseButton onClick={() => minimizeBanner()}>
                <CloseIcon fill={theme.colors.text_inverse} />
                <VisuallyHidden>Minimize</VisuallyHidden>
              </NativeBannerCloseButton>
            </NativeBannerRight>
          </NativeBanner>
          <NativeAppModal openModal={openModal} setOpenModal={setOpenModal} />
        </>
      ) : null}
    </>
  )
}

export default NativeAppBanner
