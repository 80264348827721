import Step1 from '../../../../assets/instructions-v3-step1.png'
import Step2 from '../../../../assets/instructions-v3-step2.png'
import Step3 from '../../../../assets/instructions-v3-step3.png'
import Carousel from '../../../../components/carousel'
import InstructionCard from '../../../../components/instruction-card'
import { InstructionCardGrid, MobileCarousel } from './instruction-cards.styles'

const Cards = [
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step1}
      stepText="Step 1"
      headerText="Flip Sensor"
      instructionText="Flip sensor over so middle button is facing up"
    />
  ),
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step2}
      stepText="Step 2"
      headerText="Press Button"
      instructionText="Press down on button for 2-4 seconds (or until you hear a beep) and release the button"
    />
  ),
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step3}
      stepText="Step 3"
      headerText="Wait"
      instructionText="Button will emit a blinking purple light as the sensor begins to activate"
    />
  ),
]

export const InstructionCards = () => {
  return (
    <>
      <MobileCarousel>
        <Carousel numberOfSlides={Cards.length} autoplay={false}>
          {Cards.map((card, index) => {
            return card(index)
          })}
        </Carousel>
      </MobileCarousel>
      <InstructionCardGrid>
        {Cards.map((card, index) => {
          return card(index)
        })}
      </InstructionCardGrid>
    </>
  )
}

export default InstructionCards
