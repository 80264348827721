import axios from 'axios'
axios.defaults.withCredentials = true

const headers = {
  Accept: 'application/json',
}

const API = {
  doDelete: (url: string, options: any = {}) => {
    const opts = {
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    }
    return axios.delete(url, opts)
  },
  get: (url: string, options: any = {}) => {
    const opts = {
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    }
    return axios.get(url, opts)
  },
  post: (url: string, body: any, options: any = {}, cancel: any = {}) => {
    const opts = {
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    }
    return axios.post(url, body, opts)
  },
  put: (url: string, body: any, options: any = {}) => {
    const opts = {
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    }
    return axios.put(url, body, opts)
  },
  patch: (url: string, body: any, options: any = {}) => {
    const opts = {
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    }
    return axios.patch(url, body, opts)
  },
}

export default API
