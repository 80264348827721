import '@reach/dialog/styles.css'
import { navigate, RouteComponentProps } from '@reach/router'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { Formik } from 'formik'
import queryString from 'query-string'
import { useEffect, useState } from 'react'

import Alert from '../../components/alert'
import DisplayPasswordReqValidationResult from '../../components/display-password-validation-result'
import PasswordField from '../../components/password-field'
import ResetPasswordModal, { ResetFormValues } from '../../components/reset-password-modal'
import { Error } from '../../shared-styles/alert.styles'
import { LinkButton } from '../../shared-styles/button.styles'
import { CenteredButtonWrapper, FormField, StyledForm } from '../../shared-styles/form.styles'
import { DesktopOnly } from '../../shared-styles/responsive.styles'
import {
  MobileSplash,
  Splash,
  SplashButtonContainer,
  SplashContentContainer,
  SplashGrid,
  SplashPageSubTitle,
  SplashPageTitle,
} from '../../shared-styles/splashscreen.styles'
import API from '../../utils/api'
import { newPasswordReqValidation } from '../../utils/validators'
import { Btn } from './../../components/button/index'
import { isTokenExpired } from './helpers'

interface FormValues {
  newPassword: string
  confirmPassword: string
}

const ResetPassword = (props: RouteComponentProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorCount, setErrorCount] = useState<number>(0)
  const [bounceError, setBounceError] = useState<boolean>(false)
  const [requestNewTokenLink, setRequestNewTokenLink] = useState<boolean>(false)
  const [resetEmailSent, setResetEmailSent] = useState<string | undefined>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())

  useEffect(() => {
    if (props && props.location) {
      const queryParams = queryString.parse(props.location.search)
      const token = queryParams.ref as string
      if (!token || isTokenExpired(token)) {
        setErrorMessage('Your password reset link is expired or invalid.')
        setRequestNewTokenLink(true)
      }
    }
  }, [props])

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  }

  const displayErrorMessage = () => {
    if (errorMessage) return errorMessage
    return false
  }

  const handleSubmitError = (message: string) => {
    setErrorMessage(message)
    setErrorCount(errorCount + 1)
    if (errorCount > 0) setBounceError(true)
  }

  const onSubmit = async ({ newPassword }: FormValues) => {
    setErrorCount(0)
    try {
      if (props && props.location) {
        const queryParams = queryString.parse(props.location.search)
        const token = queryParams.ref

        // Post to API to reset password
        const res = await API.put(`/api/passwords/changepasswordwithtoken`, {
          newPassword,
          token,
        })
        // Redirect to login page with success message
        if (res.status === 200) navigate('/login?message=reset-password-successful')
      } else {
        handleSubmitError('Something was wrong in our system.')
      }
    } catch (e) {
      if (!window.navigator.onLine) {
        handleSubmitError('There is no internet connection. Please check your network.')
      } else if (((e as AxiosError).response?.data as ApiV1Error)?.detail === 'weak passwords are not allowed') {
        handleSubmitError('The password provided is too common, please try again.')
      } else {
        handleSubmitError('Something was wrong in our system.')
      }

      setTimeout(() => setBounceError(false), 1000)
    }
  }

  const onResetPasswordSubmit = async ({ resetEmail }: ResetFormValues) => {
    // Call API to send reset e-mail
    const res = await API.get(`/api/passwords/resetpassword?email=${encodeURIComponent(resetEmail)}`, source.token)
    if (res.status === 200) {
      setResetEmailSent(resetEmail)
      setErrorMessage(null)
    } else {
      setErrorMessage('Failed to send reset password email.')
    }
    setShowDialog(false)
  }

  return (
    <SplashGrid>
      <MobileSplash>
        <div>
          <SplashPageTitle>
            <strong>Meshify</strong> Protect
          </SplashPageTitle>
        </div>
      </MobileSplash>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <SplashContentContainer>
            <DesktopOnly>
              <SplashPageTitle data-cy="meshify-protect">
                <strong data-cy="loginPageTitle">Meshify</strong> Protect
              </SplashPageTitle>
            </DesktopOnly>
            <SplashPageSubTitle>Reset Password</SplashPageSubTitle>
            {displayErrorMessage() && (
              <Error className={bounceError ? 'animated bounce' : ''}>{displayErrorMessage()}</Error>
            )}
            {!requestNewTokenLink && (
              <StyledForm onSubmit={handleSubmit}>
                <PasswordField
                  name="newPassword"
                  label="New Password"
                  onChange={value => setFieldValue('newPassword', value)}
                  onBlur={handleBlur}
                  errorState={!!(touched.newPassword && errors.newPassword)}
                />
                <DisplayPasswordReqValidationResult
                  newPasswordValidationResult={newPasswordReqValidation(values.newPassword)}
                  newPasswordLength={values.newPassword.length}
                />
                <Btn
                  type="submit"
                  fullwidth={true}
                  waiting={isSubmitting}
                  disabled={!newPasswordReqValidation(values.newPassword).valid}
                >
                  Reset and Log In
                </Btn>
              </StyledForm>
            )}
            {resetEmailSent && (
              <FormField>
                <Alert type="info_support" icon="check_nocircle" message={`Email sent to ${resetEmailSent}`} />
              </FormField>
            )}
            {requestNewTokenLink && (
              <SplashButtonContainer>
                <CenteredButtonWrapper>
                  <LinkButton onClick={() => setShowDialog(true)}>Request new link</LinkButton>
                </CenteredButtonWrapper>
              </SplashButtonContainer>
            )}
          </SplashContentContainer>
        )}
      </Formik>

      <Splash />
      <ResetPasswordModal
        title="Reset Password"
        openModal={showDialog}
        onDismiss={() => {
          setShowDialog(false)
        }}
        onResetPasswordSubmit={onResetPasswordSubmit}
      />
    </SplashGrid>
  )
}

export default ResetPassword
