import { SkeletonLine } from './../skeleton-line'
import { StyledWrapper } from './loading-sidenav.styles'

const LoadingSidenav = () => {
  return (
    <StyledWrapper>
      <SkeletonLine height="15px" light={true} rounded={true} />
      <SkeletonLine height="15px" light={true} rounded={true} />
    </StyledWrapper>
  )
}

export default LoadingSidenav
