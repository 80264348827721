import styled from 'styled-components/macro'

interface ButtonProps {
  theme: IThemeProps
  fullwidth?: boolean
  disabledStyling?: boolean
  dangerStyling?: boolean
  uppercaseStyling?: boolean
  waiting?: boolean
}

export const Button = styled.button<ButtonProps>`
  background: ${({ theme }: ThemeProps) => theme.colors.brand01};
  border: 0;
  border-radius: 0.4rem;
  box-sizing: border-box;
  margin-top: ${({ theme }: ThemeProps) => theme.space.l};
  padding: 1.2rem 1.6rem;
  width: 100%;

  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xxs};
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 992px) {
    max-width: ${({ fullwidth }: ButtonProps) => (fullwidth ? 'none' : '24.4rem')};
  }

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background: ${({ theme }: ThemeProps) => theme.colors.ui04};

    &:hover {
      cursor: default;
    }
  }
  ${({ disabledStyling, theme }: ButtonProps) => {
    if (disabledStyling) {
      return `
        background: ${theme.colors.ui04};
    
        &:hover {
          cursor: default;
        }
      `
    }
  }}
  ${({ dangerStyling, theme }: ButtonProps) => {
    if (dangerStyling) {
      return `
        background: ${theme.colors.support_error};
      `
    }
  }}
  ${({ waiting }: ButtonProps) => {
    if (waiting) {
      return `
        padding: .6rem 1.6rem .5rem 1.6rem;
      `
    }
  }}
  
  ${({ uppercaseStyling }: ButtonProps) => {
    if (uppercaseStyling === false) {
      return `
        text-transform: unset;
      `
    }
  }}
`

export const SecondaryButton = styled.button<ButtonProps>`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  border: 1px solid ${({ theme }: ThemeProps) => theme.colors.brand01};
  border-radius: 0.4rem;
  box-sizing: border-box;
  margin-top: ${({ theme }: ThemeProps) => theme.space.l};
  padding: 1.2rem 1.6rem;
  width: 100%;

  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xxs};
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 992px) {
    max-width: ${({ fullwidth }: ButtonProps) => (fullwidth ? 'none' : '24.4rem')};
  }

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui05};
    color: ${({ theme }: ThemeProps) => theme.colors.ui05};
    &:hover {
      cursor: default;
    }
  }
  ${({ disabledStyling, theme }: ButtonProps) => {
    if (disabledStyling) {
      return `
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid ${theme.colors.ui05};
      color: ${theme.colors.ui05};
    
        &:hover {
          cursor: default;
        }
      `
    }
  }}
  ${({ dangerStyling, theme }: ButtonProps) => {
    if (dangerStyling) {
      return `
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid ${theme.colors.support_error};
        color: ${theme.colors.support_error};
      `
    }
  }}
  ${({ waiting }: ButtonProps) => {
    if (waiting) {
      return `
        padding: .6rem 1.6rem .5rem 1.6rem;
      `
    }
  }}
  
  ${({ uppercaseStyling }: ButtonProps) => {
    if (uppercaseStyling === false) {
      return `
        text-transform: unset;
      `
    }
  }}
`

interface TertiaryButtonProps {
  theme: IThemeProps
  fullwidth?: boolean
  disabledStyling?: boolean
  dangerStyling?: boolean
  uppercaseStyling?: boolean
  secondaryStyling?: boolean
  waiting?: boolean
}
export const TertiaryButton = styled.button<TertiaryButtonProps>`
  background-color: ${({ theme }: ThemeProps) => theme.colors.accent_blue};
  border: 0;
  border-radius: 2rem;
  box-sizing: border-box;
  margin-top: ${({ theme }: ThemeProps) => theme.space.l};
  padding: 1.2rem 1.6rem;
  width: 100%;

  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xxs};
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 992px) {
    max-width: ${({ fullwidth }: ButtonProps) => (fullwidth ? 'none' : '28.8rem')};
  }

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: ${({ theme }: ThemeProps) => theme.colors.ui05};
    &:hover {
      cursor: default;
    }
  }
  ${({ disabledStyling, theme }: ButtonProps) => {
    if (disabledStyling) {
      return `
      background-color: ${theme.colors.ui05};
    
        &:hover {
          cursor: default;
        }
      `
    }
  }}
  ${({ dangerStyling, theme }: ButtonProps) => {
    if (dangerStyling) {
      return `
        background-color: ${theme.colors.support_error};
      `
    }
  }}
  ${({ waiting }: ButtonProps) => {
    if (waiting) {
      return `
        padding: .6rem 1.6rem .5rem 1.6rem;
      `
    }
  }}
  
  ${({ uppercaseStyling }: ButtonProps) => {
    if (uppercaseStyling === false) {
      return `
        text-transform: unset;
      `
    }
  }}
  
  ${({ secondaryStyling, disabledStyling, uppercaseStyling, theme }: TertiaryButtonProps) => {
    if (secondaryStyling === true && !disabledStyling && uppercaseStyling) {
      return `
        background-color: ${theme.colors.accent_blue_muted};
      `
    }
  }}
`

export const GhostButton = styled.button<ButtonProps>`
  background: 0;
  border: 0;
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  letter-spacing: 1px;
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  text-align: center;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
  &:disabled {
    color: ${({ theme }: ThemeProps) => theme.colors.ui04};

    &:hover {
      cursor: default;
      text-decoration: none;
    }
  }

  ${({ disabledStyling, theme }: ButtonProps) => {
    if (disabledStyling) {
      return `
      color: ${theme.colors.ui04};
    
      &:hover {
        cursor: default;
        text-decoration: none;
      }
      `
    }
  }}

  ${({ dangerStyling, theme }: ButtonProps) => {
    if (dangerStyling) {
      return `
        color: ${theme.colors.support_error};
      `
    }
  }}

  ${({ uppercaseStyling }: ButtonProps) => {
    if (uppercaseStyling === false) {
      return `
        text-transform: unset;
      `
    }
  }}
`

export const LinkButton = styled(GhostButton)`
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: 0;
  text-transform: none;

  &:disabled {
    color: ${({ theme }: ThemeProps) => theme.colors.ui05};

    &:hover {
      cursor: default;
      text-decoration: none;
    }
  }

  ${({ disabledStyling, theme }: ButtonProps) => {
    if (disabledStyling) {
      return `
      color: ${theme.colors.ui05};
    
      &:hover {
        cursor: default;
        text-decoration: none;
      }
      `
    }
  }}

  ${({ dangerStyling, theme }: ButtonProps) => {
    if (dangerStyling) {
      return `
        color: ${theme.colors.support_error};
      `
    }
  }}
`

export const SecondaryLinkButton = styled(LinkButton)`
  color: ${({ theme }: ThemeProps) => theme.colors.support_info};

  ${({ dangerStyling, theme }: ButtonProps) => {
    if (dangerStyling) {
      return `
        color: ${theme.colors.support_error};
      `
    }
  }}
`

export const NotificationPreferencesButton = styled(SecondaryButton)<ButtonProps & { active: boolean }>`
  font-weight: normal;
  border-color: #00000011;
  background-color: ${({ theme, active }) => (active ? theme.colors.brand01 : 'white')}14;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  margin: 0;

  &:disabled {
    background-color: ${({ theme }: ThemeProps) => theme.colors.ui03};
    border-color: #00000011;
    &:hover {
      cursor: default;
    }
  }
`
