import { Accordion, AccordionItem } from '../accordion'
import { FAQHeader } from './header.styles'

const OnboardingHelp = () => {
  return (
    <div>
      <p>
        If you have any questions regarding your account, please call <strong>Sensor Support</strong> at (844) 468-1866
        | Monday – Friday, 8 a.m. – 8 p.m. Eastern Time.
      </p>
      <FAQHeader>Frequently Asked Questions</FAQHeader>
      <Accordion collapsible={true}>
        <AccordionItem headerText="How do my sensors arrive?">
          <p>
            Your sensors will be delivered to the address you provide during enrollment via a shipping service (i.e.
            FedEx).
          </p>
        </AccordionItem>
        <AccordionItem headerText="Are the sensors easy to install?">
          <p>
            Yes, the sensor kit was designed to be self-installed. There is an installation and placement guide included
            in the package and online. The sensors do not require professional installation by an electrician or
            plumber.
          </p>
        </AccordionItem>
        <AccordionItem headerText="Once I get my sensors, how quickly should I install them?">
          <p>
            To get the most value from the sensors and to protect your facility, we suggest you install them within 10
            days of delivery. The sooner your sensors are installed, the sooner your facility will be able to take
            advantage of the 24/7 monitoring system.
          </p>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default OnboardingHelp
