import { createContext, Dispatch, SetStateAction } from 'react'

const ActivationContext = createContext<{
  devices: IDeviceType[]
  setDevices: Dispatch<SetStateAction<IDeviceType[]>>
}>({
  devices: [],
  setDevices: () => {},
})

export default ActivationContext
