import { navigate, useParams } from '@reach/router'
import { VisuallyHidden } from '@reach/visually-hidden'
import { useCallback, useContext, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import HSBLogo from '../../../assets/HSB-logo.svg'
import meshifyLogo from '../../../assets/meshify-logo-grayscale.png'
import Directory from '../../../components/directory/virtual-directory'
import { ChevronIcon, CloseIcon } from '../../../components/icon'
import { ListItem, ListView } from '../../../components/list-view'
import { SkeletonLine } from '../../../components/skeleton-line'
import View from '../../../components/view'
import { AppContext } from '../../../contexts/app-context'
import AuthContext from '../../../contexts/auth-context'
import UserContext from '../../../contexts/user-context'
import API from '../../../utils/api'
import Support from '../../support'
import {
  MobileSidebarScrollable,
  SidebarHeading,
  SidePanelFooterLogos,
  StyledSidebarResponsive,
  TBar,
  TBarBackButton,
  TBarCloseButton,
} from '../dashboard.styles'
import ProfileModal from '../modals/profile'
import { Sidepanel } from './../../../components/sidepanel/index'

/**
 * This UI control is uded only in responsive mode (iphone and smaller devices)
 * when the the hamburger button appears at the top navigation bar
 * @param param0
 */
const SidePanelLeft = ({ showSidebar }: { showSidebar: boolean }) => {
  const handleError = useErrorHandler()
  const { foldersLoading, user } = useContext(AppContext)
  const { setDisplayFloatingSidebar } = useContext(UserContext)
  const [displayLocationList, setDisplayLocationList] = useState<boolean>(false)
  const { setIsAuth } = useContext(AuthContext)
  const { folderId }: { folderId: string } = useParams()
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false)
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false)

  const doLogout = async () => {
    try {
      const response = await API.get('/api/logout', {})
      if (response.status === 200) {
        localStorage.removeItem('protectToken')
        setIsAuth(false)
      }
    } catch (e) {
      handleError(e)
    }
  }

  // Change the router path when a folder is selected
  const onSelectFolder = useCallback(
    (currentId: string, pathName?: string) => {
      setDisplayFloatingSidebar(false)
      if (currentId !== 'undefined' && currentId !== folderId) {
        navigate(pathName || `/dashboard/${currentId}`)
      }
    },
    [folderId, setDisplayFloatingSidebar]
  )

  return showSidebar ? (
    <View>
      {!displayLocationList ? (
        <>
          <TBar>
            <TBarCloseButton onClick={() => setDisplayFloatingSidebar(false)}>
              <VisuallyHidden>Close</VisuallyHidden>
              <span aria-hidden={true}>
                <CloseIcon width={14} height={14} />
              </span>
            </TBarCloseButton>
          </TBar>
          <StyledSidebarResponsive>
            <ListView>
              <ListItem
                title="Locations"
                onClick={() => {
                  setDisplayLocationList(true)
                }}
                showRevealArrow={true}
              />
              <ListItem
                title="Profile"
                onClick={() => {
                  setShowProfileModal(true)
                  setDisplayFloatingSidebar(false)
                }}
              />
              <ListItem
                title="Support"
                onClick={() => {
                  setShowSupportModal(true)
                  setDisplayFloatingSidebar(false)
                }}
              />
              <ListItem title="Log Out" onClick={doLogout} />
            </ListView>
            <SidePanelFooterLogos>
              Powered by
              <img src={HSBLogo} alt="HSB" />
              <img src={meshifyLogo} alt="Meshify" />
            </SidePanelFooterLogos>
          </StyledSidebarResponsive>

          <ProfileModal
            showProfileModal={showProfileModal}
            folderId={folderId}
            onDismiss={() => {
              setShowProfileModal(false)
            }}
          />
          {user && (
            <Sidepanel
              openModal={showSupportModal}
              onDismiss={() => setShowSupportModal(false)}
              title="Monitoring Support"
              showMobileTitleAboveDivider={true}
              titleSize="large"
              variation="support"
            >
              <Support />
            </Sidepanel>
          )}
        </>
      ) : (
        <>
          <TBar>
            <TBarBackButton onClick={() => setDisplayLocationList(false)}>
              <div aria-hidden={true}>
                <ChevronIcon />
              </div>
              <div>Back</div>
            </TBarBackButton>
          </TBar>

          <StyledSidebarResponsive id="mobileSidebar">
            <MobileSidebarScrollable id="sidebar-scrollable">
              {foldersLoading ? (
                <SidebarHeading>
                  <SkeletonLine height="20px" rounded={true} />
                </SidebarHeading>
              ) : (
                <SidebarHeading>Locations</SidebarHeading>
              )}
              <Directory
                rootPath="dashboard"
                folderId={folderId}
                onSelectFolder={onSelectFolder}
                containerElement="#mobileSidebar"
              />
              {foldersLoading && (
                <>
                  <SidebarHeading>
                    <SkeletonLine height="20px" rounded={true} />
                  </SidebarHeading>
                  <SidebarHeading>
                    <SkeletonLine height="20px" rounded={true} />
                  </SidebarHeading>
                  <SidebarHeading>
                    <SkeletonLine height="20px" rounded={true} />
                  </SidebarHeading>
                </>
              )}
            </MobileSidebarScrollable>
            <SidePanelFooterLogos>
              Powered by
              <img src={HSBLogo} alt="HSB" />
              <img src={meshifyLogo} alt="Meshify" />
            </SidePanelFooterLogos>
          </StyledSidebarResponsive>
        </>
      )}
    </View>
  ) : (
    <></>
  )
}

export default SidePanelLeft
