import styled from 'styled-components/macro'

import { CTAPanel, CTAPanelRight } from '../onboarding/onboarding.styles'

interface SidebarProps {
  theme: IThemeProps
  mobilenavsection: 'sidebar' | 'content'
}

interface SidebarHeaderProps {
  disabled?: boolean
}

export const PageLayout = styled.div`
  position: relative;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    grid-template-columns: 355px 1fr;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.xl}) {
    grid-template-columns: 493px 1fr;
  }
`

export const Sidebar = styled.aside`
  display: ${({ mobilenavsection }: SidebarProps) => (mobilenavsection === 'sidebar' ? 'block' : 'none')};
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  box-sizing: border-box;
  min-height: calc(100vh - 65px);
  padding: 27px 16px;
  padding-bottom: 73px;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: block;
    padding: 70px 20px;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.xl}) {
    padding: 70px 45px 70px 37px;
  }
`

export const SidebarHeader = styled.h2`
  margin: 0;
  margin-bottom: 11px;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.s};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xl};
  }
`

export const SidebarSubHeader = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.1rem 0;
  opacity: ${(props: SidebarHeaderProps) => (props.disabled ? '0.7' : '1')};
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: 1.5;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  }
`

export const SidebarSubHeaderNote = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
`

export const SensorSelectContainer = styled.div`
  position: relative;
`

interface ContentSectionProps {
  verticallyCenter?: boolean
  theme: IThemeProps
}
export const ContentSection = styled.section<ContentSectionProps>`
  box-sizing: border-box;
  background-color: ${(props: ContentSectionProps) => props.theme.colors.ui02};
  height: 100%;
  min-height: calc(100vh - 6.2rem);
  padding: 1.6rem 0 10rem 0;
  width: 100%;
  display: ${(props: ContentSectionProps) => (props.verticallyCenter ? 'flex' : 'block')};
  justify-content: ${(props: ContentSectionProps) => (props.verticallyCenter ? 'center' : '')};
  flex-direction: ${(props: ContentSectionProps) => (props.verticallyCenter ? 'column' : '')};

  @media (min-width: ${({ theme }: ContentSectionProps) => theme.breakpoint.m}) {
    min-height: auto;
    width: 100%;
    padding: 7rem 2.4rem 15rem 0;
  }
`

export const ContentHeader = styled.h1`
  width: 100%;
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
`

export const ContentSubheader = styled.h2`
  width: 100%;
  color: #000000;
  font-size: 20px;
  font-weight: 499;
  line-height: 40px;
  text-align: center;
`

export const InstructionalText = styled.p`
  margin: 25px auto;
  width: 100%;
  max-width: 630px;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`

export const InstructionalTextLoading = styled(InstructionalText)`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  margin: 0 auto 27px auto;
`

export const InstructionalImageContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 4.4rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1.3rem;
`

export const MobileInstructions = styled.div`
  margin-bottom: 2rem;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: none;
  }
`

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button {
    width: auto;
    max-width: none;
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    flex-direction: row;
    align-items: flex-end;

    button {
      margin-left: 1.2rem;
      max-width: 16rem;
    }
  }
`

export const ModalCTA = styled.div`
  display: flex;
  justify-content: flex-end;
  width: calc(100% + 7rem);

  > button:first-child {
    margin: 3.2rem 4.6rem 0 -4.6rem;
  }
`

export const MobileModalCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > button:first-child {
    margin-bottom: 2.8rem;
  }
`

export const EmptyFillerSpace = styled.div`
  height: 80px;
  display: grid;
`

export const ActivationCTAPanel = styled(CTAPanel)<{ isDeviceScreen: boolean }>`
  border-top: 0.5px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  @media (max-width: 767px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 1;
    ${props => {
      if (props.isDeviceScreen) {
        return `display: none;`
      }
    }}
  }
`

export const ActivationCTAPanelRight = styled(CTAPanelRight)`
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;

    button {
      width: 100%;
    }
  }
`

export const LeftAlignParagraph = styled.p`
  text-align: left;
`

export const SidebarSearchSection = styled.div`
  display: flex;
  margin-bottom: 2.4rem;
`

export const MobileModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  border-top: 0.5px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  margin: 0 -1.6rem;
  width: 100%;
  position: fixed;
  bottom: 0;

  button {
    width: 100%;
    margin: 0;
    padding: 1.2rem;
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: none;
  }
`

export const SearchContainer = styled.div`
  margin-bottom: 1.6rem;
`

export const SearchResultsHeading = styled.h3`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
`

export const NoSearchResults = styled.p`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: 1.7;
`

export const SidebarHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
    font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  }
`

export const TooltipContainer = styled.div`
  position: absolute;
  top: calc(50% - 53px / 2);
  right: -20px;
`

export const MobileDeviceActivationSuccessHeader = styled(ContentHeader)`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: 1.8rem;
  margin-bottom: 0;
`

export const MobileDeviceActivationSuccessSubheader = styled(ContentSubheader)`
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: 1.4rem;
  margin: 0;
`

export const MobileDeviceActivationSuccessBtnContainer = styled.div`
  text-align: center;

  button {
    font-size: 1.3rem;
    margin: 0;
  }
`

export const TutorialSlide = styled.div`
  padding: 5rem 0 0 0;
  max-width: 28.7rem;

  img {
    margin: auto;
  }
  h3 {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xl};
  }
  p {
    margin-bottom: 0;
  }
`
