import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { ErrorIcon, ErrorStatusIcon, WarningIcon } from '../../../../components/icon'
import Section from '../../../../components/section'
import Sidepanel from '../../../../components/sidepanel'
import UserContext from '../../../../contexts/user-context'
import { Table, Td, Th } from '../../../../shared-styles/table.styles'
import { truncateText } from '../../../../utils/helpers'
import { getUseCase } from '../../../../utils/usecase-matrix'
import { parseAlarm } from '../../methods/genericMethods'

const DeviceTableRow = styled.tr`
  &:hover {
    ${({ theme }: ThemeProps) => theme.elevation.elevation03};
    cursor: pointer;
  }
`

const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 10rem;
  margin-bottom: 0.2rem;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 16rem;
  }
`

const AlarmContainer = styled.div``

const Alarm = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    display: block;
  }
`

const AlarmText = styled.div`
  margin-left: 0.8rem;
`

const LocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const LocationText = styled.button`
  background: none;
  border: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  cursor: pointer;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
  text-align: left;
  margin: 0;
  padding: 0;
`

interface DeviceRowProps {
  device: IDeviceType
  groupType: 'type' | 'location'
  onViewSingleDevice: (device: IDeviceType) => void
}

/** Display a row in a table for a device, handling those with issues differently than those without issues */
const DeviceRow = ({ device, groupType, onViewSingleDevice }: DeviceRowProps) => {
  const { folderAlarms: alarms } = useContext(UserContext)
  const [deviceAlarms, setDeviceAlarms] = useState<IAlertHistory[]>([])

  // Get the alarms for this device only
  useEffect(() => {
    setDeviceAlarms(
      alarms.filter(a => a.nodeId === device.id && a.status === 'active').sort((a, b) => (a.date > b.date ? -1 : 1))
    )
  }, [alarms, device.id])

  const displayByType = () => {
    return truncateText(
      `${device.area || 'Undefined Location'}${device.deviceLocationNotes ? ' · ' + device.deviceLocationNotes : ''}`,
      60
    )
  }
  const displayByLocation = () => {
    return truncateText(
      `${getUseCase(device.deviceUseCase).vanity}${
        device.deviceLocationNotes ? ' · ' + device.deviceLocationNotes : ''
      }`,
      60
    )
  }

  return (
    <DeviceTableRow onClick={e => onViewSingleDevice(device)} className="fs-exclude">
      <Td>
        {(deviceAlarms.length > 0 || device.isOffline || device.hasLowSignal || device.hasLowBattery) && (
          <StatusContainer>
            <AlarmContainer>
              {device.isOffline && (
                <Alarm>
                  <ErrorIcon width={16} height={16} />
                  <AlarmText>Offline</AlarmText>
                </Alarm>
              )}
              {device.hasLowSignal && (
                <Alarm>
                  <ErrorStatusIcon width={16} height={16} />
                  <AlarmText>Poor Signal</AlarmText>
                </Alarm>
              )}
              {device.hasLowBattery && (
                <Alarm>
                  <ErrorStatusIcon width={16} height={16} />
                  <AlarmText>Low Battery</AlarmText>
                </Alarm>
              )}
              {deviceAlarms.map(alarm => {
                return (
                  <Alarm key={`alarm-${alarm.date.replace(' ', '')}`}>
                    {alarm.name.toLowerCase().includes('offline') ? (
                      <ErrorIcon width={16} height={16} />
                    ) : alarm.tags.includes('urgent') ? (
                      <ErrorStatusIcon width={16} height={16} />
                    ) : alarm.tags.includes('warning') ? (
                      <WarningIcon width={16} height={16} />
                    ) : null}
                    <AlarmText>{parseAlarm(alarm)}</AlarmText>
                  </Alarm>
                )
              })}
            </AlarmContainer>
          </StatusContainer>
        )}

        <LocationContainer>
          <LocationText
            aria-label={`View Device Details for ${groupType === 'type' ? displayByType() : displayByLocation()}`}
            onClick={() => /* This button is for accessibility */ onViewSingleDevice(device)}
          >
            {groupType === 'type' ? displayByType() : displayByLocation()}
          </LocationText>
          {!device.isOffline && 'Online'}
        </LocationContainer>
      </Td>
    </DeviceTableRow>
  )
}

const DeviceTable = styled(Table)`
  @media (max-width: 991px) {
    border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    width: 100vw;
    box-shadow: none;
  }
  @media (min-width: 992px) {
    margin-top: 1.6rem;
  }
`

export const ModalContent = ({
  groupType,
  devices,
  devicesWithIssues,
  devicesWithNoIssues,
  onViewSingleDevice,
}: {
  groupType: 'type' | 'location'
  devices: IDeviceType[]
  devicesWithIssues: IDeviceType[]
  devicesWithNoIssues: IDeviceType[]
  onViewSingleDevice: (device: IDeviceType) => void
}) => {
  return (
    <Section>
      <DeviceTable>
        {devicesWithIssues.length > 0 && (
          <>
            <thead className="fs-exclude">
              <tr>
                <Th colSpan={2}>
                  {`${
                    groupType === 'type'
                      ? `${getUseCase(devices[0].deviceUseCase).vanity} Device`
                      : `${devices[0].area || 'Undefined Location'} Device`
                  }s with Issues  (${devicesWithIssues.length})`}
                </Th>
              </tr>
            </thead>
            <tbody>
              {devicesWithIssues.map((device: IDeviceType) => {
                return (
                  <DeviceRow
                    device={device}
                    groupType={groupType}
                    onViewSingleDevice={onViewSingleDevice}
                    key={device.id}
                  />
                )
              })}
            </tbody>
          </>
        )}

        {devicesWithNoIssues.length > 0 && (
          <>
            <thead className="fs-exclude">
              <tr>
                <Th colSpan={2}>
                  {devicesWithIssues.length > 0
                    ? `All Other ${
                        groupType === 'type'
                          ? `${getUseCase(devices[0].deviceUseCase).vanity} Device`
                          : `${devices[0].area || 'Undefined Location'} Device`
                      }s (${devicesWithNoIssues.length})`
                    : `${
                        groupType === 'type'
                          ? `${getUseCase(devices[0].deviceUseCase).vanity} Device`
                          : `${devices[0].area || 'Undefined Location'} Device`
                      }s (${devicesWithNoIssues.length})`}
                </Th>
              </tr>
            </thead>
            <tbody>
              {devicesWithNoIssues.map((device: IDeviceType) => {
                return (
                  <DeviceRow
                    device={device}
                    groupType={groupType}
                    onViewSingleDevice={onViewSingleDevice}
                    key={device.id}
                  />
                )
              })}
            </tbody>
          </>
        )}
      </DeviceTable>
    </Section>
  )
}

interface IProps {
  devices: IDeviceType[]
  groupType: 'type' | 'location'
  title: string
  showModal: boolean
  closeModal: () => void
  onViewSingleDevice: (device: IDeviceType) => void
}

/** Display a modal (sidepanel) listing multiple devices in a table separated by those with issues and those without */
const ModalMultipleDevices = ({ devices, groupType, title, showModal, closeModal, onViewSingleDevice }: IProps) => {
  const devicesWithIssues = devices.filter(
    device => device.activeAlertsCount || device.isOffline || device.hasLowSignal || device.hasLowBattery
  )
  const devicesWithNoIssues = devices.filter(
    device => !device.activeAlertsCount && !device.isOffline && !device.hasLowSignal && !device.hasLowBattery
  )

  return (
    <Sidepanel title={title} openModal={showModal} onDismiss={closeModal} titleSize="large">
      <ModalContent
        groupType={groupType}
        devices={devices}
        devicesWithIssues={devicesWithIssues}
        devicesWithNoIssues={devicesWithNoIssues}
        onViewSingleDevice={onViewSingleDevice}
      />
    </Sidepanel>
  )
}

export default ModalMultipleDevices
