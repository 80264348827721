import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import Alert from '../../components/alert'
import Btn from '../../components/button'
import { CheckIconOutline } from '../../components/icon'
import StepPanel from '../../components/step-panel'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import { ContentIconContainer } from '../../shared-styles/success-modals.styles'
import { deviceIsSearching } from '../../utils/helpers'
import { DeviceResponseTypes, getDeviceStatus } from './'
import {
  ContentSection,
  MobileDeviceActivationSuccessBtnContainer,
  MobileDeviceActivationSuccessHeader,
  MobileDeviceActivationSuccessSubheader,
} from './activating.styles'
import ModalContext from './modal-context'
import Step1ChooseLocation from './sensor-activation/step-1-choose-location'
import Step2ActivateDevice from './sensor-activation/step-2-activate-device'
import Step3PlaceDevice from './sensor-activation/step-3-place-device'

const AlertContainer = styled.div`
  margin-top: 2.4rem;
`

const SetupDeviceHeader = styled.h3`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  margin: 0 1.6rem 1.6rem 1.6rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xl};
    margin: 0 0 1.6rem 0;
  }
`

interface SensorActivationProps {
  device: IDeviceType
  deviceLocations: string[]
  handleBack: () => void
  updateSensorProperty: (
    id: number,
    property: 'area' | 'building' | 'floor' | 'deviceLocationNotes' | 'deviceUseCase',
    value: string
  ) => void
}

interface StepsComplete {
  step1: boolean
  step2: boolean
  step3: boolean
}

/**
 * When a device is selected, we should do a few tasks:
 * ✔ Check that the `order` node in the folder has a `delivered_date` channel (this is checked in index.tsx when we first load the activation flow since it applies to all devices)
 * ✔ Retrieve active alarms for the nodes in the folder. (see getDeviceStatus())
 * ✔ Check for _sensor_ values since the delivered date via both the `api/data/history` endpoint and websockets. -> should we listen for alarms from websocket? Currently assuming yes.
 * ✔ Start a timer counting down from 5 minutes for timeout
 */
const SensorActivation = ({ device, deviceLocations, handleBack, updateSensorProperty }: SensorActivationProps) => {
  const _isMounted = useRef(true)

  const isSearching = deviceIsSearching(device)
  const [sensorResponses, setSensorResponses] = useState<DeviceResponseTypes[]>([])
  const [stepsComplete, setStepsComplete] = useState<StepsComplete>({
    step1: !!device.area,
    step2: !isSearching,
    step3: !!device.deviceLocationNotes,
  })
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalIndex, setModalIndex] = useState<string | undefined>()

  // TODO: if gateway, fetch device so we can look at currentValues for the powersource to determine on battery status to display; per Max, we only really care about activation for Leak & Freeze devices, so maybe do nothing for this?

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      _isMounted.current = false // used to handle memory leaks when performing a state changing when the component has already unmounted
    }
  }, [])

  // Un-complete Step 1 if location information is cleared out
  useEffect(() => {
    setStepsComplete(prevValue => {
      if (prevValue.step1 && !device.area) return { ...prevValue, step1: false }
      return prevValue
    })
  }, [device.area])

  // Un-complete Step 3 if location notes information is cleared out
  useEffect(() => {
    setStepsComplete(prevValue => {
      if (prevValue.step3 && !device.deviceLocationNotes) return { ...prevValue, step3: false }
      return prevValue
    })
  }, [device.deviceLocationNotes])

  const addSensorResponse = useCallback((response: DeviceResponseTypes) => {
    if (_isMounted.current)
      setSensorResponses(prevSensorResponses => {
        let newSensorResponses = prevSensorResponses
        newSensorResponses.push(response)
        newSensorResponses = newSensorResponses.filter((a, b) => newSensorResponses.indexOf(a) === b) // remove dupes
        return newSensorResponses
      })
  }, [])

  const removeSensorResponse = useCallback((response: DeviceResponseTypes) => {
    if (_isMounted.current)
      setSensorResponses(prevSensorResponses => {
        let newSensorResponses = prevSensorResponses
        newSensorResponses = newSensorResponses.filter(res => res !== response)
        return newSensorResponses
      })
  }, [])

  // Handle device status messaging: waiting (we haven't received data and are waiting for the timer to expire), offline (if timer has expired), online, or online and with a battery or signal issue
  // When sensor is selected, update the device status messaging to communicating if device is currently online, otherwise start a timer that will show the device connection error message when it expires if no new data comes through the websocket
  // Message will be "Waiting for device to communicate" until we populate sensorResponses or until the <TIMEOUT> minute timeout runs out
  useEffect(() => {
    // If offline, return "waiting" and let the timer return offline after <TIMEOUT> minutes (connection timeout)
    if (!isSearching) {
      // Device is online
      // Check for low signal/low battery/gateway on battery for additional statuses we need to display
      if (device.hasLowSignal) addSensorResponse('low_signal')
      else removeSensorResponse('low_batt')

      if (device.hasLowBattery) addSensorResponse('low_batt')
      else removeSensorResponse('low_batt')

      if (device.nodeTypeName === 'gateway' && device.currentValues?.powersource?.value === 'BATT')
        addSensorResponse('on_batt')
      else removeSensorResponse('on_batt')

      if (_isMounted.current) {
        addSensorResponse('online')
        removeSensorResponse('offline')
      }
    } else {
      if (_isMounted.current) setSensorResponses([]) // Waiting for data or timer to expire
    }
  }, [
    addSensorResponse,
    removeSensorResponse,
    isSearching,
    device.hasLowSignal,
    device.hasLowBattery,
    device.nodeTypeName,
    device.currentValues?.powersource?.value,
  ])

  return (
    <ModalContext.Provider
      value={{
        showModal,
        setShowModal,
        modalIndex,
        setModalIndex,
      }}
    >
      <ContentSection aria-live="polite" id="mainContent">
        <SetupDeviceHeader>Setup Device</SetupDeviceHeader>
        <StepPanel
          step={1}
          stepHeader="Choose Location"
          initialToggle={!stepsComplete.step1}
          stepComplete={stepsComplete.step1}
          secondaryStepHeader={stepsComplete.step1 ? device.area : undefined}
          continueIsDisabled={!device.area || !device.deviceUseCase}
          onSelectContinue={() => setStepsComplete({ ...stepsComplete, step1: true })}
        >
          <Step1ChooseLocation
            device={device}
            deviceLocations={deviceLocations}
            updateSensorProperty={updateSensorProperty}
          />
        </StepPanel>
        <StepPanel
          step={2}
          stepHeader="Activate Device"
          stepComplete={stepsComplete.step2 && !sensorResponses.includes('offline') && sensorResponses.length > 0}
          secondaryStepHeader={
            stepsComplete.step2
              ? sensorResponses.includes('on_batt')
                ? 'On Battery Power'
                : sensorResponses.includes('low_signal')
                ? 'Poor Signal'
                : sensorResponses.includes('low_batt')
                ? 'Low Battery'
                : sensorResponses.includes('offline')
                ? 'Offline'
                : sensorResponses.length === 0
                ? undefined
                : 'Device Connected'
              : undefined
          }
          continueIsDisabled={
            sensorResponses.length === 0 || sensorResponses.includes('waiting') || sensorResponses.includes('offline')
          }
          onSelectContinue={() => setStepsComplete({ ...stepsComplete, step2: true })}
          toggleStep={stepsComplete.step1 && !stepsComplete.step2}
        >
          <Step2ActivateDevice
            device={device}
            sensorResponses={sensorResponses}
            addSensorResponse={addSensorResponse}
          />
        </StepPanel>
        <StepPanel
          step={3}
          stepHeader="Place Device"
          stepComplete={stepsComplete.step3}
          secondaryStepHeader={
            stepsComplete.step3 && device.deviceLocationNotes ? device.deviceLocationNotes : undefined
          }
          continueIsDisabled={!device.deviceLocationNotes}
          onSelectContinue={() => setStepsComplete({ ...stepsComplete, step3: true })}
          toggleStep={stepsComplete.step1 && stepsComplete.step2 && !stepsComplete.step3}
        >
          <Step3PlaceDevice device={device} updateSensorProperty={updateSensorProperty} />
        </StepPanel>
        {getDeviceStatus(device) === 'active' && stepsComplete.step3 && (
          <>
            <DesktopOnly>
              <AlertContainer>
                <Alert
                  type="info_success"
                  icon="check_outline"
                  message="Device is now active. You may continue with another device."
                />
              </AlertContainer>
            </DesktopOnly>
            <MobileOnly>
              <ContentIconContainer>
                <CheckIconOutline height={40} width={40} />
              </ContentIconContainer>
              <MobileDeviceActivationSuccessHeader>Success!</MobileDeviceActivationSuccessHeader>
              <MobileDeviceActivationSuccessSubheader className="fs-exclude">
                {device.vanity} is now active.
              </MobileDeviceActivationSuccessSubheader>
              <MobileDeviceActivationSuccessBtnContainer>
                <Btn buttonType="ghost" onClick={() => handleBack()}>
                  Continue with another device
                </Btn>
              </MobileDeviceActivationSuccessBtnContainer>
            </MobileOnly>
          </>
        )}
      </ContentSection>
    </ModalContext.Provider>
  )
}

export default SensorActivation
