import { useMemo } from 'react'

import theme from '../../theme'
import { LM_ROLE, prettifyPhoneNumber, SD_ROLE } from '../../utils/helpers'
import SkeletonLine from '../skeleton-line'
import { BellOff } from './../icon/index'
import { Tooltip } from './../tooltip/index'
import {
  ChipLocationManager,
  Notification,
  NotificationInfo,
  NotificationName,
  NotificationNameText,
  NotificationPriority,
  NotificationWrapper,
} from './notification-contacts-list.styles'

const NotificationContactsList = ({
  contacts,
  onSelectContact,
  loading,
}: {
  contacts: INotificationContactType[]
  onSelectContact: (contact: INotificationContactType) => void
  loading: boolean
}) => {
  const allContacts = useMemo(() => {
    const notificationContacts = contacts.filter(c => !c.doNotContact).sort((a, b) => a.priority - b.priority)

    const noNotificationContacts = contacts
      .filter(c => c.doNotContact)
      .sort((a, b) =>
        `${a.user.information.first} ${a.user.information.last}`.localeCompare(
          `${b.user.information.first} ${b.user.information.last}`
        )
      )

    return notificationContacts.concat(noNotificationContacts)
  }, [contacts])

  return (
    <>
      {loading ? (
        <Notification>
          <NotificationWrapper>
            <NotificationPriority />
            <div>
              <NotificationName>
                <NotificationNameText>
                  <SkeletonLine width="200px" alt="Loading contacts..." />
                </NotificationNameText>
              </NotificationName>
              <NotificationInfo>
                <SkeletonLine light={true} />
              </NotificationInfo>
            </div>
          </NotificationWrapper>
        </Notification>
      ) : (
        <>
          {allContacts.map((userInfo, index) => {
            const { user, doNotContact } = userInfo

            return (
              <Notification key={user.id} onClick={() => onSelectContact(userInfo)}>
                <NotificationWrapper>
                  <NotificationPriority className="fs-exclude">
                    {doNotContact ? <BellOff fill={theme.colors.text02} /> : index + 1}
                  </NotificationPriority>
                  <div>
                    <NotificationName className="fs-exclude">
                      <NotificationNameText
                        aria-label={`Edit Contact: ${user.information?.first} ${user.information?.last}`}
                        onClick={e => {
                          // This button is for accessibility, since the alert on/off tooltip is also a button and cannot be nested in a button (if the entire row were a button)
                          e.preventDefault()
                          onSelectContact(userInfo)
                        }}
                        data-testid="name"
                      >
                        {user.information?.first} {user.information?.last}
                      </NotificationNameText>
                      {user.role.name === LM_ROLE && (
                        <ChipLocationManager>
                          <Tooltip icon={<>Location Manager</>} alignment="bottom">
                            <p>
                              The <strong>Location Manager</strong> is the account admin.
                            </p>
                          </Tooltip>
                        </ChipLocationManager>
                      )}
                      {user.role.name === SD_ROLE && <ChipLocationManager>Service Desk</ChipLocationManager>}
                    </NotificationName>
                    <NotificationInfo className="fs-exclude">
                      {user.email}
                      {user.email && user.phone && ' | '}
                      {user.phone && <>{prettifyPhoneNumber(user.phone)}</>}
                    </NotificationInfo>
                  </div>
                </NotificationWrapper>
              </Notification>
            )
          })}
        </>
      )}
    </>
  )
}

export default NotificationContactsList
