import { useEffect, useRef } from 'react'

const AgreementText = ({ agreementContent }: { agreementContent?: string }) => {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef.current && agreementContent) {
      divRef.current.innerHTML = agreementContent
    }
  }, [agreementContent])

  return <div ref={divRef} />
}

export default AgreementText
