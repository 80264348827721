import { PanelBulletList, PanelSubHeader } from './placement-guides.styles'

const PipePlacementGuide = () => {
  return (
    <>
      <PanelSubHeader>Pipe Sensor</PanelSubHeader>
      <p>The pipe sensor monitors the temperature of a pipe.</p>
      <p>Tips for placement:</p>
      <PanelBulletList>
        <li>Directly on a pipe near a window, an intake vent or along the north side of the building</li>
      </PanelBulletList>
    </>
  )
}

export default PipePlacementGuide
