import { useContext } from 'react'

import Btn from '../../../../components/button'
import Sidepanel from '../../../../components/sidepanel'
import { MobileOnly } from '../../../../shared-styles/responsive.styles'
import ModalContext from '../../modal-context'
import { ButtonContainer, PanelContent } from './placement-guides.styles'

const PlacementGuide = ({
  children,
  title,
  modalIndexValue,
}: {
  children: React.ReactNode
  title: string
  modalIndexValue: string
}) => {
  const { showModal, setShowModal, modalIndex } = useContext(ModalContext)
  return (
    <Sidepanel
      title={title}
      showMobileTitleAboveDivider={true}
      openModal={showModal && modalIndex === modalIndexValue}
      onDismiss={() => {
        setShowModal(false)
        // This fixes a bug where user ends up at bottom of the screen after dismissing the modal
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 0)
      }}
    >
      <PanelContent>
        <div>{children}</div>
        <MobileOnly>
          <ButtonContainer>
            <Btn buttonType="ghost" onClick={() => setShowModal(false)}>
              Got It
            </Btn>
          </ButtonContainer>
        </MobileOnly>
      </PanelContent>
    </Sidepanel>
  )
}

export default PlacementGuide
