import styled from 'styled-components/macro'

import { GhostButton } from '../../../../shared-styles/button.styles'

export const NotificationContainer = styled.section`
  border-radius: 0.4rem 0.4rem 0 0.4rem;
  width: 100%;
  ${({ theme }: ThemeProps) => theme.elevation.elevation03};
  text-align: left;
`

export const NotificationHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
`

export const NotificationHeader = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
  margin: 1.2rem 1.8rem;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.075rem;
  text-transform: uppercase;
`

export const NotificationDesktopButtonContainer = styled.div`
  align-items: center;
  display: none;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: flex;
  }
`

export const NotificationMobileButtonContainerMinimized = styled.div`
  align-items: center;
  display: flex;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: none;
  }
`

export const Notification = styled.div`
  margin: 0;
  padding: 1.8rem;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};

  &:nth-last-child(2) {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }: ThemeProps) => theme.colors.hover_row};
    cursor: pointer;
  }
`

export const NotificationName = styled.h4`
  margin: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
`

export const NotificationNameText = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  padding-right: ${({ theme }: ThemeProps) => theme.space.s};
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};

  &:hover {
    cursor: pointer;
  }
`

export const NotificationInfo = styled.p`
  margin-bottom: 0;
  margin-top: ${({ theme }: ThemeProps) => theme.space.xxs};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
`

export const ChipLocationManager = styled.span`
  background-color: ${({ theme }: ThemeProps) => theme.colors.accent_green};
  border-radius: 1.6rem;
  margin-right: 1.2rem;
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.xs}`};
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  white-space: nowrap;

  button {
    color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
    font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  }
`

export const ChipPrimary = styled.span`
  background-color: ${({ theme }: ThemeProps) => theme.colors.accent_blue};
  border-radius: 1.6rem;
  margin-right: 1.2rem;
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.xs}`};
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  white-space: nowrap;

  button {
    color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
    font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  }
  svg {
    margin-right: 0.3rem;
  }
`

export const ChipAlerts = styled.span`
  margin-right: 1.2rem;

  svg {
    position: absolute;
    margin-top: -16px;
  }
`

export const NotificationMobileButtonContainer = styled.div`
  padding: 1.2rem 1.8rem;
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (min-width: 567px) {
    flex-direction: row;
  }

  button {
    margin-right: 0;
  }
`

export const AddContactButton = styled(GhostButton)`
  margin: 0;
  margin-right: 1.8rem;
  padding: 0;
  display: flex;
  align-items: center;

  svg:first-child {
    display: block;
    margin-right: 1rem;
  }
`
