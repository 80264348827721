import styled from 'styled-components/macro'

export const StyledSkipLinks = styled.button`
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;

  &:active,
  &:focus,
  &:hover {
    background: transparent;
    border: 0;
    padding: 1.25rem 2.5rem;
    color: ${({ theme }: ThemeProps) => theme.colors.brand01};
    font-family: 'Work Sans';
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.2;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: visible;
  }
`

export const StyledSkipLinksActivation = styled(StyledSkipLinks)`
  &:active,
  &:focus,
  &:hover {
    top: 6px;
    left: 150px;
  }
`
