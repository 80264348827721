import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import ApexChart from 'react-apexcharts'

import { useDates, useTemperatures } from '../../utils/preference-hooks'

interface SeriesDataType {
  /** Date string */
  x: string
  /** Number value, such as temperature degrees or percentages */
  y: number
}
interface SeriesType {
  name: string
  fillColor: string
  strokeColor: string
  data: SeriesDataType[]
}
interface ChartProps {
  unit: string
  series: SeriesType[]
  xAxisType?: 'category' | 'datetime' | 'numeric'
  curve?: 'smooth' | 'straight' | 'stepline'
  strokeWidth?: number
  channel?: 'temp' | 'leak' | 'powersource' | 'hum'
}

export const AreaChart = ({
  unit,
  series,
  xAxisType = 'datetime',
  curve = 'smooth',
  strokeWidth = 1,
  channel,
}: ChartProps) => {
  const { formatDateShortTime } = useDates()
  const { formatTemperature, tempUnits } = useTemperatures()
  const seriesColors = series.map(s => s.fillColor)
  const seriesStrokeColors = series.map(s => s.strokeColor)
  const seriesCategories = series.map(s => s.data.map(d => d.x))[0]
  const seriesValues = series.map(s => {
    return {
      name: s.name,
      data: s.data.map(d => d.y),
    }
  })

  const options: ApexOptions = {
    series: seriesValues,
    colors: seriesColors,
    chart: {
      type: 'area',
      toolbar: {
        export: {
          csv: {
            headerCategory: 'Date and Time',
            dateFormatter(timestamp: unknown) {
              return formatDateShortTime(timestamp as string)
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
    },
    stroke: {
      curve,
      colors: seriesStrokeColors,
      width: strokeWidth,
    },
    xaxis: {
      type: xAxisType,
      categories: seriesCategories,
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM',
          day: 'MM/dd',
          hour: 'HH:mm',
          minute: 'HH:mm',
        },
      },
      // If the earliest date for the series is less than within the last week, then the min date should be the earliest date; otherwise, the min date should be 7 days ago
      min:
        moment(series[0].data[series[0].data.length - 1]?.x).diff(moment(), 'days') <= -7
          ? moment().subtract(7, 'days').valueOf()
          : Number(series[0].data[series[0].data.length - 1]?.x),
    },
    yaxis: {
      showForNullSeries: false,
      labels: {
        formatter: (value: number) => {
          if (channel === 'leak' && value === 1) return 'Water'
          if (channel === 'leak' && value === 0) return 'No Water'
          if (channel === 'leak' && value !== 1 && value !== 0) return ''
          if (channel === 'powersource' && value === 0) return 'Line'
          if (channel === 'powersource' && value === 1) return 'Battery'
          if (channel === 'powersource' && value !== 1 && value !== 0) return ''
          if (channel === 'temp') return String(formatTemperature(value, 'integer', undefined, false))
          return String(Math.round(value))
        },
      },
    },
    tooltip: {
      x: {
        formatter: (value: unknown) => {
          return formatDateShortTime(value as string) ?? ''
        },
      },
      y: {
        title: {
          formatter: (_seriesName: string) => '',
        },
        formatter: (value: number) => {
          if (channel === 'leak' && value === 1) return 'Water'
          if (channel === 'leak' && value === 0) return 'No Water'
          if (channel === 'powersource' && value === 0) return 'Line'
          if (channel === 'powersource' && value === 1) return 'Battery'
          if (channel === 'temp') return `${formatTemperature(value)}${tempUnits}`
          return `${value}${unit}`
        },
      },
      marker: {
        show: false,
      },
      style: {
        fontFamily: 'Work Sans',
      },
    },
    markers: {
      colors: seriesStrokeColors,
    },
    noData: {
      text: 'No data',
    },
  }

  return (
    <>
      <ApexChart options={options} series={seriesValues} type="area" width="100%" />
    </>
  )
}

export default AreaChart
