import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import './leaflet.css'

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
  shadowAnchor: [12.5, 41],
})

L.Marker.prototype.options.icon = DefaultIcon

interface IProps {
  lat: number
  lng: number
  /** Set this to true whenever you need to open a modal over the map to fix z-index issues where the map ends up showing on top of the modal */
  modalIsOpen?: boolean
  /** Initial zoom level of map */
  zoom?: number
}

/**
 * Parent container must have a fixed height
 */
export const AddressMap = ({ lat, lng, modalIsOpen = false, zoom = 16 }: IProps) => {
  const position: [number, number] = [lat, lng]

  return (
    <MapContainer center={position} zoom={zoom} style={{ height: '100%' }} className={modalIsOpen ? 'modalIsOpen' : ''}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} />
    </MapContainer>
  )
}

export default AddressMap
