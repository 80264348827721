import styled from 'styled-components/macro'

import SplashMobileScreen from '../assets/splash-mobile.svg'
import SplashScreen from '../assets/splash.svg'
import { LinkButton } from './button.styles'
import { FormField } from './form.styles'
import { H2 } from './typography.styles'

interface SplashEndNoteProps {
  noBorder?: boolean
  theme: IThemeProps
}

interface SplashPageSubTitleProps {
  mobile?: boolean
  theme: IThemeProps
}

export const SplashGrid = styled.div`
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: grid;
    grid-template-columns: 49.3rem 1fr;
    min-height: 100vh;
  }
`

export const SplashContentContainer = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  padding: ${({ theme }: ThemeProps) => `0 ${theme.space.s} ${theme.space.s} ${theme.space.s}`};
  min-height: calc(100vh - 78px);
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  margin-top: -1.4rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
    padding: ${({ theme }: ThemeProps) => `${theme.space.xl} ${theme.space.xxl}`};
    margin: 0;
    min-height: none;
    font-size: unset;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    padding: ${({ theme }: ThemeProps) => `0 ${theme.space.xxl}`};
  }
`

export const Splash = styled.div`
  background-image: url(${SplashMobileScreen});
  background-size: cover;
  background-position: center center;
  position: relative;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    background-image: url(${SplashScreen});
  }
`

export const MobileSplash = styled(Splash)`
  height: 60px;

  > div {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    position: absolute;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: none;
  }
`

export const SplashFooter = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  text-align: center;
  margin-top: 0;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    position: absolute;
    background-color: transparent;
    right: 1.6rem;
    bottom: 4.8rem;
    z-index: 1;
    color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
    text-align: right;
    width: calc(100vw - 49.3rem);
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    background-color: transparent;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.xl}) {
    gap: 3.6rem;
    right: 0;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
    text-align: left;
  }
`

export const SplashFooterLinks = styled.div`
  a {
    color: ${({ theme }: ThemeProps) => theme.colors.text03};
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    a {
      color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
      font-weight: normal;
    }
  }
`

export const SplashFooterButton = styled(LinkButton)`
  color: inherit;
  font: inherit;
`

export const SplashPageTitle = styled.div`
  img {
    display: block;
    width: 250px;
    height: 45px;
  }
`

export const SplashPageSubTitle = styled(H2).attrs(() => ({
  size: 4,
}))<SplashPageSubTitleProps>`
  text-align: left;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: 0.8rem 1.6rem;
  margin: 1.6rem -1.6rem;
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: ${(props: SplashPageSubTitleProps) => (props.mobile ? 'none' : 'block')};
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
    border: 0;
    padding: 0;
    margin: 2.4rem 0 0 0;
  }
`
export const SplashIntroText = styled.p`
  margin-bottom: 0;
  padding-top: ${({ theme }: ThemeProps) => theme.space.m};
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`

export const SplashButtonContainer = styled.div`
  margin-top: ${({ theme }: ThemeProps) => theme.space.s};
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    margin-top: ${({ theme }: ThemeProps) => theme.space.m};
  }
`

export const SplashEndNote = styled.div`
  border-top: ${({ noBorder, theme }: SplashEndNoteProps) => (noBorder ? '0' : `1px solid ${theme.colors.ui04}`)};
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
  padding-top: ${({ theme }: ThemeProps) => theme.space.s};
  width: 100%;
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xl};
  text-align: center;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
    padding-top: ${({ theme }: ThemeProps) => theme.space.m};
  }
`

export const SplashLanguageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }: ThemeProps) => theme.space.m};
  padding-top: ${({ theme }: ThemeProps) => theme.space.s};
  width: 100%;
  text-align: center;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    padding-top: ${({ theme }: ThemeProps) => theme.space.m};
  }
`

export const SplashAlertContainer = styled(FormField)`
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    margin-bottom: -2.4rem;
  }
`
export const SplashAgreementText = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
`

export const SplashIntroOverlay = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  text-align: center;
  margin: 9rem auto;
  padding: 0 5rem;
  max-width: 60rem;
  line-height: 1.4;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    margin: 12.5rem auto;
  }
`
