import { createContext, Dispatch, SetStateAction } from 'react'

import { WelcomeDataProps } from '../routes/dashboard'

const UserContext = createContext<{
  folderAlarms: IAlertHistory[]
  /** Activated nodes in the selected folder */
  devices: IDeviceType[] | undefined
  setDevices: Dispatch<SetStateAction<IDeviceType[] | undefined>>
  devicesLoaded: boolean
  welcomeData: WelcomeDataProps | undefined
  displayType: 'all' | 'alertsonly'
  setDisplayType: (displayType: 'all' | 'alertsonly') => void
  groupType: 'type' | 'location' | 'none' | undefined
  setGroupType: (groupType: 'type' | 'location' | 'none') => void
  displayFloatingSidebar: boolean
  setDisplayFloatingSidebar: (displayFloatingSidebar: boolean) => void
  selectedDeviceId: number | undefined
  setSelectedDeviceId: (deviceId: number | undefined) => void
  companyBranding: IFolderTheme | undefined
  themeLoading: boolean
}>({
  folderAlarms: [],
  devices: undefined,
  setDevices: () => {},
  devicesLoaded: false,
  welcomeData: undefined,
  displayType: 'all',
  setDisplayType: () => {},
  groupType: undefined,
  setGroupType: () => {},
  displayFloatingSidebar: false,
  setDisplayFloatingSidebar: () => {},
  selectedDeviceId: -1,
  setSelectedDeviceId: () => {},
  companyBranding: {},
  themeLoading: false,
})

export default UserContext
