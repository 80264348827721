import styled from 'styled-components/macro'

export const StyledMagnifying = styled.img`
  height: 16px;
  width: 16px;
`

export const StyledClearButton = styled.button`
  img {
    width: 12px;
  }
`

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  ${StyledMagnifying} {
    position: absolute;
    top: 8px;
    left: 15px;
    height: 25px;
  }
  ${StyledClearButton} {
    position: absolute;
    font-weight: 100;
    top: 14px;
    right: 16px;
    height: 12px;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0;
  }
`

export const Search = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  padding: 5px 40px 5px 45px;
  border: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  border-radius: 4px;

  &:disabled {
    border-color: #ededed;
    cursor: not-allowed;
  }

  &:focus {
    outline: 0;
    border-color: ${({ theme }: ThemeProps) => theme.colors.support_info};
  }

  @media (min-width: 992px) {
    font-size: 14px;
  }
`
