import { Formik } from 'formik'
import { useContext, useMemo } from 'react'
import 'react-intl-tel-input/dist/main.css'
import { useSnackbar } from 'react-simple-snackbar'

import Alert from '../../../../components/alert'
import Btn from '../../../../components/button'
import NotificationContactForm from '../../../../components/notification-contact-form'
import { CTAButtonContainer } from '../../../../components/notification-contact-form/notification-contact-form.styles'
import PasswordField from '../../../../components/password-field'
import '../../../../components/phone-input/phoneInput.css'
import Sidepanel from '../../../../components/sidepanel'
import SkeletonLine from '../../../../components/skeleton-line'
import { AppContext } from '../../../../contexts/app-context'
import { GhostButton } from '../../../../shared-styles/button.styles'
import { FormField, Label, Radio, Select, SelectContainer } from '../../../../shared-styles/form.styles'
import { StyledForm } from '../../../../shared-styles/form.styles'
import { StyledTable, StyledTh, Td } from '../../../../shared-styles/table.styles'
import API from '../../../../utils/api'
import { SIDEPANEL_ERROR_OPTS, SIDEPANEL_SUCCESS_OPTS } from '../../../../utils/constants'
import { useDates } from '../../../../utils/preference-hooks'
import { cachedTimezoneList } from '../../../../utils/timezoneData'
import { newPasswordReqValidation } from '../../../../utils/validators'
import DisplayPasswordReqValidationResult from './../../../../components/display-password-validation-result/index'

interface PreferencesFormValues {
  timezone: string
  dateFormat: 'MM-DD-YYYY' | 'DD.MM.YYYY'
  timeFormat: '12hr' | '24hr'
  tempUnits: string
}

interface PasswordFormValues {
  currentPassword: string
  newPassword: string
}

const ProfileModal = ({
  showProfileModal,
  folderId,
  onDismiss,
}: {
  showProfileModal: boolean
  folderId: string
  onDismiss: () => void
}) => {
  const { user, setUser, folders, foldersLoading, roles } = useContext(AppContext)
  const { localeTimeFormat } = useDates()

  const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(SIDEPANEL_SUCCESS_OPTS)
  const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(SIDEPANEL_ERROR_OPTS)
  const folder = useMemo(() => folders.find(f => f.id === Number(folderId)), [folderId, folders])
  const userWithRole = useMemo(() => {
    return {
      ...user,
      role: {
        id: user?.roleId,
        name: roles.find(r => r.id === user?.roleId)?.name,
      },
    } as IUserType
  }, [user, roles])

  const preferencesInitialValues = {
    timezone: user?.information?.timezone || '',
    dateFormat: user?.preferences?.dateFormat ? user.preferences.dateFormat : 'MM-DD-YYYY',
    timeFormat: user?.preferences?.timeFormat ? user.preferences.timeFormat : localeTimeFormat,
    tempUnits: user?.preferences?.tempUnits || 'degF',
  }

  const passwordInitialValues = {
    currentPassword: '',
    newPassword: '',
  }

  const submitSavePreferences = async ({ timezone, tempUnits, dateFormat, timeFormat }: PreferencesFormValues) => {
    closeSuccessSnackbar()
    closeErrorSnackbar()

    if (user) {
      // Update the user
      // Update the user state
      // Display success alert
      try {
        const updateUser = await API.put(`/api/users/${user.id}`, {
          information: {
            ...user.information,
            timezone,
          },
          preferences: {
            ...user.preferences,
            tempUnits,
            dateFormat,
            timeFormat,
          },
        })

        // Update `user` state
        setUser(updateUser.data)

        openSuccessSnackbar('Your information was successfully updated.')
      } catch {
        openErrorSnackbar('Something was wrong in our system.')
      }
    }
  }

  const submitSavePassword = async ({ newPassword, currentPassword }: PasswordFormValues) => {
    closeSuccessSnackbar()
    closeErrorSnackbar()

    try {
      await API.post(`/api/authentication/changepassword`, {
        newPassword,
        oldPassword: currentPassword,
      })

      // Display success alerts
      openSuccessSnackbar('Password has successfully been changed.')
    } catch (e) {
      if (((e as AxiosError).response?.data as ApiV1Error)?.detail === 'weak passwords are not allowed') {
        openErrorSnackbar('The password provided is too common, please try again.')
      } else {
        openErrorSnackbar('Could not change password. Please try again.')
      }
    }
  }

  const dismissModal = () => {
    // Reset data and alerts
    closeSuccessSnackbar()
    closeErrorSnackbar()

    onDismiss()
  }

  return (
    <Sidepanel title="Profile" titleSize="large" openModal={showProfileModal} onDismiss={dismissModal}>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Contact Info</StyledTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>
              {userWithRole && folder ? (
                <NotificationContactForm
                  onBack={(msg: string) => {
                    if (msg === 'dismiss') {
                      dismissModal()
                    } else {
                      openSuccessSnackbar('Your information was successfully updated.')
                    }
                  }}
                  user={userWithRole}
                  folder={folder as IFolderType}
                  // @ts-ignore - ignore missing role and status properties that do not exist in /users/me
                  editUserInfo={{ user: userWithRole, priority: 0, isEditable: folder?.folderType === 'Participant' }}
                  canAddUsers={false}
                  serviceDeskContact={false}
                  receiveAlertsCount={2}
                  userCount={2}
                  screen="profile"
                />
              ) : foldersLoading ? (
                <SkeletonLine height="600px" />
              ) : (
                <Alert
                  type="info_warning"
                  icon="solid_info"
                  message={
                    'You can edit your notification preferences and other contact information after selecting a participant folder.'
                  }
                  iconAlignment="top"
                />
              )}
            </Td>
          </tr>
        </tbody>
      </StyledTable>

      <Formik initialValues={preferencesInitialValues} onSubmit={submitSavePreferences} enableReinitialize={true}>
        {({ values, handleChange, handleSubmit, setFieldValue, dirty, isSubmitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <StyledTable>
              <thead>
                <tr>
                  <StyledTh>Preferences</StyledTh>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>
                    <FormField>
                      <Label htmlFor="timezone">Time Zone</Label>
                      <SelectContainer>
                        <Select id="timezone" value={values.timezone} onChange={handleChange}>
                          <option value="">Select Time Zone...</option>
                          {cachedTimezoneList.map(timezone => (
                            <option key={timezone.header} value={timezone.tzCode}>
                              {timezone.header} - {timezone.subtitle}
                            </option>
                          ))}
                        </Select>
                      </SelectContainer>
                    </FormField>

                    <FormField>
                      <p>
                        <strong>Date Format</strong>
                      </p>
                      <Radio
                        type="radio"
                        id="date_format_us"
                        name="dateFormat"
                        onClick={() => setFieldValue('dateFormat', 'MM-DD-YYYY')}
                        defaultChecked={values.dateFormat === 'MM-DD-YYYY'}
                      />
                      <Label htmlFor="date_format_us">MM-DD-YYYY</Label>
                      <Radio
                        type="radio"
                        id="date_format_intl"
                        name="dateFormat"
                        onClick={() => setFieldValue('dateFormat', 'DD.MM.YYYY')}
                        defaultChecked={values.dateFormat === 'DD.MM.YYYY'}
                      />
                      <Label htmlFor="date_format_intl">DD.MM.YYYY</Label>
                    </FormField>

                    <FormField>
                      <p>
                        <strong>Time Format</strong>
                      </p>
                      <Radio
                        type="radio"
                        id="time_format_12hr"
                        name="timeFormat"
                        onClick={() => setFieldValue('timeFormat', '12hr')}
                        defaultChecked={values.timeFormat === '12hr'}
                      />
                      <Label htmlFor="time_format_12hr">2:14 PM</Label>
                      <Radio
                        type="radio"
                        id="time_format_24hr"
                        name="timeFormat"
                        onClick={() => setFieldValue('timeFormat', '24hr')}
                        defaultChecked={values.timeFormat === '24hr'}
                      />
                      <Label htmlFor="time_format_24hr">14:14</Label>
                    </FormField>

                    <FormField>
                      <p>
                        <strong>Temperature</strong>
                      </p>
                      <Radio
                        type="radio"
                        id="temp_F"
                        name="tempUnits"
                        onClick={() => setFieldValue('tempUnits', 'degF')}
                        defaultChecked={values.tempUnits === 'degF'}
                      />
                      <Label htmlFor="temp_F">F°</Label>
                      <Radio
                        type="radio"
                        id="temp_C"
                        name="tempUnits"
                        onClick={() => setFieldValue('tempUnits', 'degC')}
                        defaultChecked={values.tempUnits === 'degC'}
                      />
                      <Label htmlFor="temp_C">C°</Label>
                    </FormField>

                    <CTAButtonContainer center={false}>
                      <GhostButton
                        onClick={e => {
                          e.preventDefault()
                          dismissModal()
                        }}
                      >
                        Cancel
                      </GhostButton>
                      <Btn buttonType="secondary" type="submit" disabled={!dirty} waiting={isSubmitting}>
                        Save Changes
                      </Btn>
                    </CTAButtonContainer>
                  </Td>
                </tr>
              </tbody>
            </StyledTable>
          </StyledForm>
        )}
      </Formik>

      <Formik initialValues={passwordInitialValues} onSubmit={submitSavePassword}>
        {({ values, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <StyledTable>
              <thead>
                <tr>
                  <StyledTh>Change Password</StyledTh>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>
                    <PasswordField
                      name="currentPassword"
                      label="Current"
                      onChange={value => setFieldValue('currentPassword', value)}
                      onBlur={handleBlur}
                      errorState={false}
                    />

                    <PasswordField
                      name="newPassword"
                      label="New Password"
                      onChange={value => setFieldValue('newPassword', value)}
                      onBlur={handleBlur}
                      errorState={false}
                    />

                    <DisplayPasswordReqValidationResult
                      newPasswordValidationResult={newPasswordReqValidation(values.newPassword)}
                      newPasswordLength={values.newPassword.length}
                    />

                    <CTAButtonContainer center={false}>
                      <GhostButton
                        onClick={e => {
                          e.preventDefault()
                          dismissModal()
                        }}
                      >
                        Cancel
                      </GhostButton>
                      <Btn
                        buttonType="secondary"
                        type="submit"
                        disabled={
                          !values.currentPassword ||
                          !values.newPassword ||
                          !newPasswordReqValidation(values.newPassword).valid
                        }
                        waiting={isSubmitting}
                      >
                        Update Password
                      </Btn>
                    </CTAButtonContainer>
                  </Td>
                </tr>
              </tbody>
            </StyledTable>
          </StyledForm>
        )}
      </Formik>
    </Sidepanel>
  )
}

export default ProfileModal
