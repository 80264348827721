import { LoadingIcon } from '../icon'
import { ContentSection, InstructionalImageContainer, InstructionalText } from './loading-screen.styles'

const Loading = () => (
  <ContentSection verticallyCenter={true}>
    <InstructionalImageContainer>
      <LoadingIcon />
    </InstructionalImageContainer>
    <InstructionalText>Just a few seconds while we get your information.</InstructionalText>
  </ContentSection>
)

export default Loading
