import Step1 from '../../../../assets/instructions-leak-and-freeze-step1.png'
import Step2 from '../../../../assets/instructions-leak-and-freeze-step2.png'
import Step3 from '../../../../assets/instructions-leak-and-freeze-step3.png'
import Step4 from '../../../../assets/instructions-leak-and-freeze-step4.png'
import Carousel from '../../../../components/carousel'
import InstructionCard from '../../../../components/instruction-card'
import { InstructionCardGrid, MobileCarousel } from './instruction-cards.styles'

const Cards = [
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step1}
      stepText="Step 1"
      headerText="Remove Cover"
      instructionText="Remove the battery cover from the sensor."
    />
  ),
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step2}
      stepText="Step 2"
      headerText="Confirm Signal"
      instructionText="Pull tab from the battery. The LED light will blink orange to indicate the sensor has powered on. When sensor finds a cellular signal, it will blink green. This may take up to 30 seconds."
    />
  ),
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step3}
      stepText="Step 3"
      headerText="Replace Cover"
      instructionText="Replace battery cover and press firmly to close."
    />
  ),
  (index: number) => (
    <InstructionCard
      key={`card-${index}`}
      imageSrc={Step4}
      stepText="Step 4"
      headerText="Place Sensor"
      instructionText="Place the sensor flat on the ground with the label facing down."
    />
  ),
]

export const InstructionCards = () => {
  return (
    <>
      <MobileCarousel>
        <Carousel numberOfSlides={4} autoplay={false}>
          {Cards.map((card, index) => {
            return card(index)
          })}
        </Carousel>
      </MobileCarousel>
      <InstructionCardGrid>
        {Cards.map((card, index) => {
          return card(index)
        })}
      </InstructionCardGrid>
    </>
  )
}

export default InstructionCards
