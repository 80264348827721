import { Dialog } from '@reach/dialog'
import styled from 'styled-components/macro'

import { GhostButton } from '../../shared-styles/button.styles'
import theme from '../../theme'

interface ModalContainerProps {
  variation: 'default' | 'support'
  padding?: string
}

export const ModalContainer = styled(Dialog)<ModalContainerProps>`
  font-family: ${theme.fontFamily};
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0;
  background-color: ${theme.colors.ui01};
  padding: 1.6rem 1.6rem 6.4rem 1.6rem;
  overflow-x: hidden;
  position: relative;

  @media (min-width: 992px) {
    width: ${props => (props.variation === 'support' ? '67vw' : '43vw')};
    max-width: ${props => (props.variation === 'support' ? '78.6rem' : '61.7rem')};
    height: 100vh;
    padding: ${props => (props.variation === 'support' ? '1.875rem 7.5rem' : props.padding || '1.875rem')};
    margin: 0 0 0 auto;
    min-height: 0;
  }
`

export const ModalHeaderSpacer = styled.div`
  @media (min-width: 992px) {
    height: 3.3rem;
  }
`

interface ModalTitleProps {
  theme: IThemeProps
  titleSize?: 'small' | 'large'
  variation?: 'default' | 'support'
}
export const ModalTitle = styled.h2<ModalTitleProps>`
  color: ${theme.colors.text01};
  font-size: ${theme.fontSizes.default};
  font-weight: ${theme.fontWeights.semibold};
  line-height: 2;
  margin: 2rem 0 0 0;

  @media (min-width: 992px) {
    font-size: ${props => (props.titleSize === 'large' ? theme.fontSizes.xl : theme.fontSizes.l)};
    line-height: 1.3;
    text-align: ${props => (props.variation === 'support' ? 'center' : 'left')};
    margin-top: ${props => (props.variation === 'support' ? '4.4rem' : '3rem')};
  }
`

export const ModalContent = styled.div`
  color: ${theme.colors.text02};
  font-size: ${theme.fontSizes.s};
  line-height: 1.7;
  margin-top: 2.4rem;

  p,
  ul {
    width: 100%;
  }
  ul {
    box-sizing: border-box;
  }
`

export const ModalCloseButton = styled.button`
  display: block;
  background-color: transparent;
  border: 0;
  padding: 15px;
  margin: -17px -15px 0 0;

  @media (min-width: 992px) {
    margin-top: -15px;
  }

  &:hover {
    cursor: pointer;
  }
`

interface ModalCloseButtonContainerProps {
  theme: IThemeProps
  showBackButton?: boolean
}

export const ModalCloseButtonContainer = styled.div<ModalCloseButtonContainerProps>`
  display: flex;
  justify-content: ${({ showBackButton }) => (showBackButton ? 'space-between' : 'flex-end')};
  border-bottom: 1px solid ${theme.colors.ui04};
  margin: 0 -1.6rem;
  padding: 0 1.6rem;

  @media (max-width: 992px) {
    justify-content: flex-end;
  }
  @media (min-width: 992px) {
    position: absolute;
    width: 100%;
    right: 0;
    padding: 0 1.875rem;
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.colors.ui04};
    margin: 0;
  }
`

interface ModalBackButtonProps {
  theme: IThemeProps
  showBackButton?: boolean
}

export const ModalBackButton = styled<any>(GhostButton)`
  color: ${theme.colors.ui07};
  display: flex;
  align-items: center;
  margin: -15px 0 0 -8px;
  text-align: left;
  overflow: hidden;
  width: 100%;
  text-transform: uppercase;

  svg {
    transform: rotate(180deg);
    margin-right: 0.9rem;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg,
  span {
    display: block;
  }

  @media (min-width: 992px) {
    ${({ showBackButton }: ModalBackButtonProps) => {
      if (!showBackButton) {
        return `display: none;`
      }
    }};
  }
`

export const ModalTitleAboveDivider = styled.h2`
  color: ${theme.colors.text01};
  font-size: ${theme.fontSizes.default};
  font-weight: ${theme.fontWeights.semibold};
  display: flex;
  align-items: center;
  margin: -15px 0 0 -8px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  @media (min-width: 992px) {
    display: none;
  }
`
