import styled from 'styled-components/macro'

export const MobileOnly = styled.div`
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: none;
  }
`

export const DesktopOnly = styled.div`
  display: none;
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: block;
  }
`
