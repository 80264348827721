import { PanelBulletList, PanelSubHeader } from './placement-guides.styles'

const TemperaturePlacementGuide = () => {
  return (
    <>
      <PanelSubHeader>Temperature Sensor</PanelSubHeader>
      <p>The temperature sensor can be used for either room temperature monitoring or refrigeration monitoring.</p>
      <p>Tips for placement:</p>
      <PanelBulletList>
        <li>Place in a heated area with a thermostat</li>
        <li>If used for refrigeration, place it near the door of a walk-in refrigerator or freezer</li>
      </PanelBulletList>
    </>
  )
}

export default TemperaturePlacementGuide
