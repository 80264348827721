import { Link as RouterLink } from '@reach/router'
import styled from 'styled-components/macro'

// Headings
interface HeadingProps {
  size?: 1 | 2 | 3 | 4 | 5 | 6
  theme: IThemeProps
}

export const H1 = styled.h1`
  color: ${({ theme }: HeadingProps) => theme.colors.text01};
  font-size: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.fontSizes.xxxl
      case 2:
        return theme.fontSizes.xxl
      case 3:
        return theme.fontSizes.xl
      case 4:
        return theme.fontSizes.l
      case 5:
        return theme.fontSizes.default
      case 6:
        return theme.fontSizes.s
      default:
        return theme.fontSizes.xxxl
    }
  }};
  font-weight: ${({ theme }: HeadingProps) => theme.fontWeights.semibold};
  line-height: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.lineHeights.default
      case 2:
        return theme.lineHeights.default
      case 3:
        return theme.lineHeights.s
      case 4:
        return theme.lineHeights.s
      case 5:
        return theme.lineHeights.default
      case 6:
        return theme.lineHeights.l
      default:
        return theme.lineHeights.default
    }
  }};
  letter-spacing: ${({ size }: HeadingProps) => (size === 6 ? '0.75px' : 'normal')};
  text-transform: ${({ size }: HeadingProps) => (size === 6 ? 'uppercase' : 'none')};
`

export const H2 = styled.h2`
  color: ${({ theme }: HeadingProps) => theme.colors.text01};
  font-size: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.fontSizes.xxxl
      case 2:
        return theme.fontSizes.xxl
      case 3:
        return theme.fontSizes.xl
      case 4:
        return theme.fontSizes.l
      case 5:
        return theme.fontSizes.default
      case 6:
        return theme.fontSizes.s
      default:
        return theme.fontSizes.xxl
    }
  }};
  font-weight: ${({ theme }: HeadingProps) => theme.fontWeights.semibold};
  line-height: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.lineHeights.default
      case 2:
        return theme.lineHeights.default
      case 3:
        return theme.lineHeights.s
      case 4:
        return theme.lineHeights.s
      case 5:
        return theme.lineHeights.default
      case 6:
        return theme.lineHeights.l
      default:
        return theme.lineHeights.default
    }
  }};
  letter-spacing: ${({ size }: HeadingProps) => (size === 6 ? '0.75px' : 'normal')};
  text-transform: ${({ size }: HeadingProps) => (size === 6 ? 'uppercase' : 'none')};
`

export const H3 = styled.h3`
  color: ${({ theme }: HeadingProps) => theme.colors.text01};
  font-size: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.fontSizes.xxxl
      case 2:
        return theme.fontSizes.xxl
      case 3:
        return theme.fontSizes.xl
      case 4:
        return theme.fontSizes.l
      case 5:
        return theme.fontSizes.default
      case 6:
        return theme.fontSizes.s
      default:
        return theme.fontSizes.xl
    }
  }};
  font-weight: ${({ theme }: HeadingProps) => theme.fontWeights.semibold};
  line-height: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.lineHeights.default
      case 2:
        return theme.lineHeights.default
      case 3:
        return theme.lineHeights.s
      case 4:
        return theme.lineHeights.s
      case 5:
        return theme.lineHeights.default
      case 6:
        return theme.lineHeights.l
      default:
        return theme.lineHeights.s
    }
  }};
  letter-spacing: ${({ size }: HeadingProps) => (size === 6 ? '0.75px' : 'normal')};
  text-transform: ${({ size }: HeadingProps) => (size === 6 ? 'uppercase' : 'none')};
`

export const H4 = styled.h4`
  color: ${({ theme }: HeadingProps) => theme.colors.text01};
  font-size: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.fontSizes.xxxl
      case 2:
        return theme.fontSizes.xxl
      case 3:
        return theme.fontSizes.xl
      case 4:
        return theme.fontSizes.l
      case 5:
        return theme.fontSizes.default
      case 6:
        return theme.fontSizes.s
      default:
        return theme.fontSizes.l
    }
  }};
  font-weight: ${({ theme }: HeadingProps) => theme.fontWeights.semibold};
  line-height: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.lineHeights.default
      case 2:
        return theme.lineHeights.default
      case 3:
        return theme.lineHeights.s
      case 4:
        return theme.lineHeights.s
      case 5:
        return theme.lineHeights.default
      case 6:
        return theme.lineHeights.l
      default:
        return theme.lineHeights.s
    }
  }};
  letter-spacing: ${({ size }: HeadingProps) => (size === 6 ? '0.75px' : 'normal')};
  text-transform: ${({ size }: HeadingProps) => (size === 6 ? 'uppercase' : 'none')};
`

export const H5 = styled.h5`
  color: ${({ theme }: HeadingProps) => theme.colors.text01};
  font-size: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.fontSizes.xxxl
      case 2:
        return theme.fontSizes.xxl
      case 3:
        return theme.fontSizes.xl
      case 4:
        return theme.fontSizes.l
      case 5:
        return theme.fontSizes.default
      case 6:
        return theme.fontSizes.s
      default:
        return theme.fontSizes.default
    }
  }};
  font-weight: ${({ theme }: HeadingProps) => theme.fontWeights.semibold};
  line-height: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.lineHeights.default
      case 2:
        return theme.lineHeights.default
      case 3:
        return theme.lineHeights.s
      case 4:
        return theme.lineHeights.s
      case 5:
        return theme.lineHeights.default
      case 6:
        return theme.lineHeights.l
      default:
        return theme.lineHeights.default
    }
  }};
  letter-spacing: ${({ size }: HeadingProps) => (size === 6 ? '0.75px' : 'normal')};
  text-transform: ${({ size }: HeadingProps) => (size === 6 ? 'uppercase' : 'none')};
`

export const H6 = styled.h6`
  color: ${({ theme }: HeadingProps) => theme.colors.text01};
  font-size: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.fontSizes.xxxl
      case 2:
        return theme.fontSizes.xxl
      case 3:
        return theme.fontSizes.xl
      case 4:
        return theme.fontSizes.l
      case 5:
        return theme.fontSizes.default
      case 6:
        return theme.fontSizes.s
      default:
        return theme.fontSizes.s
    }
  }};
  font-weight: ${({ theme }: HeadingProps) => theme.fontWeights.semibold};
  line-height: ${({ size, theme }: HeadingProps) => {
    switch (size) {
      case 1:
        return theme.lineHeights.default
      case 2:
        return theme.lineHeights.default
      case 3:
        return theme.lineHeights.s
      case 4:
        return theme.lineHeights.s
      case 5:
        return theme.lineHeights.default
      case 6:
        return theme.lineHeights.l
      default:
        return theme.lineHeights.l
    }
  }};
  letter-spacing: ${({ size }: HeadingProps) => (size === 6 ? '0.75px' : 'normal')};
  text-transform: ${({ size }: HeadingProps) => (size === 6 ? 'uppercase' : 'none')};
`

export const ModalHeader = styled(H3)`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.75px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
`

// Links
export const A = styled.a`
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export const AgreementLink = styled.a`
  color: ${({ theme }: ThemeProps) => theme.colors.support_info};
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:visited {
    color: ${({ theme }: ThemeProps) => theme.colors.accent_blue};
  }
`

export const Link = styled(RouterLink)`
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

// Paragraphs
interface Body1PProps {
  theme: IThemeProps
  mobileBody2?: boolean
}
export const Body1P = styled.p<Body1PProps>`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  line-height: 2;

  @media (max-width: 767px) {
    ${({ theme, mobileBody2 }: Body1PProps) => {
      if (mobileBody2) {
        return `font-size: ${theme.fontSizes.s}`
      }
    }}
  }
`

export const Body2P = styled.p`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: 1.7;
`
