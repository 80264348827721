import styled, { keyframes } from 'styled-components/macro'

/* Animation based on: https://codepen.io/FlorinPop17/pen/drJJzK */
const blip = keyframes`
  0% {
    transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(51, 135, 178, .7); */
  }
  70% {
    transform: scale(1);
		box-shadow: 0 0 0 10px rgba(51, 135, 178, 0);
  }
  100% {
    transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 135, 178, 0);
  }
`

const StyledGlowCallout = styled.div`
  background: ${({ theme }: ThemeProps) => theme.colors.support_info};
  border-radius: 50%;
  box-shadow: 0 0 0 0 ${({ theme }: ThemeProps) => theme.colors.support_info};
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: ${blip} 2s infinite;
`

export const GlowCallout = () => <StyledGlowCallout />

export default GlowCallout
