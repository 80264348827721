const theme = {
  fontFamily: "'Work Sans', sans-serif;",
  fontSizes: {
    xxxl: '4.4rem',
    xxl: '3.2rem',
    xl: '2.4rem',
    l: '1.8rem',
    default: '1.6rem',
    s: '1.4rem',
    xs: '1.3rem',
  },
  fontWeights: {
    regular: 400,
    semibold: 600,
  },
  lineHeights: {
    xl: 2,
    l: 1.7,
    default: 1.5,
    s: 1.3,
    xs: 1.1,
    xxs: 1,
  },
  colors: {
    brand01: '#0078EC',
    brand02: '#232735',
    brand03: '#7CC6FF',
    text01: '#232735',
    text02: '#505565',
    text03: '#8B90A0',
    text_inverse: '#FFFFFF',
    ui01: '#FFFFFF',
    ui02: '#FAFAFA',
    ui03: '#EBEBEB',
    ui04: '#D3D4D8',
    ui05: '#A1A4B1',
    ui06: '#464646',
    ui07: '#1B1B1B',
    hover_primary: '#FFB16F',
    hover_row: '#EEEEF1',
    support_info: '#3387B2',
    support_success: '#47B282',
    support_error: '#C92434',
    support_warning: '#FFCC69',
    accent_blue: '#1F447E',
    accent_blue_muted: '#6180B2',
    accent_green: '#53D0C0',
    accent_orange: '#FFA83C',
    accent_gray: '#6F99A5',
  },
  space: {
    xxxl: '12.8rem',
    xxl: '6.4rem',
    xl: '4.8rem',
    l: '3.2rem',
    m: '2.4rem',
    s: '1.6rem',
    xs: '0.8rem',
    xxs: '0.4rem',
  },
  elevation: {
    elevation02: 'box-shadow: 0px 0.1rem 0.2rem rgba(0, 0, 0, 0.1);',
    elevation03: 'box-shadow: 0px 0.4rem 0.8rem rgba(0, 0, 0, 0.1);',
    elevation04: 'box-shadow: 0px 0.6rem 0.12rem rgba(0, 0, 0, 0.1);',
    elevation05: 'box-shadow: 0px 0.8rem 1.6rem rgba(0, 0, 0, 0.1);',
    elevation06: 'box-shadow: 0px 1.0rem 2.4rem rgba(0, 0, 0, 0.1);',
  },
  breakpoint: {
    xl: '1200px',
    l: '992px',
    m: '768px',
    s: '576px',
  },
}

export default theme
