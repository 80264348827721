import { useContext } from 'react'

import { DropdownButton, DropdownMenu, DropdownMenuItem, DropdownMenuList } from '../../../components/dropdown'
import { CheckIconNoCircle, SmallArrowIcon } from '../../../components/icon'
import { SearchInput } from '../../../components/search-input'
import { SkeletonLine } from '../../../components/skeleton-line'
import { AppContext } from '../../../contexts/app-context'
import UserContext from '../../../contexts/user-context'
import { DesktopOnly, MobileOnly } from '../../../shared-styles/responsive.styles'
import theme from '../../../theme'
import {
  FilterDisplay,
  FilterDropdownCategory,
  FilterIcon,
  FilterLabel,
  FiltersContainer,
  FiltersDropdowns,
  FilterSelection,
  FilterText,
} from '../dashboard.styles'
import { displayAlertsOnly, groupByDeviceLocation, groupByDeviceType } from '../methods/genericMethods'

export const FiltersErrored = () => (
  <FiltersContainer>
    <FiltersDropdowns>
      <FilterDisplay>
        <DropdownMenu>
          <DropdownButton>Devices: -</DropdownButton>
        </DropdownMenu>
      </FilterDisplay>
      <FilterDisplay>
        <DropdownMenu>
          <DropdownButton>Grouped by: -</DropdownButton>
        </DropdownMenu>
      </FilterDisplay>
    </FiltersDropdowns>
  </FiltersContainer>
)

const Filters = () => {
  const { foldersLoading } = useContext(AppContext)
  const { devices, devicesLoaded, displayType, setDisplayType, groupType, setGroupType, setSelectedDeviceId } =
    useContext(UserContext)

  return (
    <>
      {!devicesLoaded || foldersLoading ? (
        <FiltersContainer>
          <SkeletonLine
            rounded={true}
            maxWidth="306px"
            height="15px"
            light={true}
            alt="Loading devices at selected location"
          />
        </FiltersContainer>
      ) : (
        <>
          <DesktopOnly>
            <FiltersContainer>
              <FiltersDropdowns>
                <DropdownMenu>
                  <DropdownButton>
                    <FilterText>
                      <FilterLabel>Devices: </FilterLabel>
                      <FilterSelection>
                        {(displayType === 'all' && `All ${devices ? `(${devices.length})` : ''}`) ||
                          (displayType === 'alertsonly' &&
                            `With Alerts ${devices ? `(${displayAlertsOnly(devices).length})` : ''}`)}
                      </FilterSelection>
                      <FilterIcon>
                        <SmallArrowIcon />
                      </FilterIcon>
                    </FilterText>
                  </DropdownButton>

                  <DropdownMenuList minwidth="20rem">
                    <FilterDropdownCategory>Show:</FilterDropdownCategory>
                    <DropdownMenuItem
                      value="all"
                      onSelect={() => setDisplayType('all')}
                      isSelected={displayType === 'all'}
                    >
                      All Devices
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      value="alertsonly"
                      onSelect={() => setDisplayType('alertsonly')}
                      isSelected={displayType === 'alertsonly'}
                    >
                      Devices with Alerts
                    </DropdownMenuItem>
                  </DropdownMenuList>
                </DropdownMenu>

                <DropdownMenu>
                  <DropdownButton>
                    <FilterText>
                      <FilterLabel>Grouped by: </FilterLabel>
                      <FilterSelection>
                        {(groupType === 'none' && `None`) ||
                          (groupType === 'type' &&
                            `Type ${
                              devices
                                ? `(${
                                    displayType === 'alertsonly'
                                      ? groupByDeviceType(displayAlertsOnly(devices)).length
                                      : groupByDeviceType(devices).length
                                  })`
                                : ''
                            }`) ||
                          (groupType === 'location' &&
                            `Location ${
                              devices
                                ? `(${
                                    displayType === 'alertsonly'
                                      ? groupByDeviceLocation(displayAlertsOnly(devices)).length
                                      : groupByDeviceLocation(devices).length
                                  })`
                                : ''
                            }`)}
                      </FilterSelection>
                      <FilterIcon>
                        <SmallArrowIcon />
                      </FilterIcon>
                    </FilterText>
                  </DropdownButton>

                  <DropdownMenuList minwidth="16rem">
                    <FilterDropdownCategory>Group by:</FilterDropdownCategory>
                    <DropdownMenuItem
                      value="type"
                      onSelect={() => setGroupType('type')}
                      isSelected={groupType === 'type'}
                    >
                      Device Type
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      value="location"
                      onSelect={() => setGroupType('location')}
                      isSelected={groupType === 'location'}
                    >
                      Device Location
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      value="none"
                      onSelect={() => setGroupType('none')}
                      isSelected={groupType === 'none'}
                    >
                      None
                    </DropdownMenuItem>
                  </DropdownMenuList>
                </DropdownMenu>
              </FiltersDropdowns>
              <SearchInput devices={devices} setSelectedDeviceId={setSelectedDeviceId} />
            </FiltersContainer>
          </DesktopOnly>

          <MobileOnly>
            <FiltersContainer>
              <FiltersDropdowns>
                <FilterDisplay>
                  <DropdownMenu>
                    <DropdownButton>
                      Display: {(displayType === 'all' && `All, `) || (displayType === 'alertsonly' && `With Alerts, `)}
                      {(groupType === 'type' && `by Device Type`) ||
                        (groupType === 'location' && `by Device Location`) ||
                        (groupType === 'none' && `Not Grouped`)}
                      <SmallArrowIcon fill="#505565" />
                    </DropdownButton>

                    <DropdownMenuList>
                      <FilterDropdownCategory>Show:</FilterDropdownCategory>
                      <DropdownMenuItem
                        value="all"
                        onSelect={() => setDisplayType('all')}
                        isSelected={displayType === 'all'}
                      >
                        All Devices
                        {displayType === 'all' && <CheckIconNoCircle fill={theme.colors.brand01} width={12} />}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        value="alertsonly"
                        onSelect={() => setDisplayType('alertsonly')}
                        isSelected={displayType === 'alertsonly'}
                      >
                        Devices with Alerts
                        {displayType === 'alertsonly' && <CheckIconNoCircle fill={theme.colors.brand01} width={12} />}
                      </DropdownMenuItem>
                      <FilterDropdownCategory>Group by:</FilterDropdownCategory>
                      <DropdownMenuItem
                        value="none"
                        onSelect={() => setGroupType('none')}
                        isSelected={groupType === 'none'}
                      >
                        None
                        {groupType === 'none' && <CheckIconNoCircle fill={theme.colors.brand01} width={12} />}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        value="location"
                        onSelect={() => setGroupType('location')}
                        isSelected={groupType === 'location'}
                      >
                        Device Location
                        {groupType === 'location' && <CheckIconNoCircle fill={theme.colors.brand01} width={12} />}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        value="type"
                        onSelect={() => setGroupType('type')}
                        isSelected={groupType === 'type'}
                      >
                        Device TypeX
                        {groupType === 'type' && <CheckIconNoCircle fill={theme.colors.brand01} width={12} />}
                      </DropdownMenuItem>
                    </DropdownMenuList>
                  </DropdownMenu>
                </FilterDisplay>

                <DropdownMenu>
                  <DropdownMenuList>
                    <DropdownMenuItem
                      value="none"
                      onSelect={() => setGroupType('none')}
                      isSelected={groupType === 'none'}
                    >
                      None
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      value="location"
                      onSelect={() => setGroupType('location')}
                      isSelected={groupType === 'location'}
                    >
                      Device Location
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      value="type"
                      onSelect={() => setGroupType('type')}
                      isSelected={groupType === 'type'}
                    >
                      Device Type
                    </DropdownMenuItem>
                  </DropdownMenuList>
                </DropdownMenu>
              </FiltersDropdowns>
              <div />
            </FiltersContainer>
          </MobileOnly>
        </>
      )}
    </>
  )
}

export default Filters
