// lists provided from AC
export const cachedTimezoneList = [
  {
    header: 'Pacific Time',
    key: 3,
    subtitle: 'Los Angeles, Vancouver, Tijuana',
    tzCode: 'America/Los_Angeles',
  },
  {
    header: 'Mountain Time',
    key: 4,
    subtitle: 'Denver, Edmonton, Juarez',
    tzCode: 'America/Denver',
  },
  {
    header: 'Central Time',
    key: 5,
    subtitle: 'Chicago, Winnipeg, Mexico City',
    tzCode: 'America/Chicago',
  },
  {
    header: 'Eastern Time',
    key: 6,
    subtitle: 'New York, Toronto, Bogota, Lima',
    tzCode: 'America/New_York',
  },
  {
    header: 'Atlantic Time',
    key: 7,
    subtitle: 'Santiago, La Paz, Halifax',
    tzCode: 'America/Halifax',
  },
  {
    header: 'Brasilia Time',
    key: 8,
    subtitle: 'Sao Paulo, Buenos Aires',
    tzCode: 'America/Argentina/Buenos_Aires',
  },
  {
    header: 'Fernando de Noronha Time',
    key: 9,
    subtitle: 'Fernando de Noronha',
    tzCode: 'America/Noronha',
  },
  {
    header: 'Azores Time',
    key: 10,
    subtitle: 'Azores, Cape Verde',
    tzCode: 'Atlantic/Azores',
  },
  {
    header: 'Universal Time',
    key: 11,
    subtitle: 'London, Lisbon, Dublin, Accra',
    tzCode: 'Europe/London',
  },
  {
    header: 'Central European Time',
    key: 12,
    subtitle: 'Berlin, Madrid, Rome, Warsaw',
    tzCode: 'Europe/Berlin',
  },
  {
    header: 'Eastern European Time',
    key: 13,
    subtitle: 'Sofia, Athens, Jerusalem, Kyiv',
    tzCode: 'Europe/Sofia',
  },
  {
    header: 'Arabic & Moscow Time',
    key: 14,
    subtitle: 'Riyadh, Baghdad, Moscow',
    tzCode: 'Europe/Moscow',
  },
  {
    header: 'Near East Time',
    key: 15,
    subtitle: 'Dubai, Tbilisi, Baku',
    tzCode: 'Asia/Dubai',
  },
  {
    header: 'Pakistan Time',
    key: 16,
    subtitle: 'Karachi, Tashkent',
    tzCode: 'Asia/Karachi',
  },
  {
    header: 'India Time',
    key: 17,
    subtitle: 'Mumbai, Colombo',
    tzCode: 'Asia/Colombo',
  },
  {
    header: 'Bangladesh Time',
    key: 18,
    subtitle: 'Dhaka, Omsk, Almaty',
    tzCode: 'Asia/Dhaka',
  },
  {
    header: 'Indochina Time',
    key: 19,
    subtitle: 'Bangkok, Hanoi, Jakarta',
    tzCode: 'Asia/Bangkok',
  },
  {
    header: 'China & Taiwan Time',
    key: 20,
    subtitle: 'Shanghai, Taipei, Singapore',
    tzCode: 'Asia/Shanghai',
  },
  {
    header: 'Japan & Korea Time',
    key: 21,
    subtitle: 'Tokyo, Seoul',
    tzCode: 'Asia/Tokyo',
  },
  {
    header: 'Australia Eastern Time',
    key: 22,
    subtitle: 'Sydney, Vladivostok',
    tzCode: 'Australia/Sydney',
  },
  {
    header: 'Solomon Time',
    key: 23,
    subtitle: 'Noumea',
    tzCode: 'Pacific/Noumea',
  },
  {
    header: 'New Zealand Time',
    key: 24,
    subtitle: 'Auckland',
    tzCode: 'Pacific/Auckland',
  },
  {
    header: 'Midway Islands Time',
    key: 25,
    subtitle: 'Midway, Pago Pago',
    tzCode: 'Pacific/Pago_Pago',
  },
  {
    header: 'Hawaii Time',
    key: 26,
    subtitle: 'Honolulu',
    tzCode: 'Pacific/Honolulu',
  },
  {
    header: 'Alaska Time',
    key: 27,
    subtitle: 'Anchorage, Juneau',
    tzCode: 'America/Anchorage',
  },
]
