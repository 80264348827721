import { useEffect, useState } from 'react'

import theme from '../../theme'
import { ChevronIcon } from '../icon/index'
import { ILanguage, IWindow } from './index'
import {
  LanguageButtonContainer,
  LanguageMenu,
  LanguageMenuButton,
  LanguageMenuItem,
  LanguageMenuList,
} from './language-pickers.styles'

const SelectLanguagePicker = () => {
  const transifex = ((window as unknown) as IWindow)?.Transifex
  const [currentLanguage, setCurrentLanguage] = useState<ILanguage['code'] | undefined>(undefined)

  const changeLanguage = (langCode: string) => {
    if (transifex?.live) {
      transifex.live.translateTo(langCode)
      setCurrentLanguage(langCode)
    }
  }

  useEffect(() => {
    transifex?.live?.onFetchLanguages(() => {
      setCurrentLanguage(transifex.live?.detectLanguage())
    })
  }, [transifex?.live])

  if (!currentLanguage) return <></>
  return (
    <LanguageMenu>
      <LanguageMenuButton>
        {currentLanguage}{' '}
        <LanguageButtonContainer>
          <ChevronIcon fill={theme.colors.ui05} />
        </LanguageButtonContainer>
      </LanguageMenuButton>
      <LanguageMenuList>
        <LanguageMenuItem onSelect={() => changeLanguage('en')} selected={currentLanguage === 'en'}>
          English
        </LanguageMenuItem>
        <LanguageMenuItem onSelect={() => changeLanguage('de')} selected={currentLanguage === 'de'}>
          Deutsch
        </LanguageMenuItem>
      </LanguageMenuList>
    </LanguageMenu>
  )
}

export default SelectLanguagePicker
