import { createContext } from 'react'

interface StateType {
  showAccountModal: boolean
  showContactsModal: boolean
  showBackButton: boolean
  setShowAccountModal: (val: boolean) => void
  setShowContactsModal: (val: boolean) => void
  setShowBackButton: (val: boolean) => void
}

const initialState: StateType = {
  showAccountModal: false,
  showContactsModal: false,
  showBackButton: false,
  setShowAccountModal: () => {},
  setShowContactsModal: () => {},
  setShowBackButton: () => {},
}

const Context = createContext<StateType>(initialState)

export default Context
