import { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components/macro'

import { ChevronIcon } from '../../components/icon/index'
import { CenteredButtonWrapper } from '../../shared-styles/form.styles'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import {
  ContentIconContainer,
  SuccessModal,
  SuccessModalContent,
  SuccessModalMinimized,
  ToggleButton,
  ToggleButtonWrapper,
} from '../../shared-styles/success-modals.styles'
import theme from '../../theme'
import { Btn } from './../../components/button/index'
import { CheckIconOutline } from './../../components/icon/index'
import { ButtonContainer, ContentHeader, ContentSubheader, InstructionalText } from './activating.styles'

const ButtonContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -2.8rem;
`

const ContinueButtons = ({
  buttonWaiting,
  setButtonWaiting,
  completeActivation,
}: {
  buttonWaiting: boolean
  setButtonWaiting: (bool: boolean) => void
  completeActivation: () => void
}) => {
  return (
    <ButtonContainer>
      <Btn
        onClick={() => {
          setButtonWaiting(true)
          completeActivation()
        }}
        waiting={buttonWaiting}
      >
        View Dashboard
      </Btn>
    </ButtonContainer>
  )
}

const CompletionPanel = ({
  devices,
  completeActivation,
}: {
  devices: IDeviceType[]
  completeActivation: () => void
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true)
  const [buttonWaiting, setButtonWaiting] = useState<boolean>(false)

  // Calculate the number of devices that have issues (has not communicated, low signal, low battery)
  const devicesWithIssues = devices.filter(device => !device.lastCheckIn || device.hasLowSignal || device.hasLowBattery)
  const numWithIssues = devicesWithIssues.length

  const displayIssues = (device: IDeviceType) => {
    const issues = []
    if (!device.lastCheckIn) {
      issues.push('no connection')
    }
    if (device.hasLowSignal) {
      issues.push('low signal')
    }
    if (device.hasLowBattery) {
      issues.push('low battery')
    }
    return issues.join('/')
  }

  return (
    <>
      {modalOpen ? (
        <SuccessModal allowPinchZoom={true} aria-label="Completion Panel">
          <ThemeProvider theme={theme}>
            <ToggleButton
              minimized={!!modalOpen}
              onClick={() => setModalOpen(!modalOpen)}
              aria-label={modalOpen ? 'Minimize Pane' : 'Maximize Pane'}
            >
              <ChevronIcon />
            </ToggleButton>
            {numWithIssues === 0 && (
              <SuccessModalContent>
                <ContentIconContainer>
                  <CheckIconOutline height={63} width={63} />
                </ContentIconContainer>
                <ContentHeader>Success!</ContentHeader>
                <ContentSubheader>You activated all your devices.</ContentSubheader>
                <InstructionalText>You can now view your Monitoring Dashboard.</InstructionalText>
                <ContinueButtons
                  buttonWaiting={buttonWaiting}
                  setButtonWaiting={setButtonWaiting}
                  completeActivation={completeActivation}
                />
              </SuccessModalContent>
            )}
            {numWithIssues > 0 && (
              <SuccessModalContent>
                <ContentHeader>Warning</ContentHeader>
                <ContentSubheader>
                  {numWithIssues} of your devices {numWithIssues > 1 ? 'were' : 'was'} not activated properly.
                </ContentSubheader>
                {devicesWithIssues.map(device => (
                  <InstructionalText key={device.id}>
                    The device {device.metadata.device_location_note} has not been properly activated:{' '}
                    {displayIssues(device)}.
                  </InstructionalText>
                ))}
                <InstructionalText>
                  One or more of your devices encountered an activation error. If you have not already, please attempt
                  to troubleshoot the individual device. If you are unable to resolve the issue, call Sensor Support at
                  (844) 468-1866. Monday – Friday, 8 a.m. – 8 p.m. Eastern Time
                </InstructionalText>
                <ContinueButtons
                  buttonWaiting={buttonWaiting}
                  setButtonWaiting={setButtonWaiting}
                  completeActivation={completeActivation}
                />
              </SuccessModalContent>
            )}
          </ThemeProvider>
        </SuccessModal>
      ) : (
        <SuccessModalMinimized>
          <ThemeProvider theme={theme}>
            {numWithIssues === 0 && (
              <>
                <DesktopOnly>
                  <ToggleButtonWrapper>
                    <ToggleButton
                      minimized={modalOpen}
                      onClick={() => {
                        setModalOpen(!modalOpen)
                      }}
                      aria-label="Maximize Pane"
                    >
                      <ChevronIcon />
                    </ToggleButton>
                  </ToggleButtonWrapper>
                  <ButtonContainerRight>
                    <Btn
                      onClick={() => {
                        setButtonWaiting(true)
                        completeActivation()
                      }}
                      waiting={buttonWaiting}
                    >
                      View Dashboard
                    </Btn>
                  </ButtonContainerRight>
                </DesktopOnly>

                <MobileOnly>
                  <SuccessModalContent modalMinimized={!modalOpen}>
                    <ToggleButtonWrapper>
                      <ToggleButton
                        minimized={modalOpen}
                        onClick={() => {
                          setModalOpen(!modalOpen)
                        }}
                        aria-label="Maximize Pane"
                      >
                        <ChevronIcon />
                      </ToggleButton>
                    </ToggleButtonWrapper>
                    <CenteredButtonWrapper>
                      <Btn
                        onClick={() => {
                          setButtonWaiting(true)
                          completeActivation()
                        }}
                        waiting={buttonWaiting}
                        style={{ marginTop: 0 }}
                      >
                        View Dashboard
                      </Btn>
                    </CenteredButtonWrapper>
                  </SuccessModalContent>
                </MobileOnly>
              </>
            )}
            {numWithIssues > 0 && (
              <SuccessModalContent>
                <ToggleButtonWrapper>
                  <ToggleButton
                    minimized={modalOpen}
                    onClick={() => {
                      setModalOpen(!modalOpen)
                    }}
                    aria-label="Maximize Pane"
                  >
                    <ChevronIcon />
                  </ToggleButton>
                </ToggleButtonWrapper>
                <ContentHeader>Warning</ContentHeader>
              </SuccessModalContent>
            )}
          </ThemeProvider>
        </SuccessModalMinimized>
      )}
    </>
  )
}

export default CompletionPanel
