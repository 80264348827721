import { navigate } from '@reach/router'
import axios, { CancelTokenSource } from 'axios'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import { SensorSelectSkeleton } from '../../../../components/sensor-select'
import { AppContext } from '../../../../contexts/app-context'
import UserContext from '../../../../contexts/user-context'
import API from '../../../../utils/api'
import { formatDevice } from '../../../../utils/helpers'
import { DeviceGrid } from '../../dashboard.styles'
import { displayAlertsOnly, groupByDeviceLocation, groupByDeviceType } from '../../methods/genericMethods'
import ModalSingleDevice from '../../modals/devices/single-device'
import GroupedDevicesByType from './grouped-devices-by-type'
import UngroupedDevices from './ungrouped-devices'

const Devices = () => {
  const _isMounted = useRef(true)
  const handleError = useErrorHandler()
  const { foldersLoading } = useContext(AppContext)
  const { devices, devicesLoaded, displayType, groupType, selectedDeviceId, setSelectedDeviceId } = useContext(
    UserContext
  )
  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedCardKey, setSelectedCardKey] = useState<number | undefined>()
  const [device, setDevice] = useState<IDeviceType | undefined>()

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      source.cancel('cancelled') // clean up axios calls when component unmounts
      _isMounted.current = false // used to handle memory leaks when performing a state change when the component has already unmounted
    }
  }, [source])

  const getDevice = useCallback(async () => {
    let _device = devices?.find(item => item.id === selectedDeviceId)
    if (!_device) {
      try {
        const apiData = (
          await API.get(`/api/v2/protect/devices/${selectedDeviceId}`, {
            cancelToken: source.token,
          })
        ).data as IDeviceTypeAPI
        if (_isMounted.current) _device = formatDevice(apiData)
      } catch (e) {
        if (e.message !== 'cancelled') handleError(e)
      }
    }
    setDevice(_device)
    setSelectedCardKey(selectedDeviceId)
    setShowModal(true)
    if (_device?.folderId) navigate(`/dashboard/${_device?.folderId}`)
  }, [devices, selectedDeviceId, source.token, handleError])

  useEffect(() => {
    if (!!selectedDeviceId && selectedDeviceId > 0) {
      getDevice()
    }
  }, [selectedDeviceId, getDevice])

  return (
    <>
      <DeviceGrid>
        {!devicesLoaded || foldersLoading ? (
          <SensorSelectSkeleton />
        ) : devices && devices.length === 0 && !foldersLoading ? null : (
          <>
            {devices && devicesLoaded && groupType === 'none' && (
              <UngroupedDevices
                devices={displayType === 'alertsonly' ? displayAlertsOnly(devices) : devices}
                setShowModal={setShowModal}
                setSelectedCardKey={setSelectedCardKey}
                setDevice={setDevice}
              />
            )}
            {devices && devicesLoaded && groupType === 'type' && (
              <GroupedDevicesByType
                groupType="type"
                groupedDevices={groupByDeviceType(displayType === 'alertsonly' ? displayAlertsOnly(devices) : devices)}
                setShowModal={setShowModal}
                setSelectedCardKey={setSelectedCardKey}
                setDevice={setDevice}
              />
            )}
            {devices && devicesLoaded && groupType === 'location' && (
              <GroupedDevicesByType
                groupType="location"
                groupedDevices={groupByDeviceLocation(
                  displayType === 'alertsonly' ? displayAlertsOnly(devices) : devices
                )}
                setShowModal={setShowModal}
                setSelectedCardKey={setSelectedCardKey}
                setDevice={setDevice}
              />
            )}
          </>
        )}
      </DeviceGrid>
      {device && showModal && selectedCardKey === device?.id && (
        <ModalSingleDevice
          device={device}
          showModal={showModal && selectedCardKey === device?.id}
          closeModal={() => {
            setShowModal(false)
            setSelectedCardKey(undefined)
            setSelectedDeviceId(undefined)
            setDevice(undefined)
          }}
          showBackButton={false}
          openGateway={(gateway: IDeviceType) => {
            setSelectedCardKey(gateway.id)
            setSelectedDeviceId(gateway.id)
          }}
        />
      )}
    </>
  )
}

export default Devices
