import { ResizableBox } from 'react-resizable'
import styled, { css } from 'styled-components/macro'

export const StyledSearchBar = styled.div`
  box-sizing: border-box;
  width: 100%;
  grid-row-start: 2;
  grid-row-end: end2;
  grid-column-start: 2;
  grid-column-end: 2;
  border-radius: 2px;
  padding-left: 1.6rem;
  padding-right: 1.6rem;

  @media (min-width: 992px) {
    padding: 0;
  }
`

interface StyledEllipsisOverflowProps {
  hasAlerts?: boolean
  isChild?: boolean
  width: number
}
export const StyledEllipsisOverflow = styled.div<StyledEllipsisOverflowProps>`
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(${(props: StyledEllipsisOverflowProps) => `${props.width}px`} - 34px);
  ${(props: StyledEllipsisOverflowProps) => {
    if (props.isChild) {
      return `padding-right: 25px;`
    }
    if (props.hasAlerts) {
      return `padding-right: 10px;`
    }
  }}

  @media (min-width: 992px) {
    ${(props: StyledEllipsisOverflowProps) => {
      if (props.hasAlerts) {
        return `padding-right: 40px;`
      }
    }}
  }
`

export const StyledHiddenArrowDropDown = styled.img`
  visibility: hidden;
`

export const StyledContents = styled.div<{ active: boolean }>`
  box-sizing: border-box;
  display: grid;
  align-content: center;
  overflow: hidden;
  border-radius: 2px;
  color: ${props => props.theme.primaryFontColor};
  ${props => (props.active ? `background-color: ${props.theme.colors.hover_row}; font-weight: bold;` : '')};

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: ${({ theme }: ThemeProps) => theme.colors.hover_row};
  }
`

export const StyledArrowIcon = styled.img``

export const StyledResizable = styled(ResizableBox)`
  grid-row-start: 3;
  grid-column-start: 2;
  padding-bottom: 20px;
`

export const StyledScrollable = styled.div`
  overflow-y: auto;
  height: 100%;
  scrollbar-width: none; /* Firefox */

  /* Style the scrollbar - Chrome and Safari */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5rem;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(161, 164, 177, 0.4);
  }

  &:hover,
  &:focus {
    scrollbar-width: thin; /* Firefox */
    ::-webkit-scrollbar-thumb {
      background: rgba(161, 164, 177, 0.8);
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 5rem;
    }
  }
`

export const StyledBanner = styled.button<{
  active: boolean
}>`
  box-sizing: border-box;
  background: transparent;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  align-items: center;
  padding: 8px 0;
  padding-left: 12px;
  ${props => (props.active ? `background-color: ${props.theme.colors.hover_row}; font-weight: bold;` : '')};

  @media (min-width: 992px) {
    padding-left: 0;
  }

  div {
    margin-left: 10px;
  }
  li {
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: ${({ theme }: ThemeProps) => theme.colors.hover_row};
  }
`

export const StyledItem = styled.button<{
  width?: number
  active: boolean
  isLeaf: boolean
}>`
  box-sizing: border-box;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  padding: 8px 0;
  padding-left: 36px;
  list-style-type: none;
  ${props => (props.active ? `background-color: ${props.theme.colors.hover_row}; font-weight: bold;` : '')};

  & > img {
    ${props => (props.isLeaf ? 'visibility: hidden' : '')};
  }

  @media (min-width: 992px) {
    padding-left: 0;
  }
`

export const StyledNoWrapDiv = styled.div`
  white-space: nowrap;
`

export const StyledHighlightedTitle = styled.div<{ width: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  div {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
  }
`

export const StyledDirectoryContainer = styled.div`
  display: grid;
  grid-template-columns: 0px 1fr 0px;
  grid-template-rows: 10px 40px 1fr;
  grid-row-gap: 1vh;
`

export const Aside = styled.aside`
  grid-row-start: 1;
  grid-row-end: end1;
  grid-column-start: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: -1.5rem;
`

export const StyledSegments = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 21px 0 0 20px;
  color: ${props => props.theme.selectedTextColor};
`

export const StyledNoFoldersFound = styled.div<{ found: boolean }>`
  grid-row-start: 3;
  grid-column-start: 2;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  text-align: center;
  display: none;
  ${props =>
    props.found === false &&
    css`
      display: block;
      z-index: 10000;
    `}
    :hover {
      cursor: disabled;
    }
  }
  margin-top: 20px;
`

export const StyledAlarm = styled.div`
  background: ${({ theme }: ThemeProps) => theme.colors.brand01};
  border: 1px solid ${({ theme }: ThemeProps) => theme.colors.hover_row};
  border-radius: 100px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 16px;
  right: 16px;
`

export const StyledAlarmScreenReader = styled.span`
  border: 0px none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px;
  overflow-wrap: normal;
`
