import GroupedDevice from './grouped-device'
import UngroupedDevice from './ungrouped-device'

interface IProps {
  groupedDevices: IDeviceType[][]
  groupType: 'type' | 'location'
  setShowModal: (arg: boolean) => void
  setSelectedCardKey: (arg: number | undefined) => void
  setDevice: (arg: IDeviceType | undefined) => void
}

/** Display devices grouped by type or ungrouped (single) device if there is only one of its type */
const GroupedDevicesByType = ({ groupedDevices, groupType, setShowModal, setSelectedCardKey, setDevice }: IProps) => {
  return (
    <>
      {groupedDevices.map((deviceType: IDeviceType[], index: number) => {
        if ((groupType === 'type' && deviceType.length > 1) || groupType === 'location') {
          return (
            <GroupedDevice
              device={deviceType[0]}
              devices={deviceType}
              index={index}
              groupType={groupType}
              key={index}
              setShowModal={setShowModal}
              setSelectedCardKey={setSelectedCardKey}
              setDevice={setDevice}
            />
          )
        } else {
          return deviceType.map((device: IDeviceType) => {
            return (
              <UngroupedDevice
                device={device}
                key={device.id}
                setShowModal={setShowModal}
                setSelectedCardKey={setSelectedCardKey}
                setDevice={setDevice}
              />
            )
          })
        }
      })}
    </>
  )
}

export default GroupedDevicesByType
