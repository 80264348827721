import '@reach/tooltip/styles.css'
import debounce from 'lodash/debounce'
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TreeWalker, VariableSizeNodeData, VariableSizeNodePublicState, VariableSizeTree } from 'react-vtree'
import { NodeComponentProps } from 'react-vtree/dist/es/Tree'

import ArrowDropDown from '../../assets/arrow-down.png'
import ArrowRight from '../../assets/arrow-right.png'
import { AppContext } from '../../contexts/app-context'
import { StyledSkipLinks } from '../../shared-styles/accessibility.styles'
import SearchBar from '../search-bar'
import LoadingSidenav from './../loading-sidenav'
import { generateAnnotatedFolderMap } from './helpers'
import { HighlightMatch } from './highlight-match'
import './react-resizable.css'
import {
  Aside,
  StyledAlarm,
  StyledAlarmScreenReader,
  StyledArrowIcon,
  StyledContents,
  StyledDirectoryContainer,
  StyledEllipsisOverflow,
  StyledItem,
  StyledNoFoldersFound,
  StyledResizable,
  StyledScrollable,
  StyledSearchBar,
} from './styled'
import Tooltip from './tooltip'

type TreeNode = Readonly<{
  children: string[]
  id: string
  name: string
}>

type ExtendedData = VariableSizeNodeData &
  Readonly<{
    isLeaf: boolean
    name: string
    nestingLevel: number
  }> &
  AnnotatedFolder

type NodeMeta = Readonly<{
  nestingLevel: number
  node: TreeNode
}>

const VirtualDirectory = ({
  rootPath,
  folderId,
  onSelectFolder,
  autoSelect = false,
  containerElement,
}: {
  rootPath: string
  folderId: string
  onSelectFolder: (currentId: string, pathName?: string) => void
  autoSelect?: boolean
  containerElement: string
}) => {
  const _isMounted = useRef(true)
  const { folders, foldersLoading } = useContext(AppContext)
  const [searchText, setSearchText] = useState<string>('')

  const tree = useRef<VariableSizeTree<ExtendedData>>(null)
  const itemSize = 40
  const [treeHeight, setTreeHeight] = useState<number>(330)

  const [annotatedFolderMap, rootFolders, showTree] = useMemo(
    () => generateAnnotatedFolderMap(folders, searchText),
    [folders, searchText]
  )

  const defaultOpenPaths = useMemo(() => {
    if (!folderId || foldersLoading) return []

    const ids = []
    // traverse up the tree starting with the child. keep finding parent nodes until there are none left
    let node = annotatedFolderMap.get(folderId)
    while (node !== undefined) {
      ids.push(node.id)
      node = annotatedFolderMap.get(node.folder.parentId.toString())
    }

    return ids
  }, [annotatedFolderMap, folderId, foldersLoading])

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      _isMounted.current = false // used to handle memory leaks when performing a state changing when the component has already unmounted
    }
  }, [])

  // If autoSelect prop is set to true, then select the first folder that appears in the list
  useEffect(() => {
    if (autoSelect && !folderId && rootFolders.length === 1) {
      onSelectFolder(String(rootFolders[0].id))
    }
  }, [autoSelect, folderId, rootFolders, onSelectFolder])

  // Node component receives all the data we created in the `treeWalker` +
  // internal openness state (`isOpen`), function to change internal openness
  const Node: React.FC<NodeComponentProps<ExtendedData, VariableSizeNodePublicState<ExtendedData>>> = ({
    data: { nestingLevel, isLeaf, name, id, hasAlerts, childHasAlerts },
    isOpen,
    setOpen,
    style,
  }) => {
    /* 
      If currently unexpanded, set search text to nil and push id, else 
      get parent folder and expand parent.
    */
    const handleClick = (currentId: string | symbol) => {
      if (currentId !== folderId) {
        const pathName = `/${rootPath}/${String(currentId)}`
        onSelectFolder(String(currentId), pathName)
      }
      setSearchText('')
      setOpen(!isOpen)
    }

    const containerEl = document.querySelector(containerElement)
    const initialWidth =
      document && containerEl
        ? (containerEl?.clientWidth || 0) -
          (parseFloat(getComputedStyle(containerEl).getPropertyValue('padding-left')) +
            parseFloat(getComputedStyle(containerEl).getPropertyValue('padding-right')))
        : 327
    const marginPerLevel = 10
    const width = initialWidth - nestingLevel * marginPerLevel

    return folders.length > 0 ? (
      <StyledContents
        style={{ ...style, paddingLeft: `${nestingLevel * marginPerLevel}px` }}
        active={id === folderId}
        id={`location-node-${id}`}
        className="fs-exclude"
      >
        <StyledItem onClick={() => handleClick(id)} active={id === folderId} isLeaf={isLeaf}>
          <StyledArrowIcon
            src={isOpen ? ArrowDropDown : ArrowRight}
            alt={
              isLeaf
                ? undefined
                : isOpen
                ? 'Location is expanded, select to collapse'
                : 'Location is collapsed, select to expand'
            }
          />

          <Tooltip label={name} ariaLabel={`Name for currently hovered`}>
            <StyledEllipsisOverflow hasAlerts={hasAlerts || (childHasAlerts && !isOpen)} isChild={true} width={width}>
              <HighlightMatch width={width} name={name} searchText={searchText} />
              {(hasAlerts || (childHasAlerts && !isOpen)) && (
                <StyledAlarm>
                  <StyledAlarmScreenReader>
                    {hasAlerts
                      ? 'This location has devices with issues.'
                      : childHasAlerts && !isOpen
                      ? 'There is a location or locations under this location with devices that have issues.'
                      : ''}
                  </StyledAlarmScreenReader>
                </StyledAlarm>
              )}
            </StyledEllipsisOverflow>
          </Tooltip>
        </StyledItem>
      </StyledContents>
    ) : (
      <div />
    )
  }

  const treeWalker = useCallback(
    function* (): ReturnType<TreeWalker<ExtendedData, NodeMeta>> {
      const formatNodeData = (folder: AnnotatedFolder, parent?: NodeMeta) => ({
        data: {
          defaultHeight: itemSize,
          isOpenByDefault:
            defaultOpenPaths.includes(folder.id.toString()) || folder.nameMatchesSearch || folder.childrenContainMatch,
          nestingLevel: parent ? parent.nestingLevel + 1 : 0,

          ...folder,
          isLeaf: folder.children.length === 0,
        },
        nestingLevel: parent ? parent.nestingLevel + 1 : 0,
        node: { children: folder.children, id: folder.id.toString(), name: folder.name } as TreeNode,
      })

      // vtree doesn't handle empty trees very well
      // we need to give the treeWalker function at least one yielded value when we don't want to render the tree
      if (!showTree) {
        yield formatNodeData({ id: '0', name: '0', children: [] as string[] } as AnnotatedFolder)
        return undefined
      }

      // yield all root nodes
      for (const root of rootFolders) {
        const foundFolder = annotatedFolderMap.get(root.id.toString())
        if (!foundFolder) continue

        if (searchText === '' || (searchText && (foundFolder.childrenContainMatch || foundFolder.nameMatchesSearch)))
          yield formatNodeData(foundFolder)
      }

      // loop over all the yielded root nodes above to add the children
      // and keep looping until we yield no more, meaning we have yielded all roots and children and children of children
      let parentMeta: NodeMeta
      // assign parentMeta to the yield'ed value and if that value is undefined, we can stop looping
      // tslint:disable-next-line no-conditional-assignment
      while ((parentMeta = yield) !== undefined) {
        // yield a node for each child that the parent has
        for (const childId of parentMeta.node.children) {
          const foundFolder = annotatedFolderMap.get(childId)
          if (!foundFolder) continue

          if (searchText === '' || (searchText && (foundFolder.childrenContainMatch || foundFolder.nameMatchesSearch)))
            yield formatNodeData(foundFolder, parentMeta)
        }
      }

      return undefined
    },
    [annotatedFolderMap, rootFolders, defaultOpenPaths, searchText, showTree]
  )

  // Change the height of the tree element depending on how many items are visible (number of locations visible * 40px)
  useLayoutEffect(() => {
    if (tree?.current) {
      setTimeout(() => {
        const newHeight = (tree?.current?.state?.order?.length || 0) * itemSize
        if (_isMounted.current) {
          setTreeHeight(prevValue => {
            if (prevValue !== newHeight && newHeight > 0) {
              return newHeight
            }
            return prevValue
          })
        }
      }, 0)
    }
  }, [searchText, tree])

  // Scroll the sidebar scrollable area to the correct location when a location is selected so it is visible
  useEffect(() => {
    if (tree?.current) {
      setTimeout(() => {
        const el = document.getElementById(`location-node-${folderId}`)
        const scrollable = document.getElementById('sidebar-scrollable')
        if (el && scrollable) {
          if (el.offsetTop !== scrollable.scrollTop) {
            scrollable.scrollTop = el.offsetTop
          }
        }
      }, 0)
    }
  }, [folderId, tree])

  const container = document.querySelector(containerElement)
  const resizableWidth = containerElement === '#mobileSidebar' && document && container ? container.clientWidth : 327
  const resizableHeight =
    containerElement === '#mobileSidebar' && document && container
      ? container.clientHeight - 215
      : treeHeight < 330
      ? treeHeight
      : 330

  // The next few lines handle issues when resizing the browser where sizing and truncation of location names get a bit glitchy
  // Create a forceUpdate method so we can re-render the component after a resize; a little bit hacky since functional components don't have this ¯\_(ツ)_/¯
  const [, forceUpdate] = useReducer(() => ({}), {})

  const refreshAfterResize = () => {
    if (_isMounted.current) forceUpdate()
  }

  // Use useRef() hook as value returned by useRef() does not get re-evaluated every time the functional component is executed.
  // Use lodash's debounce so the component doesn't re-render until the user has stopped resizing for a short delay
  const refreshAfterResizeDebounced = useRef(debounce(refreshAfterResize, 250))

  // Listen for the resize event then hook into useRef with .current()
  window.addEventListener('resize', () => {
    refreshAfterResizeDebounced.current()
  })

  return (
    <Aside>
      <StyledDirectoryContainer>
        <StyledSkipLinks
          onClick={() => {
            document.getElementById('navLinks')?.focus()
          }}
        >
          Skip Locations
        </StyledSkipLinks>
        <StyledSkipLinks
          onClick={() => {
            document.getElementById('mainContent')?.setAttribute('tabindex', '-1')
            document.getElementById('mainContent')?.focus()
          }}
        >
          Skip to Main Content
        </StyledSkipLinks>
        <StyledSearchBar>
          <SearchBar searchText={searchText} setSearchText={setSearchText} placeHolderText="Search Locations" />
        </StyledSearchBar>
        {foldersLoading ? (
          <LoadingSidenav />
        ) : (
          <StyledResizable width={resizableWidth} height={resizableHeight} axis="y" resizeHandles={['s']}>
            <StyledScrollable>
              {showTree && (
                <AutoSizer disableWidth={true}>
                  {({ height }) => {
                    return (
                      <VariableSizeTree
                        ref={tree}
                        // @ts-ignore
                        treeWalker={treeWalker}
                        itemData={itemSize}
                        height={!showTree ? 0 : height ?? 0}
                        width="100%"
                      >
                        {Node}
                      </VariableSizeTree>
                    )
                  }}
                </AutoSizer>
              )}
              <StyledNoFoldersFound found={showTree}>There are no matching results</StyledNoFoldersFound>
            </StyledScrollable>
          </StyledResizable>
        )}
      </StyledDirectoryContainer>
    </Aside>
  )
}

export default VirtualDirectory
