import { CSSObject } from 'styled-components'

import { Button, GhostButton, LinkButton, SecondaryButton, TertiaryButton } from '../../shared-styles/button.styles'
import theme from '../../theme'
import { WaitIcon } from '../icon'
import { SecondaryLinkButton } from './../../shared-styles/button.styles'

interface ButtonProps {
  children: React.ReactText | React.ReactElement | React.ReactElement[]
  /** Determines styling of the button */
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'link' | 'secondary-link'
  onClick?: (e: any) => void
  /** Determines whether or not to show the spinner */
  waiting?: boolean
  /** Adds the disabled attribute to the button, and displays button with disabled styling */
  disabled?: boolean
  /** Displays the button with disabled styling, without adding the disabled attribute; not required if using the `disabled` prop */
  disabledStyling?: boolean
  /** Displays the button with red text or background */
  dangerStyling?: boolean
  /** Changes text to be uppercase; does not apply to `link` or `secondary-link` buttons */
  uppercaseStyling?: boolean
  /** This is used in the tertiary button to change the style of the button to show a different active state (example: water shutoff button) */
  secondaryStyling?: boolean
  /** Displays the button as full width instead of constrained by a max-width of 24.4rem */
  fullwidth?: boolean
  style?: CSSObject
  type?: 'button' | 'submit' | 'reset'
  [x: string]: any
}

export const Btn = ({
  children,
  buttonType = 'primary',
  onClick,
  waiting = false,
  disabled = false,
  disabledStyling = false,
  dangerStyling = false,
  uppercaseStyling = true,
  secondaryStyling = false,
  fullwidth = false,
  style,
  type = 'button',
  ...rest
}: ButtonProps) => {
  let spinnerColor = theme.colors.ui01
  if (buttonType === 'secondary' || buttonType === 'ghost' || buttonType === 'link') spinnerColor = theme.colors.brand01
  if (buttonType === 'secondary-link') spinnerColor = theme.colors.support_info
  if (
    (buttonType === 'secondary' ||
      buttonType === 'ghost' ||
      buttonType === 'link' ||
      buttonType === 'secondary-link') &&
    dangerStyling &&
    !disabled &&
    !disabledStyling
  )
    spinnerColor = theme.colors.support_error
  if (
    (buttonType === 'secondary' || buttonType === 'link' || buttonType === 'secondary-link') &&
    !dangerStyling &&
    (disabled || disabledStyling)
  )
    spinnerColor = theme.colors.ui05
  if (buttonType === 'ghost' && !dangerStyling && (disabled || disabledStyling)) spinnerColor = theme.colors.ui04

  return (
    <>
      {buttonType === 'primary' && (
        <Button
          onClick={onClick}
          disabled={disabled}
          disabledStyling={disabledStyling}
          dangerStyling={dangerStyling}
          uppercaseStyling={uppercaseStyling}
          fullwidth={fullwidth}
          waiting={waiting}
          style={style}
          type={type}
          {...rest}
        >
          {waiting ? <WaitIcon fill={spinnerColor} /> : children}
        </Button>
      )}
      {buttonType === 'secondary' && (
        <SecondaryButton
          onClick={onClick}
          disabled={disabled}
          disabledStyling={disabledStyling}
          dangerStyling={dangerStyling}
          uppercaseStyling={uppercaseStyling}
          fullwidth={fullwidth}
          waiting={waiting}
          style={style}
          type={type}
          {...rest}
        >
          {waiting ? <WaitIcon fill={spinnerColor} /> : children}
        </SecondaryButton>
      )}
      {buttonType === 'tertiary' && (
        <TertiaryButton
          onClick={onClick}
          disabled={disabled}
          disabledStyling={disabledStyling}
          dangerStyling={dangerStyling}
          uppercaseStyling={uppercaseStyling}
          secondaryStyling={secondaryStyling}
          fullwidth={fullwidth}
          waiting={waiting}
          style={style}
          type={type}
          {...rest}
        >
          {waiting ? <WaitIcon fill={spinnerColor} /> : children}
        </TertiaryButton>
      )}
      {buttonType === 'ghost' && (
        <GhostButton
          onClick={onClick}
          disabled={disabled}
          disabledStyling={disabledStyling}
          dangerStyling={dangerStyling}
          uppercaseStyling={uppercaseStyling}
          fullwidth={fullwidth}
          waiting={waiting}
          style={style}
          type={type}
          {...rest}
        >
          {waiting ? <WaitIcon fill={spinnerColor} /> : children}
        </GhostButton>
      )}
      {buttonType === 'link' && (
        <LinkButton
          onClick={onClick}
          disabled={disabled}
          disabledStyling={disabledStyling}
          dangerStyling={dangerStyling}
          fullwidth={fullwidth}
          waiting={waiting}
          style={style}
          type={type}
          {...rest}
        >
          {waiting ? <WaitIcon fill={spinnerColor} width={14} height={14} /> : children}
        </LinkButton>
      )}
      {buttonType === 'secondary-link' && (
        <SecondaryLinkButton
          onClick={onClick}
          disabled={disabled}
          disabledStyling={disabledStyling}
          dangerStyling={dangerStyling}
          fullwidth={fullwidth}
          waiting={waiting}
          style={style}
          type={type}
          {...rest}
        >
          {waiting ? <WaitIcon fill={spinnerColor} width={14} height={14} /> : children}
        </SecondaryLinkButton>
      )}
    </>
  )
}

export default Btn
