import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { Alert } from '../../../components/alert'
import { LinkButton } from '../../../shared-styles/button.styles'
import { FormField, Label, Radio, Select, SelectContainer } from '../../../shared-styles/form.styles'
import { H6 } from '../../../shared-styles/typography.styles'
import {
  isGateway,
  isLeakSensor,
  isMotionSensor,
  isNbiotSensor,
  isNonNbiotSensor,
  isProbeSensor,
} from '../../../utils/helpers'
import { getUseCase } from '../../../utils/usecase-matrix'
import GatewayPlacementGuide from '../components/placement-guides/gateway'
import NbiotPlacementGuide from '../components/placement-guides/nbiot'
import PlacementGuide from '../components/placement-guides/placement-guide'
import TemperaturePlacementGuide from '../components/placement-guides/temperature'
import ModalContext from '../modal-context'
import { Checkbox } from './../../../shared-styles/form.styles'
import PipePlacementGuide from './../components/placement-guides/pipe'
import WaterPlacementGuide from './../components/placement-guides/water'

const IntroText = styled.p`
  margin-top: 0;
`

const FormFieldContainer = styled.div`
  max-width: 42.3rem;
`

const AlertContainer = styled.div`
  margin-top: 1.6rem;
`

interface IProps {
  device: IDeviceType
  deviceLocations: string[] | string
  updateSensorProperty: (
    id: number,
    property: 'area' | 'building' | 'floor' | 'deviceLocationNotes' | 'deviceUseCase',
    value: string
  ) => void
}

export const parsedDeviceLocations = (deviceLocations: string | string[]): string[] => {
  if (Array.isArray(deviceLocations)) return deviceLocations
  else {
    return deviceLocations.split(',').map(l => l.trim())
  }
}

export const Step1ChooseLocation = ({ device, deviceLocations, updateSensorProperty }: IProps) => {
  const { setShowModal, setModalIndex } = useContext(ModalContext)

  const [area, setArea] = useState<string | undefined>(device.area)
  const [locationConfirmation, setLocationConfirmation] = useState<boolean>(false)

  // Automatically set the device use case if it has one allowed use case
  useEffect(() => {
    if (device.allowedUseCases.length === 1 && !device.deviceUseCase)
      updateSensorProperty(device.id, 'deviceUseCase', device.allowedUseCases[0])
  }, [device.id, device.allowedUseCases, device.deviceUseCase, updateSensorProperty])

  // Check if the pre-populated location value is included in the list of available device locations
  // If it's not, return an empty string to save to the API
  useEffect(() => {
    setArea(prevValue => {
      if (prevValue && deviceLocations && !deviceLocations.includes(prevValue)) return ''
      return prevValue
    })
  }, [deviceLocations, device.area])

  // Update the sensor's location whenever it changes (must be a string, can be empty)
  useEffect(() => {
    if (typeof area === 'string' && device.area !== area) {
      updateSensorProperty(device.id, 'area', area)
    }
  }, [area, device.id, device.area, updateSensorProperty])

  return (
    <>
      <IntroText>
        Follow the{' '}
        <LinkButton
          onClick={() => {
            setShowModal(true)
            setModalIndex('placement-guide')
          }}
        >
          placement guide
        </LinkButton>{' '}
        to choose the ideal location for your device.
      </IntroText>
      <FormFieldContainer>
        <FormField>
          <Label htmlFor="sensor_location">Device Location</Label>
          <SelectContainer>
            <Select
              id="sensor_location"
              value={area || ''}
              onChange={e => {
                setArea(e.target.value)
              }}
            >
              <option value="">Select a location</option>
              {parsedDeviceLocations(deviceLocations)
                .sort()
                .map((deviceLocation, index) => (
                  <option key={index} value={deviceLocation}>
                    {deviceLocation}
                  </option>
                ))}
            </Select>
          </SelectContainer>
        </FormField>
      </FormFieldContainer>
      {(isNbiotSensor(device.nodeTypeName) || isGateway(device.nodeTypeName)) && (
        <p>
          We recommend that you activate devices at the exact location where you intend to place them to ensure adequate
          signal.
        </p>
      )}
      {isNonNbiotSensor(device.nodeTypeName) && (
        <p>
          We recommend that you activate devices at the exact location where you intend to place them to ensure proper
          communication with the gateway.
        </p>
      )}
      {area && (
        <>
          <Checkbox>
            <input
              type="checkbox"
              onClick={() => {
                setLocationConfirmation(!locationConfirmation)
              }}
            />
            <span>
              <span>I confirm that I am currently in the {area}.</span>
            </span>
          </Checkbox>
          {!locationConfirmation && (
            <AlertContainer>
              <Alert
                icon="solid_info"
                iconAlignment="top"
                message="You can proceed to activation without confirming. However, we cannot guarantee that the signal strength will be the same as the placement location."
                type="info_warning"
              />
            </AlertContainer>
          )}
        </>
      )}

      {device.allowedUseCases.length > 1 ? (
        <>
          <H6 style={{ marginBottom: 0 }}>Choose what to monitor</H6>
          {device.allowedUseCases
            .map(u => getUseCase(u))
            .map(useCase => (
              <div key={useCase.name}>
                <Radio
                  id={`useCase_${useCase.name}`}
                  type="radio"
                  name="useCase"
                  value={useCase.name}
                  defaultChecked={device.deviceUseCase === useCase.name}
                  onChange={e => updateSensorProperty(device.id, 'deviceUseCase', e.target.value)}
                />
                <Label htmlFor={`useCase_${useCase.name}`}>{useCase.vanity}</Label>
                <br />
              </div>
            ))}
        </>
      ) : null}

      {isNbiotSensor(device.nodeTypeName) && (
        <PlacementGuide title="Placement Guide" modalIndexValue="placement-guide">
          <NbiotPlacementGuide />
        </PlacementGuide>
      )}
      {isGateway(device.nodeTypeName) && (
        <PlacementGuide title="Placement Guide" modalIndexValue="placement-guide">
          <GatewayPlacementGuide />
        </PlacementGuide>
      )}
      {isMotionSensor(device.nodeTypeName) && (
        <PlacementGuide title="Placement Guide" modalIndexValue="placement-guide">
          <TemperaturePlacementGuide />
        </PlacementGuide>
      )}
      {isLeakSensor(device.nodeTypeName) && (
        <PlacementGuide title="Placement Guide" modalIndexValue="placement-guide">
          <WaterPlacementGuide />
        </PlacementGuide>
      )}
      {isProbeSensor(device.nodeTypeName) && (
        <PlacementGuide title="Placement Guide" modalIndexValue="placement-guide">
          <PipePlacementGuide />
        </PlacementGuide>
      )}
    </>
  )
}

export default Step1ChooseLocation
