import { useContext, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import { CTASection } from '.'
import Alert from '../../components/alert'
import AddressMap from '../../components/map'
import SkeletonLine from '../../components/skeleton-line'
import { AppContext } from '../../contexts/app-context'
import API from '../../utils/api'
import { hasMultiFolderAccess } from '../../utils/helpers'
import {
  AddressContainer,
  AddressContent,
  AddressHeader,
  AddressSection,
  AddressSubheader,
  AlertContainer,
  Instructions,
  MapSection,
  StepHeader,
  StepSubheader,
} from './onboarding.styles'

const ConfirmAddress = ({
  parentLoading = false,
  folder,
  onNext,
}: {
  parentLoading?: boolean
  folder: IFolderType
  onNext: () => void
}) => {
  const handleError = useErrorHandler()
  const { folders } = useContext(AppContext)

  const [buttonWaiting, setButtonWaiting] = useState<boolean>(false)

  const onContinue = async () => {
    try {
      setButtonWaiting(true)
      await API.post(`/api/v2/protect/confirm-address/${folder.id}`, {})
      onNext()
      setButtonWaiting(false)
    } catch (e) {
      handleError(e as unknown as Error)
      setButtonWaiting(false)
    }
  }

  return (
    <>
      <StepHeader data-cy="firstStep">Step 1 of 3</StepHeader>
      <StepSubheader data-cy="confirmLocationTitle">Confirm Your Address</StepSubheader>
      <Instructions>Please review the address we have on file for the location of your sensors.</Instructions>

      <AddressContainer loading={parentLoading} className="fs-exclude">
        {!parentLoading ? (
          <>
            <AddressSection>
              <AddressHeader data-cy="folderName">{folder.name}</AddressHeader>
              <AddressSubheader>Address</AddressSubheader>
              <AddressContent data-cy="address">
                {folder.information.address.address}
                <br />
                {folder.information.address.address2 && (
                  <>
                    {folder.information.address.address2}
                    <br />
                  </>
                )}
                {folder.information.address.locality}, {folder.information.address.region}{' '}
                {folder.information.address.code}
                <br />
                {folder.information.address.country}
              </AddressContent>
            </AddressSection>
            <MapSection>
              {folder && folder.location && (
                <AddressMap lat={Number(folder.location.lat)} lng={Number(folder.location.lng)} />
              )}
            </MapSection>
          </>
        ) : (
          <SkeletonLine height="250px" />
        )}
      </AddressContainer>
      <AlertContainer>
        <Alert
          type="custom"
          backgroundcolor="rgba(51, 135, 178, 0.1)"
          icon="solid_info"
          iconAlignment="top"
          message="If you need to make any changes, please contact your insurance provider or Sensor Support at (844) 468-1866 Monday – Friday,
8 a.m. – 8 p.m. Eastern Time"
        />
      </AlertContainer>

      <CTASection
        folder={folder}
        hasMultiFolderAccess={hasMultiFolderAccess(folders)}
        step={1}
        showBackButton={false}
        onPrimaryClick={() => onContinue()}
        buttonWaiting={buttonWaiting}
        disabled={parentLoading}
      />
    </>
  )
}

export default ConfirmAddress
