import theme from '../../theme'
import Icon, {
  CheckIcon,
  CheckIconNoCircle,
  CheckIconOutline,
  ErrorIcon,
  InfoIcon,
  SolidInfoIcon,
  WaitIcon,
  WarningIcon,
} from '../icon'
import {
  StyledAction,
  StyledActionSeparator,
  StyledAlert,
  StyledContent,
  StyledIcon,
  StyledMessage,
} from './alert.styles'

export type AlertTypes =
  | 'offline'
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'info_success'
  | 'info_support'
  | 'info_warning'
  | 'info_error'
  | 'info_permission_denied'
  | 'custom'

interface Actions {
  text: string
  onClick: () => void
}

interface IProps {
  /** Controls background color and overall styling of the alert.
   */
  type:
    | 'offline'
    | 'success'
    | 'warning'
    | 'error'
    | 'info'
    | 'info_success'
    | 'info_support'
    | 'info_warning'
    | 'info_error'
    | 'info_permission_denied'
    | 'custom'
  icon: 'check' | 'check_nocircle' | 'check_outline' | 'warning' | 'error' | 'info' | 'solid_info' | 'wait'
  message: string
  /** Actions are an array of objects in the following format:
   * {
   *    text: string,
   *    onClick: () => void
   * }
   */
  actions?: Actions[]
  /** Used with "custom" `type` */
  backgroundcolor?: string
  iconAlignment?: 'top' | 'center'
}

export const Alert = ({ type, icon, message, actions, backgroundcolor, iconAlignment = 'center' }: IProps) => {
  let displayIcon: React.ReactNode
  switch (icon) {
    case 'check':
      displayIcon = CheckIcon
      break
    case 'check_nocircle':
      displayIcon = CheckIconNoCircle
      break
    case 'check_outline':
      displayIcon = CheckIconOutline
      break
    case 'warning':
      displayIcon = WarningIcon
      break
    case 'error':
      displayIcon = ErrorIcon
      break
    case 'info':
      displayIcon = InfoIcon
      break
    case 'solid_info':
      displayIcon = SolidInfoIcon
      break
    case 'wait':
      displayIcon = WaitIcon
      break
  }
  return (
    <StyledAlert alerttype={type} backgroundcolor={backgroundcolor}>
      <StyledIcon iconAlignment={iconAlignment} alerttype={type}>
        <Icon
          render={displayIcon}
          fill={
            type === 'info_success' || type === 'info_support' || type === 'info_error'
              ? theme.colors.ui01
              : type === 'info_warning' || type === 'info_permission_denied'
              ? theme.colors.support_warning
              : undefined
          }
        />
      </StyledIcon>
      <StyledMessage>
        <StyledContent>
          {message}
          {actions &&
            actions.length > 0 &&
            actions.map((action, index) => {
              return (
                <span key={index}>
                  {index > 0 && <StyledActionSeparator>/</StyledActionSeparator>}
                  <StyledAction alerttype={type} onClick={action.onClick}>
                    {action.text}
                  </StyledAction>
                </span>
              )
            })}
        </StyledContent>
      </StyledMessage>
    </StyledAlert>
  )
}

export default Alert
