const PowerOutageIcon = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 2C18.8019 2.00259 18.6126 2.08244 18.4725 2.22253C18.3324 2.36263 18.2526 2.55189 18.25 2.75V8H5.75C5.55189 8.00259 5.36263 8.08244 5.22253 8.22253C5.08244 8.36263 5.00259 8.55189 5 8.75V21.25C5.00259 21.4481 5.08244 21.6374 5.22253 21.7775C5.36263 21.9176 5.55189 21.9974 5.75 22H18.25C18.4481 21.9974 18.6374 21.9176 18.7775 21.7775C18.9176 21.6374 18.9974 21.4481 19 21.25V11.8C19.1989 11.8 19.3897 11.721 19.5303 11.5803C19.671 11.4397 19.75 11.2489 19.75 11.05V2.75C19.7474 2.55189 19.6676 2.36263 19.5275 2.22253C19.3874 2.08244 19.1981 2.00259 19 2ZM17.5 20.25C17.5 20.3163 17.4737 20.3799 17.4268 20.4268C17.3799 20.4737 17.3163 20.5 17.25 20.5H6.75C6.6837 20.5 6.62011 20.4737 6.57322 20.4268C6.52634 20.3799 6.5 20.3163 6.5 20.25V9.75C6.5 9.6837 6.52634 9.62011 6.57322 9.57322C6.62011 9.52634 6.6837 9.5 6.75 9.5H17.25C17.3163 9.5 17.3799 9.52634 17.4268 9.57322C17.4737 9.62011 17.5 9.6837 17.5 9.75V20.25Z"
        fill={color}
      />
      <path
        d="M13.75 17.0696C13.2186 16.7607 12.6146 16.5985 12 16.5996C11.3868 16.6001 10.7841 16.7585 10.25 17.0596C10.1482 17.1111 10.0626 17.1897 10.0026 17.2868C9.94261 17.3838 9.91056 17.4955 9.90997 17.6096C9.90996 17.6879 9.92555 17.7655 9.95582 17.8377C9.98609 17.91 10.0305 17.9755 10.0863 18.0304C10.1422 18.0853 10.2084 18.1286 10.2811 18.1576C10.3539 18.1867 10.4317 18.2009 10.51 18.1996C10.6158 18.2041 10.7205 18.1762 10.81 18.1196C11.17 17.9062 11.5815 17.7955 12 17.7996C12.4099 17.8026 12.8124 17.9093 13.17 18.1096C13.2645 18.1737 13.3758 18.2085 13.49 18.2096C13.6491 18.2096 13.8017 18.1464 13.9142 18.0339C14.0268 17.9214 14.09 17.7687 14.09 17.6096C14.091 17.4965 14.0596 17.3854 13.9993 17.2897C13.939 17.1939 13.8525 17.1175 13.75 17.0696Z"
        fill={color}
      />
      <path
        d="M16.2 12.93C14.9258 12.1846 13.4763 11.7912 12 11.79C10.5331 11.7942 9.09252 12.1802 7.82002 12.91C7.72885 12.9603 7.65288 13.0342 7.6001 13.1239C7.54731 13.2136 7.51965 13.3159 7.52002 13.42C7.52002 13.5792 7.58324 13.7318 7.69576 13.8443C7.80828 13.9568 7.96089 14.02 8.12002 14.02C8.21364 14.0352 8.30951 14.0284 8.40002 14C9.49356 13.3652 10.7355 13.0308 12 13.0308C13.2645 13.0308 14.5065 13.3652 15.6 14C15.6914 14.0521 15.7948 14.0797 15.9 14.08C15.9788 14.08 16.0568 14.0645 16.1296 14.0344C16.2024 14.0042 16.2686 13.96 16.3243 13.9043C16.38 13.8486 16.4242 13.7824 16.4544 13.7096C16.4845 13.6369 16.5 13.5588 16.5 13.48C16.5079 13.3693 16.4839 13.2585 16.4307 13.161C16.3775 13.0636 16.2974 12.9834 16.2 12.93Z"
        fill={color}
      />
      <path
        d="M15 14.9998C14.0899 14.4672 13.0545 14.1865 12 14.1865C10.9456 14.1865 9.91012 14.4672 9.00003 14.9998C8.90797 15.0541 8.83181 15.1316 8.77916 15.2247C8.7265 15.3177 8.69922 15.4229 8.70003 15.5298C8.70003 15.6916 8.7643 15.8467 8.87869 15.9611C8.99309 16.0755 9.14825 16.1398 9.31003 16.1398C9.42422 16.1387 9.53553 16.1039 9.63003 16.0398C10.3496 15.6208 11.1674 15.4001 12 15.4001C12.8327 15.4001 13.6505 15.6208 14.37 16.0398C14.4645 16.1039 14.5758 16.1387 14.69 16.1398C14.8214 16.1341 14.9476 16.0868 15.0503 16.0047C15.1531 15.9226 15.227 15.8099 15.2616 15.683C15.2961 15.5561 15.2893 15.4215 15.2423 15.2987C15.1953 15.1759 15.1105 15.0712 15 14.9998Z"
        fill={color}
      />
    </svg>
  )
}

export default PowerOutageIcon
