import styled from 'styled-components/macro'

interface ContentSectionProps {
  verticallyCenter?: boolean
  height?: string
  theme: IThemeProps
}
export const ContentSection = styled.section<ContentSectionProps>`
  box-sizing: border-box;
  background-color: transparent;
  height: ${(props: ContentSectionProps) => (props.height ? props.height : '100%')};
  padding: 27px 16px;
  width: 100%;
  display: ${(props: ContentSectionProps) => (props.verticallyCenter ? 'flex' : 'block')};
  justify-content: ${(props: ContentSectionProps) => (props.verticallyCenter ? 'center' : '')};
  flex-direction: ${(props: ContentSectionProps) => (props.verticallyCenter ? 'column' : '')};
  position: absolute;
  top: 6.3rem;
  left: 0;
  font-family: ${(props: ContentSectionProps) => props.theme.fontFamily};
`

export const InstructionalText = styled.p`
  margin: 0 auto;
  margin-bottom: 27px;
  width: 100%;
  max-width: 630px;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`

export const InstructionalImageContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const ErrorReasonText = styled.p`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  text-align: center;
  max-width: 60rem;
  margin: 0 auto;
`
