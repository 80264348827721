const ActivationHelp = () => {
  return (
    <div>
      <p>
        If you need help, please contact Sensor Support at{' '}
        <a href="mailto:sensorsupport@hsb.com">sensorsupport@hsb.com</a> or (844) 468-1866 | Monday – Friday, 8am – 8pm
        Eastern Time
      </p>
    </div>
  )
}

export default ActivationHelp
