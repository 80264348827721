import { PanelBulletList, PanelSubHeader } from './placement-guides.styles'

const WaterPlacementGuide = () => {
  return (
    <>
      <PanelSubHeader>Water Sensor</PanelSubHeader>
      <p>The water sensor is used to detect the presence of unwanted water.</p>
      <p>Tips for placement:</p>
      <PanelBulletList>
        <li>On the floor away from foot traffic</li>
        <li>Next to the boiler</li>
        <li>Near a hot water heater, a sump pump, or in a low area where water can collect</li>
      </PanelBulletList>
    </>
  )
}

export default WaterPlacementGuide
