import '@reach/dialog/styles.css'
import { navigate, RouteComponentProps } from '@reach/router'
import { VisuallyHidden } from '@reach/visually-hidden'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import queryString from 'query-string'
import { useEffect, useState } from 'react'

import Alert from '../../components/alert'
import Btn from '../../components/button'
import DisplayPasswordReqValidationResult from '../../components/display-password-validation-result'
import PasswordField from '../../components/password-field'
import ResetPasswordModal, { ResetFormValues } from '../../components/reset-password-modal'
import { Error } from '../../shared-styles/alert.styles'
import { LinkButton } from '../../shared-styles/button.styles'
import { StyledForm } from '../../shared-styles/form.styles'
import { CenteredButtonWrapper, Checkbox, FormField } from '../../shared-styles/form.styles'
import { DesktopOnly } from '../../shared-styles/responsive.styles'
import {
  MobileSplash,
  Splash,
  SplashAgreementText,
  SplashButtonContainer,
  SplashContentContainer,
  SplashGrid,
  SplashPageSubTitle,
  SplashPageTitle,
} from '../../shared-styles/splashscreen.styles'
import { AgreementLink } from '../../shared-styles/typography.styles'
import API from '../../utils/api'
import { PRIVACY_POLICY } from '../../utils/constants'
import { TERMS_AND_CONDITIONS } from '../../utils/constants'
import { newPasswordReqValidation } from '../../utils/validators'
import { isTokenExpired } from '../reset-password/helpers'

interface FormValues {
  newPassword: string
  agreementAccepted: boolean
}

const SetPassword = (props: RouteComponentProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorCount, setErrorCount] = useState<number>(0)
  const [bounceError, setBounceError] = useState(false)
  const [requestNewTokenLink, setRequestNewTokenLink] = useState<boolean>(false)
  const [resetEmailSent, setResetEmailSent] = useState<string | undefined>()
  const [showDialog, setShowDialog] = useState<boolean>(false)

  useEffect(() => {
    if (props && props.location) {
      const queryParams = queryString.parse(props.location.search)
      const token = queryParams.ref as string
      if (!token || isTokenExpired(token)) {
        setErrorMessage('Your set password link is expired or invalid.')
        setRequestNewTokenLink(true)
      }
    }
  }, [props])

  const initialValues = {
    newPassword: '',
    agreementAccepted: false,
  }

  const displayErrorMessage = () => {
    if (errorMessage) return errorMessage
    return false
  }

  const handleSubmitError = (message: string) => {
    setErrorMessage(message)
    setErrorCount(errorCount + 1)
    if (errorCount > 0) setBounceError(true)
  }

  const onSubmit = async ({ newPassword }: FormValues) => {
    setErrorCount(0)
    try {
      if (props && props.location) {
        const queryParams = queryString.parse(props.location.search)
        const token = queryParams.ref

        // Post to API to reset password
        const res = await API.put(`/api/passwords/changepasswordwithtoken`, {
          newPassword,
          token,
        })
        // Redirect to login page with success message
        if (res.status === 200) navigate('/login?message=account-creation-successful')
      } else {
        handleSubmitError('Something was wrong in our system.')
      }
    } catch (e) {
      if (!window.navigator.onLine) {
        handleSubmitError('There is no internet connection. Please check your network.')
      } else if (((e as AxiosError).response?.data as ApiV1Error)?.detail === 'weak passwords are not allowed') {
        handleSubmitError('The password provided is too common, please try again.')
      } else {
        handleSubmitError('Something was wrong in our system.')
      }

      setTimeout(() => setBounceError(false), 1000)
    }
  }

  const onResetPasswordSubmit = async ({ resetEmail }: ResetFormValues) => {
    // Call API to send reset e-mail
    try {
      await API.get(`/api/passwords/resetpassword?email=${encodeURIComponent(resetEmail)}`)

      setResetEmailSent(resetEmail)
      setErrorMessage(null)
    } catch (e) {
      setErrorMessage('Failed to send reset password email.')
    } finally {
      setShowDialog(false)
    }
  }

  return (
    <SplashGrid>
      <MobileSplash>
        <div>
          <SplashPageTitle>
            <strong>Meshify</strong> Protect
          </SplashPageTitle>
        </div>
      </MobileSplash>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <SplashContentContainer>
              <DesktopOnly>
                <SplashPageTitle data-cy="meshify-protect">
                  <strong data-cy="loginPageTitle">Meshify</strong> Protect
                </SplashPageTitle>
              </DesktopOnly>
              <SplashPageSubTitle>Set Password</SplashPageSubTitle>
              {displayErrorMessage() && (
                <Error className={bounceError ? 'animated bounce' : ''}>{displayErrorMessage()}</Error>
              )}

              {requestNewTokenLink && !resetEmailSent && (
                <SplashButtonContainer>
                  <CenteredButtonWrapper>
                    <LinkButton type="button" onClick={() => setShowDialog(true)}>
                      Request new link
                    </LinkButton>
                  </CenteredButtonWrapper>
                </SplashButtonContainer>
              )}
              {resetEmailSent && (
                <FormField>
                  <Alert type="info_support" icon="check_nocircle" message={`Email sent to ${resetEmailSent}`} />
                </FormField>
              )}

              {!requestNewTokenLink && (
                <>
                  <PasswordField
                    name="newPassword"
                    label="New Password"
                    onChange={value => setFieldValue('newPassword', value)}
                    onBlur={handleBlur}
                    errorState={false}
                  />

                  <DisplayPasswordReqValidationResult
                    newPasswordValidationResult={newPasswordReqValidation(values.newPassword)}
                    newPasswordLength={values.newPassword.length}
                  />

                  <FormField>
                    <Checkbox>
                      <input
                        aria-label="agreementAccepted"
                        type="checkbox"
                        onClick={() => setFieldValue('agreementAccepted', !values.agreementAccepted)}
                      />
                      <span>
                        <SplashAgreementText>
                          I agree to the{' '}
                          <AgreementLink href={PRIVACY_POLICY} target="_blank" rel="noopener">
                            Privacy Policy<VisuallyHidden>(PDF)</VisuallyHidden>
                          </AgreementLink>{' '}
                          and to the{' '}
                          <AgreementLink href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener">
                            Terms &amp; Conditions<VisuallyHidden>(PDF)</VisuallyHidden>
                          </AgreementLink>
                        </SplashAgreementText>
                      </span>
                    </Checkbox>
                  </FormField>

                  <Btn
                    type="submit"
                    fullwidth={true}
                    waiting={isSubmitting}
                    disabled={!newPasswordReqValidation(values.newPassword).valid || !values.agreementAccepted}
                  >
                    Save Password
                  </Btn>
                </>
              )}
            </SplashContentContainer>
          </StyledForm>
        )}
      </Formik>

      <ResetPasswordModal
        title="Reset Password"
        openModal={showDialog}
        onDismiss={() => {
          setShowDialog(false)
        }}
        onResetPasswordSubmit={onResetPasswordSubmit}
      />
      <Splash />
    </SplashGrid>
  )
}

export default SetPassword
