import { navigate } from '@reach/router'
import debounce from 'lodash/debounce'
import { useContext, useEffect, useRef, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import { AppContext } from '../../contexts/app-context'
import AuthenticationContext from '../../contexts/auth-context'
import API from '../../utils/api'
import { Container, StopButton, UserNameSpan } from './impersonate-banner.styles'

/**
 * The ImpersonateBanner component is used in private routes to show who is being impersonated
 */
export const ImpersonateBanner = () => {
  const { setUserToImpersonate } = useContext(AuthenticationContext)
  const { user, setUser } = useContext(AppContext)
  const handleError = useErrorHandler()
  const [buttonWaiting, setButtonWaiting] = useState<boolean>(false)

  const onUnimpersonate = async () => {
    try {
      setButtonWaiting(true)
      const originalUser = await API.get('/api/users/unimpersonate')
      setUser(originalUser.data)
      setUserToImpersonate(undefined)
      navigate('/login-redirect')
    } catch (e) {
      handleError(e)
    }
  }

  const updateWrapperMargin = () => {
    const bannerHeight = document?.getElementById('impersonateBanner')?.offsetHeight
    const wrapper = document?.getElementById('privateRouteWrapper')
    if (wrapper) wrapper.style.marginTop = bannerHeight + 'px'
  }

  useEffect(() => {
    updateWrapperMargin()
  }, [])

  // After resizing, wait a bit then refresh the wrapper margin
  const refreshMarginAfterResizeDebounced = useRef(
    debounce(() => {
      updateWrapperMargin()
    }, 250)
  )

  // Listen for the resize event then hook into useRef with .current()
  window.addEventListener('resize', () => {
    refreshMarginAfterResizeDebounced.current()
  })

  return (
    <Container id="impersonateBanner">
      <div>
        <strong>Viewing as:</strong>{' '}
        <UserNameSpan className="fs-exclude">
          {user?.information?.first} {user?.information?.last}
        </UserNameSpan>
      </div>
      <div>
        <StopButton onClick={() => onUnimpersonate()} waiting={buttonWaiting}>
          Stop
        </StopButton>
      </div>
    </Container>
  )
}

export default ImpersonateBanner
