import TroubleshootingPanel from './troubleshooting-panel'
import { Hr, TroubleshootingContent } from './troubleshooting-panels.styles'

export const TroubleshootingPanelConnectionErrorContent = () => {
  return (
    <TroubleshootingContent>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>
          Press and hold the button on the bottom of the sensor for 2-4 seconds and then release to attempt to
          reconnect.
        </li>
        <li>
          If the lights aren’t turning on, or the beep isn’t occurring, remove the sensor cover by twisting the base
          until the unlock icon is aligned with the notch near it.
        </li>
        <li>Check that the plastic tab in the battery compartment has been removed and attempt to connect again.</li>
        <li>If the sensor is still unable to connect, contact the Sensor Support Team for more support.</li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelConnectionError = () => {
  return (
    <TroubleshootingPanel title="Connection Error" modalIndexValue="connection_error">
      <TroubleshootingPanelConnectionErrorContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowBatteryContent = () => {
  return (
    <TroubleshootingContent>
      <p>You can continue activation, but replace battery as soon as possible.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>
          Remove the sensor cover by twisting the base until the unlock icon on the bottom of the sensor is aligned with
          the notch near it.
        </li>
        <li>
          Check that the batteries are inserted correctly, and contact points are clean (try rolling the batteries
          inside the battery compartment).
        </li>
        <li>Connect the sensor again by pressing the button for between 2 and 4 seconds.</li>
        <li>
          If batteries are still showing low, replace both batteries with two new Lithium Ion AA size batteries, and
          reconnect the sensor.
        </li>
        <li>If batteries are still showing low, contact the Sensor Support Team for more support.</li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowBattery = () => {
  return (
    <TroubleshootingPanel title="Low Battery" modalIndexValue="low_battery">
      <TroubleshootingPanelLowBatteryContent />
    </TroubleshootingPanel>
  )
}

export const TroubleshootingPanelLowSignalContent = () => {
  return (
    <TroubleshootingContent>
      <p>You can continue activation, but poor signal may impact battery life or sensor connectivity.</p>
      <p>
        <strong>How to Resolve</strong>
      </p>
      <ul>
        <li>Move closer to the gateway and press the button for between 2 and 4 seconds to try to connect again.</li>
        <li>
          Check the area for items that may block the signal such as large metallic objects, refrigeration units, or
          areas with high signal interference (i.e. network closets), and make sure they are not between the sensor and
          the gateway.
        </li>
      </ul>
      <Hr />
      <p>
        For further assistance, please contact Sensor Support at (844) 468-1866 Monday – Friday, 8 a.m. – 8 p.m. Eastern
        Time
      </p>
    </TroubleshootingContent>
  )
}

export const TroubleshootingPanelLowSignal = () => {
  return (
    <TroubleshootingPanel title="Poor Signal" modalIndexValue="low_signal">
      <TroubleshootingPanelLowSignalContent />
    </TroubleshootingPanel>
  )
}
