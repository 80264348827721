import moment from 'moment'
import momenttz from 'moment-timezone'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr-ca'
import { useCallback, useContext, useEffect, useState } from 'react'

import { AppContext } from '../contexts/app-context'

export const useDates = () => {
  const { user } = useContext(AppContext)

  // since changing moment properties won't cause a natural re-render,
  // we need to force the hook to rerender with the updated languages.
  const [, setRefresh] = useState<number>(0)

  useEffect(() => {
    if (user?.information?.timezone) {
      momenttz.tz.setDefault(user.information.timezone)
    } else {
      momenttz.tz.setDefault()
    }
    setRefresh((v: number) => v + 1)
  }, [user?.information?.timezone])

  // TODO: implement locale from transifex if user has picked something other than English; for now, set to English
  // useEffect(() => {
  //   if (currentLocale) {
  //     try {
  //       moment.locale(currentLocale)
  //     } catch {
  //       // Do nothing
  //     }
  //     setRefresh(v => v + 1)
  //   }
  // }, [currentLocale])
  useEffect(() => {
    try {
      moment.locale('en')
    } catch {
      // Do nothing
    }
    setRefresh((v: number) => v + 1)
  }, [])

  const localeTimeFormat = (moment().set('hour', 14).set('minute', 14).format('LT') === '14:14'
    ? '24hr'
    : '12hr') as TimeFormat

  const formatCalendarDate = (date: string) => {
    if (!date) return undefined

    const today = '[Today ·] '
    const tomorrow = '[Tomorrow ·] '
    const yesterday = '[Yesterday ·] '

    return user?.preferences?.dateFormat === 'DD.MM.YYYY' ||
      (!user?.preferences?.dateFormat && localeTimeFormat === '24hr')
      ? moment(date).calendar({
          sameDay: `${today}D MMMM`,
          nextDay: `${tomorrow}D MMMM`,
          nextWeek: 'D MMMM',
          lastDay: `${yesterday}D MMMM`,
          lastWeek: 'D MMMM',
          sameElse: 'D MMMM',
        })
      : moment(date).calendar({
          sameDay: `${today}MMMM D`,
          nextDay: `${tomorrow}MMMM D`,
          nextWeek: 'MMMM D',
          lastDay: `${yesterday}MMMM D`,
          lastWeek: 'MMMM D',
          sameElse: 'MMMM D',
        })
  }

  const formatDate = (date: string) => {
    if (!date) return undefined

    const format =
      user?.preferences?.dateFormat === 'DD.MM.YYYY'
        ? 'DD.MM.yyyy'
        : user?.preferences?.dateFormat === 'MM-DD-YYYY'
        ? 'MM-DD-yyyy'
        : 'L' // Default to locale aware date format

    return moment(date).format(format)
  }

  const formatDateTime = (date: string) => {
    if (!date) return undefined

    const dateFormat =
      user?.preferences?.dateFormat === 'DD.MM.YYYY'
        ? 'DD.MM.yyyy'
        : user?.preferences?.dateFormat === 'MM-DD-YYYY'
        ? 'MM-DD-yyyy'
        : 'L' // Default to locale aware date format
    const timeFormat =
      user?.preferences?.timeFormat === '24hr'
        ? 'HH:mm:ss'
        : user?.preferences?.timeFormat === '12hr'
        ? 'hh:mm:ss A'
        : 'LTS' // Default to locale aware time format

    return moment(date).format(dateFormat + ' ' + timeFormat)
  }

  const formatDateShortTime = (date: string) => {
    if (!date) return undefined

    const dateFormat =
      user?.preferences?.dateFormat === 'DD.MM.YYYY'
        ? 'DD.MM.yyyy'
        : user?.preferences?.dateFormat === 'MM-DD-YYYY'
        ? 'MM-DD-yyyy'
        : 'L' // Default to locale aware date format
    const timeFormat =
      user?.preferences?.timeFormat === '24hr' ? 'HH:mm' : user?.preferences?.timeFormat === '12hr' ? 'hh:mm A' : 'LTS' // Default to locale aware time format

    return moment(date).format(dateFormat + ' ' + timeFormat)
  }

  const formatDateTimeShortMonth = (date: string) => {
    if (!date) return undefined

    const dateFormat =
      user?.preferences?.dateFormat === 'DD.MM.YYYY'
        ? 'D MMM yyyy'
        : user?.preferences?.dateFormat === 'MM-DD-YYYY'
        ? 'MMM D yyyy'
        : 'LL' // Default to locale aware date format with month name
    const timeFormat =
      user?.preferences?.timeFormat === '24hr'
        ? 'HH:mm:ss'
        : user?.preferences?.timeFormat === '12hr'
        ? 'hh:mm:ss A'
        : 'LT' // Default to locale aware time format

    return moment(date).format(dateFormat + ' ' + timeFormat)
  }

  const formatDateShortMonth = (date: string) => {
    if (!date) return undefined

    const format =
      user?.preferences?.dateFormat === 'DD.MM.YYYY'
        ? 'D MMM yyyy'
        : user?.preferences?.dateFormat === 'MM-DD-YYYY'
        ? 'MMM D, yyyy'
        : 'LL' // Default to locale aware date format with month name

    return moment(date).format(format)
  }

  const formatDateDayMonth = (date: string) => {
    if (!date) return undefined

    const format =
      user?.preferences?.dateFormat === 'DD.MM.YYYY'
        ? 'D/M'
        : user?.preferences?.dateFormat === 'MM-DD-YYYY'
        ? 'M/D'
        : 'l' // Default to locale aware date format with month name

    // trim year off the beginning or end if we don't know the user preference
    let formattedDate = moment(date).format(format)
    if (format === 'l') {
      const year = moment(date).format('Y')
      formattedDate = formattedDate.replace(new RegExp(`.${year}`), '')
      formattedDate = formattedDate.replace(new RegExp(`${year}.`), '')
    }

    return formattedDate
  }

  const formatTime = (date: string) => {
    if (!date) return undefined

    const format =
      user?.preferences?.timeFormat === '24hr' ? 'HH:mm' : user?.preferences?.timeFormat === '12hr' ? 'h:mm A' : 'LT' // Default to locale aware time format

    return moment(date).format(format)
  }

  return {
    formatCalendarDate,
    formatDate,
    formatDateTime,
    formatDateShortTime,
    formatTime,
    formatDateTimeShortMonth,
    formatDateShortMonth,
    formatDateDayMonth,
    localeTimeFormat,
  }
}

export const useTemperatures = () => {
  const { user } = useContext(AppContext)
  const tempUnits = user?.preferences?.tempUnits === 'degC' ? 'C' : 'F'
  const tempFormat = (user?.preferences?.tempUnits === 'degC' ? 'degC' : 'degF') as TemperatureFormat

  const formatTemperature = useCallback(
    (temp: number, rounding: 'tenths' | 'integer' = 'tenths', fromUnit = 'degF', showUnit = true) => {
      let temperature = temp
      if (tempFormat === 'degC' && fromUnit === 'degF') {
        temperature = (temp - 32) * (5 / 9)
      }
      if (tempFormat === 'degF' && fromUnit === 'degC') {
        temperature = temp * (9 / 5) + 32
      }
      const roundedTemp = rounding === 'integer' ? Math.round(temperature) : Math.round(temperature * 10) / 10

      return showUnit ? `${roundedTemp}°` : roundedTemp
    },
    [tempFormat]
  )

  return { formatTemperature, tempUnits, tempFormat }
}
