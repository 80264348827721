import styled from 'styled-components/macro'

export const PanelSubHeader = styled.h4`
  width: 100%;
  text-align: left;
  margin-bottom: 0;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
`

export const PanelBulletList = styled.ul`
  padding-left: 1.6rem;
  margin-top: -1.2rem;
`

export const PanelContent = styled.div`
  height: calc(100vh - 4.4rem - 1.6rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  border-top: 0.5px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  margin: 0 -1.6rem -1.6rem -1.6rem;

  button {
    width: 100%;
    margin: 0;
    padding: 1.2rem;
  }
`
