import { useContext } from 'react'

import Sidepanel from '../../../../components/sidepanel'
import ModalContext from '../../modal-context'

const TroubleshootingPanel = ({
  children,
  title,
  modalIndexValue,
}: {
  children: React.ReactNode
  title: string
  modalIndexValue: string
}) => {
  const { showModal, setShowModal, modalIndex } = useContext(ModalContext)
  return (
    <Sidepanel
      title={title}
      showMobileTitleAboveDivider={true}
      titleSize="large"
      openModal={showModal && modalIndex === modalIndexValue}
      onDismiss={() => {
        setShowModal(false)
        // This fixes a bug where user ends up at bottom of the screen after dismissing the modal
        // A bit hacky, think about how to improve...
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 0)
      }}
      variation="support"
    >
      {children}
    </Sidepanel>
  )
}

export default TroubleshootingPanel
