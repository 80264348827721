import { Formik } from 'formik'
import * as Yup from 'yup'

import { Button } from '../../shared-styles/button.styles'
import { ModalForm } from '../../shared-styles/form.styles'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import FormFieldInput from '../form-field-input'
import Modal from '../modal'

const ResetValidationSchema = Yup.object().shape({
  resetEmail: Yup.string().required('This field is required.').email('Invalid email address.'),
})

interface ResetPasswordModalProps {
  title: string
  openModal: boolean
  onDismiss: () => void
  onResetPasswordSubmit: (formValues: ResetFormValues) => void
}

export interface ResetFormValues {
  resetEmail: string
}

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  return (
    <Modal
      openModal={props.openModal}
      onDismiss={props.onDismiss}
      title={props.title}
      maxWidth="72.2rem"
      padding="1.875rem 6.4rem 5.4rem 6.4rem"
    >
      <DesktopOnly>
        {props.title === 'Reset Password' ? (
          <>
            <p>
              Enter the email address associated with your account. If your email address exists in our system, a
              password reset email will be sent. If you do not receive an email, please call our Sensor Support Team at
              (844) 468-1866
            </p>
            <p>Note: The password reset link will only be valid for 30 minutes.</p>
          </>
        ) : (
          <p>Enter the email address associated with your account.</p>
        )}
      </DesktopOnly>
      <MobileOnly>
        {props.title === 'Reset Password' ? (
          <>
            <p>
              Enter the email address associated with your account. If your email address exists in our system, a
              password reset email will be sent.
            </p>
            <p>If you do not receive an email, please call our Sensor Support Team at (844) 468-1866</p>
            <p>Note: The password reset link will only be valid for 30 minutes.</p>
          </>
        ) : (
          <p>Enter the email address associated with your account.</p>
        )}
      </MobileOnly>
      <Formik
        initialValues={{ resetEmail: '' }}
        validationSchema={ResetValidationSchema}
        onSubmit={props.onResetPasswordSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <ModalForm onSubmit={handleSubmit}>
            <FormFieldInput
              id="resetEmail"
              type="email"
              label="Email"
              formikData={{ values, touched, errors, handleChange, handleBlur }}
              data-testid="resetEmail"
              data-cy="emailResetInput"
            />
            <Button type="submit" fullwidth={true} data-testid="submit_reset" data-cy="resetPasswordBtn">
              {props.title === 'Reset Password' ? 'Reset Password' : 'Request Link'}
            </Button>
          </ModalForm>
        )}
      </Formik>
    </Modal>
  )
}

export default ResetPasswordModal
