import LocationCard from '../../components/location-card'
import { Instructions, StepSubheader } from '../../routes/onboarding/onboarding.styles'
import { LocationCardsContainer } from './location-cards.styles'

const LocationCards = ({ showHeader = true, folders }: { showHeader?: boolean; folders: IGenericFolderType[] }) => {
  const foldersToOnboard = folders.filter(f => (!f.isActivated || !f.isOnboarded) && f.folderType === 'Participant')

  return (
    <>
      {showHeader && <StepSubheader>Your Locations</StepSubheader>}
      <Instructions>
        You have access to multiple locations. Please select the one you would like to view or edit.
      </Instructions>
      <LocationCardsContainer>
        {foldersToOnboard.map((folder, i) => (
          <LocationCard
            key={`folder-${i}`}
            name={folder.name}
            status={
              folder.orderStatus === 'shipped' && folder.isOnboarded
                ? 'In Transit'
                : !folder.isOnboarded
                ? 'Not Complete'
                : 'In Progress'
            }
            bodyText={
              folder.orderStatus === 'shipped' && folder.isOnboarded
                ? 'Your sensors are on their way. Track your shipment to see the delivery status.'
                : !folder.isOnboarded
                ? 'Spend 10 mins to confirm information and add notification contacts to your location.'
                : 'Simply power up the device and add its location. On average, it takes 2-5 mins per device.'
            }
            buttonText={
              folder.orderStatus === 'shipped' && folder.isOnboarded
                ? 'Track Shipment'
                : !folder.isOnboarded
                ? 'Set up location'
                : 'Activate Devices'
            }
            buttonLink={
              folder.orderStatus === 'shipped' && folder.isOnboarded
                ? folder.trackingUrl || '#'
                : !folder.isOnboarded
                ? `/onboarding/1/location/${folder.id}`
                : `/activating/${folder.id}`
            }
            openInNewTab={!!(folder.orderStatus === 'shipped' && folder.isOnboarded)}
          />
        ))}
      </LocationCardsContainer>
    </>
  )
}

export default LocationCards
