import '@reach/dialog/styles.css'
import { VisuallyHidden } from '@reach/visually-hidden'
import { useEffect, useState } from 'react'

import theme from '../../theme'
import { ChevronIcon } from '../icon'
import {
  ModalBackButton,
  ModalCloseButton,
  ModalCloseButtonContainer,
  ModalContainer,
  ModalContent,
  ModalTitle,
  ModalTitleAboveDivider,
} from './modal.styles'

interface ModalProps {
  children: React.ReactNode
  /** Modal title, also used to label the modal for accessibility */
  title: string
  /** Boolean that controls when the modal should be open or closed (true is open)
   */
  openModal: boolean
  /** Method to invoke when the modal is dismissed by clicking out of it or with the close button (X) */
  onDismiss: () => void
  /** Determines if the close button (X) should appear */
  showClose?: boolean
  showCloseMobile?: boolean
  width?: string
  maxWidth?: string
  minHeight?: string
  height?: string
  padding?: string
  mobilePadding?: string
  background?: string
  mobileBackground?: string
  margin?: string
  roundedCorners?: boolean
  roundedCornersMobile?: boolean
  shadows?: boolean
  textalign?: 'left' | 'right' | 'center'
  showTitle?: boolean
  showTitleAboveDivider?: boolean
  showDivider?: boolean
  showDividerMobile?: boolean
  showBackButton?: boolean
  showBackButtonMobile?: boolean
  backButtonUppercase?: boolean
  /** Text string to display for back button */
  backButtonText?: string
  /** Method to invoke when the back button is clicked. Is used when `showBackButton` set to `true` */
  onBack?: () => void
}

export const Modal = ({
  children,
  title,
  openModal,
  onDismiss,
  showClose = true,
  showCloseMobile = true,
  width = '50vw',
  maxWidth = '41rem',
  minHeight = '0',
  height = 'auto',
  padding = '1.875rem',
  mobilePadding = '1.6rem',
  background = theme.colors.ui01,
  mobileBackground = theme.colors.ui01,
  margin = '10vh auto',
  roundedCorners = false,
  roundedCornersMobile = false,
  shadows = false,
  textalign = 'center',
  showTitle = true,
  showTitleAboveDivider = false,
  showDivider = false,
  showDividerMobile = false,
  showBackButton = false,
  showBackButtonMobile = false,
  backButtonUppercase = true,
  backButtonText = 'Back',
  onBack,
  ...rest
}: ModalProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(openModal)

  useEffect(() => {
    setShowDialog(openModal)
  }, [openModal])

  const closeDialog = () => {
    setShowDialog(false)
    onDismiss()
  }

  return (
    <ModalContainer
      allowPinchZoom={true}
      isOpen={showDialog}
      onDismiss={closeDialog}
      aria-labelledby="modalTitle"
      width={width}
      maxwidth={maxWidth}
      minheight={minHeight}
      height={height}
      padding={padding}
      mobilepadding={mobilePadding}
      margin={margin}
      roundedcorners={roundedCorners.toString()}
      roundedcornersmobile={roundedCornersMobile.toString()}
      displayshadows={shadows.toString()}
      background={background}
      mobilebackground={mobileBackground}
      data-cy="onboardStartModal"
      {...rest}
    >
      <ModalCloseButtonContainer
        showDivider={showDivider}
        showDividerMobile={showDividerMobile}
        showBackButton={showBackButton}
        showCloseMobile={showCloseMobile}
      >
        {(showBackButton || showBackButtonMobile) && onBack && (
          <ModalBackButton
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              onBack()
            }}
            showBackButton={showBackButton}
            backButtonUppercase={backButtonUppercase}
          >
            <ChevronIcon />
            <span>{backButtonText}</span>
          </ModalBackButton>
        )}
        {showTitleAboveDivider && !showBackButton && !onBack && (
          <ModalTitleAboveDivider>{title}</ModalTitleAboveDivider>
        )}
        <ModalCloseButton onClick={closeDialog} showClose={showClose} showCloseMobile={showCloseMobile}>
          <VisuallyHidden>Close</VisuallyHidden>
          <span aria-hidden={true}>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="black"
              />
            </svg>
          </span>
        </ModalCloseButton>
      </ModalCloseButtonContainer>
      {showTitle && !showTitleAboveDivider && <ModalTitle id="modalTitle">{title}</ModalTitle>}
      {!showTitle && !showTitleAboveDivider && (
        <VisuallyHidden>
          <ModalTitle id="modalTitle">{title}</ModalTitle>
        </VisuallyHidden>
      )}
      <ModalContent textalign={textalign} showClose={showClose} showCloseMobile={showCloseMobile}>
        {children}
      </ModalContent>
    </ModalContainer>
  )
}

export default Modal
