import SensorSelect from '../../../../components/sensor-select'
import { useStatusText } from '../../methods/customHooks'

interface UngroupedDeviceProps {
  device: IDeviceType
  setShowModal: (arg: boolean) => void
  setSelectedCardKey: (arg: number | undefined) => void
  setDevice: (arg: IDeviceType | undefined) => void
}

/** Display a card for a single device and a modal for the device details */
const UngroupedDevice = ({ device, setShowModal, setSelectedCardKey, setDevice }: UngroupedDeviceProps) => {
  return (
    <SensorSelect
      id={device.id}
      useCase={device.deviceUseCase}
      name={device.vanity}
      subText={device.area}
      statusText={useStatusText(device)}
      onSelect={() => {
        setShowModal(true)
        setSelectedCardKey(device.id)
        setDevice(device)
      }}
      alertCount={device.activeAlertsCount}
    />
  )
}

export default UngroupedDevice
