import { PanelBulletList, PanelSubHeader } from './placement-guides.styles'

const GatewayPlacementGuide = () => {
  return (
    <>
      <PanelSubHeader>Gateway</PanelSubHeader>
      <p>The Gateway (and its antenna) connects you to the sensors.</p>
      <p>Tips for placement:</p>
      <PanelBulletList>
        <li>Place it where there is a cell signal&mdash;near a window, for example</li>
        <li>Never place it in a basement</li>
        <li>Using the power cord provided, plug the gateway into an outlet not connected to a light switch</li>
      </PanelBulletList>
    </>
  )
}

export default GatewayPlacementGuide
