import axios, { CancelTokenSource } from 'axios'
import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import AddressMap from '../../../../components/map'
import Sidepanel from '../../../../components/sidepanel'
import { GhostButton } from '../../../../shared-styles/button.styles'
import { SecondaryLinkA } from '../../../../shared-styles/link.styles'
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  TableHeaderButtonContainer,
  Td,
  TdAlignRight,
  TdNoPadding,
} from '../../../../shared-styles/table.styles'
import API from '../../../../utils/api'
import { cleanAddress, LM_ROLE, parseNotificationPreferences } from '../../../../utils/helpers'
import { useDates } from '../../../../utils/preference-hooks'
import HeaderContext from '../../sections/header/context'
import { SkeletonLine } from './../../../../components/skeleton-line/index'
import { AddressName, AddressParagraph } from './account.styles'

interface DetailsData {
  accountNumber: string
  status: string
  agreementSignedBy: string
  agreementSignedDate: string
  installOption: string
}

interface OrderItem {
  orderNumber: string
  orderStatus: string
  lastUpdated: string
  trackingUrls: string[]
  information: {
    first: string
    last: string
    address: {
      locality: string
      region: string
      code: string
      address: string
      address2: string
      country: string
    }
  }
}

interface OrderTrackingItem extends OrderItem {
  trackingUrl: string | undefined
}

interface ReturnItem {
  returnNumber: string
  returnStatus: string
  lastUpdated: string
}

const AccountModal = ({
  selectedLocation,
  manageContacts,
}: {
  selectedLocation: IFolderType
  manageContacts: () => void
}) => {
  const handleError = useErrorHandler()
  const { formatDateTime } = useDates()
  const { showAccountModal, showBackButton, setShowAccountModal, setShowBackButton } = useContext(HeaderContext)

  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const [contacts, setContacts] = useState<INotificationContactsType>()
  const [accountDetails, setAccountDetails] = useState<DetailsData | undefined>()
  const [orders, setOrders] = useState<OrderTrackingItem[]>([])
  const [returns, setReturns] = useState<ReturnItem[]>([])
  const [locationManagers, setLocationManagers] = useState<INotificationContactType[] | undefined>()
  const [notificationContacts, setNotificationContacts] = useState<INotificationContactType[] | undefined>()

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      // clean up axios calls when component unmounts
      source.cancel('cancelled')
    }
  }, [source])

  const fetchData = useCallback(async () => {
    try {
      const apiData = (
        await API.get(`/api/v2/protect/folders/${selectedLocation.id}/notification-contacts`, {
          cancelToken: source.token,
        })
      ).data as INotificationContactsAPIType

      const data: INotificationContactsType = {
        ...apiData,
        users: apiData.users.map(u => {
          const contact: INotificationContactType = {
            ...u,
            doNotContact: !!(
              parseNotificationPreferences(u.user.preferences.notification).length === 0 && u.user.phone
            ),
          }
          return contact
        }),
      }
      setContacts(data)
      setLocationManagers(data.users.filter(u => u.user.role.name === LM_ROLE))
      setNotificationContacts(data.users.filter(u => u.user.role.name === 'Notification Contact'))
      const apiDetailsData = (
        await API.get(`/api/v2/protect/folders/${selectedLocation.id}/account-details`, {
          cancelToken: source.token,
        })
      ).data as DetailsData
      setAccountDetails(apiDetailsData)
      const apiOrderData = (
        await API.get(`/api/v2/protect/folders/${selectedLocation.id}/order-history`, {
          cancelToken: source.token,
        })
      ).data
      const newOrders: OrderTrackingItem[] = []
      apiOrderData.orders.forEach((order: OrderItem) =>
        order.trackingUrls.length > 0
          ? order.trackingUrls.forEach(url => newOrders.push({ ...order, trackingUrl: url }))
          : newOrders.push({ ...order, trackingUrl: undefined })
      )
      setOrders(newOrders)
      setReturns(apiOrderData.returns)
    } catch (e) {
      if (e.message !== 'cancelled') handleError(e as unknown as Error)
    }
  }, [selectedLocation.id, handleError, source])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onDismiss = () => {
    viewAccount()
    setShowAccountModal(false)
  }

  const onBack = () => {
    viewAccount()
  }

  const viewAccount = () => {
    setShowBackButton(false)
  }

  const formatAddress = (order: OrderItem) => {
    const { first, last } = order.information
    const { address, address2, locality, region, country, code } = order.information.address
    return cleanAddress(
      `${first} ${last}
${address}
${address2}
${locality}, ${region} ${country} ${code}`,
      country
    )
  }

  return (
    <Sidepanel
      title="Account"
      titleSize="large"
      openModal={showAccountModal}
      onDismiss={onDismiss}
      showBackButton={showBackButton}
      backButtonText="Back to Account"
      onBack={onBack}
    >
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Address</StyledTh>
          </tr>
        </thead>
        <tbody className="fs-exclude">
          {selectedLocation.location && (
            <tr>
              <TdNoPadding>
                <div style={{ height: '20rem' }}>
                  <AddressMap
                    lat={Number(selectedLocation.location.lat)}
                    lng={Number(selectedLocation.location.lng)}
                    modalIsOpen={false}
                  />
                </div>
              </TdNoPadding>
            </tr>
          )}
          <tr>
            <Td>
              <AddressParagraph>
                <AddressName>{selectedLocation.name}</AddressName>
                <br />
                {selectedLocation.information.address.address}
                <br />
                {selectedLocation.information.address.address2 && (
                  <>
                    {selectedLocation.information.address.address2}
                    <br />
                  </>
                )}
                {selectedLocation.information.address.locality}, {selectedLocation.information.address.region},{' '}
                {selectedLocation.information.address.code} {selectedLocation.information.address.country}
              </AddressParagraph>
            </Td>
          </tr>
        </tbody>
      </StyledTable>

      <StyledTable>
        <thead>
          <tr>
            <StyledTh>
              <TableHeaderButtonContainer>
                <div>Contacts</div>
                <div>
                  <GhostButton onClick={() => manageContacts()}>Manage</GhostButton>
                </div>
              </TableHeaderButtonContainer>
            </StyledTh>
          </tr>
        </thead>
        {contacts ? (
          <StyledTbody className="fs-exclude">
            {locationManagers && locationManagers.length > 0 && (
              <tr>
                <TdAlignRight>
                  Location Manager
                  {locationManagers.length > 1 && <>s</>}:
                </TdAlignRight>
                <StyledTd>
                  {locationManagers.map(u =>
                    u.user.information?.first && u.user.information?.last ? (
                      <div key={u.user.id}>
                        {u.user.information?.first} {u.user.information?.last}
                      </div>
                    ) : (
                      ''
                    )
                  )}
                </StyledTd>
              </tr>
            )}
            {notificationContacts && notificationContacts.length > 0 && (
              <tr>
                <TdAlignRight>
                  Notification Contact
                  {notificationContacts.length > 1 && <>s</>}:
                </TdAlignRight>
                <StyledTd>
                  {notificationContacts.map(u =>
                    u.user.information?.first && u.user.information?.last ? (
                      <div key={u.user.id}>
                        {u.user.information?.first} {u.user.information?.last}
                      </div>
                    ) : (
                      ''
                    )
                  )}
                </StyledTd>
              </tr>
            )}
          </StyledTbody>
        ) : (
          <StyledTbody>
            <tr>
              <TdAlignRight>
                <SkeletonLine />
              </TdAlignRight>
              <StyledTd>
                <SkeletonLine width="220px" />
                <p>
                  <SkeletonLine width="220px" />
                </p>
                <p>
                  <SkeletonLine width="220px" />
                </p>
              </StyledTd>
            </tr>
          </StyledTbody>
        )}
      </StyledTable>

      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Account Details</StyledTh>
            <StyledTh />
          </tr>
        </thead>
        {accountDetails ? (
          <StyledTbody className="fs-exclude">
            <tr>
              <TdAlignRight>Account Number:</TdAlignRight>
              <StyledTd>{accountDetails.accountNumber}</StyledTd>
            </tr>
            <tr>
              <TdAlignRight>Account Status:</TdAlignRight>
              <StyledTd>{accountDetails.status}</StyledTd>
            </tr>
            <tr>
              <TdAlignRight>Participant Agreement:</TdAlignRight>
              {accountDetails.agreementSignedBy ? (
                <StyledTd>
                  Signed on {formatDateTime(accountDetails.agreementSignedDate)} by {accountDetails.agreementSignedBy}
                </StyledTd>
              ) : (
                <StyledTd>N/A</StyledTd>
              )}
            </tr>
            <tr>
              <TdAlignRight>Install Option:</TdAlignRight>
              <StyledTd>{accountDetails.installOption}</StyledTd>
            </tr>
          </StyledTbody>
        ) : (
          <StyledTbody>
            <tr>
              <TdAlignRight>Account Number:</TdAlignRight>
              <StyledTd>
                <SkeletonLine width="220px" />
              </StyledTd>
            </tr>
            <tr>
              <TdAlignRight>Account Status:</TdAlignRight>
              <StyledTd>
                <SkeletonLine width="220px" />
              </StyledTd>
            </tr>
            <tr>
              <TdAlignRight>Participant Agreement:</TdAlignRight>
              <StyledTd>
                <SkeletonLine width="220px" />
              </StyledTd>
            </tr>
            <tr>
              <TdAlignRight>Install Option:</TdAlignRight>
              <StyledTd>
                <SkeletonLine width="220px" />
              </StyledTd>
            </tr>
          </StyledTbody>
        )}
      </StyledTable>

      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Orders</StyledTh>
            <StyledTh />
          </tr>
        </thead>
        <StyledTbody className="fs-exclude">
          {orders.map(order => {
            return (
              <Fragment key={order.orderNumber}>
                <tr>
                  <TdAlignRight>Order Number:</TdAlignRight>
                  <StyledTd>{order.orderNumber || <>&nbsp;</>}</StyledTd>
                </tr>
                {order.orderStatus === 'Shipped' && order.trackingUrl ? (
                  <tr>
                    <TdAlignRight>Order Shipment:</TdAlignRight>
                    <StyledTd>
                      <SecondaryLinkA href={order.trackingUrl} aria-label="View Tracking Details (Offsite Link)">
                        Track shipment
                      </SecondaryLinkA>
                    </StyledTd>
                  </tr>
                ) : (
                  <tr>
                    <TdAlignRight>Order Status:</TdAlignRight>
                    <StyledTd>{order.orderStatus || <>&nbsp;</>}</StyledTd>
                  </tr>
                )}
                <tr>
                  <TdAlignRight>Order Address:</TdAlignRight>
                  <StyledTd>{formatAddress(order) || <>&nbsp;</>}</StyledTd>
                </tr>
                <tr>
                  <TdAlignRight>Last Update:</TdAlignRight>
                  <StyledTd>{order.lastUpdated ? formatDateTime(order.lastUpdated) : <>&nbsp;</>}</StyledTd>
                </tr>
              </Fragment>
            )
          })}
          {orders.length === 0 && (
            <tr>
              <StyledTd>No order history found.</StyledTd>
            </tr>
          )}
        </StyledTbody>
      </StyledTable>

      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Returns</StyledTh>
            <StyledTh />
          </tr>
        </thead>
        <StyledTbody className="fs-exclude">
          {returns.map(order => {
            return (
              <Fragment key={order.returnNumber}>
                <tr>
                  <TdAlignRight>Return Number:</TdAlignRight>
                  <StyledTd>{order.returnNumber || <>&nbsp;</>}</StyledTd>
                </tr>
                <tr>
                  <TdAlignRight>Return Status:</TdAlignRight>
                  <StyledTd>{order.returnStatus || <>&nbsp;</>}</StyledTd>
                </tr>
                <tr>
                  <TdAlignRight>Last Update:</TdAlignRight>
                  <StyledTd>{order.lastUpdated ? formatDateTime(order.lastUpdated) : <>&nbsp;</>}</StyledTd>
                </tr>
              </Fragment>
            )
          })}
          {returns.length === 0 && (
            <tr>
              <StyledTd>No return history found.</StyledTd>
            </tr>
          )}
        </StyledTbody>
      </StyledTable>
    </Sidepanel>
  )
}

export default AccountModal
