import axios, { CancelTokenSource } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useSnackbar } from 'react-simple-snackbar'

import { AddContactIcon, ServiceDeskIcon } from '../../../../components/icon'
import NotificationContactForm from '../../../../components/notification-contact-form'
import Sidepanel from '../../../../components/sidepanel'
import { AppContext } from '../../../../contexts/app-context'
import { MobileOnly } from '../../../../shared-styles/responsive.styles'
import API from '../../../../utils/api'
import { SIDEPANEL_SUCCESS_OPTS } from '../../../../utils/constants'
import { parseNotificationPreferences, SD_ROLE } from '../../../../utils/helpers'
import HeaderContext from '../../sections/header/context'
import NotificationContactsList from './../../../../components/notification-contacts-list/index'
import {
  AddContactButton,
  NotificationContainer,
  NotificationDesktopButtonContainer,
  NotificationHeader,
  NotificationHeaderContainer,
  NotificationMobileButtonContainer,
  NotificationMobileButtonContainerMinimized,
} from './contacts.styles'

const ContactsModal = ({ selectedLocation }: { selectedLocation: IFolderType }) => {
  const _isMounted = useRef(true)
  const handleError = useErrorHandler()
  const { user } = useContext(AppContext)
  const { showContactsModal, setShowContactsModal, setShowBackButton } = useContext(HeaderContext)
  const [openSnackbar, closeSnackbar] = useSnackbar(SIDEPANEL_SUCCESS_OPTS)

  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const [contacts, setContacts] = useState<INotificationContactsType>()
  const [editUserInfo, setEditUserInfo] = useState<INotificationContactType | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [showContactsFormModal, setShowContactsFormModal] = useState<boolean>(false)
  const [selectedServiceDesk, setSelectedServiceDesk] = useState<boolean>(false)

  const folderId = selectedLocation.id
  const hasServiceDesk = !!contacts?.users?.find(contact => contact.user.role.name === SD_ROLE)
  const receiveAlertsCount = useMemo(
    () => contacts?.users.filter(c => c.user.preferences.notification?.length > 0).length || 0,
    [contacts?.users]
  )

  const fetchUsers = useCallback(async () => {
    try {
      const notificationContactsResponse = (
        await API.get(`/api/v2/protect/folders/${folderId}/notification-contacts`, {
          cancelToken: source.token,
        })
      ).data as INotificationContactsAPIType

      const data: INotificationContactsType = {
        ...notificationContactsResponse,
        users: notificationContactsResponse.users.map(u => {
          const contact: INotificationContactType = {
            ...u,
            doNotContact: !!(
              parseNotificationPreferences(u.user.preferences.notification).length === 0 && u.user.phone
            ),
          }
          return contact
        }),
      }
      setContacts(data)
    } catch (e) {
      handleError((e as unknown) as Error)
    } finally {
      setLoading(false)
    }
  }, [folderId, handleError, source])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      source.cancel('cancelled') // clean up axios calls when component unmounts
      _isMounted.current = false // used to handle memory leaks when performing a state change when the component has already unmounted
    }
  }, [source])

  const onDismiss = () => {
    if (_isMounted.current) {
      setShowContactsModal(false)
      setShowBackButton(false)
      setEditUserInfo(undefined)
      closeSnackbar()
    }
  }

  const onBack = (message: string) => {
    setEditUserInfo(undefined)
    setShowContactsFormModal(false)
    if (message) {
      openSnackbar(message)
      fetchUsers()
    }
  }

  const startEditing = (type: 'service-desk' | 'contact') => {
    setSelectedServiceDesk(type === 'service-desk')
    setShowContactsFormModal(true)
  }

  const startEditingServiceDesk = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    startEditing('service-desk')
  }

  const startEditingContact = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    startEditing('contact')
  }

  const bothButtons = (
    <>
      {!hasServiceDesk && (
        <AddContactButton onClick={startEditingServiceDesk}>
          <ServiceDeskIcon />
          <div>Add Service Desk</div>
        </AddContactButton>
      )}
      <AddContactButton onClick={startEditingContact}>
        <AddContactIcon />
        <div>Add Person</div>
      </AddContactButton>
    </>
  )

  return (
    <Sidepanel
      title="Contacts"
      showTitle={!showContactsFormModal}
      openModal={showContactsModal}
      onDismiss={onDismiss}
      showBackButton={showContactsFormModal}
      onBack={onBack}
    >
      {!showContactsFormModal && (
        <>
          <NotificationContainer>
            <NotificationHeaderContainer>
              <NotificationHeader aria-hidden="true">Contacts</NotificationHeader>
              {contacts?.canAddUsers && (
                <>
                  <NotificationDesktopButtonContainer>{bothButtons}</NotificationDesktopButtonContainer>
                  <NotificationMobileButtonContainerMinimized>
                    {!hasServiceDesk && (
                      <AddContactButton onClick={startEditingServiceDesk} aria-label="Add Service Desk">
                        <ServiceDeskIcon />
                      </AddContactButton>
                    )}
                    <AddContactButton onClick={startEditingContact} aria-label="Add Person">
                      <AddContactIcon />
                    </AddContactButton>
                  </NotificationMobileButtonContainerMinimized>
                </>
              )}
            </NotificationHeaderContainer>
            <NotificationContactsList
              contacts={contacts?.users ?? []}
              onSelectContact={contact => {
                setEditUserInfo(contact)
                startEditing('contact')
              }}
              loading={loading}
            />
            {contacts?.canAddUsers && (
              <MobileOnly>
                <NotificationMobileButtonContainer>{bothButtons}</NotificationMobileButtonContainer>
              </MobileOnly>
            )}
          </NotificationContainer>
        </>
      )}
      {showContactsFormModal && user && contacts && (
        <NotificationContactForm
          onBack={onBack}
          user={user}
          folder={selectedLocation}
          editUserInfo={editUserInfo}
          canAddUsers={contacts.canAddUsers}
          serviceDeskContact={selectedServiceDesk}
          receiveAlertsCount={receiveAlertsCount}
          userCount={contacts?.users.length || 0}
        />
      )}
    </Sidepanel>
  )
}

export default ContactsModal
