import { useContext } from 'react'

// import Sidebar from "../../sections/sidebar";
import UserContext from '../../../../contexts/user-context'
import { VanishingSideBarWrapper, VanishingSideBarWrapperBackdrop } from '../../dashboard.styles'
import SidePanelLeft from '../../sections/sidePanelLeft'
import './floating-sidebar.css'

const FloatingSidebar = () => {
  const { displayFloatingSidebar, setDisplayFloatingSidebar } = useContext(UserContext)

  let vanishingBackDrop = () => <></>
  let sidePanelClasses = 'side-panel-drawer'

  if (displayFloatingSidebar) {
    sidePanelClasses = 'side-panel-drawer open'
    vanishingBackDrop = () => {
      return (
        <VanishingSideBarWrapperBackdrop
          onClick={() => {
            setDisplayFloatingSidebar(false)
          }}
        />
      )
    }
  }

  return (
    <>
      <VanishingSideBarWrapper className={sidePanelClasses}>
        <SidePanelLeft showSidebar={true} />
      </VanishingSideBarWrapper>
      {vanishingBackDrop()}
    </>
  )
}

export default FloatingSidebar
