import * as Sentry from '@sentry/browser'
import { CancelTokenSource } from 'axios'
import libphonenumber from 'google-libphonenumber'
import { omit } from 'lodash'
import moment from 'moment'

import AmbientIcon from '../assets/use-cases/ambient'
import BoilerBlowdownIcon from '../assets/use-cases/boiler-blowdown'
import ColdStorageIcon from '../assets/use-cases/cold-storage'
import FineWineIcon from '../assets/use-cases/fine-wine'
import FirePumpIcon from '../assets/use-cases/fire-pump'
import FreezerIcon from '../assets/use-cases/freezer'
import LeakFreezeIcon from '../assets/use-cases/leak-freeze'
import PipeIcon from '../assets/use-cases/pipe'
import PowerOutageIcon from '../assets/use-cases/power-outage'
import RefrigeratorIcon from '../assets/use-cases/refrigerator'
import ServerRoomMonitoringIcon from '../assets/use-cases/server-room-monitoring'
import WaterIcon from '../assets/use-cases/water'
import WaterShutoffIcon from '../assets/use-cases/water-shutoff'
import theme from '../theme'
import GatewayIcon from './../assets/use-cases/gateway'
import API from './api'

const PNF = libphonenumber.PhoneNumberFormat
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const LM_ROLE = 'Location Manager'
export const SD_ROLE = 'Call Center'

export const hasPermissions = (
  permission: string,
  permissions: IPermissionType[],
  roles: IRoleType[],
  user: IUserType
) => {
  const permissionToCheck = permissions.find(p => p.name === permission)
  const userRole = roles.find(r => r.id === user.roleId)
  if (userRole && permissionToCheck && userRole.permissions.includes(permissionToCheck.id)) {
    return true
  }
  return false
}

export const truncateText = (str: string, count: number) => {
  if (str.length <= count) return str
  const subStr = str.substr(0, count - 1)
  return subStr.substr(0, subStr.lastIndexOf(' ')) + ' ...' // use word boundary when truncating
}

export const makeEllipsis = (value?: string, count?: number): string => {
  if (!!value && value.length >= (count || 25)) {
    return value.substring(0, count || 25) + ' ...'
  }
  return value || ''
}

export const parseSensorResponse = (ruleName: string) => {
  if (ruleName.includes('Low Signal Strength')) {
    return 'low_signal'
  } else if (
    ruleName.includes('Low Battery Level') ||
    ruleName.includes('Battery Has Been Depleted') ||
    ruleName.includes('Battery Levels Are Nearly Depleted')
  ) {
    return 'low_batt'
  } else if (ruleName.includes('Gateway Has Switched to Battery')) {
    return 'on_batt'
  }
  return
}

export const getUseCaseIcon = (type: DeviceUseCaseType | '', color: string = theme.colors.brand01) => {
  let icon: React.ReactNode | undefined
  switch (type) {
    case 'ambient':
      icon = AmbientIcon({ color })
      break
    case 'boiler-blowdown':
      icon = BoilerBlowdownIcon({ color })
      break
    case 'cold-storage':
      icon = ColdStorageIcon({ color })
      break
    case 'fine-wine':
      icon = FineWineIcon({ color })
      break
    case 'fire-pump':
      icon = FirePumpIcon({ color })
      break
    case 'freezer':
      icon = FreezerIcon({ color })
      break
    case 'gateway':
      icon = GatewayIcon({ color })
      break
    case 'leak-freeze':
      icon = LeakFreezeIcon({ color })
      break
    case 'pipe-freeze':
      icon = PipeIcon({ color })
      break
    case 'power-outage':
      icon = PowerOutageIcon({ color })
      break
    case 'refrigerator':
      icon = RefrigeratorIcon({ color })
      break
    case 'server-room-monitoring':
      icon = ServerRoomMonitoringIcon({ color })
      break
    case 'water-shutoff':
      icon = WaterShutoffIcon({ color })
      break
    case 'water':
      icon = WaterIcon({ color })
      break
    default:
      icon = null
      break
  }
  return icon
}

export const isNonNbiotSensor = (nodeTypeName: string | undefined) =>
  nodeTypeName === 'motion' ||
  nodeTypeName === 'motion2' ||
  nodeTypeName === 'temp3' ||
  nodeTypeName === 'leak' ||
  nodeTypeName === 'leak2' ||
  nodeTypeName === 'leak3' ||
  nodeTypeName === 'probe' ||
  nodeTypeName === 'probe2' ||
  nodeTypeName === 'bvs' ||
  nodeTypeName === 'cxamnas1' ||
  nodeTypeName === 'whiskerting'

export const isGateway = (nodeTypeName: string | undefined) => nodeTypeName === 'gateway'
export const isMotionSensor = (nodeTypeName: string | undefined) =>
  nodeTypeName === 'motion' || nodeTypeName === 'motion2' || nodeTypeName === 'temp3'
export const isLeakSensor = (nodeTypeName: string | undefined) =>
  nodeTypeName === 'leak' || nodeTypeName === 'leak2' || nodeTypeName === 'leak3'
export const isProbeSensor = (nodeTypeName: string | undefined) => nodeTypeName === 'probe' || nodeTypeName === 'probe2'
export const isNbiotSensor = (nodeTypeName: string | undefined) => nodeTypeName === 'nbiot_leak'
export const isWaterValve = (nodeTypeName: string | undefined) => nodeTypeName === 'bvs'

// Use this method when saving a phone number to the API
// The phone number should include the country code; if it doesn't, we'll try to assume the country code is +1 (US)
// If the number is still not valid, return the same number back
export const formatIntlPhoneNumber = (phoneNumber: string) => {
  try {
    return phoneUtil.format(phoneUtil.parse(phoneNumber), PNF.INTERNATIONAL)
  } catch {
    try {
      return phoneUtil.format(phoneUtil.parse(`+1 ${phoneNumber}`), PNF.INTERNATIONAL)
    } catch {
      return phoneNumber
    }
  }
}

// Use this method when displaying a phone number
// The phone number should include the country code; if it doesn't, we'll try to assume the country code is +1 (US)
// If the number is still not valid, return the same number back
export const prettifyPhoneNumber = (phoneNumber: string) => {
  try {
    return phoneUtil.format(phoneUtil.parse(phoneNumber), PNF.NATIONAL)
  } catch {
    try {
      return phoneUtil.format(phoneUtil.parse(`+1 ${phoneNumber}`), PNF.NATIONAL)
    } catch {
      return phoneNumber
    }
  }
}

export const errorHandler = (error: Error, info: any) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key])
      })
      Sentry.captureException(error)
    })
  }
}

export const hasMultiFolderAccess = (folders: IGenericFolderType[]) => {
  return folders.length > 1
}

/** Determine if we have collected all the location information we need for this device */
export const deviceDataIsComplete = (device: IDeviceType) =>
  !!device.deviceUseCase && !!device.area && !!device.deviceLocationNotes

/** Determine if the device has communicated within the last 15 minutes */
export const deviceIsSearching = (device: IDeviceType) =>
  !device.lastCheckIn || (!!device.lastCheckIn && moment(device.lastCheckIn).add(15, 'minutes').isBefore(moment()))

export const formatDevice = (apiData: IDeviceTypeAPI): IDeviceType => {
  return {
    ...omit(apiData, 'node'),
    ...apiData.node,
    area: apiData.node.area || apiData.node.metadata.device_location || '',
    deviceLocationNotes: apiData.node.metadata.device_location_note || '',
    activeAlertsCount: apiData.alerts.filter(a => a.status === 'active').length,
  }
}

export const cleanAddress = (address: string, country = '') => {
  if (country === 'United States' || country === 'USA') {
    address = address
      .replace(' United States ', ' ')
      .replace(' United States', '')
      .replace(' USA ', ' ')
      .replace(' USA', '')
  }

  // reduce last line to nothing if locality, region, country, code does not exist
  address = address.replace(/\n(\s*,\s*)/, '\n')
  // reduce multiple new lines to single new line
  address = address.replace(/\n\n/g, '\n')
  // reduce all multiple spaces to single space
  address = address.replace(/[ ][ ]+/g, ' ')
  // remove floating comma at the beginning
  address = address.replace(/^,[ ]+/g, '')
  // remove all extra white space at the beginning/end
  address = address.trim()
  // trim extra comma at the end if locality exists but not region, country, code
  address = address.replace(/,$/, '')

  return address
}

export const parseNotificationPreferences = (preferences: string | string[] | undefined) => {
  return JSON.parse(JSON.stringify(preferences || []))
}

/**
 * Return the check in interval in seconds
 *
 * This is needed to calculate nextCheckIn after a websocket message is received
 */
export const getCheckInInterval = (nodeTypeName: string, intervalChannel: string | TempReportConfigChannel) => {
  if (!intervalChannel || (nodeTypeName === 'nbiot_leak' && !(intervalChannel as TempReportConfigChannel)?.intervals))
    return
  else {
    if (nodeTypeName === 'nbiot_leak') return Math.max(...(intervalChannel as TempReportConfigChannel).intervals)
    else return Number((intervalChannel as string).split(',')[0])
  }
}

export const getDevices = async (folderId: number, isActivating = false, source: CancelTokenSource) => {
  const devices: IDeviceTypeAPI[] = []

  const getDevicesApi = async (offset = 0): Promise<IDeviceType[]> => {
    const response = (
      await API.get(`/api/v2/protect/folders/${folderId}/devices?isActivating=${isActivating}&offset=${offset}`, {
        cancelToken: source.token,
      })
    ).data // Limit is 100 by default
    devices.push(...(response.devices as IDeviceTypeAPI[]))
    if (response.hasMore) {
      // Call the function again (recursively continue to fetch devices until hasMore is false)
      return await getDevicesApi(offset + 100)
    } else {
      // Return the data
      return devices.map(d => ({
        ...omit(d, 'node'),
        ...d.node,
        area: d.node.area || d.node.metadata.device_location || '',
        deviceLocationNotes: d.node.metadata.device_location_note || '',
      })) // Flatten 'node' object into the rest of the device object, populate area with device_location if empty
    }
  }

  return await getDevicesApi()
}

export const getFolders = async (source: CancelTokenSource) => {
  let folders: IGroupedFoldersType | undefined
  const limit = 4000

  const getFoldersApi = async (offset = 0): Promise<IGroupedFoldersType> => {
    const response = (
      await API.get(`/api/v2/protect/folders?limit=${limit}&offset=${offset}`, {
        cancelToken: source.token,
      })
    ).data as IGroupedFoldersTypeAPI
    if (!folders) folders = response
    else {
      folders.companies.push(...response.companies)
      folders.programs.push(...response.programs)
      folders.participants.push(...response.participants)
    }

    if (response.hasMore) {
      // Call the function again (recursively continue to fetch devices until hasMore is false)
      return await getFoldersApi(offset + limit)
    } else {
      // Return the data
      return folders
    }
  }

  return await getFoldersApi()
}

export const convertStringToArray = (str: string) => {
  if (str?.trim().length === 0) return []

  let removedBrackets = str
  if (removedBrackets.charAt(0) === '[') removedBrackets = removedBrackets.slice(1)
  if (removedBrackets.charAt(removedBrackets.length - 1) === ']') removedBrackets = removedBrackets.slice(0, -1)
  return removedBrackets.split(',').map(item => {
    let newItem = item.trim()
    if (['"', "'"].includes(newItem.charAt(0))) newItem = newItem.slice(1)
    if (['"', "'"].includes(newItem.charAt(newItem.length - 1))) newItem = newItem.slice(0, -1)
    return newItem.trim()
  })
}

export const getLogo = (accountTheme: IFolderTheme | undefined) =>
  accountTheme?.companyLogo
    ? `/api/files/public/${accountTheme.companyLogo}` // First choice: account theme companyLogo
    : (accountTheme?.logoUrl as string) // Second choice: account theme logoUrl (full path)
