import styled from 'styled-components/macro'

import Btn from '../button'

export const Container = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.accent_blue};
  padding: 1.4rem 1.8rem;
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`

export const UserNameSpan = styled.span`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
`

export const StopButton = styled(Btn)`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  border: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.brand02};
  border-radius: 0.4rem;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  text-transform: uppercase;
  padding: 0.8rem 1.6rem;
  margin: 0;
  cursor: pointer;
  min-width: 7rem;

  // Style the spinner
  > svg {
    width: ${({ theme }: ThemeProps) => theme.fontSizes.s};
    height: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  }
  > svg > path {
    stroke: ${({ theme }: ThemeProps) => theme.colors.brand02};
  }
`
