import styled from 'styled-components/macro'

export const InstructionCardGrid = styled.div`
  display: none;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 75px;
  }
`

export const MobileCarousel = styled.div`
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    display: none;
  }
`
