import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button'
import styled from 'styled-components/macro'

import { GhostButton } from '../../shared-styles/button.styles'
import theme from '../../theme'

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 88px;
`

export const LanguageCodeText = styled(GhostButton)<{ selected: boolean }>`
  font-weight: ${theme.fontWeights.semibold};
  font-size: ${theme.fontSizes.xs};
  line-height: 24px;
  letter-spacing: 0.75px;
  text-transform: uppercase;

  margin-top: 0;
  padding: 0;

  text-decoration-line: ${props => (props.selected ? 'underline' : 'none')};
  color: ${props => (props.selected ? theme.colors.support_info : theme.colors.text03)};
`

export const LanguageMenu = styled(Menu)`
  background: transparent;
`

export const LanguageMenuList = styled(MenuList)`
  border-radius: 4px;
  border: none;

  > [data-reach-menu-item][data-selected] {
    background: ${theme.colors.hover_row};
    color: ${theme.colors.text01};
  }
`

export const LanguageMenuItem = styled(MenuItem)<{ selected: boolean }>`
  font-size: ${theme.fontSizes.xs};
  line-height: 24px;
  color: ${props => (props.selected ? theme.colors.text01 : theme.colors.text02)};
  font-weight: ${props => (props.selected ? theme.fontWeights.semibold : 'normal')};

  &:hover {
    background-color: ${theme.colors.hover_row};
    color: ${props => (props.selected ? theme.colors.text01 : theme.colors.text02)};
  }
`

export const LanguageMenuButton = styled(MenuButton)`
  font-weight: ${theme.fontWeights.semibold};
  font-size: 13px;
  line-height: 16px;

  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: transparent;
  border: none;
  color: ${theme.colors.support_info};
`

export const LanguageButtonContainer = styled.div`
  transform: rotate(90deg);
  display: inline-block;
`
