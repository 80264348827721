import styled from 'styled-components/macro'

export const NoticeContainer = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  ${({ theme }: ThemeProps) => theme.elevation.elevation02};
  padding: 1.6rem 2.4rem;
  margin: 1.2rem -1.8rem -1.3rem -1.8rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.4rem 0 -1.8rem 0;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    margin: 0;
    margin-bottom: ${({ theme }: ThemeProps) => theme.space.xs};
  }
`

export const NoticeText = styled.div`
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  margin: 0;
  display: flex;
  align-items: center;

  svg {
    display: block;
    margin-right: 1.3rem;
  }
`
