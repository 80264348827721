import { decode } from 'js-base64'

// takes jwt and attempts validate the expiration date on one of the json payloads
export const isTokenExpired = (token: string): boolean => {
  let valid: boolean = true

  const tokenParts = token.split('.')
  for (const tokenPart of tokenParts) {
    try {
      const decodedTokenPart = decode(tokenPart)
      const jsonValue = JSON.parse(decodedTokenPart)

      if (jsonValue.exp) {
        // there should be an expiration date in epoch time, compare it against the current time.
        if (Date.now() / 1000 < jsonValue.exp) {
          valid = false
        }

        // break after finding one expiration, there shouldn't be more than one
        break
      }
    } catch (e) {
      // nothing, try again
    }
  }

  return valid
}
