import { Link as RouterLink } from '@reach/router'
import styled from 'styled-components/macro'

export const ButtonLink = styled(RouterLink)`
  background: ${({ theme }: ThemeProps) => theme.colors.brand01};
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-top: 32px;
  padding: 12px 16px;
  width: 100%;
  max-width: 244px;

  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`

export const SecondaryButtonLink = styled(ButtonLink)`
  background: transparent;
  border: 1px solid ${({ theme }: ThemeProps) => theme.colors.brand01};
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  margin-top: 16px;
  text-decoration: none;

  &:hover {
    background: ${({ theme }: ThemeProps) => theme.colors.brand01};
    color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  }
`

interface GhostButtonLinkProps {
  theme: IThemeProps
  uppercasestyling?: 'true' | 'false'
}

export const GhostButtonLink = styled(RouterLink)<GhostButtonLinkProps>`
  background: 0;
  border: 0;
  margin-top: 24px;
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  text-transform: ${({ uppercasestyling }: GhostButtonLinkProps) =>
    uppercasestyling === 'true' ? 'uppercase' : 'none'};
  letter-spacing: ${({ uppercasestyling }: GhostButtonLinkProps) => (uppercasestyling === 'true' ? '1px' : 'inherit')};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`

interface GhostButtonAProps {
  theme: IThemeProps
  uppercasestyling?: 'true' | 'false'
}
export const GhostButtonA = styled.a<GhostButtonAProps>`
  background: 0;
  border: 0;
  margin-top: 24px;
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  text-transform: ${({ uppercasestyling }: GhostButtonAProps) => (uppercasestyling === 'true' ? 'uppercase' : 'none')};
  letter-spacing: ${({ uppercasestyling }: GhostButtonAProps) => (uppercasestyling === 'true' ? '1px' : 'inherit')};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`

export const SecondaryLinkA = styled.a`
  color: ${({ theme }: ThemeProps) => theme.colors.support_info};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const ButtonA = styled.a`
  background: ${({ theme }: ThemeProps) => theme.colors.brand01};
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-top: 32px;
  padding: 12px 16px;
  width: 100%;
  max-width: 244px;

  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`
