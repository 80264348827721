import { AxiosError } from 'axios'
import moment from 'moment'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import AuthContext from '../contexts/auth-context'
import API from './api'

const allowedChannels = [
  'probe',
  'temp',
  'hum',
  'leak',
  'signal_indicator',
  'battery_indicator',
  'powersource',
  'defroute',
  'cellservice',
  'cellrsrp',
  'cellrssi',
  'rssi',
  'cellsinr',
  'snr',
  'voltage',
  'chirp',
  'stealth',
  'valve',
  'interval',
  'temp_report_config',
]

export interface UpdateStateProps {
  payload: Partial<IDeviceType>
  channelValue: { channelName: string; value: string }
  timestamp: string
  deviceId: number
}

export const useWebsocket = ({
  shouldBeAlive,
  subscribeIds,
  updateState,
  handleEvent,
  subscribeChannels = allowedChannels,
  subscribeEvent = true,
}: {
  shouldBeAlive: boolean
  subscribeIds: number[]
  updateState: (props: UpdateStateProps) => void
  handleEvent?: () => void
  subscribeChannels?: string[]
  subscribeEvent?: boolean
}) => {
  const { setIsAuth } = useContext(AuthContext)
  const handleError = useErrorHandler()
  const [ws, setWs] = useState<WebSocket | undefined>()
  const subscribeIdsRef = useRef<number[]>(subscribeIds)

  const keepAliveIntervalRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (ws) {
      if (!shouldBeAlive) {
        ws.close(1000)
        clearInterval(keepAliveIntervalRef.current as unknown as number)
        setWs(undefined)
      }
    } else {
      if (shouldBeAlive) connectWebsocket(setWs, setIsAuth, handleError)
    }
  }, [ws, shouldBeAlive, handleError, setIsAuth])

  useEffect(() => {
    if (ws?.readyState === ws?.OPEN) {
      clearInterval(keepAliveIntervalRef.current as unknown as number)
      keepAliveIntervalRef.current = setInterval(() => {
        if (!ws) return
        if (ws.readyState === ws.OPEN) {
          const payload = { action: `keepalive` }
          ws.send(JSON.stringify(payload))
        }
        // websocket timeout is about a minute. This keep alive timer is 50 seconds to account for any delays or something.
      }, 1000 * 50)
    }

    return () => clearInterval(keepAliveIntervalRef.current as unknown as number)
  }, [ws, ws?.readyState, ws?.OPEN])

  const manageSubscriptions = useCallback(() => {
    if (ws && ws.readyState === ws.OPEN) {
      // Unsubscribe nodes that are no longer in list
      subscribeIdsRef.current.forEach(id => {
        if (!subscribeIds.includes(id)) unsubscribeWebsocket(id, ws)
      })
      // Subscribe nodes that are in list now but weren't previously
      subscribeIds.forEach(id => {
        if (!subscribeIdsRef.current?.includes(id)) subscribeWebsocket(id, ws, subscribeChannels, subscribeEvent)
      })
      subscribeIdsRef.current = subscribeIds
    }
  }, [subscribeIds, ws, subscribeChannels, subscribeEvent])

  const reconnectWebsocket = useCallback(
    () => connectWebsocket(setWs, setIsAuth, handleError),
    [handleError, setIsAuth]
  )

  useEffect(() => {
    if (JSON.stringify(subscribeIdsRef.current) !== JSON.stringify(subscribeIds)) {
      manageSubscriptions()
    }
  }, [subscribeIds, manageSubscriptions])

  useEffect(() => {
    if (ws) {
      ws.onopen = () => {
        subscribeIds?.forEach(id => subscribeWebsocket(id, ws, subscribeChannels, subscribeEvent))
      }
    }
  }, [subscribeIds, ws, subscribeChannels, subscribeEvent])

  useEffect(() => {
    if (ws) {
      ws.onmessage = message => {
        const websocketMessage = JSON.parse(message.data) as WebsocketDataType
        if (websocketMessage.type !== 'data' && websocketMessage.type !== 'event') return
        const payload = websocketMessage.payload
        const { channelName, value, timestamp, nodeId } = payload

        if (websocketMessage.type === 'event' && handleEvent) handleEvent()

        if (websocketMessage.type === 'data' && subscribeChannels.includes(channelName)) {
          const update: UpdateStateProps = {
            payload: {
              lastCheckIn: moment.utc(moment.unix(Number(timestamp))).toISOString(),
              isOffline: false,
            },
            channelValue: { channelName, value },
            timestamp,
            deviceId: nodeId,
          }
          switch (channelName) {
            case 'signal_indicator':
              update.payload = {
                ...update.payload,
                hasLowSignal: Number(value) < 2,
              }
              break
            case 'battery_indicator':
              update.payload = {
                ...update.payload,
                hasLowBattery: Number(value) < 2,
              }
              break
          }
          updateState(update)
        }
      }
      ws.onerror = () => {}
      ws.onclose = (response: { code?: number; message?: string; wasClean?: boolean }) => {
        // If the websocket was closed unintentionally, re-open the connection
        if (response.code && response.code !== 1000) {
          reconnectWebsocket()
        }
      }
    }
  }, [updateState, handleEvent, ws, subscribeChannels, reconnectWebsocket])

  return { ws, reconnectWebsocket }
}

export const connectWebsocket = async (
  setWs: React.Dispatch<React.SetStateAction<WebSocket | undefined>>,
  setIsAuth: (val: boolean) => void,
  handleError: (val: any) => void
) => {
  try {
    // Check if the user is logged in on the server
    await API.get('/api/users/me')
    // User is logged in, connect to websocket
    setWs(new WebSocket('wss://' + window.location.host + '/api/stream/all'))
  } catch (e) {
    // User is logged out on the server, log them out of Protect
    if ((e as AxiosError).response?.status === 403) {
      try {
        await API.get('/api/logout')
        localStorage.removeItem('protectToken')
        setIsAuth(false)
      } catch (e) {
        handleError(e)
      }
    }
    // Some other API error, display error screen
    else handleError(e)
  }
}

export const subscribeWebsocket = (
  nodeId: number,
  ws: WebSocket,
  subscribeChannels: string[],
  subscribeEvent: boolean
) => {
  subscribeChannels.forEach(channel => {
    // Subscribe (or resubscribe) the node to the websocket
    const dataPayload = {
      action: `subscribe:data`,
      arguments: {
        nodeId,
        channelName: channel,
      },
    }
    if (ws.readyState === ws.OPEN) ws.send(JSON.stringify(dataPayload))
  })
  const eventPayload = {
    action: `subscribe:event`,
    arguments: {
      nodeId,
    },
  }
  if (ws.readyState === ws.OPEN && subscribeEvent) ws.send(JSON.stringify(eventPayload))
}

export const unsubscribeWebsocket = (nodeId: number, ws: WebSocket) => {
  // Unsubscribe the node to the websocket
  let payload = {
    action: `unsubscribe:data`,
    arguments: {
      nodeId,
    },
  }
  if (ws.readyState === ws.OPEN) ws.send(JSON.stringify(payload))
  payload = {
    action: `unsubscribe:event`,
    arguments: {
      nodeId,
    },
  }
  if (ws.readyState === ws.OPEN) ws.send(JSON.stringify(payload))
}
