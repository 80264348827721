import { MenuButton, MenuItem, MenuList, MenuPopover } from '@reach/menu-button'
import styled from 'styled-components/macro'

import { GhostButton } from '../../../../shared-styles/button.styles'

export const DropdownMenuContainer = styled.div`
  position: relative;

  [data-reach-menu-popover] {
    z-index: 1;
    right: -1.2rem;
    top: 20px;
  }

  [data-reach-menu],
  [data-reach-menu-popover] {
    right: -1.2rem;
    top: 20px;
  }
  [data-reach-menu-list],
  [data-reach-menu-items] {
    border: 0;
    padding: 0;
    white-space: normal;
  }
`

export const StyledDropdownButton = styled(MenuButton)`
  background: none;
  border: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.s}`};
  padding-left: 0;
  padding-right: 0;
  margin: ${({ theme }: ThemeProps) => theme.space.xs} 0;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  svg,
  span {
    display: block;
  }
  svg {
    margin-right: 1rem;
  }
`

export const StyledMenuPopover = styled(MenuPopover)`
  z-index: 1;
  right: -1.2rem;
  top: 20px;

  [data-reach-menu],
  [data-reach-menu-popover] {
    right: -1.2rem;
    top: 20px;
  }
  [data-reach-menu-list],
  [data-reach-menu-items] {
    border: 0;
    padding: 0;
    white-space: normal;
  }
`

export const StyledDropdownMenuList = styled(MenuList)`
  border: 0;
  border-radius: 0.4rem;
  ${({ theme }: ThemeProps) => theme.elevation.elevation03};
  width: 48rem;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  padding: 0;
  max-height: calc(100vh - 30rem);
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */

  /* Style the scrollbar - Chrome and Safari */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5rem;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(161, 164, 177, 0.4);
  }

  &:hover,
  &:focus {
    scrollbar-width: thin; /* Firefox */
    ::-webkit-scrollbar-thumb {
      background: rgba(161, 164, 177, 0.8);
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 5rem;
    }
  }
`

export const CategoryHeading = styled.h3`
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: ${({ theme }: ThemeProps) => `${theme.space.xs} ${theme.space.m}`};
  margin: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xs};
  text-transform: uppercase;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    border-top: 0;
  }
`

export const CategoryHeadingWithBorder = styled(CategoryHeading)`
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  }
`

export const MobileAlarmCard = styled.button`
  background: transparent;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  text-align: left;
  line-height: 1.7;
  border: 0;
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: ${({ theme }: ThemeProps) => `${theme.space.xs} ${theme.space.s}`};
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-gap: 11px;
  align-items: center;
  width: 100%;

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  }
`

export const AlarmCard = styled(MenuItem)`
  background: transparent;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  border: 0;
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: ${({ theme }: ThemeProps) => `${theme.space.xs} ${theme.space.s}`};
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-gap: 11px;

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  }
  &:hover,
  &:focus {
    cursor: pointer;
    background: transparent;
    ${({ theme }: ThemeProps) => theme.elevation.elevation03};
  }
  &[data-selected] {
    background: transparent;
    color: inherit;
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    padding: ${({ theme }: ThemeProps) => `1.2rem ${theme.space.m}`};
    grid-template-columns: 53px 1fr;
    grid-gap: 26px;
  }
`

export const ImageCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImageContainer = styled.div`
  position: relative;

  svg {
    bottom: -0.2rem;
    right: -0.3rem;
    position: absolute;
  }
`

export const DeviceImageContainer = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui03};
  border-radius: 10rem;
  width: 5.3rem;
  height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    position: relative;
    bottom: 0;
    right: 0;
    width: 3.2rem;
    height: 3.2rem;
  }
`

export const InfoCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const AlarmCardHeader = styled.span`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xs};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`

export const AlarmCardTime = styled.span`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.xs};
`

export const NoAlertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.4rem;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  line-height: 1;

  svg {
    display: block;
  }
  div {
    margin-top: ${({ theme }: ThemeProps) => theme.space.s};
  }
`

export const ButtonRow = styled(GhostButton)`
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  padding: ${({ theme }: ThemeProps) => `${theme.space.xs} ${theme.space.m}`};
  margin: 0;
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    border: 0;
  }
`

export const NotificationsListContainer = styled.div`
  text-align: left;
  width: 100vw;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    width: 100%;
  }
`
