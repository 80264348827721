import { navigate, RouteComponentProps, useParams } from '@reach/router'
import axios, { CancelTokenSource } from 'axios'
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'

import ErrorScreen from '../../components/error-screen'
import Header from '../../components/header'
import { GhostButton } from '../../shared-styles/button.styles'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import API from '../../utils/api'
import { errorHandler, makeEllipsis } from '../../utils/helpers'
import { ProgressBar } from './../../components/progress-bar/index'
import ConfirmAddress from './confirm-address'
import OnboardingContactList from './onboarding-contact-list'
import OnboardingSuccessModal from './onboarding-success-modal'
import {
  CTAPanel,
  CTAPanelLeft,
  CTAPanelRight,
  ExitButton,
  PageContent,
  PageLayout,
  PrimaryActionButton,
} from './onboarding.styles'
import ParticipationAgreement from './participation-agreement'

export const CTASection = ({
  folder,
  hasMultiFolderAccess,
  step,
  goBack,
  onPrimaryClick,
  primaryButtonText = 'Continue',
  showBackButton = true,
  disabledStyling = false,
  buttonWaiting,
  disabled = false,
}: {
  folder?: IFolderType
  hasMultiFolderAccess: boolean
  step: number
  goBack?: (step: number) => void
  onPrimaryClick: (e: React.SyntheticEvent<Element, Event>) => void
  primaryButtonText?: string
  showBackButton?: boolean
  disabledStyling?: boolean
  buttonWaiting: boolean
  disabled?: boolean
}) => {
  const onExit: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    navigate('/dashboard')
  }

  return (
    <CTAPanel>
      <CTAPanelLeft className="fs-exclude">
        <DesktopOnly>
          <strong>Onboarding | {makeEllipsis(folder?.name)}</strong>
          {hasMultiFolderAccess && <ExitButton onClick={onExit}>Exit and setup later</ExitButton>}
        </DesktopOnly>
        <MobileOnly>{hasMultiFolderAccess && <ExitButton onClick={onExit}>Setup Later</ExitButton>}</MobileOnly>
      </CTAPanelLeft>
      <CTAPanelRight step={step}>
        <MobileOnly>
          <PrimaryActionButton
            fullwidth={false}
            onClick={(e: React.SyntheticEvent<Element, Event>) => {
              onPrimaryClick(e)
            }}
            data-cy="continueBtn"
            disabledStyling={disabledStyling}
            waiting={buttonWaiting}
            disabled={disabled}
          >
            {primaryButtonText}
          </PrimaryActionButton>
        </MobileOnly>
        <DesktopOnly>
          {showBackButton && (
            <GhostButton
              onClick={e => {
                e.preventDefault()
                goBack?.(step - 1)
              }}
            >
              Back
            </GhostButton>
          )}
          <PrimaryActionButton
            fullwidth={false}
            onClick={(e: React.SyntheticEvent<Element, Event>) => {
              onPrimaryClick(e)
            }}
            data-cy="continueBtn"
            disabledStyling={disabledStyling}
            waiting={buttonWaiting}
            disabled={disabled}
          >
            {primaryButtonText}
          </PrimaryActionButton>
        </DesktopOnly>
      </CTAPanelRight>
    </CTAPanel>
  )
}

const getOnboardingStep = (folderAttributes: any) => {
  const {
    stepConfirmUser,
    stepParticipationAgreementUser,
    participantAgreementDate,
    participantAgreementSignedByName,
  } = folderAttributes

  // If ConfirmAddress and ParticipationAgreement is done, redirect to notification contacts screen
  if (
    stepConfirmUser > 0 &&
    stepParticipationAgreementUser > 0 &&
    participantAgreementDate &&
    participantAgreementSignedByName
  ) {
    return 3
  }
  // If ConfirmAddress is done but ParticipationAgreement is not done, redirect to ParticipationAgreement
  else if (
    stepConfirmUser > 0 &&
    (stepParticipationAgreementUser < 1 || !participantAgreementDate || !participantAgreementSignedByName)
  ) {
    return 2
  } else {
    return 1
  }
}

const Onboarding = () => {
  const handleError = useErrorHandler()

  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const { currentStep, folderId }: { currentStep: string; folderId: string } = useParams()
  const initialStep = useRef<number>(Number(currentStep))
  const [folder, setFolder] = useState<IParticipantFolderType>()
  const [attributes, setAttributes] = useState<AttributesDataType>()
  const [loading, setLoading] = useState<boolean>(true)

  const step = Number(currentStep)
  const percentComplete = (step / 4) * 100

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      // clean up axios calls when component unmounts
      source.cancel('cancelled')
    }
  }, [source])

  const handleBack = () => {
    if (step > 1) {
      navigate(`/onboarding/${step - 1}/location/${folderId}`)
    }
  }

  const fetchFolder = useCallback(async () => {
    setLoading(true)

    try {
      // Call participant-children because we need the orderStatus and trackingUrl as well as the folder object
      const folderResponse = await API.get(`/api/v2/protect/folders/${folderId}/participant-children`, {
        cancelToken: source.token,
      })

      const folderAttributesResponse = await API.get(`/api/v2/folders/${folderId}/attributes`, {
        cancelToken: source.token,
      })
      setAttributes(folderAttributesResponse.data)

      const calculatedStep = getOnboardingStep(folderAttributesResponse.data)
      if (calculatedStep !== initialStep.current) {
        navigate(`/onboarding/${calculatedStep}/location/${folderId}`)
      }

      const thisFolder = folderResponse.data.participants.find(
        (f: IParticipantFolderType) => f.folder.id === Number(folderId)
      )
      setFolder(thisFolder as IParticipantFolderType)
    } catch (e) {
      if (e.message !== 'cancelled') handleError(e as unknown as Error)
    } finally {
      setLoading(false)
    }
  }, [folderId, handleError, source])

  useEffect(() => {
    fetchFolder()
  }, [fetchFolder])

  const onBack = () => navigate(`/onboarding/${step - 1}/location/${folderId}`)
  const onNext = () => navigate(`/onboarding/${step + 1}/location/${folderId}`)

  return (
    <>
      <Header type="onboarding" locationName={folder?.folder.name} handleBack={step > 1 ? handleBack : undefined} />
      <ProgressBar percentComplete={percentComplete || 6} />
      <PageLayout>
        <PageContent>
          {folder && attributes && (
            <>
              {step === 1 && <ConfirmAddress parentLoading={loading} folder={folder.folder} onNext={onNext} />}
              {step === 2 && (
                <ParticipationAgreement
                  parentLoading={loading}
                  folder={folder.folder}
                  onBack={onBack}
                  onNext={onNext}
                />
              )}
              {/* The success modal needs to show above the contact list screen */}
              {step >= 3 && (
                <OnboardingContactList parentLoading={loading} folder={folder.folder} onBack={onBack} onNext={onNext} />
              )}
              {step >= 4 && <OnboardingSuccessModal folder={folder} attributes={attributes} />}
            </>
          )}
        </PageContent>
      </PageLayout>
    </>
  )
}

const OnboardingWrapper = (_: RouteComponentProps) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <>
          <Header type="onboarding" />
          <ProgressBar percentComplete={0} />
          <ErrorScreen error={error.message} />
        </>
      )}
      onError={errorHandler}
    >
      <Onboarding />
    </ErrorBoundary>
  )
}

export default OnboardingWrapper
