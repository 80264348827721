import { VisuallyHidden } from '@reach/visually-hidden'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import Alert from '../../../components/alert'
import { AddFolderIcon, CheckIconOutline } from '../../../components/icon'
import { AppContext } from '../../../contexts/app-context'
import { SecondaryLinkButton } from '../../../shared-styles/button.styles'
import { FormField, Input, Label, LabelNote, Textarea } from '../../../shared-styles/form.styles'
import { StyledTable, StyledTh, Td } from '../../../shared-styles/table.styles'
import API from '../../../utils/api'
import { hasPermissions } from '../../../utils/helpers'
import ActivationContext from '../activation-context'
import { Btn } from './../../../components/button/index'
import { Sidepanel } from './../../../components/sidepanel/index'
import {
  AlertContainer,
  CurrentFolderText,
  CurrentText,
  FormFields,
  MoveDeviceContainer,
  MoveFolderButton,
  NoColumnsFormField,
  SaveButtonContainer,
  ScrollContainer,
  TableButton,
} from './step-3-place-devices.styles'

interface IProps {
  device: IDeviceType
  updateSensorProperty: (
    id: number,
    property: 'area' | 'building' | 'floor' | 'deviceLocationNotes' | 'deviceUseCase',
    value: string
  ) => void
}

export const Step3PlaceDevice = ({ device, updateSensorProperty }: IProps) => {
  const _isMounted = useRef(true)
  const selectedFolderRef = useRef<number | undefined>(undefined)
  const { folders, permissions, roles, user } = useContext(AppContext)
  const { setDevices } = useContext(ActivationContext)
  const [deviceLocationNotes, setDeviceLocationNotes] = useState<string | undefined>(device.deviceLocationNotes)
  const [building, setBuilding] = useState<string | undefined>(device?.building)
  const [floor, setFloor] = useState<string | undefined>(device?.floor)
  const [toggleLocationDetails, setToggleLocationDetails] = useState<boolean>(false)
  const [folder, setFolder] = useState<IGenericFolderType | undefined>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedFolderId, setSelectedFolderId] = useState<number>()
  const [buttonWaiting, setButtonWaiting] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  // Get folders user has access to (not assigned)
  const foldersAccess = useMemo(
    () => folders.filter(f => f.id === device.folderId || f.parentId === device.folderId),
    [device.folderId, folders]
  )
  const hasWriteNodesPermission = user ? hasPermissions('Write Nodes', permissions, roles, user) : false

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      _isMounted.current = false // used to handle memory leaks when performing a state changing when the component has already unmounted
      // When the component unmounts (such as when a different device is selected), update the global state to move the device to the newly selected folder and thus remove the device from the list of devices currently being activated in this folder
      const newFolderId = selectedFolderRef.current
      if (newFolderId) {
        setDevices(prev =>
          prev.map(d => {
            if (d.id === device.id) return { ...d, folderId: newFolderId }
            else return d
          })
        )
      }
    }
  }, [device.id, setDevices])

  useEffect(() => {
    selectedFolderRef.current = selectedFolderId
  }, [selectedFolderId])

  useEffect(() => {
    const timeout = setTimeout(
      () => updateSensorProperty(device.id, 'deviceLocationNotes', deviceLocationNotes || ''),
      2000
    )
    return () => clearTimeout(timeout)
  }, [deviceLocationNotes, device.id, updateSensorProperty])

  useEffect(() => {
    if (_isMounted.current) {
      setFolder(folders.find(f => f.id === device.folderId))
    }
  }, [folders, device.folderId])

  const submitUpdateFolder = async () => {
    setButtonWaiting(true)
    if (selectedFolderId) {
      try {
        await API.put(`/api/nodes/${device.id}`, { folderId: selectedFolderId })
        setFolder(foldersAccess.find(f => f.id === selectedFolderId))
        setSuccessMessage('Folder updated successfully.')
      } catch {
        setErrorMessage('Unable to update folder.')
      } finally {
        setOpenModal(false)
        setButtonWaiting(false)
      }
    }
  }

  return (
    <>
      <FormFields twoColumns={true}>
        <NoColumnsFormField>
          <FormField>
            <Label htmlFor="additional_notes">
              Notes <LabelNote>(required)</LabelNote>
            </Label>
            <Textarea
              id="additional_notes"
              placeholder="Ex. behind fridge"
              value={deviceLocationNotes}
              onChange={e => {
                setDeviceLocationNotes(e.target.value)
              }}
              onBlur={e => {
                if (e.target.value !== device.deviceLocationNotes)
                  updateSensorProperty(device.id, 'deviceLocationNotes', e.target.value)
              }}
            />
          </FormField>

          <SecondaryLinkButton
            onClick={() => {
              setToggleLocationDetails(!toggleLocationDetails)
            }}
          >
            {toggleLocationDetails ? '- Show less' : '+ Show more placement details'}
          </SecondaryLinkButton>
        </NoColumnsFormField>

        {toggleLocationDetails && (
          <>
            <FormField>
              <Label htmlFor="building">
                Building <LabelNote>(optional)</LabelNote>
              </Label>
              <Input
                id="building"
                value={building}
                onChange={e => {
                  setBuilding(e.target.value)
                }}
                onBlur={e => {
                  if (e.target.value !== device.building) updateSensorProperty(device.id, 'building', e.target.value)
                }}
              />
            </FormField>
            <FormField>
              <Label htmlFor="floor">
                Floor <LabelNote>(optional)</LabelNote>
              </Label>
              <Input
                id="floor"
                value={floor}
                onChange={e => {
                  setFloor(e.target.value)
                }}
                onBlur={e => {
                  if (e.target.value !== device.floor) updateSensorProperty(device.id, 'floor', e.target.value)
                }}
              />
            </FormField>
          </>
        )}
      </FormFields>
      {hasWriteNodesPermission && foldersAccess.length > 1 && (
        <MoveDeviceContainer>
          <MoveFolderButton
            buttonType="link"
            onClick={() => {
              setSuccessMessage(undefined)
              setErrorMessage(undefined)
              setOpenModal(true)
            }}
            type="button"
          >
            <span>
              <AddFolderIcon /> Move device to another folder
            </span>
          </MoveFolderButton>
          {folder && (
            <CurrentFolderText data-testid="current-folder-text" className="fs-exclude">
              <strong>Current:</strong> {folder.name}
            </CurrentFolderText>
          )}

          {successMessage && (
            <AlertContainer>
              <Alert icon="check_outline" iconAlignment="center" message={successMessage} type="info_success" />
            </AlertContainer>
          )}

          {errorMessage && (
            <AlertContainer>
              <Alert icon="error" iconAlignment="center" message={errorMessage} type="info_error" />
            </AlertContainer>
          )}

          <Sidepanel
            onDismiss={() => {
              setOpenModal(false)
              setSelectedFolderId(undefined)
            }}
            openModal={openModal}
            showMobileTitleAboveDivider={true}
            showTitle={true}
            title="Move to Another Folder"
            titleSize="small"
            variation="default"
          >
            <p>Select the folder to which you would like to move this device.</p>
            <StyledTable>
              <thead>
                <tr>
                  <StyledTh>Folders</StyledTh>
                </tr>
              </thead>
              <ScrollContainer data-testid="available-folders-list">
                {folder && (
                  <tr>
                    <Td>
                      <TableButton
                        selected={selectedFolderId === folder.id || !selectedFolderId}
                        onClick={() => setSelectedFolderId(undefined)}
                        className="fs-exclude"
                      >
                        {folder.name}{' '}
                        {(selectedFolderId === folder.id || !selectedFolderId) && <CurrentText>(current)</CurrentText>}
                      </TableButton>
                    </Td>
                  </tr>
                )}
                {foldersAccess.map(f => {
                  if (f.id !== folder?.id)
                    return (
                      <tr key={f.id}>
                        <Td>
                          <TableButton
                            selected={selectedFolderId === f.id}
                            notCurrent={true}
                            onClick={() => setSelectedFolderId(f.id)}
                            className="fs-exclude"
                          >
                            {f.name}{' '}
                            {selectedFolderId === f.id && (
                              <>
                                <CheckIconOutline />
                                <VisuallyHidden>(selected)</VisuallyHidden>
                              </>
                            )}
                          </TableButton>
                        </Td>
                      </tr>
                    )
                  return null
                })}
              </ScrollContainer>
            </StyledTable>
            <SaveButtonContainer>
              <Btn
                buttonType="secondary"
                onClick={() => submitUpdateFolder()}
                type="button"
                uppercaseStyling={true}
                disabled={!selectedFolderId || selectedFolderId === folder?.id}
                waiting={buttonWaiting}
              >
                Save Changes
              </Btn>
            </SaveButtonContainer>
          </Sidepanel>
        </MoveDeviceContainer>
      )}
    </>
  )
}

export default Step3PlaceDevice
