import { Portal } from '@reach/portal'
import { TooltipPopup, useTooltip } from '@reach/tooltip'
import { cloneElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { InfoIcon } from '../icon'

interface IProps {
  /** Pass in a string, or one or more elements */
  children: React.ReactNode
  /** Pass in a React component, for example: icon={<Icon />} */
  icon?: React.ReactNode
  /** Max width the tooltip can be, in pixels */
  maxWidth?: number
  /** Where the tooltip should align relative to the icon */
  alignment?: 'top' | 'right' | 'bottom' | 'left'
  /** Label for screen readers */
  ariaLabel?: string
}

interface TriangleTooltipProps {
  children: React.ReactElement
  label: React.ReactNode
  ariaLabel?: string
  alignment: 'top' | 'right' | 'bottom' | 'left'
  maxWidth: number
  showTooltip?: boolean
}

interface StyledTooltipArrowProps {
  triggerRect: DOMRect | null
  scrollY: number
  scrollX: number
  alignment: string
}

const TooltipButton = styled.button`
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
`

const TooltipArrow = styled.div`
  position: absolute;
  left: ${(props: StyledTooltipArrowProps) => {
    switch (props.alignment) {
      case 'top':
        return props.triggerRect && props.triggerRect.left + props.triggerRect.width / 4.5 + props.scrollX + 'px'
      case 'right':
        return props.triggerRect && props.triggerRect.left + props.triggerRect.width + 5 + 'px'
      case 'bottom':
        return props.triggerRect && props.triggerRect.left + props.triggerRect.width / 4.5 + props.scrollX + 'px'
      case 'left':
        return props.triggerRect && props.triggerRect.left - 15 + 'px'
      default:
        return 0
    }
  }};
  top: ${(props: StyledTooltipArrowProps) => {
    switch (props.alignment) {
      case 'top':
        return props.triggerRect && props.triggerRect.top - 10 + props.scrollY + 'px'
      case 'right':
        return (
          props.triggerRect &&
          props.triggerRect.bottom -
            props.triggerRect.height +
            (props.triggerRect.height < 30 ? 3 : 6) +
            props.scrollY +
            'px'
        )
      case 'bottom':
        return props.triggerRect && props.triggerRect.bottom + 5 + props.scrollY + 'px'
      case 'left':
        return (
          props.triggerRect &&
          props.triggerRect.bottom -
            props.triggerRect.height +
            (props.triggerRect.height < 30 ? 3 : 6) +
            props.scrollY +
            'px'
        )
      default:
        return 0
    }
  }};
  width: 0;
  height: 0;
  border-top: ${(props: StyledTooltipArrowProps) => {
    switch (props.alignment) {
      case 'top':
        return '10px solid #1b1b1b'
      case 'bottom':
        return '0'
      default:
        return '10px solid transparent'
    }
  }};
  border-right: ${(props: StyledTooltipArrowProps) => {
    switch (props.alignment) {
      case 'right':
        return '10px solid #1b1b1b'
      case 'left':
        return '0'
      default:
        return '10px solid transparent'
    }
  }};
  border-bottom: ${(props: StyledTooltipArrowProps) => {
    switch (props.alignment) {
      case 'bottom':
        return '10px solid #1b1b1b'
      case 'top':
        return '0'
      default:
        return '10px solid transparent'
    }
  }};
  border-left: ${(props: StyledTooltipArrowProps) => {
    switch (props.alignment) {
      case 'left':
        return '10px solid #1b1b1b'
      case 'right':
        return '0'
      default:
        return '10px solid transparent'
    }
  }};
`

const centeredRight = (triggerRect: any, tooltipRect: any) => {
  const left = triggerRect.left + triggerRect.width + 15
  const maxLeft = window.innerWidth - tooltipRect.width - 2
  const tooltipCenter = tooltipRect.height / 2
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom - tooltipCenter - triggerRect.width / 2 + window.scrollY,
  }
}

const centeredLeft = (triggerRect: any, tooltipRect: any) => {
  const left = triggerRect.left - tooltipRect.width - 15
  const maxLeft = window.innerWidth - tooltipRect.width - 2
  const tooltipCenter = tooltipRect.height / 2
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom - tooltipCenter - triggerRect.width / 2 + window.scrollY,
  }
}

const centeredTop = (triggerRect: any, tooltipRect: any) => {
  const left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2
  const maxLeft = window.innerWidth - tooltipRect.width - 2
  return {
    left: Math.min(Math.max(2, left), maxLeft),
    top: triggerRect.top - tooltipRect.height - 10 + window.scrollY,
  }
}

const centeredBottom = (triggerRect: any, tooltipRect: any) => {
  const left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2
  const maxLeft = window.innerWidth - tooltipRect.width - 2
  return {
    left: Math.min(Math.max(2, left), maxLeft),
    top: triggerRect.bottom + 15 + window.scrollY,
  }
}

const TriangleTooltip = ({ children, label, ariaLabel, alignment, maxWidth, showTooltip }: TriangleTooltipProps) => {
  const [trigger, tooltip] = useTooltip()
  const { isVisible, triggerRect, id } = tooltip

  const defaultSmartAlignment =
    triggerRect && triggerRect.left < maxWidth && triggerRect.right > maxWidth && alignment === 'left'
      ? 'right'
      : triggerRect && triggerRect.right < maxWidth && triggerRect.left > maxWidth && alignment === 'right'
      ? 'left'
      : alignment

  let centered
  switch (defaultSmartAlignment) {
    case 'top':
      centered = centeredTop
      break
    case 'right':
      centered = centeredRight
      break
    case 'bottom':
      centered = centeredBottom
      break
    case 'left':
      centered = centeredLeft
      break
    default:
      centered = centeredRight
  }

  return (
    <>
      {cloneElement(children, trigger)}
      {(isVisible || showTooltip) && (
        // The Triangle
        <Portal>
          <TooltipArrow
            triggerRect={triggerRect}
            scrollY={window.scrollY}
            scrollX={window.scrollX}
            alignment={defaultSmartAlignment}
            style={{ zIndex: 102 }}
          />{' '}
        </Portal>
      )}{' '}
      <TooltipPopup
        {...tooltip}
        isVisible={showTooltip || isVisible}
        label={label}
        ariaLabel={ariaLabel}
        style={{
          background: '#1B1B1B',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          maxWidth: maxWidth + 'px',
          padding: '0 8px',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          position: 'absolute',
          fontFamily: "'Work Sans', sans-serif",
          zIndex: 102,
        }}
        position={centered}
        id={'tooltipPopup' + id}
      />
    </>
  )
}

export const Tooltip = ({ children, icon, alignment = 'right', maxWidth = 340, ariaLabel }: IProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const node = useRef()

  const handleClick = (e: MouseEvent) => {
    // @ts-ignore
    if (!node.current?.contains(e.target)) setShowTooltip(false)
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <TriangleTooltip label={children} alignment={alignment} maxWidth={maxWidth} showTooltip={showTooltip}>
      <TooltipButton
        // @ts-ignore
        ref={node}
        aria-label={ariaLabel}
        onClick={e => {
          e.preventDefault()
          setShowTooltip(true)
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {icon ? icon : <InfoIcon />}
      </TooltipButton>
    </TriangleTooltip>
  )
}

export default Tooltip
