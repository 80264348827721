import { navigate, useParams } from '@reach/router'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import HSBLogo from '../../../assets/HSB-logo.svg'
import meshifyLogo from '../../../assets/meshify-logo-grayscale.png'
import Directory from '../../../components/directory/virtual-directory'
import { NativeAppButton } from '../../../components/header/native-app-banner'
import { InlineLanguagePicker } from '../../../components/language-pickers'
import Sidepanel from '../../../components/sidepanel'
import { SkeletonLine } from '../../../components/skeleton-line'
import { AppContext } from '../../../contexts/app-context'
import AuthContext from '../../../contexts/auth-context'
import UserContext from '../../../contexts/user-context'
import API from '../../../utils/api'
import { getLogo } from '../../../utils/helpers'
import Support from '../../support'
import {
  NativeAppButtonContainer,
  SidebarContent,
  SidebarHeaderLogo,
  SidebarHeaderPoweredBy,
  SidebarHeaderPoweredByLogos,
  SidebarHeading,
  SidebarLanguagePicker,
  StyledSidebar,
} from '../dashboard.styles'
import ProfileModal from '../modals/profile'

const Sidebar = ({ showSidebar }: { showSidebar: boolean }) => {
  const handleError = useErrorHandler()
  const { foldersLoading } = useContext(AppContext)
  const { setIsAuth } = useContext(AuthContext)
  const { companyBranding, themeLoading } = useContext(UserContext)
  const { folderId }: { folderId: string } = useParams()
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [logoLoading, setLogoLoading] = useState<boolean>(!!getLogo(companyBranding))
  const logo = getLogo(companyBranding)

  useEffect(() => {
    if (!themeLoading) setLogoLoading(true)
  }, [themeLoading])

  // When the image has loaded, set `logoLoading` back to false
  const handleImageLoaded = () => {
    setLogoLoading(false)
  }

  const doLogout = async () => {
    try {
      const response = await API.get('/api/logout', {})
      if (response.status === 200) {
        localStorage.removeItem('protectToken')
        setIsAuth(false)
      }
    } catch (e) {
      handleError(e)
    }
  }

  // Change the router path when a folder is selected
  const onSelectFolder = useCallback(
    (currentId: string, pathName?: string) => {
      if (currentId !== 'undefined' && currentId !== folderId) {
        navigate(pathName || `/dashboard/${currentId}`, {
          state: { noModal: !!pathName },
        })
      }
    },
    [folderId]
  )

  return showSidebar ? (
    <>
      <StyledSidebar id="sidebar" style={{ paddingTop: !logo ? 0 : '5.2rem' }}>
        <div className="fs-exclude">
          {logo && (
            <SidebarHeaderLogo>
              {(logoLoading || themeLoading) && <SkeletonLine />}
              {!themeLoading && (
                <img
                  src={logo}
                  alt=""
                  onLoad={handleImageLoaded}
                  style={{
                    opacity: logoLoading ? 0 : 1,
                    width: logoLoading ? 0 : 'auto',
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                />
              )}
            </SidebarHeaderLogo>
          )}
        </div>
        <SidebarContent id="sidebar-scrollable">
          {foldersLoading ? (
            <SidebarHeading>
              <SkeletonLine height="20px" rounded={true} />
            </SidebarHeading>
          ) : (
            <>
              <SidebarHeading>Locations</SidebarHeading>
              <Directory
                rootPath="dashboard"
                folderId={folderId}
                onSelectFolder={onSelectFolder}
                autoSelect={true}
                containerElement="#sidebar"
              />
            </>
          )}
          {foldersLoading && (
            <>
              <SidebarHeading>
                <SkeletonLine height="20px" rounded={true} />
              </SidebarHeading>
              <SidebarHeading>
                <SkeletonLine height="20px" rounded={true} />
              </SidebarHeading>
              <SidebarHeading>
                <SkeletonLine height="20px" rounded={true} />
              </SidebarHeading>
            </>
          )}
          {!foldersLoading && (
            <>
              <SidebarHeading>
                <button onClick={e => setShowProfileModal(true)} id="navLinks">
                  Profile
                </button>
              </SidebarHeading>
              <SidebarHeading>
                <button onClick={e => setShowDialog(true)}>Support</button>
              </SidebarHeading>
              <SidebarHeading>
                <button onClick={doLogout} data-cy="logOut">
                  Log Out
                </button>
              </SidebarHeading>
            </>
          )}
        </SidebarContent>
        <NativeAppButtonContainer>
          <NativeAppButton />
        </NativeAppButtonContainer>
        <SidebarLanguagePicker>
          <InlineLanguagePicker />
        </SidebarLanguagePicker>
        <SidebarHeaderPoweredBy>
          Powered by
          <SidebarHeaderPoweredByLogos>
            <img src={HSBLogo} alt="HSB" />
            <img src={meshifyLogo} alt="Meshify" />
          </SidebarHeaderPoweredByLogos>
        </SidebarHeaderPoweredBy>
        <Sidepanel
          openModal={showDialog}
          onDismiss={() => setShowDialog(false)}
          title="Monitoring Support"
          showMobileTitleAboveDivider={true}
          titleSize="large"
          variation="support"
        >
          <Support />
        </Sidepanel>
      </StyledSidebar>
      <ProfileModal
        showProfileModal={showProfileModal}
        folderId={folderId}
        onDismiss={() => setShowProfileModal(false)}
      />
    </>
  ) : null
}

export default Sidebar
