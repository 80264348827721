import '@reach/dialog/styles.css'
import { VisuallyHidden } from '@reach/visually-hidden'
import { useEffect, useState } from 'react'

import { DesktopOnly } from '../../shared-styles/responsive.styles'
import { ChevronIcon } from '../icon'
import {
  ModalBackButton,
  ModalCloseButton,
  ModalCloseButtonContainer,
  ModalContainer,
  ModalContent,
  ModalHeaderSpacer,
  ModalTitle,
  ModalTitleAboveDivider,
} from './sidepanel.styles'

interface ModalProps {
  children: React.ReactNode
  /** Modal title, also used to label the modal for accessibility */
  title: string
  /** Boolean that controls when the modal should be open or closed (true is open)
   */
  openModal: boolean
  /** Method to invoke when the modal is dismissed by clicking out of it or with the close button (X) */
  onDismiss: () => void
  showTitle?: boolean
  showMobileTitleAboveDivider?: boolean
  titleSize?: 'small' | 'large'
  showBackButton?: boolean
  /** Text string to display for back button */
  backButtonText?: string
  /** Method to invoke when the back button is clicked. Is used when `showBackButton` set to `true` */
  onBack?: (message: string) => void
  /** Custom padding for desktop */
  padding?: string
  variation?: 'default' | 'support'
}

/**
 * For support sidepanels, use the following config:
 *
 * * `showMobileTitleAboveDivider = true`
 * * `titleSize = 'large'`
 * * `variation = 'support'`
 *
 */
export const Sidepanel = ({
  children,
  title,
  openModal,
  onDismiss,
  showTitle = true,
  showMobileTitleAboveDivider = false,
  titleSize = 'small',
  showBackButton = false,
  backButtonText = 'Back',
  onBack,
  padding,
  variation = 'default',
}: ModalProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(openModal)

  useEffect(() => {
    setShowDialog(openModal)
  }, [openModal])

  const closeDialog = () => {
    setShowDialog(false)
    onDismiss()
  }

  return (
    <ModalContainer
      allowPinchZoom={true}
      isOpen={showDialog}
      onDismiss={closeDialog}
      aria-labelledby="modalTitle"
      padding={padding}
      variation={variation}
    >
      <ModalCloseButtonContainer showBackButton={showBackButton}>
        {showBackButton && onBack && (
          <ModalBackButton
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              onBack('')
            }}
            showBackButton={showBackButton}
          >
            <ChevronIcon />
            <span>{backButtonText}</span>
          </ModalBackButton>
        )}
        {showMobileTitleAboveDivider && showTitle && !showBackButton && (
          <ModalTitleAboveDivider className="fs-exclude">{title}</ModalTitleAboveDivider>
        )}
        <ModalCloseButton onClick={closeDialog}>
          <VisuallyHidden>Close</VisuallyHidden>
          <span aria-hidden={true}>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="black"
              />
            </svg>
          </span>
        </ModalCloseButton>
      </ModalCloseButtonContainer>
      <ModalHeaderSpacer />
      {showTitle && !showMobileTitleAboveDivider && (
        <ModalTitle id="modalTitle" titleSize={titleSize} variation={variation} className="fs-exclude">
          {title}
        </ModalTitle>
      )}
      {showTitle && showMobileTitleAboveDivider && (
        <DesktopOnly>
          <ModalTitle id="modalTitle" titleSize={titleSize} variation={variation} className="fs-exclude">
            {title}
          </ModalTitle>
        </DesktopOnly>
      )}
      {!showTitle && !showMobileTitleAboveDivider && (
        <VisuallyHidden>
          <ModalTitle id="modalTitle">{title}</ModalTitle>
        </VisuallyHidden>
      )}
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  )
}

export default Sidepanel
