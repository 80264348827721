import styled from 'styled-components/macro'

export const TroubleshootingContent = styled.div`
  p {
    text-align: left;
  }
`
export const TroubleshootingSection = styled.div`
  padding: 20px 0;
  border-left: 0;
  border-right: 0;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.s}) {
    display: grid;
    grid-template-columns: 155px 1fr;
    grid-gap: 20px;
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Hr = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
`
