import styled from 'styled-components/macro'

import { Button } from '../../shared-styles/button.styles'
import { ButtonA } from '../../shared-styles/link.styles'

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;

  img {
    display: block;
    max-width: 75px;

    @media (min-width: 768px) {
      max-width: 100px;
    }
  }

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`

export const HeaderRightButtonsMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:last-child {
    margin-left: 0.8rem;
  }
`

export const HeaderRightButtonsDesktop = styled.div`
  width: 100%;
  text-align: right;
`

export const HeaderLocation = styled.div`
  margin-left: 0.8rem;
  margin-right: 1.4rem;
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export const HeaderLogo = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`

export const HeaderButton = styled.button`
  background: transparent;
  color: ${({ theme }: ThemeProps) => theme.colors.support_info};
  border: 0;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  text-transform: uppercase;
  margin-left: ${({ theme }: ThemeProps) => theme.space.l};
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
`

export const FAQHeader = styled.h3`
  text-align: center;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  margin-top: 5rem;
`

export const NativeBanner = styled.div`
  background: ${({ theme }: ThemeProps) => theme.colors.support_info};
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 2rem 0.8rem 1.6rem;
  border-radius: 0.4rem;

  @media (min-width: 992px) {
    display: flex;
  }
`

export const NativeBannerText = styled.p`
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: 1.7;
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  margin: 0 0 0 0.8rem;
`

export const NativeBannerModalContent = styled.p`
  text-align: left;
`

export const NativeBannerModalContentButton = styled(ButtonA)`
  max-width: 100%;
`

export const NativeBannerButton = styled(Button)`
  background: ${({ theme }: ThemeProps) => theme.colors.ui01};
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  margin: 0 1.5rem 0 0;
`

export const NativeBannerCloseButton = styled.button`
  background: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
  }
`

export const NativeBannerLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NativeBannerRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NativeAppButtonStyled = styled(Button)`
  margin: 0 auto;
  border-radius: 1.6rem;

  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding: 4px 8px;
    width: auto;
    text-transform: none;
    white-space: nowrap;
  }
  @media (min-width: 768px) {
    border-radius: 0.4rem;
  }
`

export const NativeAppButtonDesktop = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: inline-block;
  }
`

export const NativeAppButtonMobile = styled.div`
  display: inline-block;

  @media (min-width: 992px) {
    display: none;
  }
`
