import styled from 'styled-components/macro'

export const Notification = styled.div`
  margin: 0;
  padding: 1.8rem;
  position: relative;

  &:hover {
    background-color: ${({ theme }: ThemeProps) => theme.colors.hover_row};
    cursor: pointer;
  }

  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
    height: 1px;
    width: calc(100% - 2 * 1.8rem);
    margin-top: calc(1.8rem - 1px);
    position: absolute;
  }

  &:nth-last-child(2):after {
    display: none;
  }
`

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NotificationPriority = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0 1px 1px ${({ theme }: ThemeProps) => theme.colors.text03};
  border-radius: 50%;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};

  height: 24px;
  width: 24px;
  margin-right: 16px;
`

export const NotificationName = styled.h4`
  margin: 0;
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
`

export const NotificationNameText = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  padding-right: ${({ theme }: ThemeProps) => theme.space.s};
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};

  &:hover {
    cursor: pointer;
  }
`

export const NotificationInfo = styled.p`
  margin-bottom: 0;
  margin-top: ${({ theme }: ThemeProps) => theme.space.xxs};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
`

export const ChipLocationManager = styled.span`
  display: inline-block;
  background-color: ${({ theme }: ThemeProps) => theme.colors.accent_green};
  border-radius: 1.6rem;
  margin-right: 1.2rem;
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.xs}`};
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  white-space: nowrap;
  line-height: 1.2;

  button {
    color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
    font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  }
`

export const ChipPrimary = styled.span`
  display: inline-block;
  background-color: ${({ theme }: ThemeProps) => theme.colors.accent_blue};
  border-radius: 1.6rem;
  margin-right: 1.2rem;
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.xs}`};
  color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  white-space: nowrap;
  line-height: 1.2;

  button {
    color: ${({ theme }: ThemeProps) => theme.colors.text_inverse};
    font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  }
  svg {
    margin-right: 0.3rem;
  }
`

export const ChipAlerts = styled.span`
  margin-right: 1.2rem;

  svg {
    position: absolute;
    margin-top: -16px;
  }
`
