import UngroupedDevice from './ungrouped-device'

interface IProps {
  devices: IDeviceType[]
  setShowModal: (arg: boolean) => void
  setSelectedCardKey: (arg: number | undefined) => void
  setDevice: (arg: IDeviceType | undefined) => void
}

/** Displays ungrouped devices with cards for each device and their own modal (sidepanel) */
const UngroupedDevices = ({ devices, setShowModal, setSelectedCardKey, setDevice }: IProps) => {
  return (
    <>
      {devices.map(device => {
        return (
          <UngroupedDevice
            device={device}
            setShowModal={setShowModal}
            setSelectedCardKey={setSelectedCardKey}
            setDevice={setDevice}
            key={device.id}
          />
        )
      })}
    </>
  )
}

export default UngroupedDevices
