import styled from 'styled-components/macro'

import theme from '../../theme'

interface IProps {
  fill?: string
  /** Applies to DeviceStatusIcon only */
  stroke?: string
  /** Does not apply to LoadingIcon or ThumbsUpIcon */
  width?: number
  /** Does not apply to LoadingIcon or ThumbsUpIcon */
  height?: number
}

export const CheckIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 20}
    height={height || 20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
      fill={fill ? fill : '#47B282'}
    />
  </svg>
)

export const CheckIconNoCircle = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 18}
    height={height || 14}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M6.00016 11.1701L1.83016 7.00009L0.410156 8.41009L6.00016 14.0001L18.0002 2.00009L16.5902 0.590088L6.00016 11.1701Z"
      fill={fill ? fill : '#FFFFFF'}
    />
  </svg>
)

export const CheckIconOutline = ({ fill, width, height }: IProps) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
      fill={fill || theme.colors.support_success}
    />
    <path
      d="M14.59 5.57812L8 12.1681L5.41 9.58813L4 10.9981L8 14.9981L16 6.99812L14.59 5.57812Z"
      fill={fill || theme.colors.support_success}
    />
  </svg>
)

export const WarningIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 22}
    height={height || 19}
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z" fill={fill ? fill : '#FFCC69'} />
  </svg>
)

export const ErrorIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 20}
    height={height || 20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
      fill={fill ? fill : '#C92434'}
    />
  </svg>
)

export const ErrorIconOutline = ({ fill, width, height }: IProps) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.519 0.387695C4.98904 0.387695 0.519043 4.8577 0.519043 10.3877C0.519043 15.9177 4.98904 20.3877 10.519 20.3877C16.049 20.3877 20.519 15.9177 20.519 10.3877C20.519 4.8577 16.049 0.387695 10.519 0.387695ZM13.109 6.3877L10.519 8.9777L7.92904 6.3877L6.51904 7.7977L9.10904 10.3877L6.51904 12.9777L7.92904 14.3877L10.519 11.7977L13.109 14.3877L14.519 12.9777L11.929 10.3877L14.519 7.7977L13.109 6.3877ZM2.51904 10.3877C2.51904 14.7977 6.10904 18.3877 10.519 18.3877C14.929 18.3877 18.519 14.7977 18.519 10.3877C18.519 5.9777 14.929 2.3877 10.519 2.3877C6.10904 2.3877 2.51904 5.9777 2.51904 10.3877Z"
      fill={fill ? fill : '#C92434'}
      fillOpacity="0.38"
    />
  </svg>
)

const StyledWaitIcon = styled.svg`
  animation: spin 2s linear infinite;

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const WaitIcon = ({ fill, width, height }: IProps) => (
  <StyledWaitIcon
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.03833 20.7125 6.37738 18.6667 4.54631"
      stroke={fill ? fill : theme.colors.brand01}
      strokeWidth="3"
    />
  </StyledWaitIcon>
)

export const InfoIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M13 7H11V9H13V7ZM13 11H11V17H13V11Z" fill={fill ? fill : '#3387B2'} />
    <circle cx="12" cy="12" r="10" stroke={fill ? fill : '#3387B2'} strokeWidth="2" />
  </svg>
)

export const SolidInfoIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
      fill={fill || '#3387B2'}
    />
  </svg>
)

export const CircleIcon = ({ fill, stroke, width, height }: IProps) => (
  <svg
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M7.9987 1.33203C4.3187 1.33203 1.33203 4.3187 1.33203 7.9987C1.33203 11.6787 4.3187 14.6654 7.9987 14.6654C11.6787 14.6654 14.6654 11.6787 14.6654 7.9987C14.6654 4.3187 11.6787 1.33203 7.9987 1.33203ZM7.9987 13.332C5.05203 13.332 2.66536 10.9454 2.66536 7.9987C2.66536 5.05203 5.05203 2.66536 7.9987 2.66536C10.9454 2.66536 13.332 5.05203 13.332 7.9987C13.332 10.9454 10.9454 13.332 7.9987 13.332Z"
      fill={stroke ? stroke : '#A1A4B1'}
    />
    <path
      d="M1.33203 7.9987C1.33203 4.3187 4.3187 1.33203 7.9987 1.33203C11.6787 1.33203 14.6654 4.3187 14.6654 7.9987C14.6654 11.6787 11.6787 14.6654 7.9987 14.6654C4.3187 14.6654 1.33203 11.6787 1.33203 7.9987Z"
      fill={fill ? fill : '#47B282'}
    />
  </svg>
)

const StyledLoadingIcon = styled.svg`
  animation: spin 2s linear infinite;

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
export const LoadingIcon = ({ fill }: IProps) => (
  <StyledLoadingIcon width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <g clipPath="url(#clip0)">
      <path
        d="M39.188 57.677c14.182-3.97 22.46-18.686 18.49-32.868-3.97-14.182-18.686-22.46-32.868-18.49-14.182 3.97-22.46 18.686-18.49 32.868C8.448 46.792 13.667 52.7 20.237 55.93"
        stroke="#EBEBEB"
        strokeWidth="3"
      />
      <path
        d="M50.013 53.653a1.5 1.5 0 10-1.92-2.306l1.92 2.306zM19.26 10.289a1.5 1.5 0 00-1.52-2.586l1.52 2.586zM32 57.166c-13.9 0-25.167-11.268-25.167-25.167h-3c0 15.556 12.61 28.167 28.167 28.167v-3zm16.094-5.819a25.059 25.059 0 01-16.094 5.819v3c6.85 0 13.131-2.447 18.014-6.513l-1.92-2.306zM6.832 32c0-9.249 4.988-17.334 12.428-21.71l-1.52-2.586C9.42 12.595 3.832 21.643 3.832 32h3z"
        fill={fill || '#3387B2'}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </StyledLoadingIcon>
)

export const SmallArrowIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 10}
    height={height || 5}
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M10 5L5 -2.18557e-07L-2.18557e-07 5L10 5Z" fill={fill ? fill : '#232735'} />
  </svg>
)

export const ChevronIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 8}
    height={height || 12}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
      fill={fill ? fill : '#464646'}
    />
  </svg>
)

export const SmallChevronIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 6}
    height={height || 10}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M0.442505 8.4425L3.8775 5L0.442505 1.5575L1.5 0.5L6 5L1.5 9.5L0.442505 8.4425Z"
      fill={fill ? fill : '#464646'}
    />
  </svg>
)

export const MiniHamburgerIcon = ({ fill, width, height }: IProps) => (
  <svg width={width || 4} height={height || 16} viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
      fill={fill || '#464646'}
    />
  </svg>
)

export const HamburgerMenuIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '18'}
    height={height || '12'}
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill={fill || '#505565'} />
  </svg>
)

export const CloseIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '15'}
    height={height || '15'}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill={fill || '#232735'}
    />
  </svg>
)

export const WarningStatusIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 15}
    height={height || 13}
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M0 12.9997H14.6667L7.33333 0.333008L0 12.9997ZM8 10.9997H6.66667V9.66634H8V10.9997ZM8 8.33301H6.66667V5.66634H8V8.33301Z"
      fill={fill ? fill : '#FFCC69'}
    />
  </svg>
)

export const ErrorStatusIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 14}
    height={height || 14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M7.00004 0.333008C3.32004 0.333008 0.333374 3.31967 0.333374 6.99967C0.333374 10.6797 3.32004 13.6663 7.00004 13.6663C10.68 13.6663 13.6667 10.6797 13.6667 6.99967C13.6667 3.31967 10.68 0.333008 7.00004 0.333008ZM7.66671 10.333H6.33337V8.99967H7.66671V10.333ZM7.66671 7.66634H6.33337V3.66634H7.66671V7.66634Z"
      fill={fill ? fill : '#C92434'}
    />
  </svg>
)

export const AddContactIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 22}
    height={height || 17}
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M14 8.96377C16.21 8.96377 18 7.17914 18 4.97578C18 2.77242 16.21 0.987793 14 0.987793C11.79 0.987793 10 2.77242 10 4.97578C10 7.17914 11.79 8.96377 14 8.96377ZM5 6.96978V3.97878H3V6.96978H0V8.96377H3V11.9548H5V8.96377H8V6.96978H5ZM14 10.9578C11.33 10.9578 6 12.2937 6 14.9458V16.9397H22V14.9458C22 12.2937 16.67 10.9578 14 10.9578Z"
      fill={fill ? fill : theme.colors.brand01}
    />
  </svg>
)

export const StarIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || 12}
    height={height || 11}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M5.99984 8.04439L9.29761 10.4444L8.03539 6.56883L11.3332 4.22217H7.28873L5.99984 0.222168L4.71095 4.22217H0.666504L3.96428 6.56883L2.70206 10.4444L5.99984 8.04439Z"
      fill={fill ? fill : 'white'}
    />
  </svg>
)

export const NoReceiveAlertsIcon = ({
  fill,
  secondaryFill,
  width,
  height,
}: {
  fill?: string
  secondaryFill?: string
  width?: number
  height?: number
}) => (
  <svg
    width={width || 25}
    height={height || 24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <rect width="23.9957" height="24" rx="11.9979" fill={fill || '#464646'} />
    <path
      d="M17.3333 16.4598L9.22663 8.09317L7.51329 6.3265L6.66663 7.17317L8.53329 9.03984V9.0465C8.18663 9.7065 7.99996 10.4865 7.99996 11.3265V14.6598L6.66663 15.9932V16.6598H15.82L17.1533 17.9932L18 17.1465L17.3333 16.4598ZM12 18.6665C12.74 18.6665 13.3333 18.0732 13.3333 17.3332H10.6666C10.6666 18.0732 11.26 18.6665 12 18.6665ZM16 13.7865V11.3332C16 9.27984 14.9066 7.57317 13 7.11984V6.6665C13 6.11317 12.5533 5.6665 12 5.6665C11.4466 5.6665 11 6.11317 11 6.6665V7.11984C10.9 7.13984 10.8066 7.17317 10.72 7.19984C10.6533 7.21984 10.5866 7.2465 10.52 7.27317H10.5133C10.5066 7.27317 10.5066 7.27317 10.5 7.27984C10.3466 7.33984 10.1933 7.41317 10.0466 7.4865C10.0466 7.4865 10.04 7.4865 10.04 7.49317L16 13.7865Z"
      fill={secondaryFill || 'white'}
    />
  </svg>
)

export const BriefcaseIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '20'}
    height={height || '16'}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M18.5922 2.68781H14.1278V0.96C14.1278 0.432192 13.6956 0 13.1678 0H6.83223C6.30443 0 5.87223 0.432192 5.87223 0.96V2.68781H1.40775C0.879946 2.68781 0.447754 3.12 0.447754 3.64781V15C0.447754 15.5279 0.879946 15.96 1.40775 15.96H18.5678C19.0956 15.96 19.5278 15.5279 19.5278 15V3.64781C19.5521 3.12 19.1199 2.68781 18.5921 2.68781H18.5922ZM7.79223 1.92H12.2322V2.68781H7.79223V1.92Z"
      fill={fill || '#8B90A0'}
    />
  </svg>
)

export const ContactsIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '24'}
    height={height || '16'}
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M17.0455 6.85714C18.9318 6.85714 20.4432 5.32571 20.4432 3.42857C20.4432 1.53143 18.9318 0 17.0455 0C15.1591 0 13.6364 1.53143 13.6364 3.42857C13.6364 5.32571 15.1591 6.85714 17.0455 6.85714ZM7.95455 6.85714C9.84091 6.85714 11.3523 5.32571 11.3523 3.42857C11.3523 1.53143 9.84091 0 7.95455 0C6.06818 0 4.54545 1.53143 4.54545 3.42857C4.54545 5.32571 6.06818 6.85714 7.95455 6.85714ZM7.95455 9.14286C5.30682 9.14286 0 10.48 0 13.1429V16H15.9091V13.1429C15.9091 10.48 10.6023 9.14286 7.95455 9.14286ZM17.0455 9.14286C16.7159 9.14286 16.3409 9.16572 15.9432 9.2C17.2614 10.16 18.1818 11.4514 18.1818 13.1429V16H25V13.1429C25 10.48 19.6932 9.14286 17.0455 9.14286Z"
      fill={fill || '#8B90A0'}
    />
  </svg>
)

export const NotificationsIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '14'}
    height={height || '16'}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M7 16C7.9625 16 8.75 15.2615 8.75 14.359H5.25C5.25 15.2615 6.0375 16 7 16ZM12.25 11.0769V6.97436C12.25 4.45538 10.8237 2.34667 8.3125 1.78872V1.23077C8.3125 0.549744 7.72625 0 7 0C6.27375 0 5.6875 0.549744 5.6875 1.23077V1.78872C3.185 2.34667 1.75 4.44718 1.75 6.97436V11.0769L0 12.7179V13.5385H14V12.7179L12.25 11.0769ZM10.5 11.8974H3.5V6.97436C3.5 4.93949 4.82125 3.28205 7 3.28205C9.17875 3.28205 10.5 4.93949 10.5 6.97436V11.8974Z"
      fill={fill || '#8B90A0'}
    />
  </svg>
)

export const NoDeviceAlertsIcon = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="M24.5943 33.338H6.86783C4.43228 33.338 2.45573 31.3634 2.45573 28.9259V6.86738C2.45573 4.43183 4.43228 2.45528 6.86783 2.45528H28.9263C31.3638 2.45528 33.3384 4.43183 33.3384 6.86738V24.5939C34.1177 24.7052 34.8599 24.9318 35.5455 25.2579V6.86743C35.5455 3.21313 32.5826 0.250427 28.9265 0.250427H6.86798C3.21368 0.250427 0.250977 3.21333 0.250977 6.86743V28.9259C0.250977 32.5822 3.21388 35.5449 6.86798 35.5449H25.2585C24.9323 34.8594 24.7057 34.1172 24.5944 33.3379L24.5943 33.338Z"
      fill="#D3D4D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2405 37.75C35.2834 37.75 37.7505 34.707 37.7505 32.24C37.7505 29.1971 35.2837 26.73 32.2405 26.73C29.1975 26.73 26.7305 29.7729 26.7305 32.24C26.7305 35.2829 29.1973 37.75 32.2405 37.75Z"
      fill="#D3D4D8"
    />
  </svg>
)

export const AlertSevereIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="white"
    />
    <path
      d="M8.00004 1.33398C4.32004 1.33398 1.33337 4.32065 1.33337 8.00065C1.33337 11.6807 4.32004 14.6673 8.00004 14.6673C11.68 14.6673 14.6667 11.6807 14.6667 8.00065C14.6667 4.32065 11.68 1.33398 8.00004 1.33398ZM8.66671 11.334H7.33337V10.0007H8.66671V11.334ZM8.66671 8.66732H7.33337V4.66732H8.66671V8.66732Z"
      fill={fill || '#C92434'}
    />
  </svg>
)

export const AlertWarningIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '17'}
    height={height || '17'}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M1.03472 13.6801C0.489246 14.6796 1.2127 15.8986 2.35142 15.8986H14.3149C15.4536 15.8986 16.1771 14.6796 15.6316 13.6801L9.64987 2.71865C9.08127 1.6767 7.58507 1.6767 7.01647 2.71865L1.03472 13.6801ZM8.53014 12.6803H8.1362V12.2015H8.53014V12.6803ZM8.53014 9.72266H8.1362V7.76498H8.53014V9.72266Z"
      fill={fill || '#FFA83C'}
      stroke="white"
    />
  </svg>
)

export const AlertConnectionErrorIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="white"
    />
    <path
      d="M7.99992 1.33398C4.31325 1.33398 1.33325 4.31398 1.33325 8.00065C1.33325 11.6873 4.31325 14.6673 7.99992 14.6673C11.6866 14.6673 14.6666 11.6873 14.6666 8.00065C14.6666 4.31398 11.6866 1.33398 7.99992 1.33398ZM11.3333 10.394L10.3933 11.334L7.99992 8.94065L5.60659 11.334L4.66659 10.394L7.05992 8.00065L4.66659 5.60732L5.60659 4.66732L7.99992 7.06065L10.3933 4.66732L11.3333 5.60732L8.93992 8.00065L11.3333 10.394Z"
      fill={fill || '#C92434'}
    />
  </svg>
)

export const SearchIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
      fill={fill || '#8B90A0'}
    />
  </svg>
)

export const MapPinIcon = ({ fill, width, height }: IProps) => (
  <svg width={width || '14'} height={height || '20'} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
      fill={fill || '#232735'}
    />
  </svg>
)

export const HelpIcon = ({ fill, width, height }: IProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
      fill={fill || '#8B90A0'}
    />
  </svg>
)

export const GlobeIcon = ({ fill, width, height }: IProps) => (
  <svg width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99325 0.333496C3.31325 0.333496 0.333252 3.32016 0.333252 7.00016C0.333252 10.6802 3.31325 13.6668 6.99325 13.6668C10.6799 13.6668 13.6666 10.6802 13.6666 7.00016C13.6666 3.32016 10.6799 0.333496 6.99325 0.333496ZM11.6133 4.3335H9.64659C9.43325 3.50016 9.12659 2.70016 8.72659 1.96016C9.95325 2.38016 10.9733 3.2335 11.6133 4.3335ZM6.99992 1.6935C7.55325 2.4935 7.98659 3.38016 8.27325 4.3335H5.72659C6.01325 3.38016 6.44659 2.4935 6.99992 1.6935ZM1.83992 8.3335C1.73325 7.90683 1.66659 7.46016 1.66659 7.00016C1.66659 6.54016 1.73325 6.0935 1.83992 5.66683H4.09325C4.03992 6.10683 3.99992 6.54683 3.99992 7.00016C3.99992 7.4535 4.03992 7.8935 4.09325 8.3335H1.83992ZM2.38659 9.66683H4.35325C4.56659 10.5002 4.87325 11.3002 5.27325 12.0402C4.04659 11.6202 3.02659 10.7735 2.38659 9.66683ZM4.35325 4.3335H2.38659C3.02659 3.22683 4.04659 2.38016 5.27325 1.96016C4.87325 2.70016 4.56659 3.50016 4.35325 4.3335ZM6.99992 12.3068C6.44659 11.5068 6.01325 10.6202 5.72659 9.66683H8.27325C7.98659 10.6202 7.55325 11.5068 6.99992 12.3068ZM8.55992 8.3335H5.43992C5.37992 7.8935 5.33325 7.4535 5.33325 7.00016C5.33325 6.54683 5.37992 6.10016 5.43992 5.66683H8.55992C8.61992 6.10016 8.66659 6.54683 8.66659 7.00016C8.66659 7.4535 8.61992 7.8935 8.55992 8.3335ZM8.72659 12.0402C9.12659 11.3002 9.43325 10.5002 9.64659 9.66683H11.6133C10.9733 10.7668 9.95325 11.6202 8.72659 12.0402ZM9.90659 8.3335C9.95992 7.8935 9.99992 7.4535 9.99992 7.00016C9.99992 6.54683 9.95992 6.10683 9.90659 5.66683H12.1599C12.2666 6.0935 12.3333 6.54016 12.3333 7.00016C12.3333 7.46016 12.2666 7.90683 12.1599 8.3335H9.90659Z"
      fill={fill || theme.colors.ui06}
    />
  </svg>
)

export const AddFolderIcon = ({ fill, width, height }: IProps) => (
  <svg width={width || 20} height={height || 17} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 2.00195H10L8 0.00195312H2C0.89 0.00195312 0.00999999 0.891953 0.00999999 2.00195L0 14.002C0 15.112 0.89 16.002 2 16.002H18C19.11 16.002 20 15.112 20 14.002V4.00195C20 2.89195 19.11 2.00195 18 2.00195ZM17 10.002H14V13.002H12V10.002H9V8.00195H12V5.00195H14V8.00195H17V10.002Z"
      fill={fill || theme.colors.brand01}
    />
  </svg>
)

export const BellOff = ({ fill, width, height }: IProps) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M7.99984 14.6667C8.73317 14.6667 9.33317 14.0667 9.33317 13.3334H6.6665C6.6665 14.0667 7.2665 14.6667 7.99984 14.6667ZM7.99984 4.33341C9.65984 4.33341 10.6665 5.68008 10.6665 7.33342V7.40008L11.9998 8.73342V7.33342C11.9998 5.28675 10.9132 3.57341 8.99984 3.12008V2.66675C8.99984 2.11341 8.55317 1.66675 7.99984 1.66675C7.4465 1.66675 6.99984 2.11341 6.99984 2.66675V3.12008C6.83984 3.16008 6.6865 3.22008 6.53984 3.27341L7.63317 4.36675C7.75317 4.35342 7.87317 4.33341 7.99984 4.33341ZM3.6065 2.23341L2.6665 3.17341L4.53984 5.04675C4.19317 5.71342 3.99984 6.49341 3.99984 7.33342V10.6667L2.6665 12.0001V12.6667H12.1598L13.3198 13.8267L14.2598 12.8867L3.6065 2.23341ZM10.6665 11.3334H5.33317V7.33342C5.33317 6.88008 5.41317 6.45341 5.55984 6.06675L10.6665 11.1734V11.3334Z"
        fill={fill || theme.colors.brand01}
        fillOpacity="0.6"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width || 16} height={height || 16} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const ServiceDeskIcon = ({ fill, width, height }: IProps) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M21 12.22C21 6.73 16.74 3 12 3C7.31 3 3 6.65 3 12.28C2.4 12.62 2 13.26 2 14V16C2 17.1 2.9 18 4 18C4.55 18 5 17.55 5 17V12.19C5 8.36 7.95 5.01 11.78 4.9C15.74 4.78 19 7.96 19 11.9V19H12C11.45 19 11 19.45 11 20C11 20.55 11.45 21 12 21H19C20.1 21 21 20.1 21 19V17.78C21.59 17.47 22 16.86 22 16.14V13.84C22 13.14 21.59 12.53 21 12.22Z"
        fill={fill || theme.colors.brand01}
      />
      <path
        d="M9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14Z"
        fill={fill || theme.colors.brand01}
      />
      <path
        d="M15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14Z"
        fill={fill || theme.colors.brand01}
      />
      <path
        d="M18.0005 11.03C17.5205 8.18 15.0405 6 12.0505 6C9.02046 6 5.76046 8.51 6.02046 12.45C8.49046 11.44 10.3505 9.24 10.8805 6.56C12.1905 9.19 14.8805 11 18.0005 11.03Z"
        fill={fill || theme.colors.brand01}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width || 24} height={height || 24} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Icon = ({ render: View, fill }: { render: any; fill?: string }) => {
  return <View fill={fill} />
}

export default Icon
