import styled from 'styled-components/macro'

import { MobileOnly } from '../../shared-styles/responsive.styles'

export const PageLayout = styled.div`
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: grid;
    grid-template-columns: 37.7rem 1fr;
  }
  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    display: grid;
    grid-template-columns: 0rem 1fr;
  }
`

export const LocationDetails = styled.div`
  ${({ theme }: ThemeProps) => theme.elevation.elevation02}
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  margin-bottom: ${({ theme }: ThemeProps) => theme.space.s};
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: 1.4rem;
  border-radius: 0.4rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    margin: 0;
    margin-bottom: ${({ theme }: ThemeProps) => theme.space.m};
    margin-top: 3.2rem;
    border-radius: 0;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    margin-top: 0;
  }
`

export const LocationDetailsHeaderRow = styled.div`
  padding-top: ${({ theme }: ThemeProps) => theme.space.l};
  margin-left: ${({ theme }: ThemeProps) => theme.space.s};
  margin-right: ${({ theme }: ThemeProps) => theme.space.s};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: flex;
    justify-content: space-between;
    margin-left: ${({ theme }: ThemeProps) => theme.space.m};
    margin-right: ${({ theme }: ThemeProps) => theme.space.m};
  }

  a {
    margin: 0;
  }
`

export const LocationDetailsName = styled.h1`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.s};
  margin: 0;
  padding: 0;
  margin-bottom: ${({ theme }: ThemeProps) => theme.space.s};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xl};
  }
`

export const LocationDetailsAddress = styled.div`
  margin: 0;
  margin-left: ${({ theme }: ThemeProps) => theme.space.s};
  margin-right: ${({ theme }: ThemeProps) => theme.space.s};
  margin-bottom: ${({ theme }: ThemeProps) => theme.space.s};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    margin-left: ${({ theme }: ThemeProps) => theme.space.m};
    margin-right: ${({ theme }: ThemeProps) => theme.space.m};
  }
`

export const LocationMobileButtonRow = styled(MobileOnly)`
  margin: ${({ theme }: ThemeProps) => `1.2rem ${theme.space.s}`};

  a {
    margin: 0;
    max-width: none;
  }
`

export const LocationActionRow = styled.div`
  border-top: 0.1rem solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    padding-left: ${({ theme }: ThemeProps) => theme.space.m};
    padding-right: ${({ theme }: ThemeProps) => theme.space.m};
  }
`

export const LocationActionLeftButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2 icons */
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: flex;
    width: auto;
  }
`

export const LocationActionButton = styled.button`
  background: none;
  border: 0;
  border-right: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.s}`};
  margin: ${({ theme }: ThemeProps) => theme.space.xs} 0;
  position: relative;
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    justify-content: flex-start;

    &:last-child {
      border: 0;
      padding-right: 0;
    }
  }

  &:first-child {
    @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
      padding-left: 0;
    }
  }
  &:hover {
    cursor: pointer;
  }
  svg,
  span {
    display: block;
  }
  svg {
    margin-right: 0;

    @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
      margin-right: 1rem;
    }
  }
`

export const LocationActionButtonLabel = styled.span`
  visibility: hidden;
  width: 0;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    visibility: visible;
    width: auto;
  }
`

export const LocationActionIconContainer = styled.div`
  position: relative;
`

export const AlertCount = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: -0.6rem;
  left: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: ThemeProps) => props.theme.colors.support_error};
  border-radius: 10rem;
  color: ${(props: ThemeProps) => props.theme.colors.text_inverse};
  font-size: ${(props: ThemeProps) => props.theme.fontSizes.xs};
  font-weight: ${(props: ThemeProps) => props.theme.fontWeights.regular};
  min-height: 1.6rem;
  min-width: 1.6rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    top: -0.2rem;
  }
`

export const StyledSidebar = styled.aside`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  box-sizing: border-box;
  min-height: 100vh;
  padding: 5.2rem 2.5rem 5.2rem 2.5rem;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    position: relative; /* Need this for box-shadow to work */
  }
`

export const SidebarContent = styled.div`
  flex-grow: 1;
`

export const StyledSidebarResponsive = styled.aside`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  box-sizing: border-box;
  min-height: 100vh;
  padding: 1.6rem;
  padding-bottom: 1.3rem;

  h2 {
    margin-top: 0;
  }
`

export const SidebarHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15.6rem;
`

export const SidebarLanguagePicker = styled.div`
  margin-bottom: 14px;
`

export const SidebarHeaderPoweredBy = styled.div`
  box-sizing: border-box;
  display: none;
  justify-content: space-between;
  align-items: flex-end;
  margin: 2.4rem 0 0 0;
  height: 6rem;
  width: 37.7rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  filter: drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1));
  padding: 0 1.6rem 1rem 1.6rem;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  color: ${({ theme }: ThemeProps) => theme.colors.text03};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    display: flex;
  }
`

export const SidebarHeaderPoweredByLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    display: block;
    margin: 0 1.25rem;
  }
`

export const SidePanelFooterLogos = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.6rem;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 3.2rem);
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  color: ${({ theme }: ThemeProps) => theme.colors.text03};

  img {
    display: block;
    max-height: 1.8rem;
    padding-left: 1.6rem;
  }
`

export const MobileSidebarScrollable = styled.div`
  overflow-y: auto;
  height: calc(100vh - 105px);
  margin-left: -1.6rem;
  margin-right: -1.6rem;

  > h2 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
`

export const SidebarHeading = styled.h2`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.s};
  line-height: ${({ theme }: ThemeProps) => theme.lineHeights.l};
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: -0.2rem;

  button {
    background: transparent;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }
`

export const ContentSection = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  padding: 2rem;
  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    margin-top: 2rem;
  }
`

export const MainContentSection = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui02};
  position: relative;
`

export const DeviceGrid = styled.div`
  margin-left: -2rem;
  margin-right: -2rem;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${({ theme }: ThemeProps) => `${theme.space.s} ${theme.space.m}`};
    margin-left: 0;
    margin-right: 0;
  }

  button {
    margin-bottom: ${({ theme }: ThemeProps) => theme.space.xs};
    padding: ${({ theme }: ThemeProps) => theme.space.s};

    @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
      margin-bottom: ${({ theme }: ThemeProps) => theme.space.s};
      padding: 1rem;
    }
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }: ThemeProps) => theme.space.s};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    margin-bottom: ${({ theme }: ThemeProps) => theme.space.l};
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    flex-direction: column;
  }
  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.xl}) {
    flex-direction: row;
  }
`

export const FiltersDropdowns = styled.div`
  display: flex;

  [data-reach-menu-button] {
    padding: 0;
  }
  > div:first-child {
    margin-right: 2rem;
  }
`

export const FilterDisplay = styled.div`
  [data-reach-menu-button] {
    color: ${({ theme }: ThemeProps) => theme.colors.text02};
    font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};

    svg {
      display: inline-block;
      margin-left: 1rem;
      transform: rotate(180deg);
      vertical-align: 2px;
    }
  }
  [data-reach-menu-item] {
    svg {
      float: right;
      margin-top: 0.4rem;
    }
  }
`

export const FilterDropdownCategory = styled.div`
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  padding: ${({ theme }: ThemeProps) => `${theme.space.xxs} ${theme.space.s}`};
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};

  &:first-child {
    border-top: 0;
    margin-top: -1rem;
  }
`

export const FilterText = styled.div`
  display: flex;
  align-items: center;
`

export const FilterLabel = styled.span`
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.regular};
  margin-right: 0.5rem;
`

export const FilterSelection = styled.span`
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin-right: 0.8rem;
`

export const FilterIcon = styled.div`
  transform: rotate(180deg);

  svg {
    display: block;
  }
`

export const MobileSearchContainer = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.hover_row};
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: -1.6rem;
  margin-bottom: 1.6rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;

  // DashboardDeviceSearchWrapper
  > div {
    width: 100%;
    margin-right: 1.3rem;

    // Search input
    > div > input {
      width: calc(100% - 4.5rem);
    }

    // SearchResultPane
    > div:nth-child(2) {
      width: calc(100% - 9.5rem);
    }
  }
  // Cancel button
  button {
    margin-top: 0;
    padding: 0;
    text-transform: unset;
  }
`

export const VanishingNavBar = styled.div`
  box-sizing: border-box;
  z-index: 100;
  width: 100vw;
  height: 5rem;
  margin-bottom: 1.6rem;
  padding-left: 1.6rem;
  padding-top: 1rem;
  margin-top: 0px;
  ${({ theme }: ThemeProps) => theme.elevation.elevation02};
  position: fixed;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    display: none;
  }
`

export const MenuIconButtonWrapper = styled.span`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: flex-start;
  align-items: center;
  justify-content: center;
`

export const MenuLogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-right: 34px;

  img {
    height: 3.2rem;
  }
`

export const MenuNativeButtonWrapper = styled.div`
  margin-right: 1.6rem;
  text-align: right;

  @media (min-width: 768px) {
    margin-top: -0.4rem;
  }
`

export const VanishingSideBarWrapper = styled.div`
  z-index: 300;
  width: 80vw;
  height: 100vh;
  min-height: 70%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui01};

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.l}) {
    display: none;
  }
`

export const VanishingSideBarWrapperBackdrop = styled.div`
  z-index: 200;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
`

export const TBar = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 4rem;
  width: 100%;
  padding-left: 1.6rem;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  align-items: center;
`

export const TBarCloseButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-top: 0.5rem;

  @media (min-width: 992px) {
    margin-top: -15px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const TBarBackButton = styled(TBarCloseButton)`
  display: flex;
  align-items: center;
  margin-top: 0;
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  line-height: 1.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  // Icon container
  > div:first-child {
    transform: rotate(180deg);
  }
  // Back text
  > div:nth-child(2) {
    padding-left: 1.1rem;
    padding-top: 0.3rem;
  }
`

export const ModalHeader = styled.h3`
  text-align: left;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
  width: 100%;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.m}) {
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xl};
  }
`

export const NoDevicesContainer = styled.div`
  min-height: calc(100vh - 40rem);
  padding-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > div {
    max-width: 46rem;
  }
  p {
    color: ${({ theme }: ThemeProps) => theme.colors.text03};
    font-size: ${({ theme }: ThemeProps) => theme.fontSizes.default};
    line-height: 2;
  }
`

export const NativeAppButtonContainer = styled.div`
  margin: 0 auto 1.6rem auto;
`
