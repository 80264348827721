const getUseCaseMatrix = () =>
  ({
    gateway: 'Gateway',
    'power-outage': 'Power Outage',
    ambient: 'Room Temperature',
    refrigerator: 'Refrigerator',
    freezer: 'Freezer',
    'cold-storage': 'Cold Storage',
    'fine-wine': 'Fine Wine',
    'pipe-freeze': 'Pipe Freeze',
    water: 'Water',
    'boiler-blowdown': 'Boiler Blowdown',
    'fire-pump': 'Fire Pump',
    'leak-freeze': 'Leak & Freeze',
    'server-room-monitoring': 'Server Room Monitoring',
    'water-shutoff': 'Water Shutoff',
    '': 'Unknown Use Case',
  } as Record<DeviceUseCaseType | '', string>)

export const getUseCase = (name: DeviceUseCaseType | ''): UseCaseType => {
  return {
    name,
    vanity: getUseCaseMatrix()[name],
  }
}

export default getUseCaseMatrix
