const BoilerBlowdownIcon = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.95301 11.5107H8.56701C8.25939 11.5107 8.01001 11.7601 8.01001 12.0677V15.3137C8.01001 15.6214 8.25939 15.8707 8.56701 15.8707H8.95301C9.26063 15.8707 9.51001 15.6214 9.51001 15.3137V12.0677C9.51001 11.7601 9.26063 11.5107 8.95301 11.5107Z"
        fill={color}
      />
      <path
        d="M12.193 11.5107H11.807C11.4994 11.5107 11.25 11.7601 11.25 12.0677V15.3137C11.25 15.6214 11.4994 15.8707 11.807 15.8707H12.193C12.5006 15.8707 12.75 15.6214 12.75 15.3137V12.0677C12.75 11.7601 12.5006 11.5107 12.193 11.5107Z"
        fill={color}
      />
      <path
        d="M15.433 11.5107H15.047C14.7394 11.5107 14.49 11.7601 14.49 12.0677V15.3137C14.49 15.6214 14.7394 15.8707 15.047 15.8707H15.433C15.7406 15.8707 15.99 15.6214 15.99 15.3137V12.0677C15.99 11.7601 15.7406 11.5107 15.433 11.5107Z"
        fill={color}
      />
      <path
        d="M9.99799 17.9199C10.4122 17.9199 10.748 17.5841 10.748 17.1699C10.748 16.7557 10.4122 16.4199 9.99799 16.4199C9.58377 16.4199 9.24799 16.7557 9.24799 17.1699C9.24799 17.5841 9.58377 17.9199 9.99799 17.9199Z"
        fill={color}
      />
      <path
        d="M14.002 17.9199C14.4162 17.9199 14.752 17.5841 14.752 17.1699C14.752 16.7557 14.4162 16.4199 14.002 16.4199C13.5878 16.4199 13.252 16.7557 13.252 17.1699C13.252 17.5841 13.5878 17.9199 14.002 17.9199Z"
        fill={color}
      />
      <path
        d="M12 17.9199C12.4142 17.9199 12.75 17.5841 12.75 17.1699C12.75 16.7557 12.4142 16.4199 12 16.4199C11.5858 16.4199 11.25 16.7557 11.25 17.1699C11.25 17.5841 11.5858 17.9199 12 17.9199Z"
        fill={color}
      />
      <path
        d="M11.715 10.4502C12.2462 10.47 12.7675 10.3027 13.1878 9.97734C13.6082 9.65201 13.901 9.18935 14.015 8.67015C14.277 7.29415 13.602 6.36215 12.954 5.37015C12.227 4.25415 11.867 3.88715 11.673 4.47815C11.673 4.47815 12.003 7.07815 11.273 7.07815C10.543 7.07815 11.115 5.96915 10.639 6.06415C10.163 6.15915 9.59998 8.27715 10.1 9.48715C10.2566 9.77898 10.4896 10.0227 10.7741 10.1924C11.0585 10.362 11.3838 10.4511 11.715 10.4502Z"
        fill={color}
      />
      <path
        d="M4.5 2V20.5H6.011V22H7.511V20.5H16.5V22H18V20.5H19.5V2H4.5ZM18 18.945H6.005V3.467H18V18.945Z"
        fill={color}
      />
    </svg>
  )
}

export default BoilerBlowdownIcon
